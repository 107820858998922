import { plannedValueModelCode } from "api/fixed/ControllableResource/AccountingModel";
import { ControllableResource } from "api/fixed/ControllableResource/ControllableResource";
import { EnergySource, fluctuactingCodes } from "api/fixed/ControllableResource/EnergySource";
import TransitionToPlannedValueModelStatus from "api/fixed/ControllableResource/TransitionToPlannedValueModelStatus";
import { TechnicalResource } from "api/fixed/TechnicalResource/TechnicalResource";

export const isSSE = (tr: TechnicalResource): boolean => tr.technicalResourceType?.code === "SSE";
export const isEEG = (resource: ControllableResource): boolean => resource.compensationType?.code === "Z01";
export const isEmergencyGenerator = (resource: ControllableResource): boolean => resource.energySource?.code === "Z02";    
export const isFluctuatingCR = (energySource: EnergySource): boolean => energySource == null || fluctuactingCodes.includes(energySource?.code || "");
export const canChangeIntoPlannedValueModel = (resource: ControllableResource, technicalResources: TechnicalResource[]): {valid: boolean, error: string} => {
    if (resource.accountingModel?.code === plannedValueModelCode) {
        let canChange = true;
        const isFluctuating = isFluctuatingCR(resource.energySource!);
        canChange =
            !isFluctuating ||
            (isFluctuating &&
                resource?.transitionToPlannedValueModelStatus === TransitionToPlannedValueModelStatus.Accepted);

        if (!canChange)
            return { valid: false, error: "validation:controllable_resource_details.planned_value_model_invalid" };

        if (resource.technicalResourceIds == null || resource.technicalResourceIds.length === 0)
            return {
                valid: false,
                error: "validation:controllable_resource_details.planned_value_model_wrong_billing_model",
            };

        // loop through tr and check billingModel, can only change if non is in z01 and only if it has trs
        resource.technicalResourceIds?.forEach((trId) => {
            const technicalResource = technicalResources.find((tr) => tr.inventoryItemId === trId);
            canChange = canChange && technicalResource?.billingModel?.code !== "Z01";
        });

        return {
            valid: canChange,
            error: canChange
                ? ""
                : "validation:controllable_resource_details.planned_value_model_wrong_billing_model",
        };
    }
    return { valid: true, error: "" };
};
