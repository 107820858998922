import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";

interface Props {
    open: boolean
    handleClose: () => void
}

const ViewErrorsDialog = ({ open, handleClose }: Props): ReactElement => {
    const { t } = useTranslation('tables');
    const { messageErrors } = useAppSelector((state) => state.appState)

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{t('errors_dialog.title')}</DialogTitle>
            <DialogContent>
                <List>
                    {messageErrors.map((msg) => <ListItem key={msg}>
                        <ListItemText primary={msg} sx={{ whiteSpace: "pre-wrap" }}/>
                    </ListItem>)}
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default ViewErrorsDialog
