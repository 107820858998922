import React, { ReactElement, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { LoadingComponent, useLocalStorage } from "ndr-designsystem";
import { isEqual } from "lodash";
import TimeSeriesGrid from "./Grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ToggleViewButton } from "../ToggleViewButton";
import useStyles from "../styles";
import TimeSeriesChart from "./Chart";
import { allFlexibilityConstraintsSelector, currentFlexibilityConstraintSelector } from "./store/slice";
import { buildFlexibilityConstraintsState, resetFlexibilityConstraintsState, } from "./store/thunks";
import Navigation from "../Navigation";

export type TimeSeriesViewType = "grid" | "chart"

interface RouteParams {
    controllableId: string,
    seriesId: string
}

const DataView = (): ReactElement => {
    const { controllableId, seriesId } = useParams<RouteParams>();
    const { headerHeight } = useAppSelector(state => state.appState)
    const [currentlyViewing, setCurrentlyViewing] = useLocalStorage<TimeSeriesViewType>("network_constraints.view", {
        pollingInterval: -1,
        initialValue: "chart"
    })
    const history = useHistory();
    const { currentResource: controllableResource } = useAppSelector(state => state.controllableResources)
    const allFlexibilityConstraints = useAppSelector(allFlexibilityConstraintsSelector, (left, right) => isEqual(left, right))
    const currentFlexibility = useAppSelector(currentFlexibilityConstraintSelector, (left, right) => left?.internalID === right?.internalID);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch(buildFlexibilityConstraintsState({ controllableId, seriesId, force: false }))
        return () => dispatch(resetFlexibilityConstraintsState())
        // we need to trigger this only when technicalId gets available (at the same time with controllableId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seriesId])

    const Toolbar = useCallback((): ReactElement => <div className={classes.toolbar}>
        <ToggleViewButton view={currentlyViewing} onToggleView={setCurrentlyViewing}/>
    </div>, [currentlyViewing, classes, setCurrentlyViewing]);

    if (currentFlexibility === undefined) return <LoadingComponent/>

    return (
        <Box display="flex" height={`calc(100vh - ${headerHeight + 160}px)`} flexDirection="column">
            <Navigation
                allElements={allFlexibilityConstraints ?? []} currentElement={currentFlexibility}
                elementKey="internalID"
                onNextClick={e => {
                    history.push(`/controllable/${controllableResource?.externalID}/network-constraints/${e.internalID}`)
                }}
                onPreviousClick={e => {
                    history.push(`/controllable/${controllableResource?.externalID}/network-constraints/${e.internalID}`)
                }}
            />
            <Toolbar/>
            {currentlyViewing === "grid" ?
                <TimeSeriesGrid/> :
                <TimeSeriesChart/>
            }
        </Box>
    )

}

export default DataView;