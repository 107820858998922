import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import de from "locales/data/common.de.json";
import en from "locales/data/common.en.json";
import tables from "locales/data/tables"
import enValidation from "locales/data/validation.en.json";
import deValidation from "locales/data/validation.de.json";
import enExcelImport from "locales/data/excelImport.en.json";
import deExcelImport from "locales/data/excelImport.de.json";
import enTimeseries from "locales/data/timeseries.en.json";
import deTimeseries from "locales/data/timeseries.de.json";
import apiDe from "locales/data/api.de.json";
import apiEn from "locales/data/api.en.json";
import { fallbackLanguage, Languages } from "locales";
import Config from "./app-config";

if (!Config.language) {
    i18n.use(LanguageDetector);
}

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: fallbackLanguage,
        supportedLngs: Languages,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        resources: {
            en: {
                common: en,
                tables: tables("en"),
                validation: enValidation,
                excelImport: enExcelImport,
                timeSeries: enTimeseries,
                api: apiEn
            },
            "en-gb": {
                common: en,
                tables: tables("en-gb"),
                validation: enValidation,
                excelImport: enExcelImport,
                timeSeries: enTimeseries,
                api: apiEn
            },
            de: {
                common: de,
                tables: tables("de"),
                validation: deValidation,
                excelImport: deExcelImport,
                timeSeries: deTimeseries,
                api: apiDe
            },
        },
    });

export default i18n;
