import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, ButtonGroup, Divider, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Pie } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { DateTime } from "luxon";
import { CheckCircle } from "@mui/icons-material";
import { LoadingComponent } from "ndr-designsystem";
import { useAppSelector } from "../../app/hooks";
import { tasksOverviewSelector } from "./store/slice";
import TaskStatus from "../../api/fixed/Task/TaskStatus";

const TasksOverview = ({ onPeriodChange }: { onPeriodChange: (days: 1 | 7 | 30) => void }): ReactElement => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const [days, setDays] = useState<1 | 7 | 30>(1);

    useEffect(() => {
        onPeriodChange(days);
    }, [days, onPeriodChange])

    const tasksOverview = useAppSelector(tasksOverviewSelector,
        (left, right) => left?.total === right?.total);

    const getChartOptions = useCallback(() => ({
        responsive: true,
        maintainAspectRatio: false,
        circumference: 180,
        rotation: -90,
        onClick: (event: any, elements: any) => {
            if (elements.length > 0) {
                switch (elements[0].index) {
                    case 0:
                        history.push({
                            pathname: "/tasks",
                            search: qs.stringify({
                                status: TaskStatus.Failed,
                                startedAt: [DateTime.now().minus({ days }).startOf('day').toISO(), "afterOrEqual"],
                                completedAt: [DateTime.now().endOf('day').toISO(), "beforeOrEqual"]
                            }, { arrayFormat: "comma", encode: false })
                        });
                        break;
                    case 1:
                        history.push({
                            pathname: "/tasks",
                            search: qs.stringify({
                                status: TaskStatus.Succeeded,
                                startedAt: [DateTime.now().minus({ days }).startOf('day').toISO(), "afterOrEqual"],
                                completedAt: [DateTime.now().endOf('day').toISO(), "beforeOrEqual"]
                            }, { arrayFormat: "comma", encode: false })
                        });
                        break;
                    default:
                }
            }
        }
    }), [history, days]);

    const getChartData = useMemo(() => ({
        labels: [t("dashboard.tasks_overview.failed"), t("dashboard.tasks_overview.success")],
        datasets: [{
            data: [tasksOverview?.failed.length ?? 0, tasksOverview?.success.length ?? 0],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(18,229,81)',
            ],
        }]
    }), [tasksOverview, t])

    return <Paper elevation={1} sx={{
        height: "100%",
        width: "100%", display: "flex", flexDirection: "column", alignItems: "center",
        justifyContent: "space-between",

    }}>
        <Typography gutterBottom variant="h6" component="div" sx={{ height: 30 }} padding={1}>
            {t('dashboard.tasks_overview.title')}
        </Typography>
        <Divider flexItem/>
        <ButtonGroup fullWidth sx={{ marginTop: 1 }}>
            <Button sx={{ color: days === 1 ? "white" : "black" }} variant={days === 1 ? "contained" : "outlined"}
                    onClick={() => setDays(1)}>
                1 day
            </Button>
            <Button sx={{ color: days === 7 ? "white" : "black" }} variant={days === 7 ? "contained" : "outlined"}
                    onClick={() => setDays(7)}>
                7 days
            </Button>
            <Button sx={{ color: days === 30 ? "white" : "black" }} variant={days === 30 ? "contained" : "outlined"}
                    onClick={() => setDays(30)}>
                30 days
            </Button>
        </ButtonGroup>
        {!tasksOverview &&
            <Box position="relative" width="100%" height="100%">
                <LoadingComponent/>
            </Box>
        }
        {tasksOverview &&
            <Box height="100%" width="100%">
                {tasksOverview.total > 0 &&
                    <Pie
                        // @ts-ignore
                        type="doughnut"
                        // @ts-ignore
                        data={getChartData}
                        // @ts-ignore
                        options={getChartOptions()}
                    />
                }
                {tasksOverview.total === 0 &&
                    <Box width="100%" display="flex" height="100%" justifyContent="center" flexDirection="column"
                         alignItems="center">
                        <CheckCircle sx={{ fontSize: 100 }} color="primary"/>
                        <Typography variant="body2">
                            {t('no_tasks', { days })}
                        </Typography>
                    </Box>
                }
            </Box>
        }
    </Paper>
}

export default TasksOverview;
