import React, { ReactElement, useMemo } from "react";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridField } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import EmptyWhileEditingCell from "../../../Components/EmptyWhileEditingGridCell";
import DateCell from "../../../Components/DateCell";
import { currentDowntimeSelector } from "./store/slice";
import DataPoint from "../../../api/fixed/Downtime/DataPoint";

const TimeSeriesGrid = (): ReactElement => {
    const currentDowntimes = useAppSelector(currentDowntimeSelector, (left, right) => left?.id === right?.id);
    const internationalization = useInternationalization();
    const { t } = useTranslation("tables");

    const MWCell = (props: GridCellProps): ReactElement => {
        const { dataItem }: { dataItem: DataPoint } = props;

        return (
            <EmptyWhileEditingCell
                {...props}
                isEditing={false}
                defaultValue={dataItem && `${internationalization.formatNumber(dataItem.value, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })} MW`}
            />
        );
    };

    const getGridFields = useMemo(() => ([
        {
            name: "timestamp",
            title: t("timeSeries.timestamp"),
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="timestamp"
                displayFormat={DateTime.TIME_SIMPLE}
            />
        },
        {
            name: "value",
            title: t("timeSeries.value"),
            customCell: MWCell
        }
        // we want to re-render cells only on internationalization change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [internationalization]);

    return <GridField
        className="timeSeries_2"
        noRecordsText={t("no_records_text")}
        gridFields={getGridFields}
        defaultOpen
        dataItemKey="timestamp"
        take={10}
        values={currentDowntimes?.data.dataPoints}
    />
}

export default TimeSeriesGrid;
