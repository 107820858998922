import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AccountingGroupBalancingSchedule } from "api/fixed/AccountingGroupBalancingSchedule/AccountingGroupBalancingSchedule";
import { ControllableResource } from "api/fixed/AccountingGroupBalancingSchedule/ControllableResource";
import { RequestingGridOperator } from "api/fixed/AccountingGroupBalancingSchedule/RequestingGridOperator";
import { MarketPartner } from "api/fixed/AccountingGroupBalancingSchedule/MarketPartner";

export interface AccountingGroupBalancingSchedulesState {
    allAccountingGroups: AccountingGroupBalancingSchedule[] | undefined;
    currentAccountingGroup: AccountingGroupBalancingSchedule | undefined | null;
}

const initialState: AccountingGroupBalancingSchedulesState = {
    allAccountingGroups: undefined,
    currentAccountingGroup: undefined,
};

export const accountingGroupBalancingSchedulesSlice = createSlice({
    name: "AccountingGroupBalancingSchedules",
    initialState,
    reducers: {
        setAllAccountingGroupBalancingSchedules: (
            state,
            action: PayloadAction<AccountingGroupBalancingSchedule[] | undefined>,
        ) => {
            state.allAccountingGroups = action.payload;
        },
        setCurrentAccountingGroupBalancingSchedule: (
            state,
            action: PayloadAction<AccountingGroupBalancingSchedule | undefined>,
        ) => {
            state.currentAccountingGroup = action.payload;
        },
        addNewAccountingGroupBalancingSchedule: (state, action: PayloadAction<AccountingGroupBalancingSchedule>) => {
            if (action.payload !== null) {
                if (state.allAccountingGroups !== undefined) {
                    state.allAccountingGroups.push(action.payload);
                } else {
                    state.allAccountingGroups = [action.payload];
                }
            }
        },
        updateAccountingGroupBalancingSchedules: (state, action: PayloadAction<AccountingGroupBalancingSchedule>) => {
            if (action.payload !== null) {
                if (state.allAccountingGroups !== undefined) {
                    const idx = state.allAccountingGroups.findIndex(
                        (r) => r.inventoryItemId === action.payload.inventoryItemId,
                    );
                    state.allAccountingGroups[idx] = action.payload;
                } else {
                    state.allAccountingGroups = [action.payload];
                }
            }
        },
        setControllableResource: (state, action: PayloadAction<ControllableResource | undefined>) => {
            if (state.currentAccountingGroup) {
                state.currentAccountingGroup.controllableResource = action.payload;
            }
        },
        setRequestingGridOperators: (state, action: PayloadAction<RequestingGridOperator[] | undefined>) => {
            if (state.currentAccountingGroup) {
                state.currentAccountingGroup.requestingGridOperators = action.payload;
            }
        },
        setAccountingGroup: (state, action: PayloadAction<{ data: string | undefined; elementIndex: number }>) => {
            if (
                state.currentAccountingGroup &&
                state.currentAccountingGroup.requestingGridOperators &&
                state.currentAccountingGroup.requestingGridOperators.length > 0
            ) {
                state.currentAccountingGroup.requestingGridOperators[action.payload.elementIndex].accountingGroup =
                    action.payload.data;
            }
        },
        setMarketPartner: (state, action: PayloadAction<{ data: MarketPartner | undefined; elementIndex: number }>) => {
            if (
                state.currentAccountingGroup &&
                state.currentAccountingGroup.requestingGridOperators &&
                state.currentAccountingGroup.requestingGridOperators.length > 0
            ) {
                state.currentAccountingGroup.requestingGridOperators[action.payload.elementIndex].marketPartner =
                    action.payload.data;
            }
        },
    },
});

export const {
    setAllAccountingGroupBalancingSchedules,
    setCurrentAccountingGroupBalancingSchedule,
    addNewAccountingGroupBalancingSchedule,
    updateAccountingGroupBalancingSchedules,
    setControllableResource,
    setRequestingGridOperators,
    setAccountingGroup,
    setMarketPartner,
} = accountingGroupBalancingSchedulesSlice.actions;

export default accountingGroupBalancingSchedulesSlice.reducer;
