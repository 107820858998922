import React, { ReactElement } from "react";
import { ListItem, ListItemText } from "@mui/material";

interface TimeSeriesListItemProps {
    businessType: string;
    direction: string | undefined;
    status: string | undefined;
    onClick: () => void;
    selected: boolean
}

const TimeSeriesListItem = ({
                                onClick,
                                businessType,
                                direction,
                                status,
                                selected
                            }: TimeSeriesListItemProps): ReactElement => (
    <ListItem button selected={selected}>
        <ListItemText primary={`${businessType}`}
                      secondary={status ? `${status} - ${direction ? ` ${direction}` : ""}` : undefined}
                      onClick={onClick}
                      tabIndex={-1}/>

    </ListItem>
);

export default TimeSeriesListItem;
