/**
 *
 * @export
 * @interface CompensationType
 */
export interface CompensationType {
    /**
     *
     * @type {string}
     * @memberof CompensationType
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof CompensationType
     */
    name?: string;
}

export const allowedCodes = ["Z01", "Z02", "Z03"];
