import { CustomEndAdornment, FieldWithGridProps, GridField, PageStatus, TranslatedApiValueCell } from "ndr-designsystem";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ValidationResult } from "../utils/types";
import WindPowerCurveElement from "../api/fixed/TechnicalResource/WindPowerCurveElement";
import EditingCell from "./EditingCell";
import WindPowerChart from "./WindPowerChart";

type BasicType = {
    title: string
    description?: string
    defaultOpen?: boolean
    maxFractionDigits?: number
    valueCheckFunction: (val: any, index: number) => ValidationResult
    updateElements: (value: number, index: number) => void,
    gridCheckFunction: (val: any) => ValidationResult,
    elements: WindPowerCurveElement[]
} & FieldWithGridProps

const WindPowerCurve = ({
                            pageStatus,
                            defaultOpen,
                            description,
                            title,
                            maxFractionDigits,
                            valueCheckFunction,
                            updateElements,
                            gridCheckFunction,
                            elements
                        }: BasicType): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { t } = useTranslation("tables");

    return <>
        <GridField
            values={elements}
            isEditing={isEditing || isAdding}
            noRecordsText={t("technical_resources_details.netRatedCapacityWindSpeed_completed_first")}
            defaultOpen={isAdding || defaultOpen}
            description={description}
            title={title}
            dataItemKey="speed"
            halfComponent={elements !== undefined && elements.length > 0 ?
                <Box width="100%" height="100%"> <WindPowerChart/> </Box> : undefined}
            gridFields={[
                {
                    name: "value",
                    title: "Value",
                    customCell: (props) => <EditingCell
                        {...props} isEditing={isEditing}
                        isAdding={isAdding}
                        fullWidth
                        endAdornment={<CustomEndAdornment content="kW"/>}
                        valueCheckFunction={valueCheckFunction}
                        maxFractionDigits={maxFractionDigits ?? 0}
                        updateElements={updateElements}
                    />
                },
                {
                    name: "speed",
                    title: "Speed",
                    exactWidth: 100,
                    customCell: ({ dataItem }) => <TranslatedApiValueCell value={dataItem.speed} endAdornment="m/s"/>
                }
            ]}
            showError
            validationResult={gridCheckFunction(elements)}
        />
    </>
}

WindPowerCurve.defaultProps = {
    description: undefined,
    defaultOpen: false,
    maxFractionDigits: 3
}

export default WindPowerCurve
