import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import DowntimeDto, { fromDto as fromDowntimeDto } from "../../../../api/dtos/Downtime/DowntimeDto";
import Downtime from "../../../../api/fixed/Downtime/Downtime";
import DowntimeDetailsDto, { fromDto as fromDowntimeDetailsDto } from "../../../../api/dtos/Downtime/DowntimeDetailsDto";
import DowntimeDetails from "../../../../api/fixed/Downtime/DowntimeDetails";

export interface DowntimeState {
    allDowntimes: DowntimeDto[] | undefined,
    currentDowntime: DowntimeDetailsDto | undefined
}

const initialState: DowntimeState = {
    allDowntimes: undefined,
    currentDowntime: undefined
};

export const downtimesSlice = createSlice({
    name: 'downtimesSlice',
    initialState,
    reducers: {
        setAllDowntimes: (state, action: PayloadAction<DowntimeDto[] | undefined>) => {
            state.allDowntimes = action.payload;
        },
        setCurrentDowntime: (state, action: PayloadAction<DowntimeDetailsDto | undefined>) => {
            state.currentDowntime = action.payload;
        }
    },
});

export const allDowntimesSelector = (state: RootState): Downtime[] | undefined => {
    if (state.downtime.allDowntimes) {
        return state.downtime.allDowntimes.map(d => fromDowntimeDto(d));
    }
    return state.downtime.allDowntimes
};

export const currentDowntimeSelector = (state: RootState): DowntimeDetails | undefined => {
    if (state.downtime.currentDowntime) {
        return fromDowntimeDetailsDto(state.downtime.currentDowntime)
    }
    return undefined
}


export const { setAllDowntimes, setCurrentDowntime } = downtimesSlice.actions;

export default downtimesSlice.reducer;