import React, { ReactElement, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomEndAdornment, FormattedNumberOrTextInput } from "ndr-designsystem";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { DateTime } from "luxon";
import { isEqual } from "lodash";
import CostInfoDto, { fromDto as fromCostInfoDto, toDto as toCostInfoDto } from "../../api/dtos/CostInfo/CostInfo";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CostInfoSchema } from "../../utils/inputChecking";
import useStyles from "../simplePlanningData/styles";
import { transformToSelectEntry } from "../../utils";
import CostInfo, { allowedBusinessTypeCodes as AllowedBusinessTypeCodes } from "../../api/fixed/CostInfo/CostInfo";
import { createCostInfo, deleteCostInfo } from "./store/thunks";
import { setAddNewCostInfo } from "../appStateSlice";
import { allCostInfoSelector, setAllCostInfo } from "./store/store";

interface Props {
    open: boolean
    handleClose: () => void
}

const defaultValue = {
    from: null,
    to: null,
    businessType: undefined,
    status: undefined,
    direction: undefined,
    value: undefined
}

const AddDialog = ({ open, handleClose }: Props): ReactElement => {
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState(false);
    const { addNewCostInfo } = useAppSelector(state => state.appState)
    const allCostInfo = useAppSelector(allCostInfoSelector, (left, right) => isEqual(left, right))
    const [costInfo, setCostInfo] = useState<CostInfo | null>(null)
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const {
        formState: { isValid },
        handleSubmit,
        control,
        setValue,
        getValues,
        trigger,
        watch
    } = useForm<CostInfoDto>({
        mode: "all",
        resolver: yupResolver(CostInfoSchema(allCostInfo ?? [], costInfo)),
        // @ts-ignore empty input
        defaultValues: addNewCostInfo.data ? { ...addNewCostInfo.data } : defaultValue
    });

    useEffect(() => {
        if (addNewCostInfo.data) {
            setCostInfo(fromCostInfoDto(addNewCostInfo.data))
        }
    }, [addNewCostInfo])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "businessType" && type === "change") {
                const newCostInfo = fromCostInfoDto(getValues())
                if (!newCostInfo.allowedStatusCodes) {
                    setValue('status', undefined)
                }
                if (!newCostInfo.allowedDirectionCodes) {
                    setValue('direction', undefined)
                }
                setCostInfo(newCostInfo)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, getValues, setValue]);

    const onSubmit: SubmitHandler<CostInfoDto> = async (data) => {
        setIsSaving(true);
        await dispatch(createCostInfo({ id: addNewCostInfo.id, data }));

        if (addNewCostInfo.data != null && allCostInfo != null) {
            const index = allCostInfo?.findIndex(c => c?.id === addNewCostInfo?.data?.id)
            const newElements = [...allCostInfo!]
            newElements.splice(index, 1);

            await dispatch(deleteCostInfo({
                id: addNewCostInfo?.id ?? "",
                costInfoId: addNewCostInfo?.data?.id ?? "",
            }))
            dispatch(setAllCostInfo(newElements.map(sens => toCostInfoDto(sens))))
        }

        dispatch(setAddNewCostInfo({
            show: false,
            id: "",
            data: undefined
        }))
        Object.keys(defaultValue).forEach(key => {
            // @ts-ignore
            setValue(key, defaultValue[key]);
        })
        setIsSaving(false);
    }

    const allowedBusinessTypeCodes = useMemo(() => transformToSelectEntry(AllowedBusinessTypeCodes, t, "api:cost_info_business_type"), [t])
    const allowStatusCodes = useMemo(() => transformToSelectEntry(costInfo?.allowedStatusCodes ?? [], t, "api:cost_info_status"), [t, costInfo])
    const allowedDirectionCodes = useMemo(() => transformToSelectEntry(costInfo?.allowedDirectionCodes ?? [], t, "api:direction"), [t, costInfo])
    const translatedUnit = t(`api:cost_info_unit.${costInfo?.unit?.code}`)

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t(`tables:add_cost_info.title`)}</DialogTitle>
        <DialogContent>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between"
                     sx={{ marginBottom: 1 }}
                >
                    <Box width="49%" display="flex">
                        <Controller control={control} name='from'
                                    render={({ field, fieldState }) => <FormControl
                                        className={classes.spacedFormControl}
                                        error={fieldState.error != null}
                                    >
                                        <DesktopDatePicker
                                            renderInput={(params) => <TextField name={field.name}
                                                                                variant="standard" {...params} />}
                                            value={field.value}
                                            onChange={(date: DateTime | null) => {
                                                const newEvent = {
                                                    target: {
                                                        value: date?.startOf('day').toISO()
                                                    }
                                                }
                                                field.onChange(newEvent);
                                                trigger()
                                            }}
                                            label={t(`tables:add_cost_info.start_date`)}
                                        />
                                        <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                                    </FormControl>}
                        />
                    </Box>
                    <Box width="49%" display="flex">
                        <Controller control={control} name='to'
                                    render={({ field, fieldState }) => <FormControl
                                        className={classes.formControl}
                                        error={fieldState.error != null}
                                    >
                                        <DesktopDatePicker
                                            value={field.value}
                                            renderInput={(params) => <TextField variant="standard" {...params} />}
                                            onChange={(date: DateTime | null) => {
                                                const newEvent = {
                                                    target: {
                                                        value: date?.startOf('day').toISO()
                                                    }
                                                }
                                                field.onChange(newEvent);
                                                trigger()
                                            }}
                                            label={t(`tables:add_cost_info.end_date`)}
                                        />
                                        <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                                    </FormControl>}/>
                    </Box>
                </Box>
                <Controller control={control} name='businessType' render={({ field, fieldState }) =>
                    <FormControl className={classes.formControl}
                                 fullWidth
                                 variant="standard"
                                 error={fieldState.error !== undefined}
                    >
                        <InputLabel id="businessTypeLabel">{t(`tables:add_cost_info.business_type`)}</InputLabel>
                        <Select
                            labelId="businessTypeLabel"
                            label={t(`tables:add_cost_info.business_type`)}
                            onChange={e => {
                                const newEvent = { ...e, target: { ...e.target, value: { code: e.target.value } } }
                                field.onChange(newEvent)
                                trigger()
                            }}
                            value={field.value?.code ?? ''}
                            onBlur={field.onBlur}
                        >
                            {allowedBusinessTypeCodes.map(val =>
                                <MenuItem key={val.code} value={val.code}>{val.name}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                    </FormControl>
                }/>
                {allowedDirectionCodes.length > 0 &&
                    <Controller control={control} name='direction' render={({ field, fieldState }) =>
                        <FormControl className={classes.formControl}
                                     fullWidth
                                     variant="standard"
                                     error={fieldState.error !== undefined}
                        >
                            <InputLabel id="directionLabel">{t(`tables:add_cost_info.direction`)}</InputLabel>
                            <Select
                                labelId="directionLabel"
                                label={t(`tables:add_cost_info.direction`)}
                                onChange={e => {
                                    const newEvent = { ...e, target: { ...e.target, value: { code: e.target.value } } }
                                    field.onChange(newEvent)
                                    trigger()
                                }}
                                value={field.value?.code ?? ''}
                                onBlur={field.onBlur}
                            >
                                {allowedDirectionCodes.map(val =>
                                    <MenuItem key={val.code} value={val.code}>{val.name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                        </FormControl>
                    }/>
                }
                {allowStatusCodes.length > 0 &&
                    <Controller control={control} name='status' render={({ field, fieldState }) =>
                        <FormControl className={classes.formControl}
                                     fullWidth
                                     variant="standard"
                                     error={fieldState.error !== undefined}
                        >
                            <InputLabel id="statusLabel">{t(`tables:add_cost_info.status`)}</InputLabel>
                            <Select
                                labelId="statusLabel"
                                label={t(`tables:add_cost_info.status`)}
                                onChange={e => {
                                    const newEvent = { ...e, target: { ...e.target, value: { code: e.target.value } } }
                                    field.onChange(newEvent)
                                    trigger()
                                }}
                                value={field.value?.code ?? ''}
                                onBlur={field.onBlur}
                            >
                                {allowStatusCodes.map(val =>
                                    <MenuItem key={val.code} value={val.code}>{val.name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                        </FormControl>
                    }/>
                }
                {costInfo &&
                    <Controller
                        control={control} name="value"
                        render={({ field, fieldState }) =>
                            <FormControl
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    my: 2,
                                }}
                                error={fieldState.error != null}
                            >
                                <Box width="100%" display="flex" flexDirection="row"
                                     alignItems="center"
                                     justifyContent="space-between">
                                    <Box width="50%">
                                        <Typography>
                                            {t(`tables:add_cost_info.value`)}
                                        </Typography>
                                    </Box>
                                    <Box width="50%" display="flex">
                                        <FormattedNumberOrTextInput
                                            maxFractionDigits={3}
                                            defaultValue={field.value ?? ""}
                                            type="number"
                                            onBlur={field.onBlur}
                                            isEditing
                                            alwaysNotify
                                            InputProps={{
                                                endAdornment: <CustomEndAdornment content={translatedUnit}/>
                                            }}
                                            showTooltip={false}
                                            onValueChange={val => {
                                                field.onChange(val)
                                                trigger();
                                            }}
                                            // valueCheckFunction={quantity => isFieldValid(GridElementSensitivitiesSchema(), ["quantity"], { quantity })}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                                {fieldState.error &&
                                    <FormHelperText>{t(`${fieldState.error.message}`)}</FormHelperText>
                                }
                            </FormControl>
                        }/>
                }
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t(`tables:add_cost_info.buttons.cancel`)}
                    </Button>
                    <Button type="submit" color="primary" disabled={!isValid || isSaving}>
                        {isSaving ?
                            <CircularProgress color="primary"
                                              variant="indeterminate"/> : t(`tables:add_cost_info.buttons.create`)}
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    </Dialog>
}

export default AddDialog;
