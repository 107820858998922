import * as React from "react";
import { ReactElement } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { TranslatedApiValueCell } from "ndr-designsystem";

interface Props extends GridCellProps {
    defaultValue?: string;
    isEditing: boolean;
}

const EmptyWhileEditingGridCell = ({ defaultValue, isEditing }: Props): ReactElement => {

    if (isEditing) return (<></>);

    return <TranslatedApiValueCell value={defaultValue}/>
};

EmptyWhileEditingGridCell.defaultProps = {
    defaultValue: undefined
}

export default EmptyWhileEditingGridCell;