import React, { ReactElement } from "react";
import { IconButton } from "@mui/material";
import { Cached } from "@mui/icons-material";
import { CustomButton } from "ndr-designsystem";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setAllGroups } from "../store/controlGroupsSlice";
import { fetchAllControlGroupsAndResources } from "../../appStateSlice";

const Toolbar = (): ReactElement => {
    const classes = useStyles();
    const { allGroups } = useAppSelector(state => state.controlGroups);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { t } = useTranslation("tables")

    const forceRefresh = async (): Promise<void> => {
        dispatch(setAllGroups(undefined))
        await dispatch(fetchAllControlGroupsAndResources({ force: true, fetchGroups: true, fetchResources: false }))
    };

    return <div className={classes.addNewRightButton}>
        <IconButton color="primary" disabled={allGroups === undefined} component="span"
                    onClick={forceRefresh}>
            <Cached/>
        </IconButton>
        <CustomButton disabled={allGroups === undefined} title={t("toolbar_buttons.add")}
                      onClick={() => history.push("/controlGroups/add")}/>
    </div>
}

export default Toolbar
