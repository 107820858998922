import React, { ReactElement } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import useStyles from "./styles";

const IndexCell = (props: GridCellProps): ReactElement => {
    const { dataIndex } = props;
    const styles = useStyles();

    return (
        <td className={styles.reorderButton}>
            {dataIndex + 1}
        </td>
    );
};

export default IndexCell;