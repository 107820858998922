import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GridDataState } from "ndr-designsystem";
import Api from "../../api/Api";
import SentMessage from "../../api/fixed/ConnectPlusMessage/SentMessage";
import ReceivedMessage from "../../api/fixed/ConnectPlusMessage/ReceivedMessage";
import { RootState } from "../../app/store";
import { removeNulls } from "../../utils";

export interface XMLFileDetails {
    messageType: "sent" | "received"
    fileType: "xml" | "ackxml"
    id: string
}

export interface ConnectPlusMessagesState {
    sentMessages: SentMessage[] | undefined;
    receivedMessages: ReceivedMessage[] | undefined;
    currentXMLFile: XMLFileDetails | undefined
    totalItems: number | undefined,
}

const initialState: ConnectPlusMessagesState = {
    sentMessages: undefined,
    receivedMessages: undefined,
    currentXMLFile: undefined,
    totalItems: undefined
};

export const ConnectPlusMessagesSlice = createSlice({
    name: "ConnectPlusMessages",
    initialState,
    reducers: {
        resetSentMessages: (state) => {
            state.sentMessages = undefined;
        },
        resetReceivedMessages: (state) => {
            state.receivedMessages = undefined;
        },
        setCurrentXMLFile: (state, action: PayloadAction<XMLFileDetails | undefined>) => {
            state.currentXMLFile = action.payload;
        },
        setTotalItems: (state, action: PayloadAction<number | undefined>) => {
            state.totalItems = action.payload;
        },
        setAllSentMessages: (state, action: PayloadAction<SentMessage[] | undefined>) => {
            state.sentMessages = action.payload;
        },
        setAllReceivedMessages: (state, action: PayloadAction<ReceivedMessage[] | undefined>) => {
            state.receivedMessages = action.payload;
        }
    }
})

export const {
    resetReceivedMessages,
    resetSentMessages,
    setCurrentXMLFile,
    setAllSentMessages,
    setTotalItems,
    setAllReceivedMessages
} = ConnectPlusMessagesSlice.actions;

export const fetchSentMessagesByIds = createAsyncThunk(
    "messages/fetchSentMessagesByIds",
    async ({ ids, dataState }: { dataState: GridDataState, ids: string[] }, { getState, dispatch }) => {
        let response: SentMessage[] | undefined;
        const state = getState() as RootState;
        if (dataState.take === undefined || dataState.skip === undefined) return;

        response = state.connectPlusMessages.sentMessages;
        let stateFilter = (dataState.filter?.filters.find((f: any) => f.field === "state") as any)?.value;
        let typeFilter = (dataState.filter?.filters.find((f: any) => f.field === "messageType") as any)?.value;

        if (stateFilter != null) {
            stateFilter = [Number.parseInt(stateFilter, 10)]
        }
        if (typeFilter != null) {
            typeFilter = [typeFilter]
        }
        const fullResponse = (await Api.fetchSentMessages(ids, dataState.skip / dataState.take, dataState.take, stateFilter, typeFilter)).data

        const allMessages = fullResponse.content;
        response = allMessages.map(data => removeNulls(data));
        dispatch(setTotalItems(fullResponse.totalElements))
        dispatch(setAllSentMessages(response))
    }
)


export const fetchAllSentMessages = createAsyncThunk(
    "messages/fetchAllSent",
    async ({ id, type, dataState }: { dataState: GridDataState, id: string | undefined, type: "resource" | "group" | undefined }, { getState, dispatch }) => {
        let response: SentMessage[] | undefined;
        const state = getState() as RootState;
        if (dataState.take === undefined || dataState.skip === undefined) return;

        response = state.connectPlusMessages.sentMessages;
        let stateFilter = (dataState.filter?.filters.find((f: any) => f.field === "state") as any)?.value;
        let typeFilter = (dataState.filter?.filters.find((f: any) => f.field === "messageType") as any)?.value;

        if (stateFilter != null) {
            stateFilter = [Number.parseInt(stateFilter, 10)]
        }
        if (typeFilter != null) {
            typeFilter = [typeFilter]
        }
        let fullResponse;
        if (id == null || type == null) {
            fullResponse = (await Api.fetchAllSentMessages(dataState.skip / dataState.take, dataState.take, stateFilter, typeFilter)).data
        } else
            fullResponse = (await Api.fetchSentMessagesByType(id, type, dataState.skip / dataState.take, dataState.take, stateFilter, typeFilter)).data

        const allMessages = fullResponse.content;
        response = allMessages.map(data => removeNulls(data));
        dispatch(setTotalItems(fullResponse.totalElements))
        dispatch(setAllSentMessages(response))
    }
)

export const fetchAllReceivedMessages = createAsyncThunk(
    "messages/fetchAllReceived",
    async ({
               id,
               dataState,
               type
           }: { dataState: GridDataState, id: string | undefined, type: "group" | "resource" | undefined }, {
               getState,
               dispatch
           }) => {
        let response: ReceivedMessage[] | undefined;
        const state = getState() as RootState;
        if (dataState.take === undefined || dataState.skip === undefined) return;

        response = state.connectPlusMessages.receivedMessages;
        let stateFilter = (dataState.filter?.filters.find((f: any) => f.field === "state") as any)?.value;
        let typeFilter = (dataState.filter?.filters.find((f: any) => f.field === "messageType") as any)?.value;

        if (stateFilter != null) {
            stateFilter = [Number.parseInt(stateFilter, 10)]
        }
        if (typeFilter != null) {
            typeFilter = [typeFilter]
        }
        let fullResponse;
        if (id == null || type == null) {
            fullResponse = (await Api.fetchAllReceivedMessages(dataState.skip / dataState.take, dataState.take, stateFilter, typeFilter)).data
        } else
            fullResponse = (await Api.fetchReceivedMessages(id, type, dataState.skip / dataState.take, dataState.take, stateFilter, typeFilter)).data

        const allMessages = fullResponse.content;
        response = allMessages.map(data => removeNulls(data));
        dispatch(setTotalItems(fullResponse.totalElements))
        dispatch(setAllReceivedMessages(response))
    }
)

export default ConnectPlusMessagesSlice.reducer
