/**
 *
 * @export
 * @interface AccountingModel
 */
export interface AccountingModel {
    /**
     *
     * @type {string}
     * @memberof AccountingModel
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof AccountingModel
     */
    name?: string | null;
}

export const allowedCodes = ["Z01", "Z02", "Z03"];
export const plannedValueModelCode = "Z01";