import React, { ReactElement, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  statusText: string;
  className?: string;
  additionalComponent?: ReactNode[]; 
}

const StatusBar = ({ statusText, className, additionalComponent }: Props): ReactElement => (
    <Box className={className} width="100%" height={40}>
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={1}>
        <Typography sx={{ width: "100%" }}>
          {statusText}
        </Typography>
        {additionalComponent}
      </Box>
    </Box>
  );

StatusBar.defaultProps = {
    className: null,
    additionalComponent: null
  };
  

export default StatusBar;
