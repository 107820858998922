import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PageStatus, toLocaleDate } from "ndr-designsystem";
import StatusBar from "Components/StatusBar";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useAppSelector } from "../../app/hooks";
import useStyles from "./styles";
import ControlGroupStatus from "../../api/fixed/ControlGroup/ControlGroupStatus";
import SentIndicator from "./list/columns/SentIndicator";

const Status = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { currentGroup } = useAppSelector(state => state.controlGroups)
    const classes = useStyles();
    const { t } = useTranslation("tables");
    const internationalization = useInternationalization();
    
    if (!currentGroup) return <></>
    if (currentGroup.existenceEnd) {
        const existenceEndDate = DateTime.fromISO(currentGroup.existenceEnd);
        const now = DateTime.local();

        const deactivationTextKey = existenceEndDate > now ? "willBeDeactivatedOn" : "deactivateDate";
        const formattedDate = toLocaleDate(existenceEndDate, internationalization.locale, DateTime.DATE_SHORT);
        const statusText = `${t("controllable_resources_details.status")}: ${t(`controllable_resources_details.${deactivationTextKey}`, { date: formattedDate })}`;

        return (
            <StatusBar
                className={classes.rejectedStyle}
                statusText={statusText}
            />
        );
    }

    const translatedStatusName = !isEditing && currentGroup.status && currentGroup.status.length ? t(`control_groups_details.states.${currentGroup.status}`) : null;

    return (
        <>
            {currentGroup.status === ControlGroupStatus.Accepted && (
                <StatusBar
                    className={classes.acceptedStyle}
                    statusText={`${t("controllable_resources_details.status")}: ${translatedStatusName}`}
                    additionalComponent={[<SentIndicator controlGroup={currentGroup} alwaysShowText />]}
                />
            )}
            {currentGroup.status === ControlGroupStatus.Rejected && (
                <StatusBar
                    className={classes.rejectedStyle}
                    statusText={`${t("controllable_resources_details.status")}: ${translatedStatusName}`}
                    additionalComponent={[<SentIndicator controlGroup={currentGroup} alwaysShowText />]}
                />
            )}
            {currentGroup.status !== ControlGroupStatus.Accepted &&
                currentGroup.status !== ControlGroupStatus.Rejected &&
                currentGroup.hasSuccessfulDispatch &&
                !isAdding &&
                !!translatedStatusName && (
                    <StatusBar
                        className={classes.hasSuccessfulDispatchStyle}
                        statusText={`${t("controllable_resources_details.status")}: ${translatedStatusName}`}
                        additionalComponent={[<SentIndicator controlGroup={currentGroup} alwaysShowText />]}
                    />
                )}
            {currentGroup.status !== ControlGroupStatus.Accepted &&
                currentGroup.status !== ControlGroupStatus.Rejected &&
                !currentGroup.hasSuccessfulDispatch &&
                !isAdding &&
                !!translatedStatusName && (
                    <StatusBar
                        statusText={`${t("controllable_resources_details.status")}: ${translatedStatusName}`}
                        additionalComponent={[<SentIndicator controlGroup={currentGroup} alwaysShowText />]}
                    />
                )}
        </>
    );
}

export default Status
