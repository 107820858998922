import React, { ReactElement, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { LoadingComponent, useLocalStorage } from "ndr-designsystem";
import { isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ListView from "./listView/ListView";
import { buildGeneratedCostInfoState, resetGeneratedCostInfoState } from "./store/thunks";
import { allGeneratedCostInfoSelector, selectCurrentTimeSeriesInfo } from "./store/store";
import TimeSeriesChart from "./Chart";
import Toolbar from "./Toolbar";
import Navigation from "../Navigation";
import TimeSeriesGrid from "./Grid";
import GeneratedCostInfoTimeSeries from "../../../api/fixed/GeneratedCostInfo/GeneratedCostInfoTimeSeries";

export type TimeSeriesViewType = "grid" | "chart"

interface RouteParams {
    id: string,
    dispatchId: string
}

const DataView = (): ReactElement => {
    const { id, dispatchId } = useParams<RouteParams>();
    const history = useHistory();
    const { headerHeight } = useAppSelector(state => state.appState)
    const [currentlyViewing, setCurrentlyViewing] = useLocalStorage<TimeSeriesViewType>("timeseries.view", {
        pollingInterval: -1,
        initialValue: "chart"
    })
    const isResource = history.location.pathname.includes("controllable")
    const { currentResource: controllableResource } = useAppSelector(state => state.controllableResources)
    const allGeneratedCostInfo = useAppSelector(allGeneratedCostInfoSelector, (left, right) => isEqual(left, right))
    const dispatch = useAppDispatch();
    const {
        selectedTimeSeries,
        currentGeneratedCostInfo
    } = useAppSelector(state => state.generatedCostInfo);

    useEffect(() => {
        dispatch(buildGeneratedCostInfoState({
            id,
            dispatchId,
            force: false,
            type: isResource ? "resource" : "group"
        }))
        return () => {
            dispatch(resetGeneratedCostInfoState())
        }

    }, [dispatchId, dispatch, id, isResource])

    const handleListViewClick = (item: GeneratedCostInfoTimeSeries): void => {
        dispatch(selectCurrentTimeSeriesInfo(item.id));
    };

    if (currentGeneratedCostInfo === undefined || selectedTimeSeries === undefined) return <LoadingComponent/>

    return (
        <Box display="flex" height={`calc(100vh - ${headerHeight + 60}px)`} flexDirection="column">
            <Navigation
                allElements={allGeneratedCostInfo ?? []}
                currentElement={currentGeneratedCostInfo}
                elementKey="dispatchId"
                onNextClick={e => {
                    history.push(`/${isResource ? "controllable" : "controlGroups"}/${controllableResource?.externalID}/generated-cost-info/${e.dispatchId}`)
                }}
                onPreviousClick={e => {
                    history.push(`/${isResource ? "controllable" : "controlGroups"}/${controllableResource?.externalID}/generated-cost-info/${e.dispatchId}`)
                }}
            />
            <Box display="flex" height="100%" width="100%">
                <Box width="15%" height="100%"
                     style={{ overflowY: "auto" }}>
                    <ListView onItemClick={handleListViewClick}/>
                </Box>
                <Box height="100%" width="100%" display="flex" flexDirection="column">
                    <Toolbar onCurrentlyViewingToggle={setCurrentlyViewing} currentlyViewing={currentlyViewing}/>
                    <Box display="flex" flexDirection={currentlyViewing === "grid" ? "row" : "column"} height="100%">
                        <Box flex={1} display="flex" flexDirection="column" sx={{ userSelect: "none" }}>
                            <Box height="100%">
                                {currentlyViewing === "grid" ?
                                    <TimeSeriesGrid/>
                                    :
                                    <TimeSeriesChart/>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )

}

export default DataView;
