import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ControlGroup } from "../../../api/fixed/ControlGroup/ControlGroup";
import { RootState } from "../../../app/store";
import { Fixation } from "../../../api/fixed/Fixation";
import { ControllabilityType } from "../../../api/fixed/ControllableResource/ControllabilityType";
import { Unit } from "../../../api/fixed/Unit";
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";
import { commonElementsBetweenCollectionsByProperty } from "../../../utils";
import ControlGroupStatus from "../../../api/fixed/ControlGroup/ControlGroupStatus";

export interface IControlGroupsState {
    allGroups: ControlGroup[] | undefined,
    currentGroup: ControlGroup | undefined | null
}

const initialState: IControlGroupsState = {
    allGroups: undefined,
    currentGroup: undefined
}

export const controlGroupsSlice = createSlice({
    name: "controlGroups",
    initialState,
    reducers: {
        setCurrentGroup: (state, action: PayloadAction<ControlGroup | undefined>) => {
            state.currentGroup = action.payload
        },
        setAllGroups: (state, action: PayloadAction<ControlGroup[] | undefined>) => {
            state.allGroups = action.payload;
        },
        setName: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentGroup) {
                state.currentGroup.name = action.payload;
            }
        },
        overwriteGroupInArray: (state, action: PayloadAction<ControlGroup>) => {
            if (state.allGroups) {
                const idx = state.allGroups.findIndex(r => r.inventoryItemId === action.payload.inventoryItemId);
                state.allGroups[idx] = action.payload;
            }
        },
        addGroupToArray: (state, action: PayloadAction<ControlGroup>) => {
            if (state.allGroups) {
                state.allGroups.push(action.payload);
            }
            state.currentGroup = action.payload
        },
        setStatus: (state, action: PayloadAction<ControlGroupStatus>) => {
            if (state.currentGroup) {
                state.currentGroup.status = action.payload
            }
        },
        updateGroupInArray: (state) => {
            if (state.currentGroup) {
                if (state.currentGroup.revision != null)
                    state.currentGroup.revision += 1;
                else
                    state.currentGroup.revision = 0;
                state.currentGroup.status = ControlGroupStatus.Created;
                if (state.allGroups) {
                    const idx = state.allGroups.findIndex(r => r.inventoryItemId === state.currentGroup!.inventoryItemId);
                    state.allGroups[idx] = state.currentGroup;
                }
            }
        },
        setExternalID: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentGroup) {
                state.currentGroup.externalID = action.payload;
            }
        },
        updateNewAffectedGridOperator: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentGroup && state.currentGroup.affectedGridOperators && action.payload) {
                if (state.currentGroup.affectedGridOperators.length > 0)
                    state.currentGroup.affectedGridOperators[state.currentGroup.affectedGridOperators.length - 1] = action.payload;
                else
                    state.currentGroup.affectedGridOperators.push(action.payload);
            }
        },
        swapAffectedGridOperators: (state, action: PayloadAction<{ startIndex: number, endIndex: number }>) => {
            const { startIndex, endIndex } = action.payload;
            if (state.currentGroup && state.currentGroup.affectedGridOperators) {
                const item = state.currentGroup.affectedGridOperators[startIndex];
                state.currentGroup.affectedGridOperators[startIndex] = state.currentGroup.affectedGridOperators[endIndex];
                state.currentGroup.affectedGridOperators[endIndex] = item;
            }
        },
        updateNewAdditionalAffectedGridOperator: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentGroup && state.currentGroup.additionalAffectedGridOperators && action.payload) {
                if (state.currentGroup.additionalAffectedGridOperators.length > 0)
                    state.currentGroup.additionalAffectedGridOperators[0] = action.payload;
                else
                    state.currentGroup.additionalAffectedGridOperators.push(action.payload)
            }
        },
        setAffectedGridOperatorAtIndex: (state, action: PayloadAction<{ index: number, value: string }>) => {
            const { index, value } = action.payload;
            if (state.currentGroup && state.currentGroup.affectedGridOperators) {
                state.currentGroup.affectedGridOperators[index] = value;
            }
        },
        setAdditionalAffectedGridOperatorAtIndex: (state, action: PayloadAction<{ index: number, value: string }>) => {
            const { index, value } = action.payload;
            if (state.currentGroup && state.currentGroup.additionalAffectedGridOperators) {
                state.currentGroup.additionalAffectedGridOperators[index] = value;
            }
        },
        setAffectedGridOperators: (state, action: PayloadAction<string[]>): any => {
            if (state.currentGroup) {
                state.currentGroup.affectedGridOperators = action.payload
            }
        },
        setAdditionalAffectedGridOperators: (state, action: PayloadAction<string[]>) => {
            if (state.currentGroup) {
                state.currentGroup.additionalAffectedGridOperators = action.payload
            }
        },
        setFixation: (state, action: PayloadAction<Fixation | undefined>) => {
            if (state.currentGroup)
                state.currentGroup.fixation = action.payload;
        },
        setControllabilityType: (state, action: PayloadAction<ControllabilityType | undefined>) => {
            if (state.currentGroup) {
                state.currentGroup.controllabilityType = action.payload;

                if (action.payload === ControllabilityType.STEPS) {
                    state.currentGroup.stepLength = undefined;
                    state.currentGroup.minSteps = undefined;
                    state.currentGroup.maxSteps = undefined;
                }
            }

        },
        setUnit: (state, action: PayloadAction<Unit | undefined>) => {
            if (state.currentGroup) {
                state.currentGroup.unit = action.payload;
                state.currentGroup.stepLength = undefined;
                state.currentGroup.minSteps = undefined;
                state.currentGroup.maxSteps = undefined;
            }
        },
        setLevels: (state, action: PayloadAction<number[] | undefined>) => {
            if (state.currentGroup)
                state.currentGroup.levels = action.payload;
        },
        swapLevels: (state, action: PayloadAction<{ startIndex: number, endIndex: number }>) => {
            const { startIndex, endIndex } = action.payload;
            if (state.currentGroup && state.currentGroup.levels) {
                const startItem = state.currentGroup.levels[startIndex];
                state.currentGroup.levels[startIndex] = state.currentGroup.levels[endIndex]
                state.currentGroup.levels[endIndex] = startItem;
            }
        },
        setStepLength: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentGroup)
                state.currentGroup.stepLength = action.payload;
        },
        setMinSteps: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentGroup)
                state.currentGroup.minSteps = action.payload;
        },
        setMaxSteps: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentGroup)
                state.currentGroup.maxSteps = action.payload;
        },
        setMinimumMinutesRequiredBeforeActivation: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentGroup)
                state.currentGroup.minimumMinutesRequiredBeforeActivation = action.payload;
        },
        updateNewLevel: (state, action: PayloadAction<number>) => {
            if (state.currentGroup && state.currentGroup.levels) {
                state.currentGroup.levels[0] = action.payload;
            }
        },
        setControllableResources: (state, action: PayloadAction<string[]>) => {
            if (state.currentGroup) {
                state.currentGroup.controllableResources = action.payload;
            }
        },
        updateStatusInArray: (state, action: PayloadAction<ControlGroupStatus>) => {
            if (state.currentGroup && state.allGroups) {
                state.currentGroup.status = action.payload;
                const idx = state.allGroups.findIndex(r => r.inventoryItemId === state.currentGroup?.inventoryItemId);
                state.allGroups[idx] = state.currentGroup;
            }
        }
    },
})

export const fullResourcesDataSelector = (state: RootState): ControllableResource[] | undefined =>
    state.controllableResources.allResources ?
        commonElementsBetweenCollectionsByProperty("inventoryItemId", state.controllableResources.allResources, state.controlGroups.currentGroup?.controllableResources ?? []) : undefined

export const {
    setCurrentGroup,
    setLevels,
    setAdditionalAffectedGridOperators,
    setAffectedGridOperators,
    setStepLength,
    setMaxSteps,
    setMinSteps,
    setUnit,
    setControllabilityType,
    setFixation,
    setMinimumMinutesRequiredBeforeActivation,
    setName,
    setExternalID,
    updateNewAdditionalAffectedGridOperator,
    updateNewAffectedGridOperator,
    updateNewLevel,
    setControllableResources,
    addGroupToArray,
    updateGroupInArray,
    swapAffectedGridOperators,
    setAffectedGridOperatorAtIndex,
    setAdditionalAffectedGridOperatorAtIndex,
    setAllGroups,
    overwriteGroupInArray,
    setStatus,
    swapLevels,
    updateStatusInArray
} = controlGroupsSlice.actions

export default controlGroupsSlice.reducer
