import { ValidationResult } from "../../utils/types";
import { ArrayFieldPaths } from "../../Components/props";

export enum PageStatus {
    VIEW,
    EDIT,
    ADD
}

export interface FieldWithGridProps {
    pageStatus: PageStatus;
}

export type GeneralGridOperatorsTableProps = FieldWithGridProps & {
    elements: string[] | undefined,
    actions: { setGridOperators: any, updateNewGridOperator: any, swapGridOperators?: any }
    title: string
    description?: string
    showIndex: boolean
    validationResult: ValidationResult,
    newElementPosition?: "top" | "bottom",
    firstElementCurrentTenantMarketPartner?: boolean
    showLabel: boolean
} & {
    addNewMarketPartnerData: {
        type: "control" | "group" | "technical" | "technicalTranche"
        fieldPath: ArrayFieldPaths
    }
}

export interface GeneralSimpleElementTableProps extends FieldWithGridProps {
    elements: any[] | undefined,
    actions: { setElements: any, updateNewElement: any }
    defaultElement: any
    valueCheckFunction: (val: any) => ValidationResult
    gridCheckFunction: (val: any) => ValidationResult
    title: string
    description?: string
    defaultOpen?: boolean
    type: "controlledText" | "number",
    maxFractionDigits?: number
    isFilledByEiv?: boolean
}