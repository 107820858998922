import DowntimeDetails from "api/fixed/Downtime/DowntimeDetails";
import { createDateTime } from "ndr-designsystem";
import DowntimeDto from "./DowntimeDto";
import DowntimeDataDto, { fromDto as fromDowntimeDataDto } from "./DowntimeDataDto";

export default interface DowntimeDetailsDto extends DowntimeDto {
    data: DowntimeDataDto
}

export const fromDto = (dto: DowntimeDetailsDto): DowntimeDetails => ({
    ...dto,
    start: createDateTime(dto.start),
    periodEnd: createDateTime(dto.periodEnd),
    data: fromDowntimeDataDto(dto.data)
})
