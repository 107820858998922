import { createDateTime } from "ndr-designsystem";
import Activation from "../../fixed/Activations/Activation";
import ProcessingStatus from "../../fixed/Activations/ProcessingStatus";

export default interface ActivationDto {
    id: string
    activationDate: string
    receivedAt: string
    absoluteTimeSeries?: string
    deltaTimeSeries?: string
    avatTimeSeries?: string
    processingStatus: ProcessingStatus
}

export const fromDto = (dto: ActivationDto): Activation => ({
    ...dto,
    receivedDate: createDateTime(dto.receivedAt),
    activationDate: createDateTime(dto.activationDate)
})