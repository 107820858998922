import React, { ReactElement, useEffect, useState } from "react";
import { toLocaleDate } from "ndr-designsystem";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useAppSelector } from "../../app/hooks";

interface Props {
    match: { path: string }
}

const TimeSeriesBreadcrumb = ({ match }: Props): ReactElement => {
    const [content, setContent] = useState<DateTime | string | undefined>()

    const { currentActivation } = useAppSelector(state => state.activations)
    const { currentPlanningData } = useAppSelector(state => state.planningData)
    const { currentFlexibility } = useAppSelector(state => state.flexibilityConstraints)
    const { currentUnavailability } = useAppSelector(state => state.unavailabilities)
    const { currentDowntime } = useAppSelector(state => state.downtime)
    const { currentGeneratedCostInfo } = useAppSelector(state => state.generatedCostInfo)
    const { currentGeneratedSensitivity } = useAppSelector(state => state.generatedSensitivity)
    const internationalization = useInternationalization();

    useEffect(() => {
        if (match.path.includes("activations")) {
            setContent(currentActivation?.activationDate)
        }
        if (match.path.includes("planning-data")) {
            setContent(currentPlanningData?.date)
        }
        if (match.path.includes("network-constraints")) {
            setContent(currentFlexibility?.date)
        }
        if (match.path.includes("unavailability")) {
            setContent(currentUnavailability?.start)
        }
        if (match.path.includes("downtime")) {
            setContent(currentDowntime?.start)
        }
        if (match.path.includes("generated-cost-info")) {
            setContent(currentGeneratedCostInfo?.date)
        }
        if (match.path.includes("generated-sens")) {
            setContent(currentGeneratedSensitivity?.date)
        }
    }, [match, currentActivation, currentUnavailability, currentPlanningData, currentFlexibility, currentDowntime, currentGeneratedCostInfo, currentGeneratedSensitivity])

    if (content === undefined) return <></>
    return <span>{toLocaleDate(content, internationalization.locale, DateTime.DATE_SHORT)}</span>
}

export default TimeSeriesBreadcrumb
