import React, { ReactElement, useCallback } from "react";
import { useSelector } from "react-redux";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { FieldWithGridProps, GridField,  GridFieldActionsCell,  GridFieldElement,  PageStatus,  SelectFieldGridCell, SimpleFieldGridCell, ValidationResult, isSchemaValid } from "ndr-designsystem";
import { RootState } from "app/store";
import { MarketPartner } from "api/fixed/MarketPartner";
import { useAppDispatch } from "app/hooks";
import { RequestingGridOperator } from "api/fixed/AccountingGroupBalancingSchedule/RequestingGridOperator";
import { GridOperatorsSchema, RequestingGridOperatorSchema } from "utils/inputChecking"
import { buildMarketPartnerText } from "../../../utils/index";
import { setAccountingGroup, setMarketPartner, setRequestingGridOperators } from "../store/accountingGroupBalancingSchedulesSlice";

interface Props extends FieldWithGridProps {
    elements: RequestingGridOperator[];
    validationResult: ValidationResult,
}

const RequestingGridOperatorsTable = ({ pageStatus, elements, validationResult }: Props): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { allPartners: marketPartners, currentTenantMarketPartner } = useSelector(
        (state: RootState) => state.marketPartners,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeItem, setActiveItem] = React.useState<MarketPartner | null>(null);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");

    const addNewOperator = useCallback(() => {
        if (elements === undefined || elements.length === 0) {
            dispatch(setRequestingGridOperators([{}]));
        } else {
            dispatch(setRequestingGridOperators([...elements, {}]));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements]);

    const removeAffectedGridOperator = (index: number): void => {
        if (elements === undefined) return;
        const newElements = [...elements];
        newElements.splice(index, 1);
        dispatch(setRequestingGridOperators(newElements));
    };
    
    const marketPartnerIds = useCallback(() => marketPartners?.map(mp => mp.inventoryItemId ?? "") ?? [], [marketPartners]);

    const filterMarketPartnersByCurrentValues = useCallback(
        () =>
            marketPartners?.map((value) => ({
                id: `${value.inventoryItemId}`,
                text: buildMarketPartnerText(value),
            })) ?? [],
        [marketPartners],
    );

    const Commands = (props: GridCellProps): ReactElement => {
        const { dataItem } = props;        
        const isElementValid: ValidationResult = dataItem !== undefined ? isSchemaValid(RequestingGridOperatorSchema(marketPartnerIds()), dataItem) : {
            valid: false,
            error: null
        };
        return <GridFieldActionsCell {...props}
                                     inEdit={false}
                                     validationResult={isElementValid}
                                     add={addNewOperator}
                                     remove={removeAffectedGridOperator}
        />;
    }

    const EditableAccountingGroupCell = (props: GridCellProps): ReactElement => {
        const { dataItem, dataIndex } = props;

        return (
            <SimpleFieldGridCell
                {...props}
                isEditing={isEditing || isAdding}
                onValueChange={(val) => dispatch(setAccountingGroup({ data: val, elementIndex: dataIndex }))}   
                defaultValue={dataItem.accountingGroup}
                //  valueCheckFunction={accountingGroup => isFieldValid(Schema, [SchemaConstants.accountingGroup], { accountingGroup })}
            />
        );
    }
    // const Schema = AccountingGroupBalancingSchedulesSchema(marketPartners ?? []);

    // find our market partner in the market partner list, because code and encoding is missing on the rest call
    const getFullMarketPartner = (marketPartnerId: string): MarketPartner | undefined => marketPartners?.find(mp => mp.inventoryItemId === marketPartnerId)
    
    const EditableSupplierCell = (props: GridCellProps): ReactElement => {
        const { dataItem, dataIndex } = props;

        const marketPartner = getFullMarketPartner(dataItem?.marketPartner?.internalId ?? "") || dataItem?.marketPartner;

        return (
            <SelectFieldGridCell
                {...props}
                colSpan={1}
                dataItemKey="id"
                textField="text"
                isEditing={isEditing || isAdding}
                allowAdd={false}
                values={filterMarketPartnersByCurrentValues()}
                onChangeHandler={(val) => {
                    const tempMarketPartner = marketPartners?.find((p) => p.inventoryItemId === val.id);
                    return dispatch(
                        setMarketPartner({
                            data: {
                                internalId: tempMarketPartner?.inventoryItemId,
                                name: tempMarketPartner?.name,
                            },
                            elementIndex: dataIndex,
                        }),
                    );
                }}
                defaultValue={
                    marketPartner && {
                        text: `${buildMarketPartnerText(marketPartner, true)}`,
                        id: marketPartner.inventoryItemId,
                    }
                }
                editingDefaultValue={
                    marketPartner && {
                        text: `${buildMarketPartnerText(marketPartner, true)}`,
                        id: marketPartner.inventoryItemId,
                    }
                }
                valueCheckFunction={(val) => val && isSchemaValid(GridOperatorsSchema(marketPartnerIds()), val?.id)}
            />
        );
    }

    const getGridFields = useCallback(() => {
        const grids: GridFieldElement[] = [
            {
                name: "marketPartner.internalId",
                title: t("controllable_resources_details.accountingGroupBalancingSchedules.marketPartner"),
                customCell: EditableSupplierCell,
            },
            {
                name: "accountingGroup",
                title: t("controllable_resources_details.accountingGroupBalancingSchedules.accountingGroup"),
                customCell: EditableAccountingGroupCell
            },
            {
                customCell: Commands,
                exactWidth: 150,
            },
        ];

        return grids;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements, marketPartners, isEditing]);

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            addButtonText={t("toolbar_buttons.add")}
            defaultOpen
            validationResult={validationResult}
            isEditing={isEditing || isAdding}
            onAddNew={addNewOperator}            
            values={elements}
            dataItemKey="inventoryItemId"
            title={t("controllable_resources_details.accountingGroupBalancingSchedules.requestingGridOperators")}
            gridFields={getGridFields()}
        />
    );
};


export default RequestingGridOperatorsTable;
