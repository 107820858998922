/**
 *
 * @export
 * @enum {string}
 */


enum ControlGroupStatus {
    Unknown = "Unknown",
    Created = "Created",
    Valid = "Valid",
    Confirmed = "Confirmed",
    Sent = "Sent",
    Updated = "Updated",
    SendingFailed = "SendingFailed",
    Accepted = "Accepted",
    Rejected = "Rejected",
}

export const stableControlGroupStates = [
    ControlGroupStatus.Unknown,
    ControlGroupStatus.Created,
    ControlGroupStatus.Valid,
    ControlGroupStatus.Updated,
    ControlGroupStatus.SendingFailed,
    ControlGroupStatus.Accepted,
    ControlGroupStatus.Rejected,
]

export default ControlGroupStatus;
