import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import Api from "../../../../api/Api";
import { removeNulls } from "../../../../utils";
import { setCurrentProductionData } from "./slice";

export const fetchProductionDataByCR = createAsyncThunk(
    "productionData/fetchByCRId",
    async ({ id, from, to }: { id: string, from: DateTime, to: DateTime }, {
        dispatch
    }) => {
        let { data } = await Api.fetchActualData(id, from, to)
        if (data === "") data = {
            id: DateTime.now().toMillis(),
            dataPoints: [],
            unitName: "kW",
            resolution: { timeUnit: "MINUTE", factor: 15 }
        };
        const response = removeNulls(data)

        dispatch(setCurrentProductionData(response));
    }
)

export default {}
