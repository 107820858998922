import React, { ReactElement, useCallback } from "react";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { List } from "@mui/material";
import { toLocaleDate } from "ndr-designsystem";
import TimeSeriesListItem from "./ListItem";
import { PlannedResourceTimeSeries } from "../../../../api/fixed/PlanningData/PlannedResourceTimeSeries";
import { currentPlanningDataSelector } from "../store/store";
import { useAppSelector } from "../../../../app/hooks";
import { convertBusinessTypeAndDirection } from "../../../../utils";

interface TimeSeriesListViewProps {
    onItemClick: (item: PlannedResourceTimeSeries) => void;
}

const ListView = ({ onItemClick }: TimeSeriesListViewProps): ReactElement => {
    const currentPlanningData = useAppSelector(currentPlanningDataSelector, (left, right) => left?.internalID === right?.internalID);
    const { selectedTimeSeries } = useAppSelector(state => state.planningData)
    const internationalization = useInternationalization();

    const renderItems = useCallback(() => {
        if (currentPlanningData && currentPlanningData.timeSeries) {
            return currentPlanningData.timeSeries.map((pd, index) =>
                <TimeSeriesListItem
                    selected={selectedTimeSeries === index}
                    key={pd.ExternalID}
                    itemsCount={undefined}
                    type={convertBusinessTypeAndDirection(pd.businessType, pd.direction) ?? "Unknown"}
                    onClick={() => onItemClick(pd)}
                    startDate={toLocaleDate(pd.startingTime, internationalization.locale, DateTime.TIME_SIMPLE)}
                />
            );
        }
        return [];
    }, [currentPlanningData, onItemClick, internationalization, selectedTimeSeries]);

    return (
        <List disablePadding>
            <div style={{ maxHeight: "100%" }}>
                {renderItems()}
            </div>
        </List>
    )
};

export default ListView;
