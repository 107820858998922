import React, { ReactElement } from "react";
import { GridRowProps } from "@progress/kendo-react-grid";
import AvatError from "../../../api/fixed/AvatError/AvatError";

const RowRender = (
    trElement: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
): ReactElement => {
    const { dataItem }: { dataItem: AvatError } = props;
    const red = { backgroundColor: "rgb(243, 23, 0, 0.32)" };

    let trProps;
    if (!dataItem.resolved) {
        trProps = { style: red };
    }

    return React.cloneElement(
        trElement,
        // @ts-ignore
        { ...trProps },
        trElement.props.children
    );
};

export default RowRender
