import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ProductionDataDto, {
    fromDto as fromProductionDataDto
} from "../../../../api/dtos/ProductionData/ProductionDataDto";
import { RootState } from "../../../../app/store";
import ProductionData from "../../../../api/fixed/ProductionData/ProductionData";

export interface ProductionState {
    currentProductionData: ProductionDataDto | undefined
}

const initialState: ProductionState = {
    currentProductionData: undefined
};

export const productionDataSlice = createSlice({
    name: 'productionDataSlice',
    initialState,
    reducers: {
        setCurrentProductionData: (state, action: PayloadAction<ProductionDataDto | undefined>) => {
            state.currentProductionData = action.payload;
        }
    },
});

export const currentProductionDataSelector = (state: RootState): ProductionData | undefined => {
    if (state.productionData.currentProductionData) {
        return fromProductionDataDto(state.productionData.currentProductionData)
    }
    return undefined
}


export const { setCurrentProductionData } = productionDataSlice.actions;

export default productionDataSlice.reducer;