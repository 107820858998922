import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PageStatus, toLocaleDate } from "ndr-designsystem";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import StatusBar from "Components/StatusBar";
import { useAppSelector } from "../../../app/hooks";
import ControllableResourceStatus, {
    stableControllableResourceStates
} from "../../../api/fixed/ControllableResource/ControllableResourceStatus";
import SentIndicator from "../list/columns/SentIndicator";
import EivIndicator from "../list/columns/EivIndicator";
import useStyles from "../styles";

const Status = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const { currentResource: resource } = useAppSelector(state => state.controllableResources)
    const classes = useStyles()
    const { t } = useTranslation("tables");
    const internationalization = useInternationalization();
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;

    if (!resource) return <></>
    if (resource.existenceEnd) {
        const existenceEndDate = DateTime.fromISO(resource.existenceEnd);
        const now = DateTime.local();

        const deactivationTextKey = existenceEndDate > now ? "willBeDeactivatedOn" : "deactivateDate";
        const formattedDate = toLocaleDate(existenceEndDate, internationalization.locale, DateTime.DATE_SHORT);
        const statusText = `${t("controllable_resources_details.status")}: ${t(`controllable_resources_details.${deactivationTextKey}`, { date: formattedDate })}`;
        
        return (
            <StatusBar
                className={classes.rejectedStyle}
                statusText={statusText}
            />
        );
    }

    const translatedStatusName = stableControllableResourceStates.includes(resource.status ?? ControllableResourceStatus.Created) && !isEditing && resource.status && resource.status.length ? t(`controllable_resources_details.states.${resource.status}`) : null;
    return (
        <>
            {resource.status === ControllableResourceStatus.Accepted && (
                <StatusBar
                    className={classes.acceptedStyle}
                    statusText={`${t("controllable_resources_details.status")}: ${translatedStatusName}`}
                    additionalComponent={[
                        resource.receivedFromEiv && <EivIndicator controllableResource={resource} alwaysShowText />,
                        <SentIndicator controllableResource={resource} alwaysShowText />,
                    ]}
                />
            )}
            {resource.status === ControllableResourceStatus.Rejected && (
                <StatusBar
                    className={classes.rejectedStyle}
                    statusText={`${t("controllable_resources_details.status")}: ${translatedStatusName}`}
                    additionalComponent={[
                        resource.receivedFromEiv && <EivIndicator controllableResource={resource} alwaysShowText />,
                    ]}
                />
            )}
            {resource.status !== ControllableResourceStatus.Accepted &&
                resource.status !== ControllableResourceStatus.Rejected &&
                resource.hasSuccessfulDispatch &&
                !isAdding &&
                !!translatedStatusName && (
                    <StatusBar
                        className={classes.hasSuccessfulDispatchStyle}
                        statusText={`${t("controllable_resources_details.status")}: ${translatedStatusName}`}
                        additionalComponent={[
                            resource.receivedFromEiv && <EivIndicator controllableResource={resource} alwaysShowText />,
                            <SentIndicator controllableResource={resource} alwaysShowText />,
                        ]}
                    />
                )}
            {resource.status !== ControllableResourceStatus.Accepted &&
                resource.status !== ControllableResourceStatus.Rejected &&
                !resource.hasSuccessfulDispatch &&
                !isAdding &&
                !!translatedStatusName && (
                <StatusBar
                    statusText={`${t("controllable_resources_details.status")}: ${translatedStatusName}`}
                    additionalComponent={[
                        resource.receivedFromEiv && <EivIndicator controllableResource={resource} alwaysShowText />,
                        <SentIndicator controllableResource={resource} alwaysShowText />,
                    ]}
                />                    
                )}
        </>
    );
}

export default Status
