import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { CustomEndAdornment, Field, GeneralSimpleElementTable, isFieldValid, isSchemaValid } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import {
    setActivationType,
    setControllabilityType,
    setFixation,
    setIsTolerationCase,
    setLevels,
    setMaxSteps,
    setMinSteps,
    setProcessingTimeEiv,
    setStepLength,
    setUnit,
    swapLevels,
    updateNewLevel
} from "../../store/controllableResourcesSlice";
import { allowedCodes as FixationAllowedCodes } from "../../../../api/fixed/Fixation";
import {
    allowedCodes as ControllabilityTypeAllowedCodes,
    ControllabilityType
} from "../../../../api/fixed/ControllableResource/ControllabilityType";
import { allowedCodes as UnitAllowedCodes } from "../../../../api/fixed/Unit";
import { CRControllabilitySchema, ParameterWithUnitSchema, UnitFromTolerationCase, ActivationTypeFromAccountingModel } from "../../../../utils/inputChecking";
import { useAppDispatch } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { FieldWithGridProps, PageStatus } from "../../../../utils/types";
import SchemaConstants from "../../../../utils/schemaConsts";
import { allowedCodes as ActivationTypeAllowedCodes } from "../../../../api/fixed/ControllableResource/ActivationType";
import { setNotificationAlertMessage } from "../../../appStateSlice";
import { transformToSelectEntry } from "../../../../utils";

const ControllabilitySubGrid = ({ pageStatus }: FieldWithGridProps): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { t } = useTranslation("tables")

    const { currentResource: resource } = useSelector((state: RootState) => state.controllableResources);
    const dispatch = useAppDispatch();

    const getLevelsTableActions = useCallback(() => ({
        setElements: (e: number[] | undefined) => dispatch(setLevels(e)),
        updateNewElement: (e: number | undefined) => dispatch(updateNewLevel(e)),
        swapElements: (startIndex: number, endIndex: number) => dispatch(swapLevels({ startIndex, endIndex }))
    }), [dispatch]);

    const activationTypeAllowedValues = useMemo(() => transformToSelectEntry(ActivationTypeAllowedCodes, t, "api:activation_type"), [t])
    const unitAllowedCodes = useMemo(() => transformToSelectEntry(UnitAllowedCodes, t, "api:unit"), [t])
    const fixationAllowedCodes = useMemo(() => transformToSelectEntry(FixationAllowedCodes, t, "api:fixation"), [t])
    const controllabilityTypeAllowedValues = useMemo(() => transformToSelectEntry(ControllabilityTypeAllowedCodes, t, "api:controllability_type"), [t])

    useEffect(() => {
        if (isEditing || isAdding) {
            dispatch(setActivationType(undefined));
            dispatch(setUnit(undefined));
        }
    }, []);

    useEffect(() => {
        if (isEditing || isAdding) {
            dispatch(setActivationType(undefined));
            dispatch(setUnit(undefined));
        }
    }, [resource?.isTolerationCase]);

    if (resource === undefined || resource === null) {
        return <></>;
    }

    const ControllabilitySchema = CRControllabilitySchema();
    const LevelSchema = ParameterWithUnitSchema(resource.unit);

    return (
        <>
            <Field onValueChange={val => dispatch(setIsTolerationCase(val))}
                   defaultValue={resource.isTolerationCase}
                   inputType="boolean"
                   isFilledByEiv
                   filledByEivText={t("filled_by_eiv")}
                   isEditing={isEditing || isAdding}
                   title={t('controllable_resources_details.isTolerationCase')}
            />
            {!resource.isTolerationCase &&
                <>
                    <Field
                        onValueChange={val => dispatch(setActivationType(val))}
                        isEditing={isEditing || isAdding}
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        valueCheckFunction={activationType => isFieldValid(ControllabilitySchema, [SchemaConstants.isTolerationCase, SchemaConstants.activationType], {
                            activationType,
                            isTolerationCase: resource.isTolerationCase
                        })}
                        defaultValue={activationTypeAllowedValues.find(f => f.code === resource.activationType?.code)}
                        title={t("controllable_resources_details.activationType")}
                        description={t("controllable_resources_details.activationTypeDescription")}
                        values={ActivationTypeFromAccountingModel(activationTypeAllowedValues, resource.accountingModel)}
                        keyField="code"
                        textField="name"
                        inputType='select'
                    />
                    <Field
                        maxFractionDigits={0}
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        onValueChange={val => dispatch(setProcessingTimeEiv(val))}
                        isEditing={isEditing || isAdding}
                        endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")}/>}
                        inputType="number"
                        valueCheckFunction={processingTimeEiv => isFieldValid(ControllabilitySchema, [SchemaConstants.isTolerationCase, SchemaConstants.processingTimeEiv], {
                            processingTimeEiv,
                            isTolerationCase: resource.isTolerationCase
                        })}
                        defaultValue={resource.processingTimeEiv}
                        title={t("controllable_resources_details.processingTimeEiv")}
                        description={t("controllable_resources_details.processingTimeEivDescription")}
                    />
                </>
            }
            <Field
                isFilledByEiv
                filledByEivText={t("filled_by_eiv")}
                onValueChange={val => dispatch(setFixation(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={fixation => isFieldValid(ControllabilitySchema, [SchemaConstants.fixation], { fixation })}
                title={t("controllable_resources_details.fixation")}
                values={fixationAllowedCodes}
                defaultValue={fixationAllowedCodes.find(f => f.code === resource.fixation?.code)}
                keyField="code"
                textField="name"
                inputType='select'
            />
            <Field
                onValueChange={val => dispatch(setControllabilityType(Number.parseInt(val?.code ?? "", 10)))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={controllabilityType => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType], { controllabilityType: controllabilityType?.code })}
                defaultValue={{
                    code: `${resource.controllabilityType}`,
                    name: controllabilityTypeAllowedValues.find(r => r.code === `${resource.controllabilityType}`)?.name
                }}
                title={t('controllable_resources_details.controllabilityType')}
                values={controllabilityTypeAllowedValues}
                keyField="code"
                textField="name"
                inputType='select'
            />
            <Field
                isFilledByEiv
                filledByEivText={t("filled_by_eiv")}
                onValueChange={val => { if (val !== undefined) dispatch(setUnit(val)) }}
                isEditing={isEditing || isAdding}
                valueCheckFunction={unit => isFieldValid(ControllabilitySchema, [SchemaConstants.unit], { unit })}
                title={t('controllable_resources_details.unit')}
                values={UnitFromTolerationCase(resource.isTolerationCase, unitAllowedCodes, resource.activationType)}
                defaultValue={unitAllowedCodes.find(f => f.code === resource.unit?.code)}
                keyField="code"
                textField="name"
                inputType='select'
            />
            {ControllabilitySchema.pick([SchemaConstants.unit]).isValidSync({ unit: resource.unit }) &&
                <>
                    {resource.controllabilityType === ControllabilityType.LEVELS &&
                        <GeneralSimpleElementTable
                            canReorder
                            noRecordsText={t("no_records_text")}
                            onMoreThanOneError={() => {
                                dispatch(setNotificationAlertMessage("alert_messages.one_by_one"))
                            }}
                            addButtonText={t('toolbar_buttons.add')}
                            filledByEivText={t('filled_by_eiv')}
                            defaultOpen
                            maxFractionDigits={3}
                            type="number"
                            title={t("controllable_resources_details.levels")}
                            description={t("controllable_resources_details.levelsDescription")}
                            pageStatus={pageStatus}
                            actions={getLevelsTableActions()}
                            defaultElement={undefined}
                            elements={resource.levels}
                            valueCheckFunction={val => isSchemaValid(LevelSchema, val)}
                            gridCheckFunction={levels => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType, SchemaConstants.unit, SchemaConstants.levels],
                                {
                                    controllabilityType: resource.controllabilityType,
                                    unit: resource.unit,
                                    levels
                                })}
                        />
                    }
                    {resource.controllabilityType === ControllabilityType.STEPS &&
                        <>
                            <Field
                                isFilledByEiv
                                filledByEivText={t("filled_by_eiv")}
                                isEditing={isEditing || isAdding}
                                maxFractionDigits={3}
                                defaultValue={resource.stepLength}
                                onValueChange={val => dispatch(setStepLength(val))}
                                inputType="number"
                                valueCheckFunction={stepLength => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType, SchemaConstants.unit, SchemaConstants.stepLength], {
                                    controllabilityType: resource.controllabilityType,
                                    unit: resource.unit,
                                    stepLength
                                })}
                                title={t("controllable_resources_details.stepLength")}
                                description={t("controllable_resources_details.stepLengthDescription")}
                            />
                            <Field
                                isFilledByEiv
                                filledByEivText={t("filled_by_eiv")}
                                maxFractionDigits={3}
                                isEditing={isEditing || isAdding}
                                defaultValue={resource.minSteps}
                                onValueChange={val => dispatch(setMinSteps(val))}
                                inputType="number"
                                valueCheckFunction={minSteps => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType, SchemaConstants.unit, SchemaConstants.minSteps], {
                                    controllabilityType: resource.controllabilityType,
                                    unit: resource.unit,
                                    minSteps
                                })}
                                title={t("controllable_resources_details.minSteps")}
                                description={t("controllable_resources_details.minStepsDescription")}
                            />
                            <Field
                                isFilledByEiv
                                filledByEivText={t("filled_by_eiv")}
                                maxFractionDigits={3}
                                isEditing={isEditing || isAdding}
                                defaultValue={resource.maxSteps}
                                onValueChange={val => dispatch(setMaxSteps(val))}
                                inputType="number"
                                valueCheckFunction={maxSteps => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType, SchemaConstants.unit, SchemaConstants.maxSteps], {
                                    controllabilityType: resource.controllabilityType,
                                    unit: resource.unit,
                                    maxSteps
                                })}
                                title={t("controllable_resources_details.maxSteps")}
                                description={t("controllable_resources_details.maxStepsDescription")}
                            />
                        </>
                    }
                </>
            }
        </>
    )
}

export default ControllabilitySubGrid