import { createDateTime } from "ndr-designsystem";
import Downtime from "../../fixed/Downtime/Downtime";

export default interface DowntimeDto {
    id: string,
    revision?: number,
    start: string,
    periodEnd: string,
    type: string
    
}

export const fromDto = (dto: DowntimeDto): Downtime => ({
    ...dto,
    start: createDateTime(dto.start),
    periodEnd: createDateTime(dto.periodEnd)
})
