import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
    palette: {
        primary: { main: '#ef870f' },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*::-webkit-scrollbar': {
                    height: '0.5em'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(239, 135, 15, .3)',
                    borderRadius: 100
                }
            }
        }
    }

});

export default Theme;