/**
 *
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     *
     * @type {string}
     * @memberof Unit
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof Unit
     */
    name?: string;
}

export const allowedCodes = ["MAW", "P1"];
export const allowedControlGroupCodes = ["P1"]
