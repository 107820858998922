import React, { ReactElement } from "react";
import { GridRowProps } from "@progress/kendo-react-grid";
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";
import ControllableResourceStatus from "../../../api/fixed/ControllableResource/ControllableResourceStatus";

const RowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps): ReactElement => {
    const { dataItem } = props as { dataItem: ControllableResource };
    const green = { backgroundColor: "rgba(55, 180, 0,0.32)" };
    const red = { backgroundColor: "rgba(243, 23, 0, 0.32)" };
    const blue = { backgroundColor: "rgba(101,206,246,0.32)" };

    let trProps;
    if (dataItem.hasSuccessfulDispatch) {
        switch (dataItem.status) {
            case ControllableResourceStatus.Accepted:
                trProps = { style: green };
                break;
            case ControllableResourceStatus.Rejected:
                trProps = { style: red };
                break;
            default:
                trProps = { style: blue };
                break;
        }
    } else if (dataItem.status === ControllableResourceStatus.Rejected) {
        trProps = { style: red };
    }

    return React.cloneElement(
        trElement,
        // @ts-ignore
        { ...trProps, height: 80 },
        trElement.props.children
    );
}

export default RowRender
