import React, { ReactElement } from "react";
import { IconButton } from "@mui/material";
import { Cached } from "@mui/icons-material";
import { CustomButton } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { setAddNewMarketPartnerData } from "../../appStateSlice";
import useStyles from "../styles";
import { setMarketPartners } from "../store/marketPartnersSlice";
import { fetchMarketPartners } from "../store/thunks";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

const Toolbar = (): ReactElement => {
    const classes = useStyles();
    const { allPartners } = useAppSelector(state => state.marketPartners)
    const { t } = useTranslation("tables")
    const dispatch = useAppDispatch();

    const forceRefresh = async (): Promise<void> => {
        dispatch(setMarketPartners(undefined))
        await dispatch(fetchMarketPartners(true))
    };

    return <div className={classes.addNewRightButton}>
        <IconButton color="primary" disabled={allPartners === undefined} component="span"
                    onClick={forceRefresh}>
            <Cached/>
        </IconButton>
        <CustomButton disabled={allPartners === undefined} title={t("toolbar_buttons.add")}
                      onClick={() => dispatch(setAddNewMarketPartnerData({
                          show: true,
                          type: undefined,
                          fieldPath: undefined,
                          data: undefined
                      }))}/>
    </div>
}

export default Toolbar