enum Endpoints {
    login = 'connect/token',
    controllableResources = 'controllable-resources',
    technicalResources = 'technical-resources',
    marketPartners = 'market-partners',
    tranches = 'tranches',
    controlGroups = 'control-groups',
    accountingGroupBalancingSchedules = 'accounting-group-balancing-schedules'
}


export default Endpoints;
