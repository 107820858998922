import React, { ReactElement, useMemo } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { DateTime } from "luxon";
import { toLocaleDate } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import DataSource from "api/dtos/PlanningData/DataSource";
import { useAppSelector } from "../../../app/hooks";
import CustomChart from "../../../Components/Chart";
import { currentProductionDataSelector } from "../productionData/store/slice";

const TimeSeriesChart = (): ReactElement => {
    const { currentPlanningData, selectedTimeSeries, showProductionData } = useAppSelector(
        (state) => state.planningData,
    );
    const currentProductionData = useAppSelector(
        currentProductionDataSelector,
        (left, right) => left?.id !== undefined && left?.id === right?.id,
    );
    const internationalization = useInternationalization();
    const { t } = useTranslation("tables");

    const chartData = useMemo(() => {
        if (currentPlanningData && selectedTimeSeries !== undefined) {
            const dataset: any[] = [];

            const values = currentPlanningData.timeSeries[selectedTimeSeries].values.map((v) => ({
                x: v.timestamp,
                y: v.value,
            }));

            let productionDataValues: { x: DateTime; y: number }[] = [];
            if (currentProductionData && showProductionData) {
                productionDataValues = currentProductionData.dataPoints.map((v) => ({ x: v.timestamp, y: v.value }));
            }

            const isTestData = currentPlanningData.dataSource === DataSource.TestPlanningData;

            dataset.push({
                label: isTestData
                    ? t("timeSeries.chart_labels.test_planning_data")
                    : t("timeSeries.chart_labels.planning_data"),
                data: values.map((v) => v.y * 1000),
                backgroundColor: "#ef870f",
                borderColor: "#e8cab9",
                yAxisID: "y",
            });

            if (productionDataValues.length > 0 && showProductionData) {
                dataset.push({
                    label: t("timeSeries.chart_labels.production_data"),
                    data: productionDataValues.map((v) => v.y),
                    backgroundColor: "#345a05",
                    borderColor: "#83b008",
                    yAxisID: "y",
                });
            }

            return {
                labels: values.map((v) => v.x),
                datasets: dataset,
            };
        }
        return { labels: [""], datasets: [] };
    }, [currentPlanningData, selectedTimeSeries, currentProductionData, t, showProductionData]);

    const getChartPlugins = useMemo(
        () => ({
            tooltip: {
                callbacks: {
                    label: (item: any) => {
                        if (item.dataset.label === t("timeSeries.chart_labels.production_data")) {
                            return `${item.dataset.label}: ${item.formattedValue} ${currentProductionData?.unitName}`;
                        }
                        return `${item.dataset.label}: ${item.formattedValue} kW`;
                    },
                    title: (items: any) =>
                        toLocaleDate(items[0].parsed.x, internationalization.locale, DateTime.DATETIME_SHORT),
                },
            },
            legend: {
                display: false,
            },
        }),
        [internationalization, t, currentProductionData],
    );

    const getChartScales = useMemo(
        () => ({
            y: {
                ticks: {
                    callback: (value: number) =>
                        `${internationalization.formatNumber(value, {
                            useGrouping: true,
                            maximumFractionDigits: 3,
                        })} kW`,
                },
                offset: true,
            },
            x: {
                adapters: {
                    date: {
                        zone: "Europe/Berlin",
                    },
                },
                type: "time",
                time: {
                    unit: "minute",
                    ticks: {
                        source: "data",
                    },
                    stepSize: 15,
                    displayFormats: {
                        hour: internationalization.locale === "de" ? "HH" : "h a",
                        minute: internationalization.locale === "de" ? "HH:mm" : "h:mm a",
                        second: internationalization.locale === "de" ? "HH:mm:ss" : "h:mm:ss a",
                    },
                },
                offset: true,
            },
        }),
        [internationalization],
    );

    return (
        <CustomChart
            maintainAspectRatio={false}
            locale={internationalization.locale}
            plugins={getChartPlugins}
            scales={getChartScales}
            data={chartData}
        />
    );
};

export default TimeSeriesChart;
