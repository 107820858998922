import { MarketPartner } from "api/fixed/MarketPartner";

export interface MarketPartnerDto {
    inventoryItemId?: string
    encoding?: {
        code?: string
        name?: string
    }
    code?: string
    name?: string
    _revision?: number
}

export const toDto = (original: MarketPartner): MarketPartnerDto => {
    const backup = { ...original }

    delete backup.inventoryItemId

    return {
        ...backup
    }
}