import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import { LoadingComponent, useLocalStorage } from "ndr-designsystem";
import { DateTime } from "luxon";
import TimeSeriesGrid from "./Grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { View } from "../ToggleViewButton";
import TimeSeriesChart from "./Chart";
import { fetchProductionDataByCR } from "./store/thunks";
import Toolbar from "./Toolbar";
import { setCurrentProductionData } from "./store/slice";

export type TimeSeriesViewType = "grid" | "chart"

const DataView = (): ReactElement => {
    const { currentResource } = useAppSelector(state => state.controllableResources);
    const { currentProductionData } = useAppSelector(state => state.productionData);
    const { headerHeight } = useAppSelector(state => state.appState)
    const [currentlyViewing, setCurrentlyViewing] = useLocalStorage<TimeSeriesViewType>("timeseries.view", {
        pollingInterval: -1,
        initialValue: "chart"
    })
    const dispatch = useAppDispatch();

    const onCurrentlyViewingChange = (view: View): void => {
        setCurrentlyViewing(view)
    }

    const loadData = (startDate: DateTime, endDate: DateTime): void => {
        if (currentResource != null) {
            dispatch(setCurrentProductionData(undefined))
            dispatch(fetchProductionDataByCR({
                id: currentResource.inventoryItemId!,
                from: startDate,
                to: endDate,
            }));
        }
    }

    return (
        <Box display="flex" flexDirection="column" height={`calc(100vh - ${headerHeight + 190}px)`}>
            <Toolbar currentlyViewing={currentlyViewing} onCurrentlyViewingChange={onCurrentlyViewingChange}
                     onLoadData={loadData}/>
            {currentProductionData === undefined ?
                <LoadingComponent/> :
                <Box height="100%">
                    {currentlyViewing === "grid" ? <TimeSeriesGrid/> : <TimeSeriesChart/>}
                </Box>
            }
        </Box>
    )
}

DataView.defaultProps = {
    currentlyViewing: undefined
}

export default DataView;
