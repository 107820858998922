import { createDateTime } from "ndr-designsystem";
import GeneratedCostInfoTimeSeriesDto, {
    fromDto as fromGeneratedCostInfoTimeSeriesDto
} from "./GeneratedCostInfoTimeSeriesDto";
import GeneratedCostInfoDetails from "../../fixed/GeneratedCostInfo/GeneratedCostInfoDetails";

export default interface GeneratedCostInfoDetailsDto {
    date: string,
    dispatchId: string,
    dispatchedAt: string,
    status: string,
    timeSeries: GeneratedCostInfoTimeSeriesDto[]
}

export const fromDto = (dto: GeneratedCostInfoDetailsDto): GeneratedCostInfoDetails => ({
    ...dto,
    timeSeries: dto.timeSeries.map(fromGeneratedCostInfoTimeSeriesDto),
    date: createDateTime(dto.date),
    dispatchedAt: createDateTime(dto.dispatchedAt)
})
