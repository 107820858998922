import React, { ReactElement } from "react";
import { CustomEndAdornment, Field, isFieldValid, isSchemaValid } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import { FieldWithGridProps, PageStatus } from "../../utils/types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    setAccumulatedInverterPower,
    setConsumptionNetBottleneckCapacity,
    setConsumptionNetRatedCapacity,
    setDirection,
    setEfficiencyOfEnergyStorage,
    setGrossRatedCapacity,
    setHubHeight,
    setIncline,
    setLatitude,
    setLongitude,
    setMaxEffectiveRetrievingCapacity,
    setMaxEffectiveStoringCapacity,
    setMissingValueForDirection,
    setMissingValueForIncline,
    setPlantType,
    setProductionNetBottleneckCapacity,
    setProductionNetRatedCapacity,
    setReduction70Percent,
    setUsableEnergyContentOfEnergyStorage,
    setWindPowerCurveElementAtIndex,
    setWindPowerCurveNetRatedCapacityWindSpeed,
    setWindPowerCurveShutdownWindSpeed,
} from "./store/technicalResourcesSlice";
import { TRTechnicalParametersSchema, WindPowerCurveElementSchema } from "../../utils/inputChecking";
import SchemaConstants from "../../utils/schemaConsts";
import WindPowerCurve from "../../Components/WindPowerCurve";

const TechnicalParametersSubGrid = ({ pageStatus }: FieldWithGridProps): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { currentResource: resource } = useAppSelector(state => state.technicalResources);
    const controllableResource = useAppSelector(state => state.controllableResources.currentResource);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");

    const TechnicalParametersSchema = TRTechnicalParametersSchema(controllableResource?.energySource, resource?.technicalResourceType?.code === "SSE", resource?.missingValueForDirection, resource?.missingValueForIncline);
    if (resource === null || resource === undefined) {
        return <></>;
    }

    return (
        <>
            <Field
                maxFractionDigits={3}
                onValueChange={val => dispatch(setProductionNetRatedCapacity(val))}
                isEditing={isEditing || isAdding}
                endAdornment={<CustomEndAdornment content="MW"/>}
                valueCheckFunction={productionNetRatedCapacity => isFieldValid(TechnicalParametersSchema, [SchemaConstants.productionNetRatedCapacity], { productionNetRatedCapacity })}
                inputType="number"
                defaultValue={resource.productionNetRatedCapacity}
                title={t("technical_resources_details.productionNetRatedCapacity")}
                description={t("technical_resources_details.productionNetRatedCapacityDescription")}
            />
            {controllableResource?.energySource &&
                ["B18", "B19"].includes(controllableResource?.energySource.code ?? "") &&
                <>
                    <Field
                        endAdornment={<CustomEndAdornment content="m/s"/>}
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setWindPowerCurveNetRatedCapacityWindSpeed(val))}
                        isEditing={isEditing || isAdding}
                        notificationOnDelete={false}
                        valueCheckFunction={netRatedCapacityWindSpeed => isFieldValid(TechnicalParametersSchema, [SchemaConstants.netRatedCapacityWindSpeed], {
                            netRatedCapacityWindSpeed
                        })}
                        inputType="number"
                        defaultValue={resource.windPowerCurve?.netRatedCapacityWindSpeed}
                        title={t('technical_resources_details.netRatedCapacityWindSpeed')}
                        description={t('technical_resources_details.netRatedCapacityWindSpeedDescription')}
                    />
                    <Field
                        endAdornment={<CustomEndAdornment content="m/s"/>}
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setWindPowerCurveShutdownWindSpeed(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={shutdownWindSpeed => isFieldValid(TechnicalParametersSchema, [SchemaConstants.shutdownWindSpeed], {
                            netRatedCapacityWindSpeed: resource.windPowerCurve?.netRatedCapacityWindSpeed ?? 0,
                            shutdownWindSpeed
                        })}
                        inputType="number"
                        defaultValue={resource.windPowerCurve?.shutdownWindSpeed}
                        title={t('technical_resources_details.shutdownWindSpeed')}
                        description={t('technical_resources_details.shutdownWindSpeedDescription')}
                    />
                </>
            }
            {controllableResource?.energySource &&
                ["B18", "B19"].includes(controllableResource?.energySource.code ?? "") &&
                resource.productionNetRatedCapacity !== undefined &&
                resource.productionNetRatedCapacity !== 0 &&
                <WindPowerCurve
                    elements={resource.windPowerCurve?.elements ?? []}
                    valueCheckFunction={(val, index) => isSchemaValid(WindPowerCurveElementSchema(resource.windPowerCurve?.elements ?? [], index, (resource.productionNetRatedCapacity ?? 0) * 1000), val)}
                    updateElements={(value, index) => dispatch(setWindPowerCurveElementAtIndex({ value, index }))}
                    pageStatus={pageStatus}
                    maxFractionDigits={3}
                    defaultOpen
                    title={t("technical_resources_details.windPowerCurve")}
                    gridCheckFunction={() => isFieldValid(TechnicalParametersSchema, [SchemaConstants.windPowerCurve], {
                        windPowerCurve: resource.windPowerCurve,
                        // in MW
                        productionNetRatedCapacity: (resource.productionNetRatedCapacity ?? 0) * 1000
                    })}
                />
            }
            <Field
                maxFractionDigits={3}
                onValueChange={val => dispatch(setProductionNetBottleneckCapacity(val))}
                isEditing={isEditing || isAdding}
                endAdornment={<CustomEndAdornment content="MW"/>}
                valueCheckFunction={productionNetBottleneckCapacity => isFieldValid(TechnicalParametersSchema, [SchemaConstants.productionNetBottleneckCapacity], { productionNetBottleneckCapacity })}
                inputType="number"
                defaultValue={resource.productionNetBottleneckCapacity}
                title={t('technical_resources_details.productionNetBottleneckCapacity')}
                description={t('technical_resources_details.productionNetBottleneckCapacityDescription')}
            />
            {resource.technicalResourceType?.code === "SSE" &&
                <>
                    <Field
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setConsumptionNetRatedCapacity(val))}
                        isEditing={isEditing || isAdding}
                        endAdornment={<CustomEndAdornment content="MW"/>}
                        valueCheckFunction={consumptionNetRatedCapacity => isFieldValid(TechnicalParametersSchema, [SchemaConstants.consumptionNetRatedCapacity], { consumptionNetRatedCapacity })}
                        inputType="number"
                        defaultValue={resource.consumptionNetRatedCapacity}
                        title={t("technical_resources_details.consumptionNetRatedCapacity")}
                        description={t("technical_resources_details.consumptionNetRatedCapacityDescription")}
                    />
                    <Field
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setConsumptionNetBottleneckCapacity(val))}
                        endAdornment={<CustomEndAdornment content="MW"/>}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={consumptionNetBottleneckCapacity => isFieldValid(TechnicalParametersSchema, [SchemaConstants.consumptionNetBottleneckCapacity], { consumptionNetBottleneckCapacity })}
                        inputType="number"
                        defaultValue={resource.consumptionNetBottleneckCapacity}
                        title={t("technical_resources_details.consumptionNetBottleneckCapacity")}
                        description={t("technical_resources_details.consumptionNetBottleneckCapacityDescription")}
                    />
                </>
            }
            <Field
                maxFractionDigits={3}
                onValueChange={val => dispatch(setGrossRatedCapacity(val))}
                endAdornment={<CustomEndAdornment content="MW"/>}
                isEditing={isEditing || isAdding}
                valueCheckFunction={grossRatedCapacity => isFieldValid(TechnicalParametersSchema, [SchemaConstants.grossRatedCapacity], { grossRatedCapacity })}
                inputType="number"
                defaultValue={resource.grossRatedCapacity}
                title={t('technical_resources_details.grossRatedCapacity')}
                description={t('technical_resources_details.grossRatedCapacityDescription')}
            />
            {controllableResource?.energySource && controllableResource?.energySource.code === "B16" &&
                <>
                    <Field
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setAccumulatedInverterPower(val))}
                        endAdornment={<CustomEndAdornment content="MW"/>}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={accumulatedInverterPower => isFieldValid(TechnicalParametersSchema, [SchemaConstants.accumulatedInverterPower], { accumulatedInverterPower })}
                        inputType="number"
                        defaultValue={resource.accumulatedInverterPower}
                        title={t("technical_resources_details.accumulatedInverterPower")}
                        description={t("technical_resources_details.accumulatedInverterPowerDescription")}
                    />
                    <Field
                        maxFractionDigits={0}
                        onValueChange={val => dispatch(setDirection(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={direction => isFieldValid(TechnicalParametersSchema, [SchemaConstants.direction], { direction })}
                        inputType="compass"
                        onMissingValueSwitchChange={val => dispatch(setMissingValueForDirection(val))}
                        missingValue={resource.missingValueForDirection}
                        labelText={t("angle")}
                        coordinates={[
                            t("compass.north"),
                            t("compass.west"),
                            t("compass.east"),
                            t("compass.south")
                        ]}
                        defaultValue={resource.direction}
                        title={t('technical_resources_details.direction')}
                        description={t('technical_resources_details.directionDescription')}
                    />
                    <Field
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setIncline(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={incline => isFieldValid(TechnicalParametersSchema, [SchemaConstants.incline], { incline })}
                        inputType="number"
                        onMissingValueSwitchChange={val => dispatch(setMissingValueForIncline(val))}
                        missingValue={resource.missingValueForIncline}
                        endAdornment={<CustomEndAdornment content="°"/>}
                        defaultValue={resource.incline}
                        title={t('technical_resources_details.incline')}
                        description={t('technical_resources_details.inclineDescription')}
                    />
                    <Field
                        onValueChange={val => dispatch(setReduction70Percent(val))}
                        inputType='boolean'
                        isEditing={isEditing || isAdding}
                        defaultValue={resource.reduction70Percent}
                        title={t('technical_resources_details.reduction70Percent')}
                        description={t('technical_resources_details.reduction70PercentDescription')}
                    />
                </>
            }
            {controllableResource?.energySource && ["B18", "B19"].includes(controllableResource?.energySource.code ?? "") &&
                <>
                    <Field
                        inputType="controlledText"
                        onValueChange={val => dispatch(setPlantType(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={plantType => isFieldValid(TechnicalParametersSchema, [SchemaConstants.plantType], { plantType })}
                        defaultValue={resource.plantType}
                        title={t("technical_resources_details.plantType")}
                        description={t("technical_resources_details.plantTypeDescription")}
                    />
                    <Field
                        onValueChange={val => dispatch(setHubHeight(val))}
                        isEditing={isEditing || isAdding}
                        maxFractionDigits={2}
                        valueCheckFunction={hubHeight => isFieldValid(TechnicalParametersSchema, [SchemaConstants.hubHeight], { hubHeight })}
                        inputType="number"
                        defaultValue={resource.hubHeight}
                        title={t("technical_resources_details.hubHeight")}
                        description={t("technical_resources_details.hubHeightDescription")}
                    />
                </>
            }
            <Field
                onValueChange={val => dispatch(setLongitude(val))}
                isEditing={isEditing || isAdding}
                inputType="number"
                maxFractionDigits={6}
                valueCheckFunction={longitude => isFieldValid(TechnicalParametersSchema, [SchemaConstants.longitude], { longitude })}
                defaultValue={resource.longitude}
                title={t('technical_resources_details.longitude')}
                description={t('technical_resources_details.longitudeDescription')}
            />
            <Field
                onValueChange={val => dispatch(setLatitude(val))}
                isEditing={isEditing || isAdding}
                inputType="number"
                maxFractionDigits={6}
                valueCheckFunction={latitude => isFieldValid(TechnicalParametersSchema, [SchemaConstants.latitude], { latitude })}
                defaultValue={resource.latitude}
                title={t('technical_resources_details.latitude')}
                description={t('technical_resources_details.latitudeDescription')}
            />
            {resource.technicalResourceType && resource.technicalResourceType.code === "SSE" &&
                <>
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setEfficiencyOfEnergyStorage(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={efficiencyOfEnergyStorage => isFieldValid(TechnicalParametersSchema, [SchemaConstants.efficiencyOfEnergyStorage], { efficiencyOfEnergyStorage })}
                        inputType="number"
                        defaultValue={resource.efficiencyOfEnergyStorage}
                        title={t('technical_resources_details.efficiencyOfEnergyStorage')}
                        description={t('technical_resources_details.efficiencyOfEnergyStorageDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={6}
                        onValueChange={val => dispatch(setUsableEnergyContentOfEnergyStorage(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={usableEnergyContentOfEnergyStorage => isFieldValid(TechnicalParametersSchema, [SchemaConstants.usableEnergyContentOfEnergyStorage], { usableEnergyContentOfEnergyStorage })}
                        inputType="number"
                        defaultValue={resource.usableEnergyContentOfEnergyStorage}
                        title={t('technical_resources_details.usableEnergyContentOfEnergyStorage')}
                        description={t('technical_resources_details.usableEnergyContentOfEnergyStorageDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setMaxEffectiveStoringCapacity(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={maxEffectiveStoringCapacity => isFieldValid(TechnicalParametersSchema, [SchemaConstants.maxEffectiveStoringCapacity], { maxEffectiveStoringCapacity })}
                        inputType="number"
                        defaultValue={resource.maxEffectiveStoringCapacity}
                        title={t('technical_resources_details.maxEffectiveStoringCapacity')}
                        description={t('technical_resources_details.maxEffectiveStoringCapacityDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={3}
                        onValueChange={val => dispatch(setMaxEffectiveRetrievingCapacity(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={maxEffectiveRetrievingCapacity => isFieldValid(TechnicalParametersSchema, [SchemaConstants.maxEffectiveRetrievingCapacity], { maxEffectiveRetrievingCapacity })}
                        inputType="number"
                        defaultValue={resource.maxEffectiveRetrievingCapacity}
                        title={t('technical_resources_details.maxEffectiveRetrievingCapacity')}
                        description={t('technical_resources_details.maxEffectiveRetrievingCapacityDescription')}
                    />
                </>
            }
        </>
    )
}

export default TechnicalParametersSubGrid;
