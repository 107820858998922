import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface IValidationError {
    code: string;
    message: string;
    propertyName: string;
    excelValueName: string;
    attemptedValue: any;
    values: { [name: string]: any }
}

export interface IExcelImportResult {
    technicalResourceCreated: boolean;
    controllableResourceCreated: boolean;
    controllableResourceUpdated: boolean;
    controlGroupCreated: boolean;
    controlGroupUpdated: boolean;
    technicalResourceId: string;
    controllableResourceId: string;
    controlGroupId: string;
    validationErrors: IValidationError[];
    fileName: string;
}

export interface ExcelImportState {
    results: IExcelImportResult[];
}

const initialState: ExcelImportState = {
    results: []
};

export const excelImportSelector = (s: RootState): ExcelImportState => s.excelImport;
export const excelImportResults = createSelector(excelImportSelector, s => s.results);

export const excelImportSlice = createSlice({
    name: 'excelImport',
    initialState,
    reducers: {
        addResult(state, { payload }: PayloadAction<IExcelImportResult>) {
            state.results.push(payload);
        },
        addResults(state, { payload }: PayloadAction<IExcelImportResult[]>) {
            state.results.push(...payload);
        },
        clearResults(state) {
            state.results = [];
        }
    }
});

export const { addResult, addResults, clearResults } = excelImportSlice.actions;

export default excelImportSlice.reducer;
