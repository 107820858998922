import { AccountingGroupBalancingSchedule } from "api/fixed/AccountingGroupBalancingSchedule/AccountingGroupBalancingSchedule";

export interface AccountingGroupBalancingScheduleDto {
    controllableResourceId?: string;
    RequestingGridOperators?: {
        internalId?: string;
        accountingGroup?: string;
        marketPartnerId?: string;
    }[];
}

export const toDto = (original: AccountingGroupBalancingSchedule): AccountingGroupBalancingScheduleDto => {
    const backup = { ...original };

    delete backup.inventoryItemId;
    delete backup.controllableResource;
    delete backup.requestingGridOperators;

    return {
        ...backup,
        controllableResourceId: original.controllableResource?.internalId,
        RequestingGridOperators: original.requestingGridOperators?.map((r) => ({
            internalId: r.internalId,
            accountingGroup: r.accountingGroup,
            marketPartnerId: r.marketPartner?.internalId,
        })),
    };
};
