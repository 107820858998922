import React, { ReactElement, useMemo, useRef, useState } from "react";
import { Box, DialogTitle, IconButton, } from "@mui/material";
import { DateTime } from "luxon";
import { GridCellProps, GridDetailRowProps, GridRowProps } from "@progress/kendo-react-grid";
import { Add, Check, Close, FileDownload, Remove } from "@mui/icons-material";
import { ClosableDialog, GridField } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { useAppSelector } from "../../../../app/hooks";
import { auditStepsSelector, AuditStepsWithDetails } from "../store/store";
import DateCell from "../../../../Components/DateCell";
import ConnectPlusMessagesTable from "./ConnectPlusMessagesTable";
import Api from "../../../../api/Api";

interface Props {
    onClose: () => void
}

const AuditLogDialog = ({ onClose }: Props): ReactElement => {
    const auditSteps = useAppSelector(auditStepsSelector, (left, right) => left?.length === right?.length);
    const [showDetailsForStep, setShowDetailsForStep] = useState<number | undefined>(undefined)
    const [selected, setSelected] = useState(0);
    const { currentActivation } = useAppSelector(state => state.activations)
    const { t } = useTranslation("tables")
    const boxRef = useRef<HTMLElement>();
    const expandableAuditSteps = useMemo(() => auditSteps?.map((step, id) => ({
        ...step,
        showDetails: showDetailsForStep === id
    })), [auditSteps, showDetailsForStep])

    const downloadAttachment = async (mail: AuditStepsWithDetails): Promise<void> => {
        if (!boxRef.current) return;
        if (!currentActivation) return;

        const element = document.createElement('a');
        const blob = await Api.fetchAttachmentEmail(currentActivation.id, mail.id ?? "")

        element.setAttribute('href', URL.createObjectURL(blob))

        element.setAttribute('download', `Activation - ${currentActivation.activationDate}.xlsx`);
        element.style.display = 'none';
        boxRef.current.appendChild(element);
        element.click();

        boxRef.current.removeChild(element);
    }

    const gridFields = [
        {
            customCell: (props: GridCellProps) => {
                const { dataIndex, dataItem }: { dataIndex: number, dataItem: AuditStepsWithDetails } = props
                return <td>
                    {dataItem.details !== undefined &&
                        <IconButton
                            onClick={() => setShowDetailsForStep(showDetailsForStep === dataIndex ? undefined : dataIndex)}>
                            {dataIndex === showDetailsForStep ? <Remove style={{ fontSize: 16 }}/> :
                                <Add style={{ fontSize: 16 }}/>}
                        </IconButton>
                    }
                    {dataItem.attachment !== undefined &&
                        <IconButton
                            onClick={() => downloadAttachment(dataItem)}>
                            <FileDownload sx={{ fontSize: 16 }}/>
                        </IconButton>
                    }
                </td>
            }
        },
        {
            title: t("audit.step"),
            customCell: (props: GridCellProps) => {
                const isEmailTitle = props.dataItem.title.includes("audit.email.title")
                const isPhoneTitle = props.dataItem.title.includes("audit.phone.title")
                const splitTitle = props.dataItem.title.split(":");
                return <td>{isEmailTitle || isPhoneTitle ? t(splitTitle[0], { to: splitTitle[1] }) : t(props.dataItem.title)}</td>
            }
        },
        {
            title: t("audit.date"), customCell: (props: GridCellProps) => <DateCell {...props} fieldPath="date"
                                                                                    displayFormat={DateTime.DATETIME_MED_WITH_SECONDS}/>
        },
        {
            title: t("audit.success"),
            customCell: (props: GridCellProps): ReactElement => {
                const { dataItem } = props;
                return <td>{dataItem.success ? <Check/> : <Close/>}</td>
            }
        }
    ];

    const detailsGridFields = useMemo((): GridFieldElement[] => [
        {
            title: t("audit.step"),
            customCell: ({ dataItem }) => <td>{t(dataItem.title)}</td>,
        },
        {
            title: t("audit.date"), customCell: (props: GridCellProps) => <DateCell {...props} fieldPath="date"
                                                                                    displayFormat={DateTime.DATETIME_MED_WITH_SECONDS}/>
        }
    ], [t])

    const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ): ReactElement => {
        const { dataItem }: { dataItem: AuditStepsWithDetails } = props;
        const green = { backgroundColor: "rgb(55, 180, 0,0.32)" };
        const red = { backgroundColor: "rgb(243, 23, 0, 0.32)" };

        let trProps;

        if (dataItem.success) {
            trProps = { style: green }
        } else {
            trProps = { style: red }
        }

        return React.cloneElement(
            trElement,
            // @ts-ignore
            { ...trProps },
            trElement.props.children
        );
    };

    return (
        <ClosableDialog
            contentPadding={2}
            dialogTitle={<DialogTitle>{t("audit.title")}</DialogTitle>}
            open
            onClose={onClose}
            maxWidth="xl">
            <Box ref={boxRef}/>
            <TabStrip selected={selected} onSelect={e => setSelected(e.selected)}>
                <TabStripTab title={t("audit.steps_performed")}>
                    <GridField
                        noRecordsText={t("no_records_text")}
                        rowRender={rowRender}
                        pageable={false}
                        detailComponent={(props: GridDetailRowProps) =>
                            <GridField pageable={false}
                                       noRecordsText={t("no_records_text")}
                                       values={props.dataItem.details}
                                       gridFields={detailsGridFields}
                                       defaultOpen
                            />}
                        defaultOpen gridFields={gridFields}
                        values={expandableAuditSteps}/>
                </TabStripTab>
                <TabStripTab title={t("audit.connectplus_messages")}>
                    <ConnectPlusMessagesTable/>
                </TabStripTab>
            </TabStrip>
        </ClosableDialog>
    )
}


export default AuditLogDialog
