import {
    setConsumptionAccountingGroup,
    setConsumptionSupplier,
    setConsumptionTranches,
    setConsumptionVoltageLevel,
    setConsumptionVoltageTransformation,
    setProductionAccountingGroup,
    setProductionSupplier,
    setProductionTranches,
    setProductionVoltageLevel,
    setProductionVoltageTransformation,
    updateNewConsumptionTrancheAccountingGroup,
    updateNewConsumptionTrancheExternalID,
    updateNewConsumptionTrancheSize,
    updateNewConsumptionTrancheSizeUnit,
    updateNewConsumptionTrancheSupplier,
    updateNewProductionTrancheAccountingGroup,
    updateNewProductionTrancheExternalID,
    updateNewProductionTrancheSize,
    updateNewProductionTrancheSizeUnit,
    updateNewProductionTrancheSupplier
} from "./store/technicalResourcesSlice";
import { FieldWithGridProps } from "../../utils/types";

export enum FieldForActions {
    PRODUCTION = "production",
    CONSUMPTION = "consumption"
}

export interface FieldWithBuiltActions extends FieldWithGridProps {
    field: FieldForActions
}

export interface MarketLocationSubGridActions {
    setSupplier: any
    setVoltageLevel: any
    setVoltageTransformation: any
    setAccountingGroup: any
}

export interface MeasurementLocationsTableActions {
    setElements: any
    updateNewElement: any
}

export interface TranchesTableActions {
    updateNewTrancheExternalID: any,
    setTranches: any,
    updateNewTrancheAccountingGroup: any,
    updateNewTrancheSize: any
    updateNewTrancheSizeUnit: any
    updateNewTrancheSupplier: any
}

export const buildMarketLocationGridActionsByField = (field: FieldForActions): MarketLocationSubGridActions => {
    if (field === FieldForActions.PRODUCTION)
        return {
            setSupplier: setProductionSupplier,
            setVoltageLevel: setProductionVoltageLevel,
            setVoltageTransformation: setProductionVoltageTransformation,
            setAccountingGroup: setProductionAccountingGroup,
        }

    return {
        setSupplier: setConsumptionSupplier,
        setVoltageLevel: setConsumptionVoltageLevel,
        setVoltageTransformation: setConsumptionVoltageTransformation,
        setAccountingGroup: setConsumptionAccountingGroup,
    }
}

export const buildTranchesTableActionsByField = (field: FieldForActions): TranchesTableActions => {
    if (field === FieldForActions.PRODUCTION)
        return {
            setTranches: setProductionTranches,
            updateNewTrancheAccountingGroup: updateNewProductionTrancheAccountingGroup,
            updateNewTrancheSize: updateNewProductionTrancheSize,
            updateNewTrancheSizeUnit: updateNewProductionTrancheSizeUnit,
            updateNewTrancheSupplier: updateNewProductionTrancheSupplier,
            updateNewTrancheExternalID: updateNewProductionTrancheExternalID
        }

    return {
        setTranches: setConsumptionTranches,
        updateNewTrancheAccountingGroup: updateNewConsumptionTrancheAccountingGroup,
        updateNewTrancheSize: updateNewConsumptionTrancheSize,
        updateNewTrancheSizeUnit: updateNewConsumptionTrancheSizeUnit,
        updateNewTrancheSupplier: updateNewConsumptionTrancheSupplier,
        updateNewTrancheExternalID: updateNewConsumptionTrancheExternalID
    }
}