import { createDateTime } from "ndr-designsystem";
import { AvatCallStatus } from "../../fixed/Activations/AvatCallStatus";
import AvatCall from "../../fixed/Activations/AvatCall";

export default interface AvatCallDto {
    id: string,
    status: AvatCallStatus,
    statusDetails: string,
    requestedAt: string
}

export const fromDto = (dto: AvatCallDto): AvatCall => ({
    ...dto,
    requestedAt: createDateTime(dto.requestedAt)
})
