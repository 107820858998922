import React, { ReactElement, useMemo } from "react";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridField } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import EmptyWhileEditingCell from "../../../Components/EmptyWhileEditingGridCell";
import DateCell from "../../../Components/DateCell";
import { currentActivationSelector } from "./store/store";
import PeriodInterval from "../../../api/fixed/Activations/PeriodInterval";

const TimeSeriesGrid = (): ReactElement => {
    const currentActivation = useAppSelector(currentActivationSelector, (left, right) => left?.id === right?.id);
    const { direction } = useAppSelector(state => state.activations)
    const internationalization = useInternationalization();
    const { t } = useTranslation("tables");

    const gridData = useMemo(() => {
        switch (direction) {
            case "up":
                return currentActivation?.upTimeSeries?.periodIntervals
            case "down":
                return currentActivation?.downTimeSeries?.periodIntervals
            case "avat":
                return currentActivation?.avatTimeSeriesValue
            case "absolute":
                return currentActivation?.absoluteTimeSeriesValue
            case "delta":
                return currentActivation?.deltaTimeSeriesValue
            default:
                return []
        }
    }, [direction, currentActivation])

    const MWCell = (props: GridCellProps): ReactElement => {
        const { dataItem }: { dataItem: PeriodInterval } = props;
        let unit: string;
        switch (direction) {
            case "up":
                unit = currentActivation?.upTimeSeries?.measureUnit.code === "MAW" ? "MW" : "%"
                break;
            case "down":
                unit = currentActivation?.downTimeSeries?.measureUnit.code === "MAW" ? "MW" : "%"
                break;
            case "avat":
                unit = "MW"
                break;
            case "absolute":
                unit = "MW"
                break;
            case "delta":
                unit = "MW"
                break;
            default:
                unit = "undefined"
                break;
        }

        return (
            <EmptyWhileEditingCell
                {...props}
                isEditing={false}
                defaultValue={dataItem && `${internationalization.formatNumber(dataItem.quantity, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })} ${unit}`}
            />
        );
    };

    const getGridFields = useMemo(() => ([
        {
            name: "timestamp",
            title: t("timeSeries.timestamp"),
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="timestamp"
                displayFormat={DateTime.TIME_SIMPLE}
            />
        },
        {
            name: "value",
            title: t("timeSeries.value"),
            customCell: MWCell
        }
        // we want to re-render cells only on internationalization change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [internationalization, currentActivation, direction]);

    return <GridField
        className="timeSeries_2"
        noRecordsText={t("no_records_text")}
        gridFields={getGridFields}
        defaultOpen
        take={10}
        dataItemKey="timestamp"
        values={gridData}
    />
}

export default TimeSeriesGrid;
