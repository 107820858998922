enum SchemaConstants {
    externalID = "externalID",
    name = "name",
    affectedGridOperatorIds = "affectedGridOperatorIds",
    affectedGridOperators = "affectedGridOperators",
    additionalAffectedGridOperatorIds = "additionalAffectedGridOperatorIds",
    additionalAffectedGridOperators = "additionalAffectedGridOperators",
    operationsManagerId = "operationsManagerId",
    energySource = "energySource",
    controlArea = "controlArea",
    compensationType = "compensationType",
    accountingModel = "accountingModel",
    minPower = "minPower",
    minOperatingTime = "minOperatingTime",
    minDownTime = "minDownTime",
    rampUpTimeCold = "rampupTimeCold",
    rampUpTimeHot = "rampupTimeHot",
    startupTimeCold = "startupTimeCold",
    startupTimeHot = "startupTimeHot",
    shutdownTime = "shutdownTime",
    ratedCapacityGradient = "ratedCapacityGradient",
    ratedCapacityGradientUnit = "ratedCapacityGradientUnit",
    ratedCapacityGradientBaseSize = "ratedCapacityGradientBaseSize",
    minCapacityGradient = "minCapacityGradient",
    minCapacityGradientUnit = "minCapacityGradientUnit",
    minCapacityGradientBaseSize = "minCapacityGradientBaseSize",
    marketMasterDataRegistryId = "marketMasterDataRegistryId",
    technicalResourceType = "technicalResourceType",
    powerPlantCode = "powerPlantCode",
    assignedStorages = "assignedStorages",
    eegUnitCodes = "eegUnitCodes",
    billingModel = "billingModel",
    operatorId = "operatorId",
    productionNetRatedCapacity = "productionNetRatedCapacity",
    productionNetBottleneckCapacity = "productionNetBottleneckCapacity",
    consumptionNetRatedCapacity = "consumptionNetRatedCapacity",
    consumptionNetBottleneckCapacity = "consumptionNetBottleneckCapacity",
    grossRatedCapacity = "grossRatedCapacity",
    accumulatedInverterPower = "accumulatedInverterPower",
    direction = "direction",
    incline = "incline",
    plantType = "plantType",
    hubHeight = "hubHeight",
    longitude = "longitude",
    latitude = "latitude",
    efficiencyOfEnergyStorage = "efficiencyOfEnergyStorage",
    usableEnergyContentOfEnergyStorage = "usableEnergyContentOfEnergyStorage",
    maxEffectiveStoringCapacity = "maxEffectiveStoringCapacity",
    maxEffectiveRetrievingCapacity = "maxEffectiveRetrievingCapacity",
    fixation = "fixation",
    controllabilityType = "controllabilityType",
    unit = "unit",
    levels = "levels",
    stepLength = "stepLength",
    minSteps = "minSteps",
    maxSteps = "maxSteps",
    accountingGroup = "accountingGroup",
    tranches = "tranches",
    supplierId = "supplierId",
    voltageLevel = "voltageLevel",
    voltageTransformation = "voltageTransformation",
    measurementLocations = "measurementLocations",
    isTolerationCase = "isTolerationCase",
    activationType = "activationType",
    processingTimeEiv = "processingTimeEiv",
    preliminaryDecommissioningDateReached = "preliminaryDecommissioningDate",
    finalDecommissioningDateReached = "finalDecommissioningDate",
    size = "size",
    sizeUnit = "sizeUnit",
    minimumMinutesRequiredBeforeActivation = "minimumMinutesRequiredBeforeActivation",
    consumesEnergy = "consumesEnergy",
    producesEnergy = "producesEnergy",
    windPowerCurve = "windPowerCurve",
    shutdownWindSpeed = "shutdownWindSpeed",
    netRatedCapacityWindSpeed = "netRatedCapacityWindSpeed",
    production = "production",
    controllableResource = "controllableResource",
    requestingGridOperators = "requestingGridOperators",
    consumption = "consumption",
    value = "value",
}

export default SchemaConstants;