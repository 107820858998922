import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridDataState, useQuery } from "ndr-designsystem";
import { isEqual } from "lodash";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    fetchAllReceivedMessages,
    fetchAllSentMessages,
    resetReceivedMessages,
    resetSentMessages,
    setTotalItems
} from "./ConnectPlusMessagesSlice";
import ConnectPlusMessagesGrid from "../../Components/connectPlusMessagesGrid/ConnectPlusMessagesGrid";
import { setCurrentPage, setMessageErrors } from "../appStateSlice";
import { ConnectPlusMessagesViewType } from "../controllableResources/details/tabs/ConnectPlusMsgsTable";
import { ConnectPlusMessageDirection } from "../../utils";

const AllMessagesView: React.FC = () => {
    const { totalItems } = useAppSelector((state) => state.connectPlusMessages);
    const sentMessages = useAppSelector((state) => state.connectPlusMessages.sentMessages, (left, right) => isEqual(left, right));
    const receivedMessages = useAppSelector((state) => state.connectPlusMessages.receivedMessages, (left, right) => isEqual(left, right));
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [dataState, setDataState] = useState<GridDataState | undefined>(undefined);
    const [data, setData] = useState<any[] | undefined>(undefined);
    const { direction } = useQuery<ConnectPlusMessageDirection>();
    const [currentlyViewing, setCurrentlyViewing] = useState<ConnectPlusMessagesViewType>(direction != null ? direction : "sent")

    useEffect(() => {
        if (dataState) {
            let action: any = null;
            if (currentlyViewing === "sent")
                action = fetchAllSentMessages({ dataState, id: undefined, type: undefined })
            else if (currentlyViewing === "received")
                action = fetchAllReceivedMessages({ dataState, id: undefined, type: undefined })
            if (action)
                dispatch(action);
        }
    }, [dataState, dispatch, currentlyViewing, receivedMessages, sentMessages])

    useEffect(() => {
        if (currentlyViewing === "sent") setData(sentMessages);
        else setData(receivedMessages)
    }, [currentlyViewing, sentMessages, receivedMessages])

    useEffect(() => {
        dispatch(resetReceivedMessages())
        dispatch(resetSentMessages())
        dispatch(setCurrentPage("ConnectPlusMessages"))
    }, [dispatch])


    const onViewChange = (e: ChangeEvent): void => {
        const newValue = (e.target as HTMLInputElement).value
        // @ts-ignore we know that possible values are sent and received
        setCurrentlyViewing(newValue)
        history.replace({ search: qs.stringify({ direction: newValue }, { encode: false }) })
    }

    const Toolbar = useMemo(() => <FormControl component="fieldset">
        <RadioGroup value={currentlyViewing}
                    onChange={onViewChange}
                    row aria-label="gender" name="row-radio-buttons-group">
            <FormControlLabel value="sent" control={<Radio color="primary"/>}
                              label={`${t('tables:connect_plus_messages.sent')}`}/>
            <FormControlLabel value="received" control={<Radio color="primary"/>}
                              label={`${t('tables:connect_plus_messages.received')}`}/>
        </RadioGroup>
    </FormControl>, /* eslint-disable react-hooks/exhaustive-deps */ [currentlyViewing, t])

    return (
        <ConnectPlusMessagesGrid
            filterable
            data={data}
            onViewErrorsClick={(errors) => {
                dispatch(setMessageErrors(errors))
            }}
            toolbar={Toolbar}
            direction={currentlyViewing}
            processData={false}
            totalMessages={totalItems}
            onDataStateChange={async (newState) => {
                if (isEqual(dataState, newState)) return;

                if (currentlyViewing === "sent") {
                    dispatch(resetSentMessages());
                } else dispatch(resetReceivedMessages())

                if (!isEqual(dataState?.filter, newState.filter)) {
                    dispatch(setTotalItems(undefined))
                }

                setDataState(newState);
            }}
        />
    )
}

export default AllMessagesView
