import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from "react-i18next";

interface IAlertProps {
    onAgree?: () => void,
    onCancel?: () => void,
    cancelText?: string
    acceptText?: string,
    title?: string,
    text: string,
    open: boolean,
}

const AlertDialog: React.FC<IAlertProps> = (props: IAlertProps) => {
    const { onAgree, title, text, open, onCancel, cancelText, acceptText } = props
    const { t } = useTranslation('common');

    return (
        <div>
            <Dialog
                open={open}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ textAlign: "center" }}>
                    <ErrorOutlineIcon color="primary" style={{ fontSize: 100 }}/>
                    {title && t(title)}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t(text)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {cancelText &&
                    <Button onClick={onCancel} color="primary">
                        {t(cancelText)}
                    </Button>
                    }
                    {acceptText &&
                    <Button onClick={onAgree} color="primary">
                        {t(acceptText)}
                    </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

AlertDialog.defaultProps = {
    title: undefined,
    cancelText: undefined,
    acceptText: undefined,
    onAgree: undefined,
    onCancel: undefined
}

export default AlertDialog