import React, { ReactElement } from "react";
import { Redirect, Route } from "react-router-dom";
import { useQuery } from "ndr-designsystem";

interface ProtectedRoutesProps {
    isAuth: boolean;
    Component: any;
    path: string;
}

interface RouterRenderProps {
    location: any
}

const ProtectedRoutes = ({ isAuth, Component, path }: ProtectedRoutesProps): ReactElement => {
    const search = useQuery();
    return <Route path={path} render={(props: RouterRenderProps) => {
        if (isAuth) {
            return <Component/>
        }
        return <Redirect to={{ pathname: '/login', state: { from: props.location, search } }}/>
    }}
    />
}

export default ProtectedRoutes;