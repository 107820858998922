import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        addNewRightButton: {
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
        titleText: {
            flex: 2
        },
        scrollableTabs: {
            height: "100%",
            '& .k-tabstrip-items': {
                userSelect: "none",
                width: 300,
            }
        },
        mobileScrollableTabs: {
            height: "100%",
            '& .k-tabstrip-items': {
                userSelect: "none",
                width: "100%",
                scrollableWidth: "1px",
                overflowX: "auto",
                overscrollBehavior: "contain",
                overflowY: "hidden"
            },
        },
        saveButton: {
            marginTop: 30,
            width: 200
        },
        acceptedStyle: {
            backgroundColor: "rgba(55, 180, 0,0.32)",
            color: "black",
            fontSize: 20,
            fontWeight: 700,
            userSelect: "none",
            borderRadius: 20
        },
        rejectedStyle: {
            backgroundColor: "rgba(243, 23, 0, 0.32)",
            color: "black",
            fontSize: 20,
            fontWeight: 700,
            userSelect: "none",
            borderRadius: 20
        },
        hasSuccessfulDispatchStyle: {
            backgroundColor: "rgba(101,206,246,0.32)",
            color: "black",
            fontSize: 20,
            fontWeight: 700,
            userSelect: "none",
            borderRadius: 20
        },
    }),
);

export default useStyles;
