import { MarketLocation } from "../../fixed/MarketLocation/MarketLocation";

export interface MarketLocationDto {
    externalID?: string
    accountingGroup?: string
    voltageLevel?: string
    voltageTransformation?: string
    measurementLocations?: string[]
    supplierId?: string
    revision?: number
}

export const toDto = (original: MarketLocation | undefined): MarketLocationDto | undefined => {
    if (original === undefined) return undefined;
    const backup = { ...original };

    delete backup.voltageLevel
    delete backup.voltageTransformation
    delete backup.tranches
    delete backup.inventoryItemId

    return {
        ...backup,
        externalID: original.externalID?.toString(),
        voltageLevel: original.voltageLevel?.code,
        voltageTransformation: original.voltageTransformation?.code,
    }
}
