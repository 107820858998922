import React, { ReactElement } from "react";
import { GridRowProps } from "@progress/kendo-react-grid";
import { ControlGroup } from "../../../api/fixed/ControlGroup/ControlGroup";
import ControlGroupStatus from "../../../api/fixed/ControlGroup/ControlGroupStatus";

const RowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps): ReactElement => {
    const { dataItem } = props as { dataItem: ControlGroup };
    const green = { backgroundColor: "rgba(55, 180, 0,0.32)" };
    const red = { backgroundColor: "rgba(243, 23, 0, 0.32)" };
    const blue = { backgroundColor: "rgba(101,206,246,0.32)" };

    let trProps;
    if (dataItem.hasSuccessfulDispatch) {
        switch (dataItem.status) {
            case ControlGroupStatus.Accepted:
                trProps = { style: green };
                break;
            case ControlGroupStatus.Rejected:
            case ControlGroupStatus.SendingFailed:
                trProps = { style: red };
                break;
            default:
                trProps = { style: blue };
                break;
        }
    } else if (dataItem.status === ControlGroupStatus.Rejected) {
        trProps = { style: red };
    }

    return React.cloneElement(
        trElement,
        // @ts-ignore
        { ...trProps },
        trElement.props.children
    );
}

export default RowRender
