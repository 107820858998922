import React, { ReactElement, useEffect, useMemo } from "react";
import { GridField } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { Box, Button, IconButton } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Add, Remove } from "@mui/icons-material";
import { DateTime } from "luxon";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    allAvatErrorsSelector,
    buildAvatErrorsState,
    fetchAllAvatErrors,
    resetAvatErrorsState,
    setExpandedItemId
} from "../store";
import DetailsView from "./DetailsView";
import DateCell from "../../../Components/DateCell";
import AvatError from "../../../api/fixed/AvatError/AvatError";
import BooleanColumn from "../../../Components/BooleanColumn";
import RowRender from "./RowRender";

export const AvatErrorsList = (): ReactElement => {
    const allErrors = useAppSelector(allAvatErrorsSelector, (left, right) => isEqual(left, right))
    const { expandedItemId } = useAppSelector(state => state.avatErrors)
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");
    const history = useHistory();

    const errorsWithExpandedProps = useMemo(() => {
        if (allErrors) {
            return allErrors.map(error => ({ ...error, showDetails: error.id === expandedItemId }))
        }
        return undefined
    }, [expandedItemId, allErrors])

    useEffect(() => {
        dispatch(buildAvatErrorsState(false));
        return () => dispatch(resetAvatErrorsState())
    }, [dispatch])

    const onForceRefresh = (): void => {
        dispatch(resetAvatErrorsState())
        dispatch(fetchAllAvatErrors(false));
    }

    const gridFields = useMemo((): GridFieldElement[] => [
        {
            customCell: (props: GridCellProps) => {
                const { dataItem }: { dataItem: AvatError } = props
                return <td>
                    <IconButton
                        onClick={() => dispatch(setExpandedItemId(dataItem.id))}>
                        {expandedItemId === dataItem.id ? <Remove style={{ fontSize: 16 }}/> :
                            <Add style={{ fontSize: 16 }}/>}
                    </IconButton>
                </td>
            }
        },
        {
            title: t("avatErrors.text"),
            name: "text",
        },
        {
            title: t("avatErrors.raisedAt"),
            name: "raisedAt",
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="raisedAt"
                displayFormat={DateTime.DATETIME_MED_WITH_SECONDS}
            />,
        },
        {
            title: t("avatErrors.acknowledged"),
            name: "acknowledged",
            customCell: (props: GridCellProps) => <BooleanColumn {...props} path="acknowledged"/>,
        },
        {
            title: t("avatErrors.acknowledgedAt"),
            name: "acknowledgedAt",
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="acknowledgedAt"
                displayFormat={DateTime.DATETIME_MED_WITH_SECONDS}
            />,
        },
        {
            title: t("avatErrors.resolved"),
            name: "resolved",
            customCell: (props: GridCellProps) => <BooleanColumn {...props} path="resolved"/>,
        },
        {
            title: t("avatErrors.resolvedAt"),
            name: "resolvedAt",
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="resolvedAt"
                displayFormat={DateTime.DATETIME_MED_WITH_SECONDS}
            />,
        },
        {
            title: t("avatErrors.itemExternalID"),
            name: "itemExternalID",
            customCell: (props: GridCellProps) => {
                const { dataItem }: { dataItem: AvatError } = props;
                return <td><Button color="secondary" onClick={() => {
                    if (dataItem.type === "resource") {
                        history.push(`/controllable/${dataItem.itemExternalID}`)
                    } else {
                        history.push(`/controlGroups/${dataItem.itemExternalID}`)
                    }
                }}>{t(dataItem.type === "resource" ? 'avatErrors.goToResource' : "avatErrors.goToGroup")}</Button></td>
            }
        },
    ], [t, dispatch, expandedItemId, history])

    return <Box width="100%" height="100%">
        <GridField
            dataItemKey="id"
            detailComponent={DetailsView}
            values={errorsWithExpandedProps}
            onRowClick={e => dispatch(setExpandedItemId(e.dataItem.internalID))}
            gridFields={gridFields}
            rowRender={RowRender}
            defaultOpen
            onRefresh={onForceRefresh}
            noRecordsText={t("no_records_text")}
        />
    </Box>
}

export default AvatErrorsList
