import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AvatErrorDto, { fromDto as fromAvatErrorDto } from "../../../api/dtos/AvatError/AvatErrorDto";
import { RootState } from "../../../app/store";
import AvatError from "../../../api/fixed/AvatError/AvatError";

export interface MarketPartnersState {
    allErrors: AvatErrorDto[] | undefined,
    currentError: AvatErrorDto | undefined,
    expandedItemId: string | undefined
}

const initialState: MarketPartnersState = {
    allErrors: undefined,
    currentError: undefined,
    expandedItemId: undefined
};

export const avatErrorsSlice = createSlice({
    name: 'avatErrorsSlice',
    initialState,
    reducers: {
        setAllErrors: (state, action: PayloadAction<AvatErrorDto[] | undefined>) => {
            state.allErrors = action.payload;
        },
        setCurrentError: (state, action: PayloadAction<AvatErrorDto | undefined>) => {
            state.currentError = action.payload
        },
        setExpandedItemId: (state, action: PayloadAction<string | undefined>) => {
            if (state.expandedItemId === action.payload) {
                state.expandedItemId = undefined
            } else {
                state.expandedItemId = action.payload
            }
        }
    },
});

export const allAvatErrorsSelector = (state: RootState): AvatError[] | undefined => {
    if (state.avatErrors.allErrors) {
        return state.avatErrors.allErrors.map(e => fromAvatErrorDto(e));
    }

    return state.avatErrors.allErrors
}

export const {
    setAllErrors,
    setCurrentError,
    setExpandedItemId
} = avatErrorsSlice.actions;

export default avatErrorsSlice.reducer;
