import React, { ReactElement, useEffect, useMemo } from "react";
import { GridField } from "ndr-designsystem";
import { useHistory } from "react-router-dom";
import { GridCellProps } from "@progress/kendo-react-grid";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { fetchActivations } from "../timeseries/activations/store/thunks";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { allActivationsSelector } from "../timeseries/activations/store/store";
import DateCell from "../../Components/DateCell";

const ActivationsTable = (): ReactElement => {
    const allActivations = useAppSelector(allActivationsSelector, (left, right) => left?.length === right?.length)
    const dispatch = useAppDispatch();
    const { currentGroup } = useAppSelector(state => state.controlGroups);
    const history = useHistory();
    const { t } = useTranslation("tables");

    useEffect(() => {
        if (allActivations === undefined && currentGroup != null) {
            dispatch(fetchActivations({ force: false, type: "group", id: currentGroup.inventoryItemId! }))
        }
    }, [allActivations, currentGroup, dispatch])

    const gridFields = useMemo(() => [
        {
            title: t("controllable_resources_details.activations.date_received"),
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="receivedDate"
                    displayFormat={DateTime.DATE_SHORT}
                />
            )
        },
        {
            title: t("controllable_resources_details.activations.date_activation"),
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="activationDate"
                    displayFormat={DateTime.DATE_SHORT}
                />
            )
        },
    ], [t])

    return <GridField
        defaultOpen
        dataItemKey="id"
        noRecordsText={t("no_records_text")}
        onRowClick={event => history.push(`/controlGroups/${currentGroup?.externalID}/activations/${event.dataItem.id}`)}
        values={allActivations}
        gridFields={gridFields}
    />
}

export default ActivationsTable;