import React, { ReactElement, useEffect, useMemo } from "react";
import { GridField } from "ndr-designsystem";
import { useHistory } from "react-router-dom";
import { GridCellProps, GridRowProps } from "@progress/kendo-react-grid";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { fetchActivations } from "../../../timeseries/activations/store/thunks";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { allActivationsSelector } from "../../../timeseries/activations/store/store";
import DateCell from "../../../../Components/DateCell";
import Activation from "../../../../api/fixed/Activations/Activation";
import ProcessingStatus from "../../../../api/fixed/Activations/ProcessingStatus";

const ActivationsTable = (): ReactElement => {
    const allActivations = useAppSelector(allActivationsSelector, (left, right) => left?.length === right?.length)
    const dispatch = useAppDispatch();
    const { currentResource } = useAppSelector(state => state.controllableResources);
    const history = useHistory();
    const { t } = useTranslation("tables")

    useEffect(() => {
        if (allActivations === undefined && currentResource != null) {
            dispatch(fetchActivations({ force: false, type: "resource", id: currentResource.inventoryItemId! }))
        }
    }, [allActivations, currentResource, dispatch])

    const gridFields = useMemo(() => [
        {
            title: t("controllable_resources_details.activations.date_received"),
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="receivedDate"
                    displayFormat={DateTime.DATETIME_SHORT}
                />
            )
        },
        {
            title: t("controllable_resources_details.activations.date_activation"),
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="activationDate"
                    displayFormat={DateTime.DATE_SHORT}
                />
            )
        },
    ], [t])

    const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ): ReactElement => {
        const { dataItem }: { dataItem: Activation } = props;
        const green = { backgroundColor: "rgba(55, 180, 0,0.32)" };
        const red = { backgroundColor: "rgba(243, 23, 0, 0.32)" };
        const orange = { backgroundColor: "rgba(243,146,0,0.68)" };

        let trProps;
        switch (dataItem.processingStatus) {
            case ProcessingStatus.HandledSuccessfully:
                trProps = { style: green };
                break;
            case ProcessingStatus.HandlingFailed:
                trProps = { style: red };
                break;
            case ProcessingStatus.HandlingInProgress:
                trProps = { style: orange };
                break;
            default:
                trProps = null;
                break;
        }


        return React.cloneElement(
            trElement,
            // @ts-ignore
            { ...trProps },
            trElement.props.children
        );
    };

    return <GridField
        defaultOpen
        dataItemKey="id"
        rowRender={rowRender}
        noRecordsText={t("no_records_text")}
        onRowClick={event => history.push(`/controllable/${currentResource?.externalID}/activations/${event.dataItem.id}`)}
        values={allActivations}
        gridFields={gridFields}
    />
}

export default ActivationsTable;