import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import ConnectPlusMessagesReducer from 'features/ConnectPlusMsgs/ConnectPlusMessagesSlice';
import AccountingGroupBalancingSchedulesReducer from 'features/accountingGroupBalancingSchedules/store/accountingGroupBalancingSchedulesSlice';
import ControllableReducer from "../features/controllableResources/store/controllableResourcesSlice"
import MarketPartnersReducer from "../features/marketpartner/store/marketPartnersSlice"
import TechnicalResourcesReducer from "../features/technicalResources/store/technicalResourcesSlice"
import appStateReducer from "../features/appStateSlice"
import ControlGroupsReducer from "../features/ControlGroups/store/controlGroupsSlice"
import ExcelImportReducer from "../features/ImportExcel/store/excelImportSlice"
import PlanningDataReducer from "../features/timeseries/planningData/store/store"
import UnavailabilitiesReducer from "../features/timeseries/unavailability/store/store"
import FlexibilityConstraintsReducer from "../features/timeseries/flexibilityConstraints/store/slice"
import ActivationsReducer from "../features/timeseries/activations/store/store"
import DowntimeReducer from "../features/timeseries/downtime/store/slice"
import SensitivitiesReducer from "../features/simplePlanningData/sensitivities/store/slice"
import ProductionData from "../features/timeseries/productionData/store/slice"
import GeneratedCostInfo from "../features/timeseries/generatedCostInfo/store/store"
import GeneratedSensitivity from "../features/timeseries/generatedSensitivities/store/store"
import AvatErrors from "../features/avatErrors/store"
import Tasks from "../features/tasks/store/store"
import CostInfo from "../features/costInfo/store/store"
import IndividualQuotasReducer from "../features/individualQuotes/store/individualQuotesSlice"

export const store = configureStore({
    reducer: {
        controllableResources: ControllableReducer,
        connectPlusMessages: ConnectPlusMessagesReducer,
        marketPartners: MarketPartnersReducer,
        technicalResources: TechnicalResourcesReducer,
        controlGroups: ControlGroupsReducer,
        appState: appStateReducer,
        planningData: PlanningDataReducer,
        excelImport: ExcelImportReducer,
        unavailabilities: UnavailabilitiesReducer,
        sensitivities: SensitivitiesReducer,
        flexibilityConstraints: FlexibilityConstraintsReducer,
        activations: ActivationsReducer,
        downtime: DowntimeReducer,
        productionData: ProductionData,
        tasks: Tasks,
        costInfo: CostInfo,
        generatedCostInfo: GeneratedCostInfo,
        avatErrors: AvatErrors,
        generatedSensitivity: GeneratedSensitivity,
        accountingGroupBalancingSchedules: AccountingGroupBalancingSchedulesReducer,
        individualQuotas: IndividualQuotasReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
