import { createDateTime } from "ndr-designsystem";
import Email from "../../fixed/Activations/Email";

export default interface EmailDto {
    attachmentName?: string
    attachmentPath?: string
    htmlBody?: string
    id?: string
    requestedAt: string
    status?: string
    statusDetails?: string
    subject?: string
    textBody?: string
    toAddress?: string
}

export const fromDto = (dto: EmailDto): Email => ({
    ...dto,
    requestedAt: createDateTime(dto.requestedAt)
})
