import React, { ReactElement, useEffect, useMemo } from "react";
import { GridField, TranslatedApiValueCell } from "ndr-designsystem";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { GridCellProps } from "@progress/kendo-react-grid";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { RootState } from "../../app/store";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { allDowntimesSelector } from "../timeseries/downtime/store/slice";
import DateCell from "../../Components/DateCell";
import { fetchDowntimesByTR } from "../timeseries/downtime/store/thunks";

const DowntimeTable = (): ReactElement => {
    const history = useHistory();
    const { currentResource } = useSelector((state: RootState) => state.technicalResources);
    const { currentResource: controllableResource } = useSelector((state: RootState) => state.controllableResources);
    const allDowntimes = useAppSelector(allDowntimesSelector, (left, right) => left?.length === right?.length)
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");

    useEffect(() => {
        if (allDowntimes === undefined && currentResource != null) {
            dispatch(fetchDowntimesByTR({ id: currentResource.inventoryItemId!, force: false }));
        }
    }, [allDowntimes, currentResource, dispatch])

    const gridFields = useMemo(() => [
        {
            title: t("technical_resources_details.downtime.date_received"),
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="start"
                    displayFormat={DateTime.DATE_SHORT}
                />
            )
        },
        {
            title: t("technical_resources_details.downtime.period.title"),
            customCell: ({ dataItem }) => <TranslatedApiValueCell
                value={dataItem.type?.toLowerCase()}
                namespace="tables:technical_resources_details.downtime.period"
            />,
        },
    ], [t])

    return <GridField
        defaultOpen
        dataItemKey="id"
        noRecordsText={t("no_records_text")}
        onRowClick={event => history.push(`/controllable/${controllableResource?.externalID}/resources/${currentResource?.externalID}/downtime/${event.dataItem.id}`)}
        values={allDowntimes}
        gridFields={gridFields}
    />
}

export default DowntimeTable
