import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import GeneratedSensitivityDto, {
    fromDto as fromGeneratedSensitivityDto
} from "../../../../api/dtos/GeneratedSensitivity/GeneratedSensitivityDto";
import GeneratedSensitivity from "../../../../api/fixed/GeneratedSensitivity/GeneratedSensitivity";
import GeneratedSensitivityDetailsDto, {
    fromDto as fromGeneratedSensitivityDetailsDto
} from "../../../../api/dtos/GeneratedSensitivity/GeneratedSensitivityDetailsDto";
import GeneratedSensitivityDetails from "../../../../api/fixed/GeneratedSensitivity/GeneratedSensitivityDetails";
import GeneratedSensitivityTimeSeries from "../../../../api/fixed/GeneratedSensitivity/GeneratedSensitivityTimeSeries";
import {
    fromDto as fromGeneratedSensitivityTimeSeriesDto
} from "../../../../api/dtos/GeneratedSensitivity/GeneratedSensitivityTimeSeriesDto";


export interface GeneratedSensitivityState {
    allGeneratedSensitivities: GeneratedSensitivityDto[] | undefined,
    currentGeneratedSensitivity: GeneratedSensitivityDetailsDto | undefined,
    selectedTimeSeries: number | undefined,
    sendingStatusGeneratedSensitivity: GeneratedSensitivityDto | undefined,
    isPreviousFetchingPossible: boolean,
    isNextFetchingPossible: boolean
}

const initialState: GeneratedSensitivityState = {
    allGeneratedSensitivities: undefined,
    currentGeneratedSensitivity: undefined,
    selectedTimeSeries: undefined,
    sendingStatusGeneratedSensitivity: undefined,
    isPreviousFetchingPossible: true,
    isNextFetchingPossible: true
};

export const store = createSlice({
    name: 'generatedSensitivitySlice',
    initialState,
    reducers: {
        setAllGeneratedSensitivities: (state, action: PayloadAction<GeneratedSensitivityDto[] | undefined>) => {
            state.allGeneratedSensitivities = action.payload
        },
        setCurrentGeneratedSensitivity: (state, action: PayloadAction<GeneratedSensitivityDetailsDto | undefined>) => {
            state.currentGeneratedSensitivity = action.payload
        },
        setIsPreviousFetchingPossible: (state, action: PayloadAction<boolean>) => {
            state.isPreviousFetchingPossible = action.payload
        },
        setIsNextFetchingPossible: (state, action: PayloadAction<boolean>) => {
            state.isNextFetchingPossible = action.payload
        },
        selectFirstTimeSeriesInfo: (state) => {
            if (state.currentGeneratedSensitivity && state.currentGeneratedSensitivity.timeSeries.length > 0) {
                state.selectedTimeSeries = 0;
            } else {
                state.selectedTimeSeries = undefined;
            }
        },
        selectCurrentTimeSeriesInfo: (state, action: PayloadAction<string>) => {
            const idx = state.currentGeneratedSensitivity?.timeSeries.findIndex(pd => pd.id === action.payload);
            state.selectedTimeSeries = idx !== -1 ? idx : undefined;
        },
        setSendingStatusGeneratedSensitivity: (state, action: PayloadAction<string | undefined>) => {
            state.sendingStatusGeneratedSensitivity = state.allGeneratedSensitivities?.find(p => p.dispatchId === action.payload);
        },
    }
});

export const allGeneratedSensitivitiesSelector = (state: RootState): GeneratedSensitivity[] | undefined => {
    if (state.generatedSensitivity.allGeneratedSensitivities) {
        return state.generatedSensitivity.allGeneratedSensitivities.map(t => fromGeneratedSensitivityDto(t))
    }
    return state.generatedSensitivity.allGeneratedSensitivities
}

export const currentGeneratedSensitivitySelector = (state: RootState): GeneratedSensitivityDetails | undefined => {
    if (state.generatedSensitivity.currentGeneratedSensitivity) {
        return fromGeneratedSensitivityDetailsDto(state.generatedSensitivity.currentGeneratedSensitivity)
    }
    return state.generatedSensitivity.currentGeneratedSensitivity
}

export const timeSeriesInfoForCurrentGeneratedSensitivity = (state: RootState): GeneratedSensitivityTimeSeries | undefined => {
    if (state.generatedSensitivity && state.generatedSensitivity.currentGeneratedSensitivity && state.generatedSensitivity.selectedTimeSeries !== undefined) {
        return fromGeneratedSensitivityTimeSeriesDto(state.generatedSensitivity.currentGeneratedSensitivity.timeSeries[state.generatedSensitivity.selectedTimeSeries]);
    }
    return undefined;
};

export const {
    setAllGeneratedSensitivities,
    setCurrentGeneratedSensitivity,
    selectFirstTimeSeriesInfo,
    selectCurrentTimeSeriesInfo,
    setSendingStatusGeneratedSensitivity,
    setIsPreviousFetchingPossible,
    setIsNextFetchingPossible
} = store.actions;

export default store.reducer;
