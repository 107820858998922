import { createDateTime } from "ndr-designsystem";
import GeneratedSensitivityTimeSeriesValue from "../../fixed/GeneratedSensitivity/GeneratedSensitivityTimeSeriesValue";

export default interface GeneratedSensitivityTimeSeriesValueDto {
    timestamp: string
    value: number
}

export const fromDto = (dto: GeneratedSensitivityTimeSeriesValueDto): GeneratedSensitivityTimeSeriesValue => ({
    ...dto,
    timestamp: createDateTime(dto.timestamp)
})
