/**
 *
 * @export
 * @enum {string}
 */

export enum ControllabilityType {
    LEVELS = 0,
    STEPS = 1
}

export const allowedCodes = [0, 1];

export const controlGroupsAllowedCodes = [0];