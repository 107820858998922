import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        formControl: {
            width: "100%"
        },
        spacedFormControl: {
            width: "100%",
            marginRight: 20
        },
        codingSchemaFormControl: {
            width: 200,
            marginRight: 20
        }
    }),
);

export default useStyles;