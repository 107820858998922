import { GridCellProps } from "@progress/kendo-react-grid";
import React, { ReactElement } from "react";
import { Box, Tooltip } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ControllableResourceStatus from "../../../../api/fixed/ControllableResource/ControllableResourceStatus";

const ValidationIndicator = (props: GridCellProps): ReactElement => {
    const { t } = useTranslation();

    const { dataItem } = props;
    let icon: ReactElement | undefined
    if ([ControllableResourceStatus.Received, ControllableResourceStatus.Unknown, ControllableResourceStatus.Updated, ControllableResourceStatus.Created].includes(dataItem.status))
        icon = <Box alignItems="center" display="flex">
            <Tooltip title={t("validation:invalid_fields") ?? ""}><ErrorOutline
                color="error"/></Tooltip>
        </Box>
    return <td>{icon}</td>
}

export default ValidationIndicator;
