import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        normalItemStyle: {
            color: "inherit",
            textDecoration: "none",
            userSelect: "none",
            cursor: "pointer"
        },
        lastItemStyle: {
            userSelect: "none",
        }
    }),
);

export default useStyles;