import React, { ReactElement, useEffect, useMemo } from "react";
import { GridField } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ActionsCell from "./columns/ActionsCell";
import Encoding from "./columns/Encoding";
import Toolbar from "./Toolbar";
import { setCurrentPage } from "../../appStateSlice";

const MarketPartnersList = (): ReactElement => {
    const { allPartners } = useAppSelector(state => state.marketPartners)
    const { t } = useTranslation("tables")
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("MarketPartnersList"))
    }, [dispatch])

    const gridFields = useMemo(() => [{
        title: t("market_partners_list.encoding"),
        name: "encoding.name",
        customCell: Encoding
    }, {
        title: t("market_partners_list.code"),
        name: "code"
    }, {
        title: t("market_partners_list.name"),
        name: "name",
    }, {
        customCell: ActionsCell,
        exactWidth: 150
    }], [t])

    return <GridField
        dataItemKey="inventoryItemId"
        values={allPartners}
        gridFields={gridFields}
        defaultOpen
        customToolbar={<Toolbar/>}
        noRecordsText={t("no_records_text")}
    />
}

export default MarketPartnersList
