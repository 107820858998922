import AckStatus from "./AckStatus";
import SentMessageStatus from "./SentMessageStatus";
import ReceivedMessageStatus from "./ReceivedMessageStatus";

export default interface ConnectPlusMessage {
    internalId: string,
    createdAt: string,
    ackStatus: AckStatus,
    ackCreatedAt: string,
    dispatchId: string,
    hasAckXml: boolean,
    hasXml: boolean,
    errors: string[],
    messageType: string,
}

export const allowedSentMessageStatuses = Object.values(SentMessageStatus).filter(value => typeof value !== 'string').map(v => v.toString());
export const allowedReceivedMessageStatuses = Object.values(ReceivedMessageStatus).filter(value => typeof value !== 'string').map(v => v);
export const allowedMessageTypes = ["AcknowledgementDocument", "ActivationDocument", "Kostenblatt", "NetworkConstraintDocument", "PlannedResourceScheduleDocument", "Stammdaten", "Unavailability_MarketDocument"]
