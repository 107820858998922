import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import Api from "../../../api/Api";
import { removeNulls } from "../../../utils";
import { addNewCostInfo, setAllCostInfo } from "./store";
import CostInfoDto from "../../../api/dtos/CostInfo/CostInfo";

export const fetchCostInfo = createAsyncThunk(
    "costInfo/fetch",
    async (id: string, { getState, dispatch }) => {
        let response: CostInfoDto[] | undefined;
        const state = getState() as RootState;

        response = state.costInfo.allCostInfo;

        if (response === undefined || response.length === 0) {
            response = (await Api.fetchAllCostInfo(id)).data.map(un => removeNulls(un));
        }

        await dispatch(setAllCostInfo(response));
    }
)

export const createCostInfo = createAsyncThunk(
    "costInfo/create",
    async ({ id, data }: { id: string, data: CostInfoDto }, { dispatch }) => {
        const response = await Api.createCostInfo(id, data)
        if (response.status === 200) {
            dispatch(addNewCostInfo({
                id: response.data,
                ...data,
            }));
        }
    }
)

export const deleteCostInfo = createAsyncThunk(
    "costInfo/delete",
    async ({ id, costInfoId }: { id: string, costInfoId: string }) => {
        await Api.deleteCostInfo(id, costInfoId)
    }
)
