import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "api/Api";
import { RootState } from "app/store";
import { removeNulls } from "ndr-designsystem";
import { AxiosError } from "axios";
import i18n from "i18n";
import { addNewAccountingGroupBalancingSchedule, setAllAccountingGroupBalancingSchedules, setCurrentAccountingGroupBalancingSchedule, updateAccountingGroupBalancingSchedules } from "./accountingGroupBalancingSchedulesSlice";
import { AccountingGroupBalancingSchedule } from "../../../api/fixed/AccountingGroupBalancingSchedule/AccountingGroupBalancingSchedule";

export const fetchAccountingGroupBalancingSchedules = createAsyncThunk(
    "accountingGroupBalancingSchedules/fetchAccountingGroupBalancingSchedules",
    async (force: boolean, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;
        let response: AccountingGroupBalancingSchedule[];

        if (
            state.accountingGroupBalancingSchedules.allAccountingGroups &&
            state.accountingGroupBalancingSchedules.allAccountingGroups.length > 0 &&
            !force
        )
            response = [...state.accountingGroupBalancingSchedules.allAccountingGroups];
        else response = removeNulls(await Api.fetchAccountingGroupBalancingSchedules());

        dispatch(setAllAccountingGroupBalancingSchedules(response));
    }

);


export const fetchAccountingGroupBalancingScheduleById = createAsyncThunk(
    "accountingGroupBalancingSchedules/fetchAccountingGroupBalancingScheduleById",
    async (id: string, { dispatch }) => {
        const quotas = await Api.fetchAccountingGroupBalancingScheduleById(id);
        await dispatch(setCurrentAccountingGroupBalancingSchedule(quotas));
    },
);


export const createAccountingGroupBalancingSchedule = createAsyncThunk(
    "accountingGroupBalancingSchedules/create",
    async ( data :AccountingGroupBalancingSchedule, { dispatch, rejectWithValue }) => {

        try{
            const response = await Api.addAccountingGroupBalancingSchedules(data)
            dispatch(addNewAccountingGroupBalancingSchedule({                
                ...data,
            }));
            const newAccountingGroup = { ...data };
            newAccountingGroup.inventoryItemId = response.data;
            dispatch(setCurrentAccountingGroupBalancingSchedule(newAccountingGroup));
            return response.data;

        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue({
                status: error.response?.status,
                detail: error.response?.status === 409 ? i18n.t('validation:external_id_not_duplicate') : ""
            })
        }         
    }
)

export const editAccountingGroupBalancingSchedule = createAsyncThunk(
    "accountingGroupBalancingSchedules/edit",
    async (data: AccountingGroupBalancingSchedule, { dispatch }) => {
        const response = await Api.updateAccountingGroupBalancingSchedules(data)
        if (response.status === 200) {
            dispatch(updateAccountingGroupBalancingSchedules({
                ...data,
            }));
        }
    }
)

export const deleteAccountingGroupBalancingSchedule = createAsyncThunk(
    "accountingGroupBalancingSchedules/delete",
    async (id: string, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;
        const response  = await Api.deleteAccountingGroupBalancingSchedule(id)
        if (response.status === 200) {        
            const newGroups = (state.accountingGroupBalancingSchedules.allAccountingGroups ?? []).filter(x => x.inventoryItemId !== id);
            dispatch(setAllAccountingGroupBalancingSchedules(newGroups));
        }
    }
)


