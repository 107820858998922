import React, { ReactElement, useEffect, useState } from "react";
import { DateTimePicker } from "@mui/lab";
import { DateTime } from "luxon";
import { Box, IconButton, TextField } from "@mui/material";
import { CustomButton } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import useStyles from "../styles";
import { ToggleViewButton, View } from "../ToggleViewButton";
import { useAppSelector } from "../../../app/hooks";
import { currentProductionDataSelector } from "./store/slice";

interface Props {
    onCurrentlyViewingChange: (view: View) => void
    currentlyViewing: View
    onLoadData: (startDate: DateTime, endDate: DateTime) => void
}

const Toolbar = ({ onCurrentlyViewingChange, currentlyViewing, onLoadData }: Props): ReactElement => {
    const classes = useStyles();
    const currentProductionData = useAppSelector(currentProductionDataSelector, (left, right) => left?.id === right?.id);
    const { t } = useTranslation("tables");
    const [startDate, setStartDate] = useState<DateTime>(DateTime.now().minus({ days: 1 }).startOf("day"));
    const [endDate, setEndDate] = useState<DateTime>(DateTime.now().startOf("day"));

    useEffect(() => {
        if (currentProductionData === undefined)
            onLoadData(startDate, endDate)
    }, [currentProductionData, startDate, endDate, onLoadData])

    return <div className={classes.toolbar}>
        <Box display="flex" alignItems="center">
            <ToggleViewButton view={currentlyViewing} onToggleView={onCurrentlyViewingChange}/>
            <IconButton color="primary" sx={{ marginLeft: 5 }} onClick={() => {
                const newEndDate = startDate;
                const newStartDate = startDate.minus({ milliseconds: endDate.diff(startDate).toMillis() })
                setEndDate(newEndDate)
                setStartDate(newStartDate)
                onLoadData(newStartDate, newEndDate)
            }}>
                <ArrowBack/>
            </IconButton>
            <DateTimePicker
                label={t("controllable_resources_details.production_data.start_date")}
                value={startDate}
                maxDateTime={endDate}
                onChange={(v) => {
                    setStartDate((v ?? DateTime.now()) as DateTime)
                }}
                renderInput={(params) => <TextField {...params} sx={{ marginRight: 2 }} size="small"/>}
            />
            <DateTimePicker
                label={t("controllable_resources_details.production_data.end_date")}
                value={endDate}
                minDateTime={startDate}
                onChange={(v) => {
                    setEndDate((v ?? DateTime.now()) as DateTime)
                }}
                renderInput={(params) => <TextField {...params} size="small"/>}
            />
            <IconButton color="primary" sx={{ marginRight: 5 }} onClick={() => {
                const newStartDate = endDate;
                const newEndDate = endDate.plus({ milliseconds: endDate.diff(startDate).toMillis() });
                setStartDate(newStartDate)
                setEndDate(newEndDate)
                onLoadData(newStartDate, newEndDate)
            }}>
                <ArrowForward/>
            </IconButton>
            <CustomButton title={t('timeSeries.fetch')} onClick={() => onLoadData(startDate, endDate)}/>
        </Box>
    </div>
}

export default Toolbar
