import React, { ReactElement, useCallback, useMemo } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Pie } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { useAppSelector } from "../../app/hooks";
import { resourcesComparisonSelector } from "./store/slice";
import ControllableResourceStatus from "../../api/fixed/ControllableResource/ControllableResourceStatus";

const ResourcesComparison = (): ReactElement => {
    const { t } = useTranslation("common");
    const history = useHistory();

    const resourcesComparison = useAppSelector(resourcesComparisonSelector,
        (left, right) => left?.total === right?.total);

    const getChartOptions = useCallback(() => ({
        responsive: true,
        maintainAspectRatio: false,
        circumference: 180,
        rotation: -90,
        onClick: (event: any, elements: any) => {
            if (elements.length > 0) {
                switch (elements[0].index) {
                    case 0:
                        history.push({
                            pathname: "controllable", search: qs.stringify({
                                status: [ControllableResourceStatus.Received, ControllableResourceStatus.Updated]
                            }, { encode: false, arrayFormat: 'comma' })
                        });
                        break;
                    case 1:
                        history.push({
                            pathname: "controllable", search: qs.stringify({
                                status: ControllableResourceStatus.Accepted
                            })
                        });
                        break;
                    case 2:
                        history.push({
                            pathname: "controllable", search: qs.stringify({
                                status: ControllableResourceStatus.Rejected
                            })
                        });
                        break;
                    default:
                }
            }
        }
    }), [history]);

    const getChartData = useMemo(() => ({
        labels: [t("dashboard.resources_comparison.unsent"), t("dashboard.resources_comparison.accepted"), t("dashboard.resources_comparison.rejected")],
        datasets: [{
            data: [resourcesComparison.unsent.length, resourcesComparison.accepted.length, resourcesComparison.rejected.length],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)'
            ],
        }]
    }), [resourcesComparison, t])

    return <Paper elevation={1} sx={{
        height: "100%",
        width: "100%", display: "flex", flexDirection: "column", alignItems: "center",
        justifyContent: "space-between",

    }}>
        <Typography gutterBottom variant="h6" component="div" sx={{ height: 30 }} padding={1}>
            {t('dashboard.resources_comparison.title')}
        </Typography>
        <Divider flexItem/>
        <Box height="100%" width="100%">
            <Pie
                // @ts-ignore
                type="doughnut"
                // @ts-ignore
                data={getChartData}
                // @ts-ignore
                options={getChartOptions()}
            />
        </Box>
    </Paper>
}

export default ResourcesComparison;
