import React, { ReactElement } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Check, Close, MobiledataOff, Publish } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ControllableResourceStatus from "../../../../api/fixed/ControllableResource/ControllableResourceStatus";
import { ControllableResource } from "../../../../api/fixed/ControllableResource/ControllableResource";

const SentIndicator = ({
                           controllableResource: dataItem,
                           alwaysShowText
                       }: { controllableResource: ControllableResource, alwaysShowText: boolean }): ReactElement => {
    const { hasSuccessfulDispatch, status } = dataItem;
    const { t } = useTranslation("tables");
    let icon: ReactElement;
    if (hasSuccessfulDispatch) {
        switch (status) {
            case ControllableResourceStatus.Accepted:
                icon = <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                    <Tooltip title={!alwaysShowText ? t("controllable_resources_list.accepted") ?? "" : ""}>
                        <Check/>
                    </Tooltip>
                    <Typography>
                        {alwaysShowText && t("controllable_resources_list.accepted")}
                    </Typography>
                </Box>
                break;
            case ControllableResourceStatus.Rejected:
                icon = <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                    <Tooltip title={!alwaysShowText ? t("controllable_resources_list.rejected") ?? "" : ""}>
                        <Close/>
                    </Tooltip>
                    <Typography>
                        {alwaysShowText && t("controllable_resources_list.rejected")}
                    </Typography>
                </Box>
                break;
            default:
                icon = <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                    <Tooltip
                        title={!alwaysShowText ? t("controllable_resources_list.successful_dispatch") ?? "" : ""}>
                        <Publish/>
                    </Tooltip>
                    <Typography>
                        {alwaysShowText && t("controllable_resources_list.successful_dispatch")}
                    </Typography>
                </Box>
                break;
        }

    } else {
        icon = <Box display="flex" alignItems="center" justifyContent="end" width="100%">
            <Tooltip title={!alwaysShowText ? t("controllable_resources_list.no_successful_dispatch") ?? "" : ""}>
                <MobiledataOff/>
            </Tooltip>
            <Typography>
                {alwaysShowText && t("controllable_resources_list.no_successful_dispatch")}
            </Typography>
        </Box>
        if (status === ControllableResourceStatus.Rejected) {
            icon = <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                <Tooltip title={!alwaysShowText ? t("controllable_resources_list.rejected") ?? "" : ""}>
                    <Close/>
                </Tooltip>
                <Typography>
                    {alwaysShowText && t("controllable_resources_list.rejected")}
                </Typography>
            </Box>
        }
    }

    if (alwaysShowText) {
        return icon
    }

    return <td>{icon}</td>
}

export default SentIndicator
