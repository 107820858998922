import { createDateTime } from "ndr-designsystem";
import { cloneDeep } from "lodash";
import AvatError from "../../fixed/AvatError/AvatError";

export default interface AvatErrorDto {
    id: string
    raisedAt: string
    text: string
    description: string
    acknowledged: boolean
    acknowledgedAt?: string
    resolved: boolean
    resolvedAt?: string
    controllableResourceId?: string
    controlGroupId?: string
}

export const fromDto = (originalDto: AvatErrorDto): AvatError => {
    const dto = cloneDeep(originalDto);
    const type = dto.controllableResourceId != null ? "resource" : "group"
    const itemExternalID = dto.controllableResourceId != null ? dto.controllableResourceId : dto.controlGroupId!
    // eslint-disable-next-line no-param-reassign
    delete dto.controllableResourceId
    // eslint-disable-next-line no-param-reassign
    delete dto.controlGroupId
    return {
        ...dto,
        type,
        itemExternalID,
        resolvedAt: dto.resolvedAt ? createDateTime(dto.resolvedAt) : undefined,
        raisedAt: createDateTime(dto.raisedAt),
        acknowledgedAt: dto.acknowledgedAt ? createDateTime(dto.acknowledgedAt) : undefined
    }
}
