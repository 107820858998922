import { setDocumentTitle } from "ndr-designsystem";
import i18n from "../i18n";
import { TITLE_BASE } from "../utils";

const buildTimeseriesTitle = (components: string[], pathname: string): void => {
    if (pathname.includes("activations") && !pathname.endsWith("activations")) {
        components.push(i18n.t("common:title.activations"))
    } else if (pathname.includes("downtime") && !pathname.endsWith("downtime")) {
        components.push(i18n.t("common:title.downtime"))
    } else if (pathname.includes("planning-data") && !pathname.endsWith("planning-data")) {
        components.push(i18n.t("common:title.planning_data"))
    } else if (pathname.includes("unavailability") && !pathname.endsWith("unavailability")) {
        components.push(i18n.t("common:title.unavailability"))
    } else if (pathname.includes("network-constraints") && !pathname.endsWith("network-constraints")) {
        components.push(i18n.t("common:title.network_constraints"))
    }
}

const buildMainItemTitle = (components: string[], pathname: string): void => {
    const matched = pathname.match(/\/controllable\/(\w+)\/resources\/(\w+)/);
    if (matched && matched.length === 3) {
        components.push(matched[2])
    }

    ["/controllable/", "/controlGroups/"].forEach(searchPattern => {
        if (pathname.startsWith(searchPattern)) {
            const nextSlash = pathname.indexOf("/", searchPattern.length)
            components.push(pathname.substring(searchPattern.length, nextSlash !== -1 ? nextSlash : undefined))
        }
    })
}

const buildMainListItemTitle = (components: string[], pathname: string): void => {
    ["/controllable", "/controlGroups", "/market-partners", "/messages", "/dashboard", "/tasks", "/avat-errors"].forEach(searchPattern => {
        if (pathname === searchPattern) {
            components.push(i18n.t(`common:title.${pathname.replaceAll("/", "")}`))
        }
    })
    if (pathname === "/") {
        components.push(i18n.t("common:title.home"))
    }
}

const buildTitle = (pathname: string): void => {
    const components: string[] = []

    buildMainListItemTitle(components, pathname);
    buildTimeseriesTitle(components, pathname);
    buildMainItemTitle(components, pathname);

    setDocumentTitle(TITLE_BASE, ...components)
}

export default buildTitle;
