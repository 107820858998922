import React, { ReactElement, useMemo } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { DateTime } from "luxon";
import { toLocaleDate } from "ndr-designsystem";
import { useAppSelector } from "../../../app/hooks";
import CustomChart from "../../../Components/Chart";

const TimeSeriesChart = (): ReactElement => {
    const { currentGeneratedSensitivity, selectedTimeSeries } = useAppSelector(state => state.generatedSensitivity);
    const internationalization = useInternationalization();

    const chartData = useMemo(() => {
        if (currentGeneratedSensitivity && selectedTimeSeries !== undefined) {
            const values = currentGeneratedSensitivity.timeSeries[selectedTimeSeries].values.map(v => ({
                x: v.timestamp,
                y: v.value * 100
            }));

            return {
                labels: values.map(v => v.x),
                datasets: [{
                    label: "Value",
                    data: values.map(v => v.y),
                    backgroundColor: "#ef870f",
                    borderColor: "#e8cab9"
                }]
            };
        }
        return { labels: [""], datasets: [] };
    }, [currentGeneratedSensitivity, selectedTimeSeries]);

    const getChartPlugins = useMemo(() => ({
        tooltip: {
            callbacks: {
                label: (item: any) => {
                    if (selectedTimeSeries !== undefined && currentGeneratedSensitivity !== undefined) {
                        return `${item.dataset.label} - ${item.formattedValue}%`
                    }
                    return ""
                },
                title: (items: any) => toLocaleDate(items[0].parsed.x, internationalization.locale, DateTime.DATETIME_SHORT)
            },
        },
        legend: {
            display: false
        },
    }), [internationalization, currentGeneratedSensitivity, selectedTimeSeries])

    const getChartScales = useMemo(() => ({
        y: {
            min: 0,
            max: 105,
            ticks: {
                callback: (value: number) => {
                    if (selectedTimeSeries !== undefined && currentGeneratedSensitivity !== undefined && value <= 100) {
                        return `${internationalization.formatNumber(value, {
                            useGrouping: true,
                            maximumFractionDigits: 3
                        })}%`
                    }
                    return ""
                }
            },
            beginAtZero: true,
        },
        x: {
            adapters: {
                date: {
                    zone: "Europe/Berlin"
                }
            },
            type: "time",
            time: {
                unit: "minute",
                ticks: {
                    source: "data",
                },
                stepSize: 15,
                displayFormats: {
                    hour: internationalization.locale === "de" ? "HH" : "h a",
                    minute: internationalization.locale === "de" ? "HH:mm" : "h:mm a",
                    second: internationalization.locale === "de" ? "HH:mm:ss" : "h:mm:ss a"
                }
            },
            offset: true
        },
    }), [internationalization, currentGeneratedSensitivity, selectedTimeSeries])

    return <CustomChart
        maintainAspectRatio={false}
        locale={internationalization.locale}
        plugins={getChartPlugins}
        scales={getChartScales}
        data={chartData}
    />

}

export default TimeSeriesChart;
