import { Field, GeneralSimpleElementTable, isFieldValid, isSchemaValid, PageStatus } from "ndr-designsystem";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    setAssignedStorages,
    setBillingModel,
    setConsumesEnergy,
    setConsumptionExternalId,
    setEegUnitCodes,
    setExternalID,
    setMarketMasterDataRegistryId,
    setName,
    setOperator,
    setPowerPlantCode,
    setProducesEnergy,
    setProductionExternalId,
    setType,
    updateNewAssignedStorage,
    updateNewEegCode
} from "./store/technicalResourcesSlice";
import SchemaConstants from "../../utils/schemaConsts";
import {
    allowedCodes as allowedTechnicalResourceTypeCodes
} from "../../api/fixed/TechnicalResource/TechnicalResourceType";
import { setAddNewMarketPartnerData, setNotificationAlertMessage } from "../appStateSlice";
import { EEGUnitCodeSchema, SimpleStringSchema, TRGeneralTabSchema } from "../../utils/inputChecking";
import { allowedCodes as allowedBillingModelCodes } from "../../api/fixed/TechnicalResource/BillingModel";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { buildMarketPartnerText, getMarketPartnersId, transformToSelectEntry } from "../../utils";
import MarketLocationIDDialog from "./MarketLocationIDDialog";

const General = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { currentResource: resource, allResources } = useAppSelector(state => state.technicalResources);
    const { allPartners: marketPartners } = useAppSelector(state => state.marketPartners);
    const { currentResource: controllableResource } = useAppSelector(state => state.controllableResources);
    const [showIDDialog, setShowIDDialog] = useState<"production" | "consumption" | undefined>(undefined);
    const { t } = useTranslation("tables");

    const dispatch = useAppDispatch();
    const allowedBillingModelValues = useMemo(() => transformToSelectEntry(allowedBillingModelCodes, t, "api:billing_model"), [t])
    const allowedTechnicalResourceTypeValues = useMemo(() => transformToSelectEntry(allowedTechnicalResourceTypeCodes, t, "api:technical_resource_type"), [t])

    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);
    const getAllTechnicalResourcesIDs = useCallback((): string[] => (allResources ?? []).filter(g => g.inventoryItemId !== resource?.inventoryItemId).map(g => g.externalID ?? ""), [allResources, resource])

    const operatorDefaultValue = useCallback(() => {
        if (resource?.operatorId) {
            const marketPartner = marketPartners?.find(p => p.inventoryItemId === resource.operatorId);

            if (marketPartner) {
                return {
                    id: marketPartner.inventoryItemId,
                    text: buildMarketPartnerText(marketPartner)
                };
            }
            return undefined;
        }
        return undefined;
    }, [marketPartners, resource?.operatorId])

    const prepareMarketPartnersList = useCallback(() => marketPartners?.map(value => ({
        id: `${value.inventoryItemId}`,
        text: buildMarketPartnerText(value)
    })) ?? [], [marketPartners])

    const getAssignedStoragesActions = useCallback(() => ({
        setElements: (e: string[] | undefined) => dispatch(setAssignedStorages(e)),
        updateNewElement: (e: string) => dispatch(updateNewAssignedStorage(e))
    }), [dispatch])

    const getEegUnitCodesActions = useCallback(() => ({
        setElements: (e: string[] | undefined) => dispatch(setEegUnitCodes(e)),
        updateNewElement: (e: string) => dispatch(updateNewEegCode(e))
    }), [dispatch])

    const GeneralTabSchema = useMemo(() =>
            TRGeneralTabSchema(prepareMP(), controllableResource?.compensationType?.code === "Z01", getAllTechnicalResourcesIDs()),
        [prepareMP, controllableResource, getAllTechnicalResourcesIDs]);


    if (resource == null) return <></>

    return <>
        {showIDDialog &&
            <MarketLocationIDDialog
                type={showIDDialog}
                onSuccess={(val) => {
                    if (showIDDialog === "consumption") {
                        dispatch(setConsumesEnergy(true))
                        dispatch(setConsumptionExternalId(val))
                    } else {
                        dispatch(setProducesEnergy(true))
                        dispatch(setProductionExternalId(val))
                    }
                    setShowIDDialog(undefined)
                }}
                onClose={() => {
                    setShowIDDialog(undefined)
                }}/>
        }
        <Field
            isFilledByEiv
            filledByEivText={t('filled_by_eiv')}
            inputType="controlledText"
            onValueChange={val => dispatch(setExternalID(val))}
            isEditing={isEditing || isAdding}
            valueCheckFunction={externalID => isFieldValid(GeneralTabSchema, [SchemaConstants.externalID], { externalID })}
            defaultValue={resource.externalID}
            title={t("technical_resources_details.externalID")}
            description={t("technical_resources_details.externalIDDescription")}
        />
        <Field
            isFilledByEiv
            filledByEivText={t('filled_by_eiv')}
            inputType="controlledText"
            onValueChange={val => dispatch(setName(val))}
            isEditing={isEditing || isAdding}
            valueCheckFunction={name => isFieldValid(GeneralTabSchema, [SchemaConstants.name], { name })}
            defaultValue={resource.name}
            title={t('technical_resources_details.name')}
            description={t('technical_resources_details.nameDescription')}
        />
        <Field
            isFilledByEiv
            filledByEivText={t('filled_by_eiv')}
            inputType="controlledText"
            isEditing={isEditing || isAdding}
            onValueChange={val => dispatch(setMarketMasterDataRegistryId(val))}
            valueCheckFunction={marketMasterDataRegistryId => isFieldValid(GeneralTabSchema, [SchemaConstants.marketMasterDataRegistryId], { marketMasterDataRegistryId })}
            defaultValue={resource.marketMasterDataRegistryId}
            title={t('technical_resources_details.marketMasterDataRegistryId')}
            description={t('technical_resources_details.marketMasterDataRegistryIdDescription')}
        />
        <Field
            isFilledByEiv
            filledByEivText={t('filled_by_eiv')}
            onValueChange={val => dispatch(setType(val))}
            isEditing={isEditing || isAdding}
            valueCheckFunction={technicalResourceType => isFieldValid(GeneralTabSchema, [SchemaConstants.technicalResourceType], { technicalResourceType })}
            title={t('technical_resources_details.type')}
            description={t('technical_resources_details.typeDescription')}
            values={allowedTechnicalResourceTypeValues}
            defaultValue={allowedTechnicalResourceTypeValues.find(f => f.code === resource.technicalResourceType?.code)}
            keyField="code"
            textField="name"
            inputType='select'
        />
        <Field
            isFilledByEiv
            filledByEivText={t('filled_by_eiv')}
            inputType="text"
            onValueChange={val => dispatch(setPowerPlantCode(val))}
            isEditing={isEditing || isAdding}
            valueCheckFunction={powerPlantCode => isFieldValid(GeneralTabSchema, [SchemaConstants.powerPlantCode], { powerPlantCode })}
            defaultValue={resource.powerPlantCode}
            title={t("technical_resources_details.powerPlantCode")}
            description={t("technical_resources_details.powerPlantCodeDescription")}
        />
        <GeneralSimpleElementTable
            canReorder={false}
            noRecordsText={t("no_records_text")}
            addButtonText={t('toolbar_buttons.add')}
            onMoreThanOneError={() => {
                dispatch(setNotificationAlertMessage("alert_messages.one_by_one"))
            }}
            defaultOpen
            type="controlledText"
            title={t("technical_resources_details.assignedStorages")}
            elements={resource.assignedStorages}
            actions={getAssignedStoragesActions()}
            defaultElement=""
            valueCheckFunction={val => isSchemaValid(SimpleStringSchema(true), val)}
            gridCheckFunction={assignedStorages => isFieldValid(GeneralTabSchema, [SchemaConstants.assignedStorages], { assignedStorages })}
            pageStatus={pageStatus}
        />
        {controllableResource?.compensationType?.code === "Z01" &&
            <GeneralSimpleElementTable
                canReorder={false}
                noRecordsText={t("no_records_text")}
                addButtonText={t('toolbar_buttons.add')}
                onMoreThanOneError={() => {
                    dispatch(setNotificationAlertMessage("alert_messages.one_by_one"))
                }}
                filledByEivText={t('filled_by_eiv')}
                defaultOpen
                type="controlledText"
                title={t("technical_resources_details.eegUnitCodes")}
                description={t("technical_resources_details.eegUnitCodesDescription")}
                elements={resource.eegUnitCodes}
                actions={getEegUnitCodesActions()}
                defaultElement=""
                valueCheckFunction={val => isSchemaValid(EEGUnitCodeSchema(true), val)}
                gridCheckFunction={eegUnitCodes => isFieldValid(GeneralTabSchema, [SchemaConstants.eegUnitCodes], { eegUnitCodes })}
                pageStatus={pageStatus}
            />
        }
        <Field
            isFilledByEiv
            filledByEivText={t('filled_by_eiv')}
            onValueChange={val => dispatch(setBillingModel(val))}
            isEditing={isEditing || isAdding}
            valueCheckFunction={billingModel => isFieldValid(GeneralTabSchema, [SchemaConstants.billingModel], { billingModel })}
            title={t("technical_resources_details.billingModel")}
            description={t("technical_resources_details.billingModelDescription")}
            values={allowedBillingModelValues}
            defaultValue={allowedBillingModelValues.find(f => f.code === resource.billingModel?.code)}
            keyField="code"
            textField="name"
            inputType='select'
        />
        <Field
            isFilledByEiv
            filledByEivText={t('filled_by_eiv')}
            allowAdd
            onAddNewClick={() => {
                dispatch(setAddNewMarketPartnerData({
                    show: true,
                    type: "technical",
                    fieldPath: "operatorId",
                    data: undefined
                }))
            }}
            onValueChange={val => dispatch(setOperator(val?.id))}
            isEditing={isEditing || isAdding}
            valueCheckFunction={operator => isFieldValid(GeneralTabSchema, [SchemaConstants.operatorId], { operatorId: operator?.id })}
            defaultValue={resource.operatorId && operatorDefaultValue()}
            title={t('technical_resources_details.operator')}
            description={t('technical_resources_details.operatorDescription')}
            values={prepareMarketPartnersList()}
            keyField="id"
            textField="text"
            inputType='select'
        />
        <Field
            disabled={resource.technicalResourceType?.code === "SEE"}
            onValueChange={val => dispatch(setProducesEnergy(val))}
            inputType='boolean'
            valueCheckFunction={producesEnergy => isFieldValid(GeneralTabSchema, [SchemaConstants.consumesEnergy, SchemaConstants.producesEnergy], {
                producesEnergy,
                consumesEnergy: resource.consumesEnergy
            })}
            defaultValue={resource.producesEnergy}
            isEditing={isEditing || isAdding}
            title={t("technical_resources_details.producesEnergy")}
        />
        {resource.producesEnergy &&
            <Field
                inputType="number"
                maxFractionDigits={0}
                useGrouping={false}
                defaultValue={resource.production?.externalID}
                title={t('marketLocation.productionExternalId')}
                isEditing={isEditing || isAdding}
                valueCheckFunction={externalID => isFieldValid(GeneralTabSchema, [SchemaConstants.production, SchemaConstants.producesEnergy], {
                    production: { externalID },
                    producesEnergy: resource.producesEnergy
                })}
                onValueChange={val => dispatch(setProductionExternalId((val)))}
            />
        }
        <Field
            disabled={resource.technicalResourceType?.code === "SSE"}
            onValueChange={val => dispatch(setConsumesEnergy(val))}
            inputType='boolean'
            valueCheckFunction={consumesEnergy => isFieldValid(GeneralTabSchema, [SchemaConstants.consumesEnergy, SchemaConstants.producesEnergy], {
                consumesEnergy,
                producesEnergy: resource.producesEnergy
            })}
            defaultValue={resource.consumesEnergy}
            isEditing={isEditing || isAdding}
            title={t("technical_resources_details.consumesEnergy")}
        />
        {resource.consumesEnergy &&
            <Field
                inputType="number"
                maxFractionDigits={0}
                useGrouping={false}
                defaultValue={resource.consumption?.externalID}
                title={t('marketLocation.consumptionExternalId')}
                isEditing={isEditing || isAdding}
                valueCheckFunction={externalID => isFieldValid(GeneralTabSchema, [SchemaConstants.consumption, SchemaConstants.consumesEnergy], {
                    consumption: { externalID },
                    consumesEnergy: resource.consumesEnergy
                })}
                onValueChange={val => dispatch(setConsumptionExternalId((val)))}
            />
        }
    </>
}

export default General
