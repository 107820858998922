import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UnavailabilityDetails from "api/fixed/Unavailability/UnavailabilityDetails";
import UnavailabilityDto, {
    fromDto as fromUnavailabilityDto
} from "../../../../api/dtos/Unavailability/UnavailabilityDto";
import UnavailabilityDetailsDto, {
    fromDto as fromUnavailabilityDetailsDto
} from "../../../../api/dtos/Unavailability/UnavailabilityDetailsDto";
import { RootState } from "../../../../app/store";
import Unavailability from "../../../../api/fixed/Unavailability/Unavailability";

export interface UnavailabilityState {
    allUnavailabilities: UnavailabilityDto[] | undefined,
    currentUnavailability: UnavailabilityDetailsDto | undefined
}

const initialState: UnavailabilityState = {
    currentUnavailability: undefined,
    allUnavailabilities: undefined,
};

export const unavailabilitySlice = createSlice({
    name: 'unavailabilityTimeSeries',
    initialState,
    reducers: {
        setAllUnavailabilities: (state, action: PayloadAction<UnavailabilityDto[] | undefined>) => {
            state.allUnavailabilities = action.payload;
        },
        setCurrentUnavailability: (state, action: PayloadAction<UnavailabilityDetailsDto | undefined>) => {
            state.currentUnavailability = action.payload;
        }
    }
});

export const currentUnavailabilitySelector = (state: RootState): UnavailabilityDetails | undefined => {
    if (state.unavailabilities && state.unavailabilities.currentUnavailability) {
        return fromUnavailabilityDetailsDto(state.unavailabilities.currentUnavailability);
    }
    return undefined;
};

export const allUnavailabilitiesSelector = (state: RootState): Unavailability[] | undefined => {
    if (state.unavailabilities.allUnavailabilities) {
        return state.unavailabilities.allUnavailabilities.map(un => fromUnavailabilityDto(un));
    }
    return state.unavailabilities.allUnavailabilities;
};

export const { setAllUnavailabilities, setCurrentUnavailability } = unavailabilitySlice.actions;

export default unavailabilitySlice.reducer;