import React, { ChangeEvent, ReactElement } from "react";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CustomButton } from "ndr-designsystem";
import { useTranslation } from "react-i18next";

interface Props {
    currentlyViewing: "configuration" | "generated"
    onViewChange: (e: ChangeEvent) => void,
    onAddClick?: () => void
}

export interface ConfigurationProps {
    currentlyViewing: "configuration" | "generated"
    onViewChange: (e: ChangeEvent) => void,
}

const ConfigurationToolbar = ({ currentlyViewing, onViewChange, onAddClick }: Props): ReactElement => {
    const { t } = useTranslation("tables")
    return <Box display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center">
        <FormControl component="fieldset">
            <RadioGroup value={currentlyViewing}
                        onChange={onViewChange}
                        row aria-label="gender" name="row-radio-buttons-group">
                <FormControlLabel value="configuration" control={<Radio color="primary"/>}
                                  label={`${t('controllable_resources_details.cost_info.configuration')}`}/>
                <FormControlLabel value="generated" control={<Radio color="primary"/>}
                                  label={`${t('controllable_resources_details.cost_info.generated')}`}/>
            </RadioGroup>
        </FormControl>
        {onAddClick &&
            <CustomButton title={t('toolbar_buttons.add')} onClick={onAddClick}/>
        }
    </Box>
}

ConfigurationToolbar.defaultProps = {
    onAddClick: undefined
}

export default ConfigurationToolbar
