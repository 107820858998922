import { createDateTime } from "ndr-designsystem";
import GeneratedCostInfo from "../../fixed/GeneratedCostInfo/GeneratedCostInfo";

export default interface GeneratedCostInfoDto {
    date: string,
    dispatchId: string,
    dispatchedAt: string,
    status: string,
    timeSeries: string[]
}

export const fromDto = (dto: GeneratedCostInfoDto): GeneratedCostInfo => ({
    ...dto,
    date: createDateTime(dto.date),
    dispatchedAt: createDateTime(dto.dispatchedAt)
})
