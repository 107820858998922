import React, { ReactElement, useCallback } from "react";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GridField, GridFieldActionsCell, isSchemaValid } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../app/store";
import { useAppDispatch } from "../../../../app/hooks";
import { setAllResources as setAllTechnicalResources } from "../../../technicalResources/store/technicalResourcesSlice";
import { deleteTechnicalResource } from "../../store/thunks";
import { fetchTechnicalResourcesByControllableResource } from "../../../technicalResources/store/thunks";
import { CRTRTableSchema, TRSchema } from "../../../../utils/inputChecking";
import { getMarketPartnersId } from "../../../../utils";
import { isEEG, isSSE } from "../utils";

interface RouteParams {
    id: string;
}

const TechnicalResourcesTable = (): ReactElement => {
    const { currentResource: resource } = useSelector((state: RootState) => state.controllableResources);
    const { allPartners: marketPartners } = useSelector((state: RootState) => state.marketPartners);
    const technicalResources = useSelector((state: RootState) => state.technicalResources.allResources);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { id } = useParams<RouteParams>();
    const { t } = useTranslation("tables");

    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);
    const getAllTechnicalResourcesIDs = useCallback(
        (): string[] =>
            (technicalResources ?? [])
                .filter((r) => !resource?.technicalResourceIds?.includes(r.inventoryItemId ?? ""))
                .map((r) => r.externalID ?? ""),
        [technicalResources, resource],
    );

    const TableSchema = CRTRTableSchema();
    if (resource === undefined || resource === null) {
        return <></>;
    }

    const removeTechnicalResource = (index: number): void => {
        if (!technicalResources) return;
        const item = technicalResources[index];
        const data = [...technicalResources];
        data.splice(index, 1);
        dispatch(setAllTechnicalResources(undefined));
        dispatch(
            deleteTechnicalResource({
                CRID: resource.inventoryItemId!,
                TRID: item.inventoryItemId!,
            }),
        ).then(() => dispatch(setAllTechnicalResources(data)));
    };

    const TechnicalResourcesTableCommands = (props: GridCellProps): ReactElement => {
        const { dataItem } = props;

        const isValid = isSchemaValid(
            TRSchema(
                prepareMP(),
                resource.energySource,
                isSSE(dataItem),
                dataItem.consumesEnergy,
                dataItem.producesEnergy,
                isEEG(resource),
                dataItem.missingValueForDirection,
                dataItem.missingValueForIncline,
                getAllTechnicalResourcesIDs(),
            ),
            dataItem,
        );
        console.log(isValid);
        if (isValid.error != null)
            isValid.error = "validation:controllable_resource_details.invalid_technical_resources";

        return <GridFieldActionsCell {...props} validationResult={isValid} remove={removeTechnicalResource} />;
    };

    const forceRefresh = async (): Promise<void> => {
        dispatch(setAllTechnicalResources(undefined));
        if (resource.inventoryItemId) {
            await dispatch(
                fetchTechnicalResourcesByControllableResource({
                    CRID: resource.inventoryItemId,
                    force: true,
                }),
            );
        }
    };

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            isEditing
            addButtonText={t("toolbar_buttons.add")}
            onAddNew={() => history.push(`/controllable/${resource.externalID}/resources/add`)}
            onRefresh={forceRefresh}
            defaultOpen
            dataItemKey='inventoryItemId'
            showError
            validationResult={isSchemaValid(TableSchema, resource.technicalResourceIds ?? [])}
            values={technicalResources}
            commandsColumn={<GridColumn cell={TechnicalResourcesTableCommands} />}
            onRowClick={(event) => history.push(`/controllable/${id}/resources/${event.dataItem.externalID}`)}
            gridFields={[
                {
                    name: "externalID",
                    title: t("technical_resources_details.externalID"),
                },
                {
                    name: "name",
                    title: t("technical_resources_details.name"),
                },
                {
                    name: "technicalResourceType.name",
                    title: t("technical_resources_details.type"),
                },
                {
                    name: "billingModel.name",
                    title: t("technical_resources_details.billingModel"),
                },
            ]}
        />
    );
};

export default TechnicalResourcesTable;
