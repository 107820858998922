import { GridCellProps } from "@progress/kendo-react-grid";
import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReceivedMessage from "../../../api/fixed/ConnectPlusMessage/ReceivedMessage";
import SentMessage from "../../../api/fixed/ConnectPlusMessage/SentMessage";
import { setCurrentXMLFile } from "../../../features/ConnectPlusMsgs/ConnectPlusMessagesSlice";
import { useAppDispatch } from "../../../app/hooks";

const FilesField = (props: GridCellProps & { direction: "sent" | "received" }): ReactElement => {
    const { dataItem, direction }: { dataItem: ReceivedMessage | SentMessage, direction: "sent" | "received" } = props;
    const links: ReactElement[] = [];
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables")

    if (dataItem.hasXml) {
        links.push(<Button key="xml" onClick={() => {
            dispatch(setCurrentXMLFile({ messageType: direction, id: dataItem.internalId, fileType: "xml" }))
        }} style={{ color: "blue" }}>{t('connect_plus_messages.get_xml')}</Button>);
    }

    if (dataItem.hasAckXml) {
        links.push(<br key="br"/>)
        links.push(<Button key="ackxml" onClick={() => dispatch(setCurrentXMLFile({
            messageType: direction,
            id: dataItem.internalId,
            fileType: "ackxml"
        }))} style={{ color: "blue" }}>{t('connect_plus_messages.get_ackxml')}</Button>)
    }

    return <td>
        {links.map(btn => btn)}
    </td>
}

export default FilesField
