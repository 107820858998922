import DowntimeData from "api/fixed/Downtime/DowntimeData";
import ResolutionDto from "./ResolutionDto";
import DataPointDto, { fromDto as fromDataPointDto } from "./DataPointDto";

export default interface DowntimeDataDto {
    unitName: string,
    resolution: ResolutionDto,
    dataPoints: DataPointDto[]
}

export const fromDto = (dto: DowntimeDataDto): DowntimeData => ({
    ...dto,
    dataPoints: dto.dataPoints.map(p => fromDataPointDto(p))
})