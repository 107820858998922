import React, { ReactElement, useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridField } from "ndr-designsystem";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DateCell from "../../Components/DateCell";
import {
    allGeneratedSensitivitiesSelector, setAllGeneratedSensitivities,
    setSendingStatusGeneratedSensitivity
} from "../timeseries/generatedSensitivities/store/store";
import { fetchGeneratedSensitivities } from "../timeseries/generatedSensitivities/store/thunks";
import { TimeseriesPageSize } from "../../utils";

const GeneratedSensitivitiesTable = (): ReactElement => {
    const allGeneratedSensitivities = useAppSelector(allGeneratedSensitivitiesSelector, (left, right) => left?.length === right?.length);
    const { currentGroup } = useAppSelector(state => state.controlGroups);
    const { isNextFetchingPossible, isPreviousFetchingPossible } = useAppSelector(state => state.generatedSensitivity)
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation('tables');

    useEffect(() => {
        if (allGeneratedSensitivities && isPreviousFetchingPossible)
            dispatch(setAllGeneratedSensitivities(undefined))
    }, [dispatch])

    const fetchSensitivities = (take: number): void => {
        if (currentGroup != null) {
            dispatch(fetchGeneratedSensitivities({
                id: currentGroup.inventoryItemId!,
                type: "group",
                take
            }));
        }
    }
    const columns = useMemo(() => [{
        name: "date",
        title: t("timeSeries.date"),
        customCell: (props: GridCellProps) => <DateCell
            {...props}
            fieldPath="date"
            displayFormat={DateTime.DATE_SHORT}
        />
    }, {
        name: "timeSeries.length",
        title: t("timeSeries.no_contained_resources")
    },
        {
            title: t("controllable_resources_details.sensitivities.view_status"),
            customCell: (props: GridCellProps): ReactElement => <td><Button color="secondary" onClick={() => {
                dispatch(setSendingStatusGeneratedSensitivity(props.dataItem.dispatchId))
            }}>{t('controllable_resources_details.sensitivities.view_status_button')}</Button></td>
        }
    ], [t, dispatch])

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            defaultOpen
            dataItemKey="internalID"
            pageable={{
                info: false,
                buttonCount: 4,
            }}
            take={TimeseriesPageSize}
            addStateToURL={false}
            onDataStateChange={state => {
                if (!state) return;
                if ((state.skip ?? 0) >= (allGeneratedSensitivities?.length ?? 0) - (state.take ?? 0) * 2 && (isNextFetchingPossible || allGeneratedSensitivities === undefined)) {
                    fetchSensitivities((state.take ?? 0) * 5);
                }
            }}
            values={allGeneratedSensitivities}
            onRowClick={event => history.push(`/controlGroups/${currentGroup?.externalID}/generated-sensitivities/${event.dataItem.dispatchId}`)}
            gridFields={columns}
        />
    )
}

export default GeneratedSensitivitiesTable
