import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            "&::-webkit-scrollbar": {
                width: 7,
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "darkgrey",
                outline: `1px solid slategrey`,
            },
        },
    })
)

export default useStyles;