import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { GridField, GridFieldActionsCell, isSchemaValid, SelectFieldGridCell } from "ndr-designsystem";
import { useLocation } from "react-router-dom";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { RootState } from "../app/store";
import { GeneralGridOperatorsTableProps, PageStatus } from "../features/ControlGroups/types";
import { useAppDispatch } from "../app/hooks";
import { MarketPartner } from "../api/fixed/MarketPartner";
import { GridOperatorsSchema } from "../utils/inputChecking";
import EmptyWhileEditingCell from "./EmptyWhileEditingGridCell";
import { setAddNewMarketPartnerData, setNotificationAlertMessage } from "../features/appStateSlice";
import { areMarketPartnersSame, buildMarketPartnerText } from "../utils";
import DragCell, { DragDropContext } from "./DragCell";
import IndexCell from "./IndexCell";
import GridOperatorsLabelCell from "./GridOperatorsLabelCell";
import { ValidationResult } from "../utils/types";

const GeneralGridOperatorsTable = ({
    pageStatus,
    elements,
    actions,
    title,
    description,
    showIndex,
    validationResult,
    newElementPosition,
    addNewMarketPartnerData,
    showLabel,
    firstElementCurrentTenantMarketPartner
}: GeneralGridOperatorsTableProps): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { setGridOperators, updateNewGridOperator, swapGridOperators } = actions;


    const {
        allPartners: marketPartners,
        currentTenantMarketPartner
    } = useSelector((state: RootState) => state.marketPartners);
    const [addingNewEntry, setAddingNewEntry] = useState(false);
    const [activeItem, setActiveItem] = React.useState<MarketPartner | null>(null);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");
    const location = useLocation();
    const prevLocation = useRef<string>(location.pathname);



    const addNewOperator = useCallback(() => {

        if (addingNewEntry) {
            dispatch(setNotificationAlertMessage("alert_messages.one_by_one"))
            return;
        }

        if (elements === undefined || elements.length === 0) {
            dispatch(setGridOperators([""]))
        } else {
            dispatch(setGridOperators(newElementPosition === "top" ? ["", ...elements,] : [...elements, ""]))
        }

        setAddingNewEntry(true);
        // other things are not changing
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements, addingNewEntry])

    const removeAffectedGridOperator = (index: number): void => {

        if (elements === undefined) return;
        const newElements = [...elements]
        newElements.splice(index, 1);
        dispatch(setGridOperators(newElements));
    }

    const discardNewAffectedGridOperator = (): void => {

        if (elements === undefined) return;

        const data = [...elements];
        data.splice(newElementPosition === "top" ? 0 : elements.length - 1, 1);

        setAddingNewEntry(false);
        dispatch(setGridOperators(data));
    }

    useEffect(() => {
        if (!isEditing && !isAdding) {
            setAddingNewEntry(false);
        }
        // eslint-disable-next-line
    }, [isEditing, addingNewEntry])

    const filterMarketPartnersByCurrentValues = useCallback(() => {
        if (!marketPartners) return []
        if (!currentTenantMarketPartner) return [];

        let toReturn: { id: string, text: string, itemDisabled: boolean }[] = []

        if (!elements || elements.length === 0) toReturn = marketPartners.map(value => ({
            id: `${value.inventoryItemId}`,
            text: buildMarketPartnerText(value),
            itemDisabled: firstElementCurrentTenantMarketPartner ? !areMarketPartnersSame(currentTenantMarketPartner, value) : false
        }));

        toReturn = marketPartners
            .filter(p => !(elements?.includes(p.inventoryItemId ?? "")))
            .map(value => ({
                id: `${value.inventoryItemId}`,
                text: buildMarketPartnerText(value),
                itemDisabled: firstElementCurrentTenantMarketPartner && (elements?.length === 0 || (elements?.length === 1 && elements[0] === "")) ? !areMarketPartnersSame(currentTenantMarketPartner, value) : false
            }));

        // show current tenant market partner first
        toReturn = [
            ...toReturn.filter(c => !c.itemDisabled),
            ...toReturn.filter(c => c.itemDisabled)
        ];

        return toReturn
    }, [marketPartners, elements, currentTenantMarketPartner, firstElementCurrentTenantMarketPartner])

    const marketPartnerIds = useCallback(() => marketPartners?.map(mp => mp.inventoryItemId ?? "") ?? [], [marketPartners]);

    const updateNewValue = (val: { text: string, id: string }): void => {
        dispatch(updateNewGridOperator(val?.id));
        setAddingNewEntry(false)
    };

    const saveNewValue = (val: MarketPartner): void => {
        setAddingNewEntry(false);
        dispatch(updateNewGridOperator(val?.inventoryItemId));
    };

    useEffect(() => {
        if (location.pathname !== prevLocation.current && addingNewEntry) {
            prevLocation.current = location.pathname;
            discardNewAffectedGridOperator()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, addingNewEntry])

    const createMarketPartnerValues = useCallback(() => (elements ?? []).map(el => marketPartners?.find(mp => mp.inventoryItemId === el)), [elements, marketPartners])

    const AffectedGridOperatorsCommands = (props: GridCellProps): ReactElement => {
        const { dataItem, dataIndex } = props;
        const isElementValid: ValidationResult = dataItem !== undefined ? isSchemaValid(GridOperatorsSchema(marketPartnerIds()), dataItem.inventoryItemId) : {
            valid: false,
            error: null
        };
        return <GridFieldActionsCell {...props}
            inEdit={addingNewEntry &&
                ((dataIndex === 0 && newElementPosition === "top") || (dataIndex === (elements?.length ?? 0) - 1 && newElementPosition === "bottom"))
            }
            validationResult={isElementValid}
            add={saveNewValue}
            remove={removeAffectedGridOperator}
            discard={discardNewAffectedGridOperator}
        />;
    }


    const reorder = (target: MarketPartner): void => {
        if (elements) {
            if (activeItem === target) {
                return;
            }
            const startIndex = elements.findIndex(el => el === target.inventoryItemId);
            const endIndex = elements.findIndex(el => el === activeItem?.inventoryItemId);
            if (endIndex === 0 || startIndex === 0 && firstElementCurrentTenantMarketPartner) return;
            dispatch(swapGridOperators({ startIndex, endIndex }));
        }
    };

    const dragStart = (startItem: MarketPartner): void => {
        setActiveItem(startItem);
    };

    const EditingAffectedGridOperatorsCell = (props: GridCellProps): ReactElement => {
        const { dataItem, dataIndex } = props;

        return (
            <SelectFieldGridCell
                {...props}
                allowAdd
                onAddNewClick={() => {
                    dispatch(setAddNewMarketPartnerData({
                        show: true, ...addNewMarketPartnerData,
                        elementIndex: dataIndex,
                        data: undefined
                    }))
                }}
                colSpan={2}
                dataItemKey="id"
                textField="text"
                isEditing={addingNewEntry && ((dataIndex === 0 && newElementPosition === "top") || (dataIndex === (elements?.length ?? 0) - 1 && newElementPosition === "bottom"))}
                values={filterMarketPartnersByCurrentValues()}
                onChangeHandler={updateNewValue}
                // disabled items happen only when selecting first affected grid operator
                disabledText={t('first_affected_grid_operator')}
                defaultValue={dataItem && {
                    text: `${buildMarketPartnerText(dataItem, false)}`,
                    id: dataItem.inventoryItemId
                }}
                editingDefaultValue={dataItem && {
                    text: buildMarketPartnerText(dataItem),
                    id: dataItem.inventoryItemId
                }}
                valueCheckFunction={val => val && isSchemaValid(GridOperatorsSchema(marketPartnerIds()), val?.id)}
            />
        )
    }

    const EmptyWhileEditingCustomCell = (props: GridCellProps): ReactElement => {
        const { dataItem, dataIndex } = props;
        return (
            <EmptyWhileEditingCell
                {...props}
                isEditing={addingNewEntry && ((dataIndex === 0 && newElementPosition === "top") || (dataIndex === (elements?.length ?? 0) - 1 && newElementPosition === "bottom"))}
                defaultValue={dataItem && dataItem.name}
            />
        );
    }

    const LabelCell = (props: GridCellProps): ReactElement => {
        const { dataIndex } = props;
        let text: string | undefined;

        if (dataIndex === 0) text = t("controllable_resources_details.connection_grid_operator");
        else if (dataIndex === (elements ?? []).length - 1) text = t("controllable_resources_details.transfer_grid_operator");

        return (
            <GridOperatorsLabelCell
                {...props}
                customText={text}
            />
        );
    };

    const getGridFields = useCallback(() => {
        const grids: GridFieldElement[] = [];
        if (swapGridOperators && (isEditing || isAdding)) {
            grids.push({
                customCell: props => <DragCell
                    {...props}
                    reorderFirstElement={!firstElementCurrentTenantMarketPartner || (elements ?? []).length > 1 && elements![0] !== currentTenantMarketPartner?.inventoryItemId}
                    visible={!addingNewEntry &&
                        firstElementCurrentTenantMarketPartner && (
                            ((elements ?? []).length > 1 && elements![0] !== currentTenantMarketPartner?.inventoryItemId) ||
                            (elements ?? []).length > 2
                        )}
                />
            });
        }
        if (showIndex) {
            grids.push({
                customCell: IndexCell
            });
        }
        grids.push({
            name: "code",
            title: t("controllable_resources_details.code"),
            customCell: EditingAffectedGridOperatorsCell
        });
        grids.push({
            name: "name",
            title: t("controllable_resources_details.name"),
            customCell: EmptyWhileEditingCustomCell
        });
        if (!addingNewEntry && showLabel) {
            grids.push({
                title: "",
                customCell: LabelCell
            });
        }

        return grids;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements, addingNewEntry, marketPartners, isEditing]);

    return (
        <DragDropContext.Provider value={{ reorder, dragStart }}>
            <GridField
                noRecordsText={t("no_records_text")}
                addButtonText={t('toolbar_buttons.add')}
                defaultOpen
                validationResult={validationResult}
                isEditing={isEditing || isAdding}
                onAddNew={addNewOperator}
                values={createMarketPartnerValues()}
                dataItemKey="inventoryItemId"
                title={title}
                filledByEivText={t("filled_by_eiv")}
                description={description}
                commandsColumn={<GridColumn cell={AffectedGridOperatorsCommands} />}
                gridFields={getGridFields()}
            />
        </DragDropContext.Provider>
    );
}

GeneralGridOperatorsTable.defaultProps = {
    newElementPosition: "top",
    firstElementCurrentTenantMarketPartner: false
}

export default GeneralGridOperatorsTable
