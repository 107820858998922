import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fromDto as fromTaskDto, TaskDto } from "../../../api/dtos/Task/TaskDto";
import { RootState } from "../../../app/store";
import Task from "../../../api/fixed/Task/Task";

export interface TasksState {
    allTasks: TaskDto[] | undefined,
    totalItems: number | undefined,
    expandedItemId: string | undefined
}

const initialState: TasksState = {
    allTasks: undefined,
    totalItems: undefined,
    expandedItemId: undefined
};

export const tasksSlice = createSlice({
    name: 'tasksSlice',
    initialState,
    reducers: {
        setAllTasks: (state, action: PayloadAction<TaskDto[] | undefined>) => {
            state.allTasks = action.payload
        },
        addNewTasks: (state, action: PayloadAction<TaskDto[]>) => {
            if (state.allTasks) {
                state.allTasks = state.allTasks.concat(action.payload)
            } else {
                state.allTasks = action.payload
            }
        },
        setTotalItems: (state, action: PayloadAction<number | undefined>) => {
            state.totalItems = action.payload
        },
        setExpandedItemId: (state, action: PayloadAction<string | undefined>) => {
            if (state.expandedItemId === action.payload) {
                state.expandedItemId = undefined
            } else {
                state.expandedItemId = action.payload
            }
        }
    },
});

export const allTasksSelector = (state: RootState): Task[] | undefined => {
    if (state.tasks.allTasks) {
        return state.tasks.allTasks.map(d => ({
            ...fromTaskDto(d),
        }));
    }
    return state.tasks.allTasks
};


export const { setAllTasks, setTotalItems, addNewTasks, setExpandedItemId } = tasksSlice.actions;

export default tasksSlice.reducer;
