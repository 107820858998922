import React, { ReactElement, useEffect, useMemo, useRef } from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridField } from "ndr-designsystem";
import { GridDataState } from "ndr-designsystem/dist/utils";
import { isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import DateCell from "../../../../../Components/DateCell";
import {
    allGeneratedSensitivitiesSelector, setAllGeneratedSensitivities,
    setSendingStatusGeneratedSensitivity
} from "../../../../timeseries/generatedSensitivities/store/store";
import { fetchGeneratedSensitivities } from "../../../../timeseries/generatedSensitivities/store/thunks";
import ConfigurationToolbar, { ConfigurationProps } from "../../../../../Components/ConfigurationToolbar";
import { TimeSeriesButtonsCount, TimeseriesPageSize } from "../../../../../utils";

const GeneratedCostInfoTable = ({ currentlyViewing, onViewChange }: ConfigurationProps): ReactElement => {
    const allGeneratedSensitivity = useAppSelector(allGeneratedSensitivitiesSelector, (left, right) => left?.length === right?.length);
    const { currentResource } = useAppSelector(state => state.controllableResources);
    const { isNextFetchingPossible, isPreviousFetchingPossible } = useAppSelector(state => state.generatedSensitivity)
    const oldState = useRef<GridDataState | undefined>(undefined)
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation('tables');

    useEffect(() => {
        if (allGeneratedSensitivity && isPreviousFetchingPossible)
            dispatch(setAllGeneratedSensitivities(undefined))
    }, [dispatch])

    const fetchSensitivities = (take: number): void => {
        if (currentResource != null) {
            dispatch(fetchGeneratedSensitivities({
                id: currentResource.inventoryItemId!,
                type: "resource",
                take
            }));
        }
    }

    const columns = useMemo(() => [
        {
            name: "date",
            title: t("timeSeries.date"),
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="date"
                displayFormat={DateTime.DATE_SHORT}
            />
        }, {
            name: "timeSeries.length",
            title: t("timeSeries.no_contained_resources")
        },
        {
            title: t("controllable_resources_details.sensitivities.view_status"),
            customCell: (props: GridCellProps): ReactElement => <td><Button color="secondary" onClick={() => {
                dispatch(setSendingStatusGeneratedSensitivity(props.dataItem.dispatchId))
            }}>{t('controllable_resources_details.sensitivities.view_status_button')}</Button></td>
        }
    ], [t, dispatch])

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            defaultOpen
            dataItemKey="internalID"
            pageable={{
                info: false,
                buttonCount: TimeSeriesButtonsCount,
            }}
            take={TimeseriesPageSize}
            addStateToURL={false}
            onDataStateChange={state => {
                if (!state) return;
                const pageNumber = ((state.skip ?? 0) + TimeseriesPageSize) / TimeseriesPageSize;

                if (((pageNumber === 1 && allGeneratedSensitivity === undefined) || pageNumber % TimeSeriesButtonsCount === 0) && isNextFetchingPossible && !isEqual(oldState.current, state)) {
                    oldState.current = state;
                    fetchSensitivities((state.take ?? 0) * 5);
                }
            }}
            values={allGeneratedSensitivity}
            customToolbar={
                <ConfigurationToolbar
                    currentlyViewing={currentlyViewing}
                    onViewChange={onViewChange}
                />
            }
            onRowClick={event => history.push(`/controllable/${currentResource?.externalID}/sensitivities/${event.dataItem.dispatchId}`)}
            gridFields={columns}
        />
    )
}

export default GeneratedCostInfoTable
