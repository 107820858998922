import React, { ReactElement, useMemo } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { DateTime } from "luxon";
import { createDateTime, toLocaleDate } from "ndr-designsystem";
import { useAppSelector } from "../../../app/hooks";
import CustomChart from "../../../Components/Chart";
import { currentProductionDataSelector } from "./store/slice";

const TimeSeriesChart = (): ReactElement => {
    const currentProductionData = useAppSelector(currentProductionDataSelector, (left, right) => left?.id !== undefined && left?.id === right?.id);
    const internationalization = useInternationalization();
    const chartData = useMemo(() => {
        if (currentProductionData) {
            const values = currentProductionData.dataPoints.map(v => {
                const newDate = createDateTime(v.timestamp);
                return { x: newDate, y: v.value };
            });

            return {
                labels: values.map(t => t.x),
                datasets: [{
                    label: "Value",
                    data: values.map(t => t.y),
                    backgroundColor: "#ef870f",
                    borderColor: "#e8cab9"
                }]
            };
        }
        return { labels: [""], datasets: [] };
    }, [currentProductionData]);

    const getChartPlugins = useMemo(() => ({
        tooltip: {
            callbacks: {
                label: (item: any) => `${item.formattedValue} ${currentProductionData?.unitName}`,
                title: (items: any) => toLocaleDate(items[0].parsed.x, internationalization.locale, DateTime.DATETIME_SHORT)
            },
        },
        legend: {
            display: false
        },
    }), [internationalization, currentProductionData])

    const getChartScales = useMemo(() => ({
        y: {
            ticks: {
                callback: (value: number) => `${internationalization.formatNumber(value, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })} ${currentProductionData?.unitName}`
            },
            offset: true
        },
        x: {
            adapters: {
                date: {
                    zone: "Europe/Berlin"
                }
            },
            type: "time",
            time: {
                unit: "minute",
                stepSize: 15,
                minUnit: "minute",
                ticks: {
                    source: "data",
                },
                displayFormats: {
                    hour: internationalization.locale === "de" ? "HH" : "h a",
                    minute: internationalization.locale === "de" ? "HH:mm" : "h:mm a",
                    second: internationalization.locale === "de" ? "HH:mm:ss" : "h:mm:ss a"
                }
            },
            offset: true
        },
    }), [internationalization, currentProductionData])

    return <CustomChart
        maintainAspectRatio={false}
        locale={internationalization.locale}
        plugins={getChartPlugins}
        scales={getChartScales}
        data={chartData}
        zoom
    />
}

export default TimeSeriesChart;
