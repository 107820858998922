import React, { ReactElement } from "react";
import { Box, Divider, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import { resourcesAttentionSelector } from "./store/slice";
import { useAppSelector } from "../../app/hooks";

const ResourcesAttention = (): ReactElement => {
    const { t } = useTranslation("common");
    const history = useHistory();

    const resourcesAttention = useAppSelector(resourcesAttentionSelector,
        (left, right) => left?.length === right?.length);

    return <Paper elevation={1} sx={{
        height: "100%",
        width: "100%", display: "flex", flexDirection: "column", alignItems: "center",
        justifyContent: "space-between",
    }}>
        <Typography gutterBottom variant="h6" component="div" sx={{ height: 30 }} padding={1}>
            {t('dashboard.resources_attention')}
        </Typography>
        <Divider flexItem/>
        {(!resourcesAttention || resourcesAttention.length === 0) &&
            <Box width="100%" display="flex" height="100%" justifyContent="center" flexDirection="column"
                 alignItems="center">
                <CheckCircle sx={{ fontSize: 100 }} color="primary"/>
                <Typography variant="body2">
                    {t('no_resources_not_enhanced')}
                </Typography>
            </Box>
        }
        <Box overflow="auto" width="100%" height="100%">
            <Table>
                <TableBody>
                    {resourcesAttention?.map((res) => (
                        <TableRow
                            hover
                            onClick={() => {
                                history.push(`/controllable/${res.externalID}`, { force: true })
                            }}
                            key={res.inventoryItemId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                        >
                            <TableCell
                                align="center">{res.externalID}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    </Paper>
}

export default ResourcesAttention;
