import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketPartner } from "../../../api/fixed/MarketPartner";
import {
    deleteMarketPartner,
    editMarketPartner,
    fetchCurrentTenantMarketPartnerData,
    fetchMarketPartners
} from "./thunks";

export interface MarketPartnersState {
    allPartners: MarketPartner[] | undefined,
    currentTenantMarketPartner: MarketPartner | undefined
}

const initialState: MarketPartnersState = {
    allPartners: undefined,
    currentTenantMarketPartner: undefined
};

export const marketPartnersSlice = createSlice({
    name: 'marketPartnersSlice',
    initialState,
    reducers: {
        setMarketPartners: (state, action: PayloadAction<MarketPartner[] | undefined>) => {
            state.allPartners = action.payload
        },
        addToMarketPartners: (state, action: PayloadAction<MarketPartner>) => {
            if (state.allPartners)
                state.allPartners.push(action.payload);
            else state.allPartners = [action.payload]
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMarketPartners.fulfilled, (state, action: PayloadAction<MarketPartner[]>) => {
                state.allPartners = action.payload;
            })
            .addCase(deleteMarketPartner.fulfilled, (state, action: PayloadAction<MarketPartner[]>) => {
                state.allPartners = action.payload;
            })
            .addCase(fetchCurrentTenantMarketPartnerData.fulfilled, (state, action: PayloadAction<MarketPartner>) => {
                state.currentTenantMarketPartner = action.payload;
            })
            .addCase(editMarketPartner.fulfilled, (state, action: PayloadAction<MarketPartner>) => {
                if (state.allPartners) {
                    const index = state.allPartners.findIndex(p => p.inventoryItemId === action.payload.inventoryItemId)
                    state.allPartners[index] = action.payload
                }
            })
    },
});

export const {
    setMarketPartners,
    addToMarketPartners
} = marketPartnersSlice.actions;

export default marketPartnersSlice.reducer;