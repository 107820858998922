import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        toolbar: {
            display: "flex",
            alignItems: "center",
            padding: 10,
            justifyContent: "space-between"
        }
    })
)

export default useStyles;