import React, { ReactElement, useMemo, useState } from "react";
import { Box, Button, DialogActions, DialogTitle, Typography } from "@mui/material";
import { ClosableDialog } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { ListItemProps, MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const AddControllableResourceDialog = ({
                                           groupId,
                                           onSave,
                                           onDialogClose
                                       }: { groupId: string, onSave: (newResources: string[]) => void, onDialogClose: () => void }): ReactElement => {
    const { t } = useTranslation();
    const { allResources } = useSelector((state: RootState) => state.controllableResources);
    const { currentGroup, allGroups } = useSelector((state: RootState) => state.controlGroups);
    const [value, setValue] = useState<{ id: string, text: string }[]>([])

    const onChange = (event: MultiSelectChangeEvent): void => {
        setValue([...event.value]);
    };

    const itemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ): ReactElement => {
        const item = itemProps.dataItem;

        return React.cloneElement(li, li.props, <Typography>{item.text}</Typography>);
    };

    const resources = useMemo(() => {
        let dataToProcess = allResources ?? []
        if (allGroups) {
            dataToProcess = dataToProcess.filter(r => allGroups?.find(g => g.controllableResources?.includes(r.inventoryItemId ?? "")) === undefined)
        }

        if (groupId) {
            dataToProcess = dataToProcess.filter(r => r.isTolerationCase).filter(r => !currentGroup!.controllableResources?.includes(r.inventoryItemId ?? ""))
        }

        return dataToProcess.map(r => ({
            id: r.inventoryItemId,
            text: `${r.externalID} - ${r.name}`
        }));
    }, [allResources, currentGroup, groupId, allGroups])

    return <ClosableDialog
        dialogTitle={
            <DialogTitle
                style={{ paddingLeft: 0 }}>{t('tables:cr_in_cg_add_dialog.title')}
            </DialogTitle>
        }
        maxWidth="xl"
        contentPadding={2}
        open
        onClose={onDialogClose}>
        <Box width={500} height="100%" overflow="hidden" py={3}>
            <MultiSelect placeholder={t("tables:control_groups_details.tabs.controllable_resources")} dataItemKey="id"
                         textField="text"
                         filterable
                         itemRender={itemRender}
                         data={resources}
                         value={value}
                         onChange={onChange}
                         style={{ width: "100%", height: "100%", marginBottom: 5 }}/>
            <Typography variant="caption">
                {t('tables:control_groups_details.only_CRs_in_toleration_case')}
            </Typography>
        </Box>
        <DialogActions>
            <Button onClick={onDialogClose} color="primary">
                {t('tables:cr_in_cg_add_dialog.cancel')}
            </Button>
            <Button type="submit" color="primary" onClick={() => {
                onSave(value.map(v => v.id))
                onDialogClose()
            }}>
                {t('tables:cr_in_cg_add_dialog.save')}
            </Button>
        </DialogActions>
    </ClosableDialog>
}

export default AddControllableResourceDialog
