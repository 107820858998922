import { Box, IconButton } from "@mui/material";
import { Cached } from "@mui/icons-material";
import { CustomButton, useQuery } from "ndr-designsystem";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import qs from "qs";
import useStyles from "../styles";
import { setAllResources } from "../store/controllableResourcesSlice";
import { fetchControllableResources } from "../store/thunks";
import { useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface FilterParameters {
    page: number
}

const Toolbar = (): ReactElement => {
    const { allResources } = useSelector((state: RootState) => state.controllableResources);
    const { t } = useTranslation(["tables", "common"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const parameters = useQuery<FilterParameters>();
    const hasFilter = Object.keys(parameters).filter(k => k !== "page").length > 0;

    const forceRefresh = async (): Promise<void> => {
        dispatch(setAllResources(undefined))
        await dispatch(fetchControllableResources(true));
    };

    return <div className={classes.addNewRightButton}>
        <IconButton color="primary" component="span" disabled={allResources === undefined}
                    onClick={forceRefresh}>
            <Cached/>
        </IconButton>
        <Box>
            <CustomButton disabled={allResources === undefined}
                          title={t('controllable_resources_list.buttons.addNew')}
                          onClick={() => history.push("/controllable/add")}/>
            {hasFilter &&
                <CustomButton disabled={allResources === undefined}
                              title={t('controllable_resources_list.buttons.clear_filters')}
                              onClick={() => history.replace({ search: parameters.page ? qs.stringify({ page: parameters.page }, { encode: false }) : undefined })}/>
            }
        </Box>
    </div>

}

export default Toolbar;
