import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { Email } from "@mui/icons-material";
import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConnectPlusMessagesCard = (): ReactElement => {
    const history = useHistory();
    const { t } = useTranslation("common");

    return <Box display="flex" justifyContent="center" height="100%" width="100%">
        <Card sx={{ textAlign: "center", display: "flex", flexDirection: "column", flexGrow: 1, pb: 1 }}>
            <CardActionArea sx={{ width: "100%" }}
                            onClick={() => history.push("/messages")}
            >
                <Email sx={{ fontSize: 50, paddingTop: 2 }}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {t('home.connect_plus_messages')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('home.connect_plus_messages_details')}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Box>
}

export default ConnectPlusMessagesCard;
