import { Tranche } from "../../fixed/Tranche/Tranche";

export interface TrancheDto {
    externalID?: string,
    accountingGroup?: string,
    supplierId?: string,
    sizeUnit?: string,
    size?: number,
    revision?: number
}

export const toDto = (original: Tranche): TrancheDto => {
    const backup = { ...original }

    delete backup.sizeUnit
    delete backup.inventoryItemId

    return {
        ...backup,
        // @ts-ignore TODO: save this with id also
        sizeUnit: original.sizeUnit?.id
    }

}