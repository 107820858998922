import * as React from "react";
import { ReactElement } from "react";

import { DropDownList, DropDownListChangeEvent, ListItemProps, } from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { useTranslation } from "react-i18next";

interface DropdownFilterCellProps extends GridFilterCellProps {
    defaultItem: string;
    data: string[];
    namespace: string
}

const DropdownFilterCell = ({
                                defaultItem,
                                onChange: propsOnChange,
                                data,
                                value, namespace
                            }: DropdownFilterCellProps): ReactElement => {
    let hasValue: any = (v: any) => Boolean(v && v !== defaultItem);
    const { t } = useTranslation();

    const onChange = (event: DropDownListChangeEvent): void => {
        hasValue = hasValue(event.target.value);
        propsOnChange({
            value: hasValue ? event.target.value : "",
            operator: hasValue ? "eq" : "",
            syntheticEvent: event.syntheticEvent,
        });
    };

    const onClearButtonClick = (event: any): void => {
        event.preventDefault();
        propsOnChange({
            value: "",
            operator: "",
            syntheticEvent: event,
        });
    };

    const valueRender = (
        element: React.ReactElement<HTMLSpanElement>,
        val: string
    ): ReactElement => {
        if (!val) {
            return element;
        }

        return React.cloneElement(element, { ...element.props }, t(`${namespace}.${val}`));
    };

    const itemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ): ReactElement => {
        const item = itemProps.dataItem;

        return React.cloneElement(li, li.props, t(`${namespace}.${item}`));
    };

    return (
        <div className="k-filtercell">
            <DropDownList
                data={data}
                onChange={onChange}
                value={value || defaultItem}
                defaultItem={defaultItem}
                valueRender={valueRender}
                itemRender={itemRender}
            />
            <Button
                title="Clear"
                disabled={!hasValue(value)}
                onClick={onClearButtonClick}
                icon="filter-clear"
            />
        </div>
    );
};

export default DropdownFilterCell