import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { setAllErrors, setCurrentError, setExpandedItemId } from "./store";
import { RootState } from "../../../app/store";
import Api from "../../../api/Api";
import { removeNulls } from "../../../utils";
import AvatErrorDto from "../../../api/dtos/AvatError/AvatErrorDto";
import { fetchAllControlGroupsAndResources, setCurrentPage } from "../../appStateSlice";


export const resetAvatErrorsState = createAsyncThunk(
    "avatErrors/resetState",
    async (_, { dispatch }) => {
        dispatch(setAllErrors(undefined))
        dispatch(setCurrentError(undefined))
        dispatch(setExpandedItemId(undefined))
    }
)

export const fetchAllAvatErrors = createAsyncThunk(
    "avatErrors/fetch",
    async (force: boolean, { getState, dispatch }) => {
        let response: AvatErrorDto[] | undefined;
        const state = getState() as RootState;

        response = state.avatErrors.allErrors;

        if (response === undefined || force) {
            const allErrors = (await Api.fetchAvatErrors()).data
            response = orderBy(allErrors.map(data => removeNulls(data)), ["raisedAt"], ["desc"]);
            dispatch(setAllErrors(response))
        }

    }
)

export const buildAvatErrorsState = createAsyncThunk(
    "avatErrors/buildState",
    async (force: boolean, { dispatch }) => {
        await dispatch(setCurrentPage("AvatErrors"));
        await dispatch(fetchAllControlGroupsAndResources({ force: false, fetchGroups: false, fetchResources: false }))
        await dispatch(fetchAllAvatErrors(force));
    }
)
