import { jsonConcat } from "ndr-designsystem"
import technicalResourceDetailsEn from "./technicalResourceDetails.en.json"
import technicalResourceDetailsDe from "./technicalResourceDetails.de.json"
import toolbarButtonsEn from "./toolbarButtons.en.json"
import toolbarButtonsDe from "./toolbarButtons.de.json"
import controllableResourcesDetailsDe from "./controllableResourcesDetails.de.json"
import controllableResourcesDetailsEn from "./controllableResourcesDetails.en.json"
import controlGroupsDetailsDe from "./controlGroupsDetails.de.json"
import controlGroupsDetailsEn from "./controlGroupsDetails.en.json"
import marketLocationDe from "./marketLocation.de.json"
import marketLocationEn from "./marketLocation.en.json"
import connectPlusMessagesDe from "./connectPlusMessages.de.json"
import connectPlusMessagesEn from "./connectPlusMessages.en.json"
import listsDe from "./lists.de.json"
import listsEn from "./lists.en.json"
import timeSeriesDe from "./timeSeries.de.json"
import timeSeriesEn from "./timeSeries.en.json"
import unavailabilityDe from "./unavailability.de.json"
import unavailabilityEn from "./unavailability.en.json"
import generalDe from "./general.de.json"
import generalEn from "./general.en.json"
import dialogsDe from "./dialogs.de.json"
import dialogsEn from "./dialogs.en.json"
import auditDe from "./audit.de.json"
import auditEn from "./audit.en.json"
import marketPartnersEn from "./marketPartners.en.json"
import marketPartnersDe from "./marketPartners.de.json"
import tasksDe from "./tasks.de.json"
import tasksEn from "./tasks.en.json"
import avatErrorsEn from "./avatErrors.en.json"
import avatErrorsDe from "./avatErrors.de.json"

export default function getTranslation(language: string): any {
    let output: any = {};

    if (language === "de") {
        output = jsonConcat(output, technicalResourceDetailsDe)
        output = jsonConcat(output, toolbarButtonsDe)
        output = jsonConcat(output, controllableResourcesDetailsDe)
        output = jsonConcat(output, controlGroupsDetailsDe)
        output = jsonConcat(output, marketLocationDe)
        output = jsonConcat(output, connectPlusMessagesDe)
        output = jsonConcat(output, listsDe)
        output = jsonConcat(output, timeSeriesDe)
        output = jsonConcat(output, unavailabilityDe)
        output = jsonConcat(output, generalDe)
        output = jsonConcat(output, dialogsDe)
        output = jsonConcat(output, auditDe)
        output = jsonConcat(output, marketPartnersDe)
        output = jsonConcat(output, tasksDe)
        output = jsonConcat(output, avatErrorsDe)
    } else {
        output = jsonConcat(output, technicalResourceDetailsEn)
        output = jsonConcat(output, toolbarButtonsEn)
        output = jsonConcat(output, controllableResourcesDetailsEn)
        output = jsonConcat(output, controlGroupsDetailsEn)
        output = jsonConcat(output, marketLocationEn)
        output = jsonConcat(output, connectPlusMessagesEn)
        output = jsonConcat(output, listsEn)
        output = jsonConcat(output, timeSeriesEn)
        output = jsonConcat(output, unavailabilityEn)
        output = jsonConcat(output, generalEn)
        output = jsonConcat(output, dialogsEn)
        output = jsonConcat(output, auditEn)
        output = jsonConcat(output, marketPartnersEn)
        output = jsonConcat(output, tasksEn)
        output = jsonConcat(output, avatErrorsEn)
    }

    return output;
}
