/**
 *
 * @export
 * @enum {string}
 */


export enum TransitionToPlannedValueModelStatus {
    NotStarted = 0,
    Started, 
    Accepted,
    Rejected,
}

export default TransitionToPlannedValueModelStatus;
