import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        listItemWithoutPadding: {
            padding: 0
        },
        horizontalList: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        listItem: {
            paddingLeft: 5
        },
        fullSize: {
            width: 400
        },
        addNewRightButton: {
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
        descriptionText: {
            marginTop: 10,
            marginBottom: 20,
            fontWeight: 600
        },
        largerInput: {
            width: 400
        },
        fullWidthInput: {
            width: "100%"
        },
        titleContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10
        },
        addNewTitleButton: {
            flex: 1
        },
        dateTimePicker: {
            display: "flex",
            alignItems: "center",
            flex: 1
        },
        startDateTimePicker: {
            paddingRight: 15
        },
        reorderButton: {
            width: 80
        },
        deactivateButton: {
            // important red            
            backgroundColor: "rgb(243, 23, 0, .50) !important"
        },
        deactivateForm: {
            width: "100%",
            marginTop: 5
        },
    }),
);

export default useStyles;
