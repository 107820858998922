/**
 *
 * @export
 * @interface TrancheSizeUnit
 */
export interface TrancheSizeUnit {
    /**
     *
     * @type {string}
     * @memberof TrancheSizeUnit
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof TrancheSizeUnit
     */
    name?: string;
}

export const allowedCodes = ["P1", "Z01"];
