import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { toLocaleDate } from "ndr-designsystem";
import { DateTime } from "luxon";
import { useAppSelector } from "../../../app/hooks";
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";

interface Props {
    dataItem: {
        controllableResources: string[], controllableResourcesWithoutData: string[], fromDate: string, toDate: string, [prop: string]: any
    }
}

const UpdatePrognosisModelsHistoryTaskView = ({ dataItem }: Props): ReactElement => {
    const allResources = useAppSelector(state => state.controllableResources.allResources) || [];
    const { t, i18n } = useTranslation("tables");
    const resourcesMap: { [id: string]: ControllableResource } = {};
    allResources.filter(x => !!x.inventoryItemId).forEach(x => { resourcesMap[x.inventoryItemId!] = x });

    const result: ReactElement[] = [];

    function getExternalIds(internalIds: string[]): string {
        return internalIds.map(x => resourcesMap[x]?.externalID).join(', ');
    }

    function format(internalIds: string[], translationKey: string): void {
        if (internalIds?.length) {
            result.push(<p>{t(`tasks.update_prognosis_models_history.${translationKey}`)}{getExternalIds(internalIds)}</p>);
        }
    }

    const startDate = toLocaleDate(dataItem.fromDate, i18n.language, DateTime.DATE_SHORT)
    const endDate = toLocaleDate(dataItem.toDate, i18n.language, DateTime.DATE_SHORT)

    const controllableResourcesWithoutData = dataItem.controllableResourcesWithoutData || [];
    result.push(<p>{t('tasks.update_prognosis_models_history.timeframe', { startDate, endDate })}</p>);
    format(dataItem.controllableResources.filter(x => controllableResourcesWithoutData.indexOf(x) === -1), 'updated');
    format(controllableResourcesWithoutData, 'noData');

    return <b>{result}</b>;
}

export default UpdatePrognosisModelsHistoryTaskView
