import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { toLocaleDate } from "ndr-designsystem";
import { useAppSelector } from "../../../app/hooks";
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";

interface Props {
    dataItem: {
        controllableResourceExternalId: string;
        to: string
        [prop: string]: any;
    };
}

const UpdatePrognosisQualityTaskView = ({ dataItem }: Props): ReactElement => {
    const allResources = useAppSelector(state => state.controllableResources.allResources) || [];
    const { t, i18n } = useTranslation("tables");
    const resourcesMap: { [id: string]: ControllableResource } = {};
    allResources.filter(x => !!x.inventoryItemId).forEach(x => { resourcesMap[x.inventoryItemId!] = x });

    return <b>{t(`tasks.update_prognosis_quality_task`, {
        controllableResourceExternalId: dataItem.controllableResourceExternalId ?? "?",
        to: toLocaleDate(dataItem.to, i18n.language, DateTime.DATE_SHORT) ?? "?"
    })}</b>
};

export default UpdatePrognosisQualityTaskView;
