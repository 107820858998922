import { createDateTime } from "ndr-designsystem";
import Task from "api/fixed/Task/Task";
import SentMessage from "api/fixed/ConnectPlusMessage/SentMessage";
import TaskType from "../../fixed/Task/TaskType";
import TaskStatus from "../../fixed/Task/TaskStatus";
import { TaskError } from "../../fixed/Task/TaskError";

export interface TaskDto {
    type: TaskType,
    primaryTaskId: string
    secondaryTaskId: string
    startedAt: string,
    completedAt?: string,
    status: TaskStatus,
    sentMessage: SentMessage,
    errors: TaskError[]
}

export const fromDto = (dto: TaskDto): Task => ({
    ...dto,
    startedAt: createDateTime(dto.startedAt),
    completedAt: dto.completedAt ? createDateTime(dto.completedAt) : undefined
})
