import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IndividualQuotaDto } from "api/dtos/IndividualQuotaDto";

export interface IndividualQuotasState {
    allIndividualQuotas: IndividualQuotaDto[] | undefined,
}

const initialState: IndividualQuotasState = {
    allIndividualQuotas: undefined,
};

export const individualQuotasSlice = createSlice({
    name: 'IndividualQuotas',
    initialState,
    reducers: {
        setAllIndividualQuotas: (state, action: PayloadAction<IndividualQuotaDto[] | undefined>) => {
            state.allIndividualQuotas = action.payload;
        },
        addNewIndividualQuota: (state, action: PayloadAction<IndividualQuotaDto>) => {
            if (action.payload !== null) {
                if (state.allIndividualQuotas !== undefined) {
                    state.allIndividualQuotas.push(action.payload);
                } else {
                    state.allIndividualQuotas = [action.payload]
                }
            }
        },
        updateIndividualQuota: (state, action: PayloadAction<IndividualQuotaDto>) => {
            if (action.payload !== null) {
                if (state.allIndividualQuotas !== undefined) {
                    const idx = state.allIndividualQuotas.findIndex(r => r.inventoryItemId === action.payload.inventoryItemId);
                    state.allIndividualQuotas[idx] = action.payload;
                } else {
                    state.allIndividualQuotas = [action.payload]
                }
            }
        }
    },
});

export const {
    setAllIndividualQuotas,
    addNewIndividualQuota,
    updateIndividualQuota
} = individualQuotasSlice.actions;


export default individualQuotasSlice.reducer;