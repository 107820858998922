import React, { ReactElement, useCallback, useEffect } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { CustomEndAdornment, GridField, GridFieldElement, SimpleFieldGridCell } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { setAddNewIndividualQuota } from "features/appStateSlice";
import { buildMarketPartnerText } from "utils";
import { fetchIndividualQuotes } from "features/individualQuotes/store/thunks";
import { addNewIndividualQuota } from "features/individualQuotes/store/individualQuotesSlice";
import { ValidationResult } from "../../../../../utils/types";
import { RootState } from "../../../../../app/store";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import ActionsCell from "./ActionsCell";

const IndividualQuotasTable = (): ReactElement => {
    const { currentResource: resource } = useAppSelector((state: RootState) => state.controllableResources);
    const individualQuotas = useAppSelector((state: RootState) => state.individualQuotas.allIndividualQuotas);
    const { allPartners: marketPartners } = useAppSelector((state) => state.marketPartners);

    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");

    useEffect(() => {
        dispatch(fetchIndividualQuotes(resource?.inventoryItemId!));
    }, [dispatch, resource?.inventoryItemId]);   
    
    const getMarketPartnerTextFromId = useCallback(
        (id: string) => {
            const marketPartner = marketPartners?.find((p) => p.inventoryItemId === id);
            return buildMarketPartnerText(marketPartner!) || "";
        },
        [marketPartners],
    );

    const totalQuote = individualQuotas?.reduce((sum, current) => sum + +current.value!, 0);

    const isElementValid: ValidationResult = (totalQuote! === 100) || (totalQuote! === 0) ? {
        valid: true,
        error: null
    } : {
        valid: false,
        error: t("validation:quotaMustBe100")
    };

    const getGridFields = useCallback(() => {
        const grids: GridFieldElement[] = [
            {
                name: "value",
                title: t("controllable_resources_details.individualQuota.quota"),
                customCell: (props: GridCellProps): ReactElement => {
                    const { dataItem } = props;

                    return (
                        <SimpleFieldGridCell
                            defaultValue={dataItem.value}
                            {...props}
                            fullWidth
                            type="number"
                            endAdornment={<CustomEndAdornment content="%" />}
                            maxFractionDigits={3}
                        />
                    );
                },
            },
            {
                name: "accountingGroupBalancingSchedule",
                title: t("marketLocation.accountingGroup"),
            },
            {
                name: "supplier.name",
                title: t("marketLocation.supplier"),
                customCell: (props: GridCellProps): ReactElement => {
                    const { dataItem } = props;

                    return (
                        <SimpleFieldGridCell
                            {...props}
                            defaultValue={dataItem.supplierId && getMarketPartnerTextFromId(dataItem.supplierId)}
                        />
                    );
                },
            },
            {
                customCell: ActionsCell,
                exactWidth: 150,
            },
        ]

        return grids;
    }, [t]);

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            addButtonText={t("toolbar_buttons.add")}
            isEditing
            onAddNew={() => {
                dispatch(
                    setAddNewIndividualQuota({
                        show: true,
                        ...addNewIndividualQuota,
                        data: undefined,
                        id: "",
                    }),
                );
            }}
            defaultOpen
            values={individualQuotas}
            gridFields={getGridFields()}
            showError={totalQuote! < 100}
            validationResult={isElementValid}
            
        />
    );
};

export default IndividualQuotasTable;
