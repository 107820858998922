import React, { ReactElement, useEffect, useRef, useState } from "react";
import beautifier from "xml-formatter"
import { Box, TextField, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { ClosableDialog, CustomButton, LoadingComponent } from "ndr-designsystem";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Api from "../../api/Api";
import { setCurrentXMLFile } from "./ConnectPlusMessagesSlice";

interface RouteParams {
    direction: "sent" | "received",
    messageId: string
    type: "xml" | "ackxml"
}

const ConnectPlusDetailsDialog = (): ReactElement => {
    const { currentXMLFile } = useAppSelector(state => state.connectPlusMessages);
    const { direction, messageId, type } = useParams<RouteParams>();
    const history = useHistory();
    const dialogRef = useRef<HTMLDivElement>();
    const dispatch = useAppDispatch();
    const inputRef = useRef<HTMLInputElement>();
    const [xmlFileContent, setXmlFileContent] = useState<string | undefined>(undefined)
    const [xmlBlob, setXmlBlob] = useState<any>(undefined)

    useEffect(() => {
        if (currentXMLFile === undefined && direction !== undefined && messageId !== undefined && type !== undefined) {
            dispatch(setCurrentXMLFile({ messageType: direction, fileType: type, id: messageId }))
            history.replace("/")
        }
    }, [direction, messageId, type, currentXMLFile, dispatch, history])

    useEffect(() => {
        setTimeout(async () => {
            if (!dialogRef || !dialogRef.current) return;
            if (!currentXMLFile) return;

            const {
                blob,
                text
            } = await Api.fetchConnectPlusMessageXml(currentXMLFile.id, currentXMLFile.fileType === "ackxml", currentXMLFile.messageType);
            setXmlFileContent(beautifier(text));
            setXmlBlob(blob)
        }, 700)

    }, [currentXMLFile])

    return <ClosableDialog
        contentPadding={0} open={currentXMLFile !== undefined}
        maxWidth="xl"
        dialogContentRef={dialogRef}
        onClose={async () => {
            dispatch(setCurrentXMLFile(undefined))
            setTimeout(() => setXmlFileContent(undefined), 200)
        }}>

        <Box padding={2} justifyContent="center" display="flex">
            {(currentXMLFile && xmlFileContent !== undefined) &&
                <Box display="flex" flexDirection="row" width="100%" alignItems="center">
                    <TextField inputRef={inputRef} sx={{ flex: 1, paddingRight: 1 }} fullWidth
                               contentEditable={false}
                               variant="standard"
                               value={`${globalThis.location.protocol}//${globalThis.location.hostname}/messages/${currentXMLFile.messageType}/${currentXMLFile.id}/${currentXMLFile.fileType}/get`}/>
                    <CustomButton title="Copy direct link" onClick={() => {
                        if (inputRef.current) {
                            inputRef.current.select()
                            navigator.clipboard.writeText(inputRef.current.value)
                        }
                    }}/>
                    <CustomButton title="Download" onClick={() => {
                        if (!dialogRef.current) return;

                        const element = document.createElement('a');
                        element.setAttribute('href', URL.createObjectURL(xmlBlob))

                        element.setAttribute('download', `${currentXMLFile.id}.xml`);
                        element.style.display = 'none';
                        dialogRef.current.appendChild(element);
                        element.click();

                        dialogRef.current.removeChild(element);
                    }}/>

                </Box>
            }
        </Box>
        <Box overflow="auto" height="100%" width="100%">
            {xmlFileContent ?
                <Typography sx={{ paddingX: 2 }}>
                    <pre style={{ whiteSpace: "pre-wrap" }}>{xmlFileContent}</pre>
                </Typography> :
                <Box width={250} height={250}><LoadingComponent/></Box>
            }
        </Box>
    </ClosableDialog>

}

export default ConnectPlusDetailsDialog
