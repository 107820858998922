import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    match: { params: { page: string } }
}

const ControlGroupPageBreadcrumb = ({ match }: Props): ReactElement => {
    const { t } = useTranslation("common")

    return <>{t(`breadcrumbs.control_group_details.${match.params.page}`)}</>
}

export default ControlGroupPageBreadcrumb