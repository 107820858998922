import { MarketLocationDto } from "./MarketLocationDto";
import { toDto as toTrancheDto, TrancheDto } from "./TrancheDto";
import { MarketLocation } from "../../fixed/MarketLocation/MarketLocation";


export interface CreateMarketLocationDto extends MarketLocationDto {
    tranches?: TrancheDto[]
}

export const toDto = (original: MarketLocation): CreateMarketLocationDto => {
    const backup = { ...original }

    delete backup.voltageLevel
    delete backup.voltageTransformation
    delete backup.tranches
    delete backup.inventoryItemId

    return {
        ...backup,
        externalID: original.externalID?.toString(),
        tranches: original.tranches?.map(x => toTrancheDto(x)),
        voltageLevel: original.voltageLevel?.code,
        voltageTransformation: original.voltageTransformation?.code,
    }
}
