import React, { ReactElement, useCallback } from "react";
import { Chart, Line } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import zoomPlugin from "chartjs-plugin-zoom";
import { ChartData, ChartType, DefaultDataPoint } from "chart.js";

function CustomChart<TData = DefaultDataPoint<ChartType>>({
                         plugins,
                         scales,
                         locale,
                         data,
                         style,
                         maintainAspectRatio,
                         zoom
                     }: { plugins: any, scales: any, locale: string, data: ChartData<ChartType, TData>, style?: any, maintainAspectRatio: boolean, zoom?: boolean }): ReactElement {
    if (zoom) Chart.register(zoomPlugin);
    const getChartOptions = useCallback(() => ({
        responsive: true,
        maintainAspectRatio,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        locale,
        scales,
        plugins: {
            zoom: {
                limits: {
                    x: { min: "original", max: "original" },
                    y: { min: "original", max: "original" }
                },
                zoom: {
                    wheel: {
                        enabled: true
                    }
                },
                pan: {
                    enabled: true
                },
                mode: "xy"
            },
            ...plugins
        }
    }), [plugins, locale, maintainAspectRatio, scales]);

    return (
        <Line
            // @ts-ignore
            data={data}
            // @ts-ignore
            options={getChartOptions()}
            style={style}
        />
    );
}

CustomChart.defaultProps = {
    style: undefined,
    zoom: true
}

export default CustomChart
