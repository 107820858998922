import React, { ReactElement } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ControllableResource } from "../../../../api/fixed/ControllableResource/ControllableResource";

const EivIndicator = ({
                          controllableResource: dataItem,
                          alwaysShowText
                      }: { controllableResource: ControllableResource, alwaysShowText: boolean }): ReactElement => {
    const { t } = useTranslation();
    let icon: ReactElement | undefined
    if (dataItem.receivedFromEiv)
        icon = <Box display="flex" width="100%" alignItems="center" justifyContent="center">
            <Tooltip title={!alwaysShowText ? t("validation:received_from_eiv") ?? "" : ""}><FiberManualRecord
                color="success"/></Tooltip>
            <Typography>
                {alwaysShowText && t("validation:received_from_eiv")}
            </Typography>
        </Box>

    if (alwaysShowText && icon)
        return icon;

    return <td>{icon}</td>
}

export default EivIndicator
