import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "api/Api";
import { IndividualQuotaDto } from "api/dtos/IndividualQuotaDto";
import { AxiosError } from "axios";
import { addNewIndividualQuota, setAllIndividualQuotas, updateIndividualQuota } from "./individualQuotesSlice";

export const fetchIndividualQuotes = createAsyncThunk(
    "individualQuotas/fetchIndividualQuotes",
    async (id: string, { dispatch }) => {
        const quotas = await Api.fetchIndividualQuotas(id);
        await dispatch(setAllIndividualQuotas(quotas));
    },
);

export const createIndividualQuota = createAsyncThunk(
    "individualQuotas/create",
    async ({ id, data }: { id: string; data: IndividualQuotaDto }, { dispatch, rejectWithValue }) => {
        try {
            const response = await Api.addIndividualQuota(id, data);
            if (response.status === 200) {
                dispatch(
                    addNewIndividualQuota({
                        ...data,
                        inventoryItemId: response.data,
                    }),
                );
            }
        } catch (e) {
            const error = e as AxiosError;
            return rejectWithValue({
                status: error.response?.status,
                detail: "",
            });
        }
        return true;
    },
);

export const editIndividualQuota = createAsyncThunk(
    "individualQuotas/edit",
    async ({ id, data }: { id: string; data: IndividualQuotaDto }, { dispatch, rejectWithValue }) => {
        try {
            const response = await Api.updateIndividualQuota(id, data);
            if (response.status === 200) {
                dispatch(
                    updateIndividualQuota({
                        ...data,
                    }),
                );
            }
        } catch (e) {
            const error = e as AxiosError;
            return rejectWithValue({
                status: error.response?.status,
                detail: "",
            });
        }
        return true;
    },
);

export const deleteIndividualQuota = createAsyncThunk(
    "individualQuotas/delete",
    async ({ id, individualQuotaId }: { id: string; individualQuotaId: string }, { dispatch }) => {
        const deleted = await Api.deleteIndividualQuota(id, individualQuotaId);
        if (deleted) {
            dispatch(deleteIndividualQuota({ id, individualQuotaId }));
        }
    },
);