import React, { ReactElement } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";

interface GridOperatorsLabelCellProps extends GridCellProps {
    customText?: string;
}

const GridOperatorsLabelCell = (props: GridOperatorsLabelCellProps): ReactElement => {
    const { customText } = props;
    return (
        <td>
            <b>{customText && customText}</b>
        </td>
    );
};

GridOperatorsLabelCell.defaultProps = {
    customText: undefined
};

export default GridOperatorsLabelCell;