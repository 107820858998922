import React, { ReactElement, useState } from "react";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { GridField, GridFieldActionsCell, isSchemaValid, PageStatus, ValidationResult } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { RootState } from "../../app/store";
import { fullResourcesDataSelector, setControllableResources } from "./store/controlGroupsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ControllableResource } from "../../api/fixed/ControllableResource/ControllableResource";
import { CGCRTableSchema } from "../../utils/inputChecking";
import AddControllableResourceDialog from "./AddControllableResourceDialog";

const ControllableResourcesTable = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const { currentGroup } = useSelector((state: RootState) => state.controlGroups);
    const currentControllableResources = useAppSelector(fullResourcesDataSelector, (left, right) => isEqual(left, right));
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [addNewCR, setAddNewCR] = useState(false);
    const { t } = useTranslation("tables")

    if (currentGroup === undefined || currentGroup === null) {
        return <></>;
    }

    const onDialogClose = (): void => {
        setAddNewCR(false);
    }

    const removeControllableResource = async (index: number): Promise<void> => {
        if (!currentGroup.controllableResources) return;
        if (currentControllableResources) {
            const indexInArray = currentGroup.controllableResources?.findIndex(x => x === currentControllableResources[index].inventoryItemId)
            const data = [...currentGroup.controllableResources];
            data.splice(indexInArray, 1)
            dispatch(setControllableResources(data))
        }
    }

    const handleAddNew = (): void => {
        setAddNewCR(true);
    };

    const ControllableResourcesTableCommands = (props: GridCellProps): ReactElement => {
        const { dataItem }: { dataItem: ControllableResource } = props;
        let validationResult: ValidationResult = {
            valid: true,
            error: null
        }

        if (!dataItem.hasSuccessfulDispatch) {
            validationResult = {
                valid: false,
                error: "validation:control_groups_details.included_controllable_resources_successful_dispatch"
            }
        }

        if (!dataItem.isTolerationCase) {
            validationResult = {
                valid: false,
                error: "validation:control_groups_details.included_controllable_resources_toleration_case"
            }
        }

        return <GridFieldActionsCell {...props}
                                     remove={isEditing ? removeControllableResource : undefined}
                                     inEdit={false}
                                     validationResult={validationResult}/>;
    }


    return (
        <>
            <GridField
                isEditing={isEditing}
                noRecordsText={t("no_records_text")}
                addButtonText={t('toolbar_buttons.add')}
                onAddNew={isEditing ? handleAddNew : undefined}
                defaultOpen
                showError
                dataItemKey="inventoryItemId"
                validationResult={isSchemaValid(CGCRTableSchema(), currentControllableResources ?? [])}
                values={currentControllableResources}
                commandsColumn={<GridColumn cell={ControllableResourcesTableCommands}/>}
                onRowClick={event => history.push(`/controllable/${event.dataItem.externalID}`, { force: true })}
                gridFields={[{
                    name: "externalID",
                    title: t("controllable_resources_details.externalID")
                }, {
                    name: "name",
                    title: t("controllable_resources_details.name")
                }]}
            />
            {addNewCR &&
                <AddControllableResourceDialog
                    onDialogClose={onDialogClose} groupId={currentGroup.inventoryItemId!}
                    onSave={(newResources) => {
                        dispatch(setControllableResources([...(currentGroup.controllableResources ?? []), ...newResources]))
                    }}
                />
            }
        </>
    )
}

export default ControllableResourcesTable
