import UnavailabilityDetails from "api/fixed/Unavailability/UnavailabilityDetails";
import { createDateTime } from "ndr-designsystem";
import { UnavailabilityType } from "../../fixed/Unavailability/UnavailabilityType";
import { ProcessType } from "../../fixed/Unavailability/ProcessType";
import { ReasonCode } from "../../fixed/Unavailability/ReasonCode";
import { UnavailabilityTimeSeriesValueDto } from "./UnavailabilityTimeSeriesValueDto";

export default interface UnavailabilityDetailsDto {
    /**
     *
     * @type {string}
     * @memberof UnavailabilityDetailsDto
     */
    internalID: string;
    /**
     *
     * @type {DateTime}
     * @memberof UnavailabilityDetailsDto
     */
    start?: string;
    /**
     *
     * @type {DateTime}
     * @memberof UnavailabilityDetailsDto
     */
    end?: string;
    /**
     *
     * @type {UnavailabilityType}
     * @memberof UnavailabilityDetailsDto
     */
    type?: UnavailabilityType;
    /**
     *
     * @type {ProcessType}
     * @memberof UnavailabilityDetailsDto
     */
    processType?: ProcessType;
    /**
     *
     * @type {ReasonCode}
     * @memberof UnavailabilityDetailsDto
     */
    reasonCode: ReasonCode;
    /**
     *
     * @type {string}
     * @memberof UnavailabilityDetailsDto
     */
    productionRegisteredResourceId: string;
    /**
     *
     * @type {string}
     * @memberof UnavailabilityDetailsDto
     */
    assetRegisteredResourceId: string;

    values: Array<UnavailabilityTimeSeriesValueDto>
}

export const fromDto = (dto: UnavailabilityDetailsDto): UnavailabilityDetails => ({
    ...dto,
    start: dto.start ? createDateTime(dto.start) : undefined,
    end: dto.end ? createDateTime(dto.end) : undefined,
    values: dto.values.map(val => ({ ...val, timestamp: createDateTime(val.timestamp) }))
});