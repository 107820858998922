/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
/* eslint-disable prefer-destructuring */
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Card, LinearProgress } from "@mui/material";
import { Cell, Workbook, Worksheet } from "exceljs";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { CardBody, CardTitle } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { setCurrentPage } from "../appStateSlice"
import {
    addResults,
    clearResults,
    excelImportResults,
    IExcelImportResult,
    IValidationError
} from "./store/excelImportSlice";
import Api from "../../api/Api";
import ExcelImportResult from "./ExcelImportResult";

function autosizeColumnCells({ columns }: Worksheet): void {
    let dataMax: number[];
    let max: number;
    for (const column of columns) {
        dataMax = [];
        if (column.eachCell) {
            column.eachCell({ includeEmpty: false }, (cell: Cell) => {
                dataMax.push(cell.value?.toString().length || 0);
            });
            max = Math.max(...dataMax);
            column.width = max < 10 ? 10 : max;
        }
    }
}

function convertResponse(response: AxiosResponse<{ [filename: string]: IExcelImportResult }>): IExcelImportResult[] {

    const data = response.data;
    const results: IExcelImportResult[] = [];
    for (const key of Object.keys(data)) {
        const result = data[key];
        result.fileName = key;
        results.push(result);
    }
    return results;
}

const ImportExcelFiles = (): ReactElement => {
    const results = useAppSelector(excelImportResults);
    const dispatch = useAppDispatch();
    const [progress, setProgress] = useState(0);
    const [isUploadStarted, setIsUploadStarted] = useState(false);
    const [isUploadDone, setIsUploadDone] = useState(false);
    const [uploadError, setUploadError] = useState<any>(null);
    const { t } = useTranslation("excelImport");
    useEffect(() => {
        dispatch(setCurrentPage("ImportExcel"))
    }, [dispatch])


    const onProcessingFinished = useCallback((response: AxiosResponse) => {
        if (response.status === 200) {
            dispatch(addResults(convertResponse(response)));
        } else
            setUploadError(response.statusText)

    }, [dispatch])

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setIsUploadStarted(true);
        setIsUploadDone(false);
        setUploadError(null);
        setProgress(0)
        dispatch(clearResults());
        Api.UploadFiles("ExcelFiles", acceptedFiles, p => {
            setProgress(p);
            if (p >= 100) {
                setIsUploadDone(true);
            }
        }).then(onProcessingFinished).catch(setUploadError)
    }, [onProcessingFinished, setUploadError, setIsUploadDone, setIsUploadStarted, dispatch]);

    const formatMessage = useCallback((error: IValidationError) => {
        const messageTemplate = t(`validationErrors.messageTemplates.${error.code}`);
        return messageTemplate.replace('{PropertyName}', error.excelValueName).replaceAll(/\{([^}]+)\}/g, x => error.values[x.substr(1, x.length - 2)]);
    }, [t]);

    const onDownloadResults = useCallback(async () => {
        const workbook = new Workbook();

        const relevantResults = results.filter(x => x.validationErrors && x.validationErrors.length > 0);
        for (const result of relevantResults) {
            const sheet = workbook.addWorksheet(result.fileName.replace('Template_', '')
                .replace('Stammdaten_Stadtwerke ', '').replace('Kundendaten Redispatch 2.0 ', '').replace('Kundendaten Redispatch 2.0_', ''));
            sheet.columns = [
                { header: t('validationErrors.columns.excelName'), key: 'excelName' },
                { header: t('validationErrors.columns.message'), key: 'message' },
                { header: t('validationErrors.columns.attemptedValue'), key: 'attemptedValue' },
            ]

            for (const error of result.validationErrors.filter(x => x.excelValueName && x.code !== "UnknownField")) {
                sheet.addRow([error.excelValueName, formatMessage(error), JSON.stringify(error.attemptedValue)]);
            }

            autosizeColumnCells(sheet);
        }

        const buf = await workbook.xlsx.writeBuffer();

        const blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `ExcelImportResults.xlsx`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }, [results]);

    let status = "";
    if (isUploadStarted) {
        if (!isUploadDone) {
            status = "Uploading..."
        } else if (!results || !results.length) {
            status = "Uploaded. Processing..."
        } else {
            status = "Processing finished."
        }
    }
    if (uploadError) {
        status = `Upload failed: ${JSON.stringify(uploadError)}`;
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop })
    return (
        <>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drop the files here ...</p>
                <p>Drag n drop some files here, or click to select files</p>
            </div>
            <p><b>{status}</b></p>
            {isUploadStarted && !isUploadDone && !uploadError &&
            <LinearProgress variant="determinate" value={progress}/>}
            {isUploadDone && !uploadError && (!results || !results.length) && <LinearProgress variant="indeterminate"/>}
            {results && results.length > 0 && <Button onClick={onDownloadResults}>Download results</Button>}
            {!!results && results.map(x => (
                <Card style={{ marginBottom: "10px" }}>
                    <CardBody>
                        <CardTitle>{x.fileName}</CardTitle>
                        <ExcelImportResult result={x}/>
                    </CardBody>
                </Card>
            ))}
        </>
    )
}


export default ImportExcelFiles
