import React, { ReactElement } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import useStyles from "./styles";
import { MarketPartner } from "../api/fixed/MarketPartner";

type ContextProps = {
    reorder: (dataItem: MarketPartner) => void;
    dragStart: (dataItem: MarketPartner) => void;
};

export const DragDropContext = React.createContext<ContextProps>({
    reorder: () => {
    },
    dragStart: () => {
    }
});

const DragCell = (props: GridCellProps & { reorderFirstElement?: boolean, visible?: boolean }): ReactElement => {
    const currentContext = React.useContext(DragDropContext);
    const styles = useStyles();
    const { dataIndex, reorderFirstElement, visible } = props;

    if (dataIndex === 0 && !reorderFirstElement || !visible) {
        return <td/>
    }
    return (
        <td
            className={styles.reorderButton}
            onDragOver={(e) => {
                currentContext.reorder(props.dataItem);

                e.preventDefault();
                // eslint-disable-next-line no-param-reassign
                e.dataTransfer.dropEffect = "copy";
            }}
        >
          <span
              className="k-icon k-i-reorder"
              draggable
              style={{ cursor: "move" }}
              onDragStart={(e) => {
                  currentContext.dragStart(props.dataItem);
                  e.dataTransfer.setData("dragging", "");
              }}
          />
        </td>
    );
};

DragCell.defaultProps = {
    reorderFirstElement: true,
    visible: true
}

export default DragCell;