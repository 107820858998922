/**
 *
 * @export
 * @interface ControlArea
 */
export interface ControlArea {
    /**
     *
     * @type {string}
     * @memberof ControlArea
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof ControlArea
     */
    name?: string | null;
}

export const allowedCodes: string[] = [
    "10YDE-ENBW-----N",
    "10YDE-EON------1",
    "10YDE-RWENET---I",
    "10YDE-VE-------2",
    "10YFLENSBURG---3"
];
