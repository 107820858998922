import { createDateTime } from "ndr-designsystem";
import DataPoint from "../../fixed/Downtime/DataPoint";

export default interface DataPointDto {
    flag: string,
    timestamp: string,
    value: number
}

export const fromDto = (dto: DataPointDto): DataPoint => ({
    ...dto,
    timestamp: createDateTime(dto.timestamp)
})