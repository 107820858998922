import { Box } from "@mui/material";
import DetailsViewToolbar from "Components/DetailsViewToolbar";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Status from "features/ControlGroups/Status";
import { Field, LoadingComponent, PageStatus, isFieldValid, isSchemaValid } from "ndr-designsystem";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { AccountingGroupBalancingSchedulesSchema } from "utils/inputChecking";
import { getMarketPartnersId } from "utils";
import SchemaConstants from "utils/schemaConsts";
import {
    createAccountingGroupBalancingSchedule,
    editAccountingGroupBalancingSchedule,
    fetchAccountingGroupBalancingScheduleById,
    fetchAccountingGroupBalancingSchedules,
} from "../store/thunks";
import RequestingGridOperatorsTable from "./RequestingGridOperatorsTable";
import {
    setControllableResource,
    setCurrentAccountingGroupBalancingSchedule,
} from "../store/accountingGroupBalancingSchedulesSlice";
import useStyles from "../styles";

interface RouteParams {
    id: string;
    page: string;
}

const AccountingGroupBalancingScheduleDetailsView: React.FC<{}> = () => {
    const { id } = useParams<RouteParams>();
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.VIEW);
    const { currentAccountingGroup, allAccountingGroups } = useAppSelector(
        (state) => state.accountingGroupBalancingSchedules,
        );
    const { allResources } = useAppSelector((state) => state.controllableResources);    
    const { allPartners: marketPartners } = useAppSelector((state) => state.marketPartners);
    const currentAccountingGroupIndex = allAccountingGroups?.findIndex((r) => r.inventoryItemId === currentAccountingGroup?.inventoryItemId) ?? -1;

    const { t } = useTranslation("tables");
    const dispatch = useAppDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;

    useEffect(() => {
        if (id === "add") {
            dispatch(
                setCurrentAccountingGroupBalancingSchedule({
                    controllableResource: {},
                    inventoryItemId: undefined,
                    requestingGridOperators: [],
                }),
            );
            setPageStatus(PageStatus.ADD);
        } else {
            dispatch(fetchAccountingGroupBalancingScheduleById(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(fetchAccountingGroupBalancingSchedules(false));
    }, [dispatch]);

    const prepareResourcesList = useCallback(() => {
        const filteredResources = allResources?.filter((resource) => {
            const isAccountingModelZ01 = resource.accountingModel?.code === 'Z01';
            const hasNoAccountingGroup = allAccountingGroups && !allAccountingGroups.some(
                (accountingGroup) => accountingGroup.controllableResource?.externalId === resource.externalID,
            );
            const isSelected = resource.externalID === currentAccountingGroup?.controllableResource?.externalId;
            return isAccountingModelZ01 && (hasNoAccountingGroup || isSelected);
        }) || [];
        return (
            filteredResources.map((value) => ({
                id: `${value.externalID}`,
                name: `${value.externalID} - ${value.name}`,
            })) ?? []
        );
    }, [allResources, allAccountingGroups, currentAccountingGroup?.inventoryItemId]);



    const forceRefresh = async (): Promise<void> => {
        try {
            setIsSaving(true);
            await dispatch(fetchAccountingGroupBalancingScheduleById(id));
        } finally {
            setIsSaving(false);
        }
    };

    const toggleEditing = async (): Promise<void> => {
        if (pageStatus) {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(t("changesWillBeReverted"));
            if (confirm) {
                setPageStatus(PageStatus.VIEW);
                await dispatch(fetchAccountingGroupBalancingScheduleById(id));
            }
            return;
        }
        setPageStatus(PageStatus.EDIT);
    };

    const saveChanges = async (adding: boolean): Promise<void> => {
        if (currentAccountingGroup) {
            setIsSaving(true);
            try {
                if (adding) {
                    try {
                        const newAccountingGroupId = await dispatch(createAccountingGroupBalancingSchedule(currentAccountingGroup)).unwrap()
                        history.replace(`/accountingGroups/${newAccountingGroupId}`);
                      } catch (rejectedValueOrSerializedError) {
                        // handle error here
                      }                  
              
                } else {
                    await dispatch(editAccountingGroupBalancingSchedule(currentAccountingGroup)).unwrap();
                    history.replace(`/accountingGroups/${currentAccountingGroup.inventoryItemId}`);
                }
                setPageStatus(PageStatus.VIEW);                
            } catch (e) {
                dispatch(fetchAccountingGroupBalancingSchedules(true));
            }

            setIsSaving(false);
        }
    };

    const handlePreviousClick = (): void => {
        if (currentAccountingGroupIndex > 0 && allAccountingGroups)
            history.push(`/accountingGroups/${allAccountingGroups[currentAccountingGroupIndex - 1].inventoryItemId}`);
    };

    const handleNextClick = (): void => {
        if (allAccountingGroups && currentAccountingGroupIndex < allAccountingGroups.length - 1)
            history.push(`/accountingGroups/${allAccountingGroups[currentAccountingGroupIndex + 1].inventoryItemId}`);
    };

    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);
    const Schema = AccountingGroupBalancingSchedulesSchema(prepareMP() ?? []);

    if (currentAccountingGroup === undefined || isSaving) {
        return <LoadingComponent />;
    }

    return (
        <Box className={classes.boxWithoutMarker}>
            <Status pageStatus={pageStatus} />
            <DetailsViewToolbar
                disabled={!isSchemaValid(Schema, currentAccountingGroup!).valid}
                disableEditWhenDeactivated={false}
                disableEnhanceWhenDeactivated={false}
                isDeactivatedInFuture={false}
                onAddClick={() => saveChanges(true)}
                onCancelClick={() => toggleEditing()}
                onSaveClick={() => saveChanges(false)}
                onEditClick={() => toggleEditing()}
                onRefresh={() => forceRefresh()}
                pageStatus={pageStatus}
                isForGroup={false}
                onPreviousClick={currentAccountingGroupIndex > 0 ? handlePreviousClick : undefined}
                onNextClick={
                    currentAccountingGroupIndex < (allAccountingGroups?.length ?? 0) - 1 && currentAccountingGroupIndex !== -1
                        ? handleNextClick
                        : undefined
                }
            />
            <Field
                inputType="select"
                onValueChange={(val) => {
                    const resource = allResources?.find((f) => f.externalID === val?.id);
                    const tempControllableResource = {
                        internalId: resource?.inventoryItemId,
                        externalId: resource?.externalID,
                        name: resource?.name,
                    };
                    dispatch(setControllableResource(tempControllableResource));
                }}
                isEditing={isEditing || isAdding}
                valueCheckFunction={(val) =>
                    isFieldValid(Schema, [SchemaConstants.controllableResource], {
                        controllableResource: { externalId: val?.id },
                    })
                }
                values={prepareResourcesList()}
                defaultValue={prepareResourcesList().find(
                    (f) => f.id === currentAccountingGroup?.controllableResource?.externalId,
                )}
                keyField="id"
                textField="name"
                title={t("controllable_resources_details.accountingGroupBalancingSchedules.controllableResource")}

            />
            <RequestingGridOperatorsTable
                pageStatus={pageStatus}
                elements={currentAccountingGroup?.requestingGridOperators || []}
                validationResult={isFieldValid(Schema, [SchemaConstants.requestingGridOperators], {
                    requestingGridOperators: currentAccountingGroup?.requestingGridOperators,
                })}
            />
        </Box>
    );
};

export default AccountingGroupBalancingScheduleDetailsView;
