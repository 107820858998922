import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { DateTime } from "luxon";
import jwtDecode from "jwt-decode";
import { ControllableResource } from "./fixed/ControllableResource/ControllableResource";
import { MarketPartner } from "./fixed/MarketPartner";
import Endpoints from "./Endpoints";
import { TechnicalResource } from "./fixed/TechnicalResource/TechnicalResource";
import { toDto as toTechnicalResourceDto } from "./dtos/TechnicalResource/TechnicalResourceDto";
import { toDto as toCreateTechnicalResourceDto } from "./dtos/TechnicalResource/CreateTechnicalResourceDto";
import { toDto as toControllableResourceDto } from "./dtos/ControllableResourceDto";
import { toDto as toMarketPartnerDto } from "./dtos/MarketPartnerDto";
import { IndividualQuotaDto } from "./dtos/IndividualQuotaDto";
import { MarketLocation } from "./fixed/MarketLocation/MarketLocation";
import { toDto as toMarketLocationDto } from "./dtos/TechnicalResource/MarketLocationDto";
import { Tranche } from "./fixed/Tranche/Tranche";
import { toDto as toTrancheDto } from "./dtos/TechnicalResource/TrancheDto";
import { toDto as toAccountingGroupDto } from "./dtos/AccountingGroupBalancingScheduleDto";
import { ControlGroup } from "./fixed/ControlGroup/ControlGroup";
import Config from "../app-config";
import { toDto as toControlGroupDto } from "./dtos/ControlGroupDto";
import { PlanningDataDetailsDto } from "./dtos/PlanningData/PlanningDataDetailsDto";
import { PlanningDataDto } from "./dtos/PlanningData/PlanningDataDto";
import AccessResult from "./fixed/LoginResult";
import SensitivityDto, { SensitivityCreateDto } from "./dtos/Sensitivity/SensitivityDto";
import { FlexibilityConstraintDto } from "./dtos/FlexibilityConstraint/FlexibilityConstraintDto";
import { FlexibilityConstraintDetailsDto } from "./dtos/FlexibilityConstraint/FlexibilityConstraintDetailsDto";
import ActivationDto from "./dtos/Activation/ActivationDto";
import DowntimeDto from "./dtos/Downtime/DowntimeDto";
import DowntimeDetailsDto from "./dtos/Downtime/DowntimeDetailsDto";
import PeriodIntervalDto from "./dtos/Activation/PeriodIntervalDto";
import ProductionDataDto from "./dtos/ProductionData/ProductionDataDto";
import { removeNulls } from "../utils";
import { TaskDto } from "./dtos/Task/TaskDto";
import CostInfoDto from "./dtos/CostInfo/CostInfo";
import ControllableResourceStatus from "./fixed/ControllableResource/ControllableResourceStatus";
import UnavailabilityDto from "./dtos/Unavailability/UnavailabilityDto";
import AvatErrorDto from "./dtos/AvatError/AvatErrorDto";
import GeneratedCostInfoDto from "./dtos/GeneratedCostInfo/GeneratedCostInfoDto";
import GeneratedCostInfoTimeSeriesDto from "./dtos/GeneratedCostInfo/GeneratedCostInfoTimeSeriesDto";
import GeneratedSensitivityDto from "./dtos/GeneratedSensitivity/GeneratedSensitivityDto";
import ControlGroupStatus from "./fixed/ControlGroup/ControlGroupStatus";
import { AccountingGroupBalancingSchedule } from "./fixed/AccountingGroupBalancingSchedule/AccountingGroupBalancingSchedule";

export default class Api {
    private static readonly DOMAIN: string = process.env.NODE_ENV === "development" ? "localhost" : (new URL(Config.auth.authority)).hostname;

    private static tenantSubdomain: string = this.getTenantSubdomain();

    private static token: string | null = null;

    static async addControlGroup(group: ControlGroup): Promise<{ data: string, status: number }> {
        const dto = toControlGroupDto(group);
        const response = await this.post(`${Endpoints.controlGroups}`, dto);
        return response;
    }

    static async deactivateControlGroup(id: string, existenceEnd: DateTime) : Promise<{ data: string, status: number }> {
        const response = await this.post(`${Endpoints.controlGroups}/${id}/deactivate`, JSON.stringify(existenceEnd.toUTC().toISO().toString()));
        return response;
    }

    public static setToken(token: string | null): void {
        if (token === null)
            this.token = null;
        else
            this.token = `Bearer ${token}`;
    }

    public static deleteAuthorization(): void {
        axios.defaults.headers.common.Authorization = "";
    }

    static async fetchControllableResources(): Promise<ControllableResource[]> {
        const response = await this.get(`${Endpoints.controllableResources}`, true);
        return response.data;
    }

    static async fetchControllableResourceStatus(id: string): Promise<ControllableResourceStatus> {
        const response = await this.get(`${Endpoints.controllableResources}/${id}/status`, true);
        return response.data.status;
    }

    static async fetchControllableResourceById(id: string): Promise<{ data: any, status: number }> {
        return this.get(`${Endpoints.controllableResources}/${id}`, true);
    }

    static async addControllableResource(resource: ControllableResource): Promise<{ data: string, status: number }> {
        const dto = toControllableResourceDto(resource);
        const response = await this.post(`${Endpoints.controllableResources}`, dto)
        return response;
    }

    static async deactivateControllableResource(id: string, existenceEnd: DateTime) : Promise<{ data: string, status: number }> {
        const response = await this.post(`${Endpoints.controllableResources}/${id}/deactivate`, JSON.stringify(existenceEnd.toUTC().toISO().toString()));
        return response;
    }

    static async deleteControllableResource(id: string): Promise<boolean> {
        const response = await this.delete(`${Endpoints.controllableResources}/${id}`);
        return response.status === 200;
    }

    static async updateControllableResource(resource: ControllableResource): Promise<{ status: number, data: any }> {
        const dto = toControllableResourceDto(resource);
        const response = await this.put(`${Endpoints.controllableResources}/${resource.inventoryItemId}`, dto);
        return response;
    }

    static async startTransitionToPlannedValueModel(resource: ControllableResource): Promise<{ status: number, data: any }> {
        const response = await this.post(`${Endpoints.controllableResources}/${resource.inventoryItemId}/start-transition-to-planned-value-model`, "");
        return response;
    }

    static async fetchPlanningDataByControllableResourceId(id: string, lastId: string | undefined, size: number): Promise<{ status: number, data: PlanningDataDto[] }> {
        const config = this.buildConfig(true);
        let url = `${Config.planningDataService.endpoint}controllable-resources/${id}/planning-data?`;
        if (lastId) {
            url += `lastId=${lastId}&`
        }
        url += `size=${size}`
        const response = await axios.get(url, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchPreviousPlanningData(CRID: string, id: string, size: number): Promise<{ status: number, data: PlanningDataDto[]}> {
        const config = this.buildConfig(true);
        const url = `${Config.planningDataService.endpoint}controllable-resources/${CRID}/planning-data/${id}/previous?size=${size}`;
        const response = await axios.get(url, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchPreviousGeneratedSensitivity(id: string, type: "resource" | "group", dispatchId: string, size: number): Promise<{ status: number, data: GeneratedSensitivityDto[]}> {
        const config = this.buildConfig(true);
        let url: string;

        if (type === "resource")
            url = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities/dispatches/${dispatchId}/previous?size=${size}`
        else
            url = `${Config.planningDataService.endpoint}control-groups/${id}/sensitivities/dispatches/${dispatchId}/previous?size=${size}`
        const response = await axios.get(url, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchSentMessages(dispatchIds: string[], index: number, length: number, states: number[] | undefined, messageTypes: string[] | undefined): Promise<{ status: number, data: { content: any[], totalElements: number } }> {
        const config = this.buildConfig(true);
        if (dispatchIds.length === 0) {
            return { status: 200, data: { content: [], totalElements: 0 } }
        }
        return axios.post(`${Config.connectPlusMessagesService.endpoint}SentMessages/Get`, {
            dispatchIds, states,
            messageTypes,
            index,
            length
        }, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))
    }

    static async fetchSentMessagesByType(id: string, type: "resource" | "group", index: number, length: number, states: number[] | undefined, messageTypes: string[] | undefined): Promise<{ status: number, data: { content: any[], totalElements: number } }> {
        const config = this.buildConfig(true);

        return axios.post(`${Config.connectPlusMessagesService.endpoint}SentMessages/Get`, {
            states,
            messageTypes,
            controllableResourceId: type === "resource" ? id : undefined,
            controlGroupId: type === "group" ? id: undefined,
            index,
            length
        }, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))
    }

    static async fetchAllSentMessages(index: number, length: number, states: number[] | undefined, messageTypes: string[] | undefined): Promise<{ status: number, data: { content: any[], totalElements: number } }> {
        const config = this.buildConfig(true);

        return axios.post(`${Config.connectPlusMessagesService.endpoint}SentMessages/Get`, {
            tenantName: this.tenantSubdomain,
            states,
            messageTypes,
            index,
            length

        }, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))
    }

    static async fetchAttachmentEmail(activationId: string, attachmentId: string): Promise<any> {
        const response = await this.getBlob(`${Config.activationsService.endpoint}activations/${activationId}/emails/${attachmentId}/attachment`);
        return response;
    }

    static async fetchReceivedMessages(id: string, type: "group" | "resource", index: number, length: number, states: number[] | undefined, messageTypes: string[] | undefined): Promise<{ status: number, data: { content: any[], totalElements: number } }> {
        const config = this.buildConfig(true);
        let data: any;
        if (type === "resource") {
            data = { controllableResourceId: id }
        } else {
            data = { controlGroupId: id }
        }
        return axios.post(`${Config.connectPlusMessagesService.endpoint}ReceivedMessages/Get`, {
            ...data,
            index,
            length,
            states,
            messageTypes
        }, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))
    }

    static async fetchAllReceivedMessages(index: number, length: number, states: number[] | undefined, messageTypes: string[] | undefined): Promise<{ status: number, data: { content: any[], totalElements: number } }> {
        const config = this.buildConfig(true);
        return axios.post(`${Config.connectPlusMessagesService.endpoint}ReceivedMessages/Get`, {
            tenantName: this.tenantSubdomain,
            states,
            messageTypes,
            index,
            length
        }, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))
    }

    static async fetchConnectPlusMessageXml(id: string, ack: boolean, type: "sent" | "received"): Promise<{ blob: Blob, text: string }> {
        const subPath = type === "sent" ? "SentMessages" : "ReceivedMessages"
        const url = `${Config.connectPlusMessagesService.endpoint}${subPath}/${id}/${ack ? 'ackxml' : 'xml'}`;
        const config = this.buildConfig(false);
        config.responseType = "blob";
        const response = await axios.get(url, config);
        const blob = response.data as Blob;
        const text = await blob.text();
        return { blob, text };
    }

    static async fetchFlexibilityConstraints(id: string): Promise<{ status: number, data: FlexibilityConstraintDto[] }> {
        const config = this.buildConfig(true);

        const response = await axios.get(`${Config.planningDataService.endpoint}controllable-resources/${id}/network-constraints`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchDowntime(id: string): Promise<{ status: number, data: DowntimeDto[] }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.downtimeService.endpoint}technical-resources/${id}`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchAvatErrors(): Promise<{ status: number, data: AvatErrorDto[] }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.activationsService.endpoint}avat/errors`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchTasks(pageNumber: number, pageSize: number, status: string | undefined, type: string | undefined, startedAtFilter: string | undefined, startedAtFilterOperator: "gte" | "lte", completedAtFilter: string | undefined, completedAtFilterOperator: "gte" | "lte"): Promise<{ status: number, data: { content: TaskDto[], totalElements: number } }> {
        const config = this.buildConfig(true);
        config.params = {
            pageNumber,
            pageSize,
            status,
            type,
            "StartedAt.GreaterThanOrEqual": startedAtFilterOperator === "gte" ? startedAtFilter : undefined,
            "StartedAt.LessThanOrEqual": startedAtFilterOperator === "lte" ? startedAtFilter : undefined,
            "CompletedAt.GreaterThanOrEqual": completedAtFilterOperator === "gte" ? completedAtFilter : undefined,
            "CompletedAt.LessThanOrEqual": completedAtFilterOperator === "lte" ? completedAtFilter : undefined
        }
        const response = await axios.get(`${Config.tasksService.endpoint}tasks`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchActualData(id: string, from: DateTime, to: DateTime): Promise<{ status: number, data: ProductionDataDto | "" }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.planningDataService.endpoint}controllable-resources/${id}/actual-production`, {
            ...config, params: {
                from: from.toISO(),
                to: to.toISO()
            }

        }).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchDowntimeDetails(id: string): Promise<{ status: number, data: DowntimeDetailsDto[] }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.downtimeService.endpoint}${id}`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchFlexibilityConstraintDetails(id: string): Promise<{ status: number, data: FlexibilityConstraintDetailsDto }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.planningDataService.endpoint}network-constraints/${id}`, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));
        return response;
    }

    static async fetchPlanningDataDetails(CRID: string, id: string): Promise<{ status: number, data: PlanningDataDetailsDto }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.planningDataService.endpoint}controllable-resources/${CRID}/planning-data/${id}`, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));
        return response;
    }

    static async fetchDispatchTimeSeries(dispatchId: string, timeSeriesId: string): Promise<{ status: number, data: GeneratedCostInfoTimeSeriesDto }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.planningDataService.endpoint}dispatches/${dispatchId}/time-series/${timeSeriesId}`, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));
        return response;
    }

    static async fetchSensitivities(id: string): Promise<{ status: number, data: { "up": SensitivityDto[], "down": SensitivityDto[] } }> {
        const config = this.buildConfig(true);
        const url = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities`

        const response = await axios.get(url, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchActivations(id: string, type: "resource" | "group"): Promise<{ status: number, data: ActivationDto[] }> {
        const config = this.buildConfig(true);

        let url: string;
        if (type === "resource") {
            url = `${Config.activationsService.endpoint}controllable-resources/${id}/activations`
        } else {
            url = `${Config.activationsService.endpoint}control-groups/${id}/activations`
        }
        const response = await axios.get(url, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchActivationById(id: string): Promise<{ status: number, data: ActivationDto }> {
        const config = this.buildConfig(true);

        const response = await axios.get(`${Config.activationsService.endpoint}activations/${id}`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchAllCostInfo(controllableId: string): Promise<{ status: number, data: CostInfoDto[] }> {
        const config = this.buildConfig(true);

        const response = await axios.get(`${Config.planningDataService.endpoint}controllable-resources/${controllableId}/cost-infos`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response
    }

    static async fetchActivationTimeSeries(activationId: string, id: string): Promise<{ status: number, data: PeriodIntervalDto[] }> {
        const config = this.buildConfig(true);

        const response = await axios.get(`${Config.activationsService.endpoint}activations/${activationId}/timeseries/${id}`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));

        return response;
    }

    static async fetchAllGeneratedCostInfo(id: string, type: "resource" | "group", lastId: string | undefined, size: number): Promise<{ status: number, data: GeneratedCostInfoDto[] }> {
        const config = this.buildConfig(true);
        let url: string;
        if (type === "resource")
            url = `${Config.planningDataService.endpoint}controllable-resources/${id}/cost-infos/dispatches?`
        else
            url = `${Config.planningDataService.endpoint}control-groups/${id}/cost-infos/dispatches?`

        if (lastId) {
            url += `lastId=${lastId}&`
        }

        url += `size=${size}`

        const response = await axios.get(url, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response;
    }

    static async fetchAllGeneratedSensitivities(id: string, type: "resource" | "group", lastId: string | undefined, size: number): Promise<{ status: number, data: GeneratedSensitivityDto[] }> {
        const config = this.buildConfig(true);
        let url: string;
        if (type === "resource")
            url = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities/dispatches?`
        else
            url = `${Config.planningDataService.endpoint}control-groups/${id}/sensitivities/dispatches?`

        if (lastId) {
            url += `lastId=${lastId}&`
        }

        url += `size=${size}`

        const response = await axios.get(url, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response;
    }

    static async fetchGeneratedSensitivityById(id: string, dispatchId: string, type: "resource" | "group"): Promise<{ status: number, data: GeneratedSensitivityDto }> {
        const config = this.buildConfig(true);
        let url: string;
        if (type === "resource")
            url = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities/dispatches/${dispatchId}`
        else
            url = `${Config.planningDataService.endpoint}control-groups/${id}/sensitivities/dispatches/${dispatchId}`

        const response = await axios.get(url, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));
        return response;
    }

    static async createSensitivity(id: string, sensitivity: SensitivityCreateDto): Promise<{ status: number, data: { sensitivityId: string, gridElementSensitivitiesIds: string[] } }> {
        const config = this.buildConfig(true);
        const url: string = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities`

        const response = await axios.post(url, sensitivity, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response
    }

    static async createCostInfo(id: string, costInfo: CostInfoDto): Promise<{ status: number, data: any }> {
        const config = this.buildConfig(false);
        const url: string = `${Config.planningDataService.endpoint}controllable-resources/${id}/cost-infos`

        const response = await axios.post(url, costInfo, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response
    }

    static async deleteCostInfo(id: string, costInfoId: string): Promise<{ status: number, data: any }> {
        const config = this.buildConfig(false);
        const url: string = `${Config.planningDataService.endpoint}controllable-resources/${id}/cost-infos/${costInfoId}`

        const response = await axios.delete(url, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response
    }

    static async updateSensitivity(id: string, sensitivity: SensitivityDto & { id: string }): Promise<{ status: number, data: any }> {
        const config = this.buildConfig(false);
        const url: string = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities/${sensitivity.id}`
        const response = await axios.put(url, sensitivity, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response
    }

    static async updateSensitivityInterval(id: string, sensitivityId: string, interval: { intervalStart: string | null, intervalEnd: string | null }): Promise<{ status: number, data: any }> {
        const config = this.buildConfig(false);
        const url: string = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities/${sensitivityId}/interval`
        const response = await axios.put(url, interval, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response
    }

    static async deleteSensitivityGridConnectionPoint(id: string, sensitivityId: string, gridId: string): Promise<{ status: number, data: any }> {
        const config = this.buildConfig(false);
        const url: string = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities/${sensitivityId}/grid-elements/${gridId}`
        const response = await axios.delete(url, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response
    }

    static async createSensitivityGridConnectionPoint(id: string, sensitivityId: string, data: any): Promise<{ status: number, data: any }> {
        const config = this.buildConfig(false);
        const url: string = `${Config.planningDataService.endpoint}controllable-resources/${id}/sensitivities/${sensitivityId}/grid-elements`
        const response = await axios.post(url, data, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response
    }

    static async deleteSensitivity(inventoryId: string, id: string): Promise<{ status: number, data: any }> {
        const config = this.buildConfig(true);
        const url: string = `${Config.planningDataService.endpoint}controllable-resources/${inventoryId}/sensitivities/${id}`

        const response = await axios.delete(url, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }))

        return response
    }

    static async fetchUnavailabilitiesByControllableResource(CRID: string): Promise<{ status: number, data: UnavailabilityDto[] }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.planningDataService.endpoint}controllable-resources/${CRID}/unavailabilities`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));
        return response;
    }

    static async fetchUnavailabilityById(CRID: string, id: string, trid: string): Promise<{ status: number, data: any }> {
        const config = this.buildConfig(true);
        const response = await axios.get(`${Config.planningDataService.endpoint}controllable-resources/${CRID}/unavailabilities/${id}/technical-resource/${trid}`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));
        return response;
    }

    static async fetchUnavailabilitiesByTechnicalResource(TRID: string): Promise<{ status: number, data: UnavailabilityDto[] }> {
        const config = this.buildConfig(true);
        console.log(Config.planningDataService.endpoint);
        const response = await axios.get(`${Config.planningDataService.endpoint}technical-resources/${TRID}/unavailabilities`, config).then(resp => ({
            data: resp.data,
            status: resp.status
        }));
        return response;
    }

    static async enhanceCR(id: string): Promise<{ status: number, data: any }> {
        const response = await this.post(`${Endpoints.controllableResources}/${id}/confirm`, null);
        return response;
    }

    static async confirmCG(id: string): Promise<{ status: number, data: any }> {
        const response = await this.post(`${Endpoints.controlGroups}/${id}/confirm`, null);
        return response;
    }

    static async updateControlGroup(group: ControlGroup): Promise<{ status: number, data: any }> {
        const dto = toControlGroupDto(group);
        const response = await this.put(`${Endpoints.controlGroups}/${group.inventoryItemId}`, dto);
        return response;
    }

    static async fetchTechnicalResourcesByControllableResource(CRID: string): Promise<(TechnicalResource & { shutdownWindSpeed: number, netRatedCapacityWindSpeed: number, windPowerPoints: any[] })[]> {
        const response = await this.get(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}`, true);
        return response.data;
    }

    static async updateTechnicalResource(resource: TechnicalResource, CRID: string): Promise<{ data: string, status: number }> {
        const dto = toTechnicalResourceDto(resource)
        const response = await this.put(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}/${resource.inventoryItemId}`, dto)
        return response;
    }

    static async addTechnicalResource(CRID: string, resource: TechnicalResource): Promise<{ data: string, status: number }> {
        const dto = toCreateTechnicalResourceDto(resource);
        const response = await this.post(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}`, dto)
        return response;
    }

    static async deleteTechnicalResource(CRID: string, TRID: string): Promise<boolean> {
        const response = await this.delete(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}/${TRID}`)
        return response.status === 200;
    }

    static async fetchTechnicalResource(id: string, CRID: string): Promise<(TechnicalResource & { shutdownWindSpeed: number, netRatedCapacityWindSpeed: number, windPowerPoints: any[] })> {
        const response = await this.get(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}/${id}`, true);
        return response.data;
    }

    static async fetchIndividualQuotas(CRID: string): Promise<(Array<IndividualQuotaDto>)> {
        const response = await this.get(`${Endpoints.controllableResources}/${CRID}/individual-quotas`, true).then(resp => ({
            data: resp.data,
            status: resp.status
        }));
        return response.data ?? [];
    }

    static async updateIndividualQuota(CRID: string, individualQuotaDto: IndividualQuotaDto): Promise<{ data: string, status: number }> {
        const response = await this.put(`${Endpoints.controllableResources}/${CRID}/individual-quotas/${individualQuotaDto.inventoryItemId}`, individualQuotaDto)
        return response;
    }

    static async addIndividualQuota(CRID: string, individualQuotaDto: IndividualQuotaDto): Promise<{ data: string, status: number }> {
        const response = await this.post(`${Endpoints.controllableResources}/${CRID}/individual-quotas`, individualQuotaDto)
        return response;
    }

    static async deleteIndividualQuota(CRID: string, individualQuotaId: string): Promise<boolean> {
        const response = await this.delete(`${Endpoints.controllableResources}/${CRID}/individual-quotas/${individualQuotaId}`)
        return response.status === 200;
    }

    static async fetchMarketPartners(): Promise<MarketPartner[]> {
        const response = await this.get(`${Endpoints.marketPartners}`, true);
        return response.data;
    }

    static async addMarketPartner(partner: { encoding: string, code: string, name: string }): Promise<{ data: string, status: number }> {
        const response = await this.post(`${Endpoints.marketPartners}`, partner);
        return response;
    }

    static async deleteMarketPartner(partnerId: string): Promise<{ data: string, status: number }> {
        const response = await this.delete(`${Endpoints.marketPartners}/${partnerId}`)
        return response;
    }

    static async fetchCurrentTenantMarketPartnerData(): Promise<{ data: MarketPartner, status: number }> {
        const response = await this.get(`${Endpoints.marketPartners}/me`, true)
        return response;
    }

    static async editMarketPartner(partner: { encoding: string, code: string, name: string, revision: number, inventoryItemId: string }): Promise<{ data: string, status: number }> {
        const response = await this.put(`${Endpoints.marketPartners}/${partner.inventoryItemId}`, partner);
        return response;
    }

    static async fetchControlGroups(): Promise<ControlGroup[]> {
        const response = await this.get(`${Endpoints.controlGroups}`, true);
        return response.data;
    }

    static async changePassword({ currentPassword, newPassword }: {currentPassword: string, newPassword: string}): Promise<boolean> {
        let config = this.buildConfig(true);
        config.validateStatus = (status) => status === 401 || status === 200;
        let response = await axios.post(`${Config.auth.authority}Account/password`,{ currentPassword, newPassword }, config);
        // Hack to get a new token when it is expired - necessary, because we are currently not checking the token
        // expiration date in the backend - except on this single endpoint
        if(response.status === 401 && this.token) {
            const token = this.token.replace("Bearer ", "");
            const tokenContent: {name: string} = jwtDecode(token);
            await this.HandleLoginAPI(tokenContent.name, currentPassword);
            config = this.buildConfig(true);
            response = await axios.post(`${Config.auth.authority}Account/password`, {
                currentPassword,
                newPassword
            }, config);
        }
        return response.data;
    }

    static async fetchControlGroupById(id: string): Promise<ControlGroup> {
        const response = await this.get(`${Endpoints.controlGroups}/${id}`, true);
        return response.data;
    }

    static async fetchControlGroupStatus(id: string): Promise<ControlGroupStatus> {
        const response = await this.get(`${Endpoints.controlGroups}/${id}/status`, true);
        return response.data.status;
    }

    static async upsertMarketLocation(location: MarketLocation | undefined, CRID: string, TRID: string, consumption: boolean): Promise<{ data: string, status: number }> {
        const dto = toMarketLocationDto(location);
        const subURL = consumption ? "consumption" : "production";
        const response = await this.put(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}/${TRID}/${subURL}`, dto);
        return response;
    }

    static async deleteMarketLocation(CRID: string, TRID: string, consumption: boolean): Promise<{ data: string, status: number }> {
        const subURL = consumption ? "consumption" : "production";
        const response = await this.delete(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}/${TRID}/${subURL}`);
        return response;
    }

    static async updateMarketPartner(partner: MarketPartner): Promise<boolean> {
        const response = await this.put(`${Endpoints.marketPartners}/${partner.inventoryItemId}`, toMarketPartnerDto(partner))
        return response.status === 200;
    }

    static async updateTranche(tranche: Tranche, CRID: string, TRID: string, consumption: boolean): Promise<{ data: string, status: number }> {
        const dto = toTrancheDto(tranche)
        const subURL = consumption ? "consumption" : "production"
        const response = await this.put(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}/${TRID}/${subURL}/${Endpoints.tranches}/${tranche.inventoryItemId}`, dto)
        return response;
    }

    static async addTranche(tranche: Tranche, CRID: string, TRID: string, consumption: boolean): Promise<{ data: string, status: number }> {
        const dto = toTrancheDto(tranche);
        const subURL = consumption ? "consumption" : "production";
        const response = await this.post(`${Endpoints.controllableResources}/${CRID}/${Endpoints.technicalResources}/${TRID}/${subURL}/${Endpoints.tranches}`, dto);
        return response;
    }

    static async fetchAccountingGroupBalancingSchedules(): Promise<AccountingGroupBalancingSchedule[]> {
        const response = await this.get(`${Endpoints.accountingGroupBalancingSchedules}`, true);
        return response.data;
    }

    static async fetchAccountingGroupBalancingScheduleById(id: string): Promise<AccountingGroupBalancingSchedule> {
        const response = await this.get(`${Endpoints.accountingGroupBalancingSchedules}/${id}`, true);
        return response.data;
    }

    static async addAccountingGroupBalancingSchedules(accountingGroupBalancingSchedule: AccountingGroupBalancingSchedule): Promise<{ data: string, status: number }> {
        const dto = toAccountingGroupDto(accountingGroupBalancingSchedule)
        const response = await this.post(`${Endpoints.accountingGroupBalancingSchedules}`, dto);
        return response;
    }

    static async deleteAccountingGroupBalancingSchedule(id: string): Promise<{ data: string, status: number }> {
        const response = await this.delete(`${Endpoints.accountingGroupBalancingSchedules}/${id}`)
        return response;
    }

    static async updateAccountingGroupBalancingSchedules(accountingGroupBalancingSchedule: AccountingGroupBalancingSchedule): Promise<{ data: string, status: number }> {
        const dto = toAccountingGroupDto(accountingGroupBalancingSchedule)
        const response = await this.put(`${Endpoints.accountingGroupBalancingSchedules}/${accountingGroupBalancingSchedule.inventoryItemId}`, dto);
        return response;
    }

    public static async checkIfUserHasAccess(): Promise<{ status: AccessResult, resources?: ControllableResource[] }> {
        // TODO: find a better way to see if user has access to this tenant
        let resources: ControllableResource[] = []
        try {
            resources = await this.fetchControllableResources();
            resources = resources.map(r => removeNulls(r));
        } catch (e) {
            const error = e as AxiosError;
            if (error.response?.status === 400)
                return { status: AccessResult.BAD_REQUEST };
            if (error.response?.status === 403)
                return { status: AccessResult.NO_ACCESS };
            if (error.response?.status === 401)
                return { status: AccessResult.EXPIRED };
            throw(e);
        }
        return { status: AccessResult.SUCCESS, resources };
    }

    public static async HandleLoginAPI(username: string, password: string): Promise<AccessResult> {

        const params = new URLSearchParams();
        params.append("client_id", "techstack-ui");
        params.append("scope", "dynamicobjectservice");
        params.append("grant_type", "password");
        params.append("username", username);
        params.append("password", password);

        let apiRes: any;

        try {
            apiRes = await axios.post(Config.auth.authority + Endpoints.login, params, this.buildConfig(true));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Handle Axios-specific errors
                console.error('Axios error:', error.message);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
            }
            apiRes = { status: 400 };
        }

        if (apiRes.status === 200) {
            this.setToken(apiRes.data.access_token);
            const accessResult = await this.checkIfUserHasAccess();
            if (accessResult.status === AccessResult.NO_ACCESS) return AccessResult.NO_ACCESS;

            if (this.DOMAIN === "localhost") {
                document.cookie = `token=${apiRes.data.access_token}; expires=Tue, 19 Jan 2038 04:14:07 GMT;path=/; secure;`;
            } else {
                document.cookie = `token=${apiRes.data.access_token}; expires=Tue, 19 Jan 2038 04:14:07 GMT; domain=${this.DOMAIN}; path=/; secure;`;
            }
            return AccessResult.SUCCESS;
        }
        return AccessResult.INCORRECT_DETAILS;
    }

    public static async getPrognosisQualityExport(CRID: string): Promise<{ data: any; fileName: string | undefined; status: number }> {
        const config = this.buildConfig(false);
        const url = `${Config.planningDataService.endpoint}controllable-resources/${CRID}/planning-data/export-test-planning-data-as-mscons`;

        function getFilenameFromHeaders(headers: any): string | undefined {
            const contentDisposition: string = headers['content-disposition'];
            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                return headers['content-disposition'].split('filename=')[1];
            }
            return undefined;
          }

        return (
            axios
                .get(url, { ...config, responseType: "blob" })                
                .then((response) => {
                    const fileName = getFilenameFromHeaders(response.headers);
                    return {
                        data: response.data,
                        fileName,
                        status: response.status,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return ({
                        data: error.response?.data || undefined,
                        fileName: undefined,
                        status: error.response?.status || undefined,
                    });
                })
        );
    }

    public static async UploadFiles(
        key: string,
        files: File[],
        onProgressChange: (progress: number) => void,
    ): Promise<AxiosResponse> {
        const config = {
            headers: this.getHeaders(),
            onUploadProgress(progressEvent: ProgressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onProgressChange(percentCompleted);
            }
        };
        const formData = new FormData();
        files.forEach(file => {
            formData.append(key, file);
        });
        const baseUrl = Config.masterDataService.endpoint;
        return axios.post(`${baseUrl}excel-import`, formData, config);
    }

    private static getHeaders(): { Authorization: string, "X-Tenant-Subdomain": string, "Content-Type": string } {
        return {
            Authorization: `${this.token ?? ""}`, 
            "X-Tenant-Subdomain": this.tenantSubdomain,
            'Content-Type': 'application/json'
        };
    }

    private static buildConfig(parse: boolean): AxiosRequestConfig {
        let config: any = null;
        if (this.token) {
            config = {
                headers: this.getHeaders()
            };
            if (!parse) {
                config.transformResponse = [];
            }
        }
        return config;
    }

    private static getTenantSubdomain(): string {
        let tenantSubdomain = window.location.hostname.split(".")[0];
        if (tenantSubdomain === "localhost") {
            tenantSubdomain = process.env.REACT_APP_TENANT_SUBDOMAIN || "";
        }

        return tenantSubdomain;
    }

    private static get(url: string, parse: boolean = false): Promise<{ data: any, status: number }> {
        const config = this.buildConfig(parse);
        const baseUrl = Config.masterDataService.endpoint;
        return axios.get(baseUrl + url, config)
        .then(resp => ({
            data: resp.data,
            status: resp.status
        }))
        .catch((error) => {
            console.error('An error occurred:', error.response);
            console.error(JSON.parse(error.response?.data));
            throw error; 
        });
    }

    private static getBlob(url: string, parse: boolean = false): Promise<{ data: any, status: number }> {
        const config = this.buildConfig(parse);
        config.responseType = 'blob';
        return axios.get(url, config).then(r => r.data);
    }

    private static put(url: string, data: any, parse: boolean = false): Promise<{ data: any, status: number }> {
        const config = this.buildConfig(parse);
        const baseUrl = Config.masterDataService.endpoint;
        return axios.put(baseUrl + url, data, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }))
        .catch((error) => {
            console.error('An error occurred:', error.response);
            console.error(JSON.parse(error.response?.data));
            throw error; 
        });
    }

    private static post(url: string, data: any, parse: boolean = false): Promise<{ data: any, status: number }> {
        const config = this.buildConfig(parse);
        const baseUrl = Config.masterDataService.endpoint;
        return axios.post(baseUrl + url, data, config)
        .then((resp): any => ({
            data: resp.data,
            status: resp.status
        }))
        .catch((error) => {
            console.error('An error occurred:', error.response);
            console.error(JSON.parse(error.response?.data));
            throw error; 
        });
    }

    private static delete(url: string, parse: boolean = false): Promise<{ data: any, status: number }> {
        const config = this.buildConfig(parse);
        const baseUrl = Config.masterDataService.endpoint;
        return axios.delete(baseUrl + url, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }))
        .catch((error) => {
            console.error('An error occurred:', error.response);
            console.error(JSON.parse(error.response?.data));
            throw error; 
        });
    }
}
