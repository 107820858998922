import React, { ReactElement } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Check, Close, MobiledataOff, Publish, SensorsOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ControlGroupStatus from "../../../../api/fixed/ControlGroup/ControlGroupStatus";
import { ControlGroup } from "../../../../api/fixed/ControlGroup/ControlGroup";

const SentIndicator = ({
                           controlGroup: dataItem,
                           alwaysShowText
                       }: { controlGroup: ControlGroup, alwaysShowText: boolean }): ReactElement => {
    const { hasSuccessfulDispatch, status } = dataItem;
    const { t } = useTranslation("tables");
    let icon: ReactElement;
    if (hasSuccessfulDispatch) {
        switch (status) {
            case ControlGroupStatus.Accepted:
                icon = <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                    <Tooltip title={!alwaysShowText ? t("control_groups_list.accepted") ?? "" : ""}>
                        <Check/>
                    </Tooltip>
                    <Typography>
                        {alwaysShowText && t("control_groups_list.accepted")}
                    </Typography>
                </Box>
                break;
            case ControlGroupStatus.Rejected:
                icon = <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                    <Tooltip title={!alwaysShowText ? t("control_groups_list.rejected") ?? "" : ""}>
                        <Close/>
                    </Tooltip>
                    <Typography>
                        {alwaysShowText && t("control_groups_list.rejected")}
                    </Typography>
                </Box>
                break;
            case ControlGroupStatus.SendingFailed:
                icon =
                    <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                        <Tooltip title={!alwaysShowText ? t("control_groups_list.sending_failed") ?? "" : ""}>
                            <SensorsOff/>
                        </Tooltip>
                        <Typography>
                            {alwaysShowText && t("control_groups_list.sending_failed")}
                        </Typography>
                    </Box>
                break;
            default:
                icon =
                    <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                        <Tooltip
                            title={!alwaysShowText ? t("control_groups_list.successful_dispatch") ?? "" : ""}>
                            <Publish/>
                        </Tooltip>
                        <Typography>
                            {alwaysShowText && t("control_groups_list.successful_dispatch")}
                        </Typography>
                    </Box>
                break;
        }

    } else {
        icon = <Box display="flex" alignItems="center" justifyContent="end" width="100%">
            <Tooltip title={!alwaysShowText ? t("control_groups_list.no_successful_dispatch") ?? "" : ""}>
                <MobiledataOff/>
            </Tooltip>
            <Typography>
                {alwaysShowText && t("control_groups_list.no_successful_dispatch")}
            </Typography>
        </Box>
        if (status === ControlGroupStatus.Rejected) {
            icon =
                <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                    <Tooltip title={!alwaysShowText ? t("control_groups_list.rejected") ?? "" : ""}>
                        <Close/>
                    </Tooltip>
                    <Typography>
                        {alwaysShowText && t("control_groups_list.no_successful_dispatch")}
                    </Typography>
                </Box>
        }
    }

    if (alwaysShowText) {
        return icon
    }

    return <td>{icon}</td>
}

export default SentIndicator
