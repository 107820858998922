import React, { ReactElement } from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

interface Props {
    allElements: any[]
    currentElement: any
    elementKey: string
    onNextClick: (val: any) => void
    onPreviousClick: (val: any) => void
}

const Navigation = ({ allElements, currentElement, elementKey, onNextClick, onPreviousClick }: Props): ReactElement => {
    const currentElementIndex = allElements.findIndex(e => e[elementKey] === currentElement[elementKey])

    return <Box display="flex" justifyContent="space-between">
        <div/>
        <Box>
            <IconButton color="primary" disabled={currentElementIndex === 0} onClick={() => {
                onPreviousClick(allElements[currentElementIndex - 1])
            }}>
                <ArrowBack/>
            </IconButton>
            <IconButton color="primary"
                        disabled={currentElementIndex === allElements.length - 1} onClick={() => {
                onNextClick(allElements[currentElementIndex + 1])
            }}>
                <ArrowForward/>
            </IconButton>
        </Box>
    </Box>
}

export default Navigation