import { GridCellProps } from "@progress/kendo-react-grid";
import React, { ReactElement } from "react";
import { SimpleFieldGridCell } from "ndr-designsystem";
import { ValidationResult } from "../utils/types";

interface Props extends GridCellProps {
    isEditing: boolean,
    isAdding: boolean,
    updateElements: (val: number, index: number) => void;
    maxFractionDigits: number
    valueCheckFunction: (val: number, index: number) => ValidationResult
    endAdornment?: ReactElement
    fullWidth?: boolean
}

const EditingCell = (props: Props): ReactElement => {
    const {
        dataItem,
        dataIndex,
        isEditing,
        isAdding,
        updateElements,
        maxFractionDigits,
        valueCheckFunction,
        endAdornment,
        fullWidth
    } = props;

    return (
        <SimpleFieldGridCell
            {...props}
            isEditing={isEditing || isAdding}
            onValueChange={v => updateElements(v, dataIndex)}
            type="number"
            fullWidth={fullWidth}
            maxFractionDigits={maxFractionDigits}
            defaultValue={dataItem.value}
            endAdornment={endAdornment}
            valueCheckFunction={val => valueCheckFunction(val, dataIndex)}
        />
    )
}

EditingCell.defaultProps = {
    endAdornment: undefined,
    fullWidth: false
}

export default EditingCell