import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

const DetailsView = ({ dataItem }: GridDetailRowProps): ReactElement => {
    const { t } = useTranslation("tables")
    return <Box id="taskDetailsView" display="flex" flexDirection="column">
        <Typography variant="body1">
            {t('avatErrors.description')}
        </Typography>
        <Typography variant="caption">
            {dataItem.description}
        </Typography>
    </Box>
}

export default DetailsView
