import React, { ReactElement } from "react";
import { ProgressBar as KendoProgressBar } from "@progress/kendo-react-progressbars";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import ControllableResourceStatus, {
    stableControllableResourceStates
} from "../../../api/fixed/ControllableResource/ControllableResourceStatus";

const ProgressBar = (): ReactElement => {
    const { currentResource } = useAppSelector(state => state.controllableResources);
    const { t } = useTranslation("tables")
    if (!currentResource || !currentResource.status || stableControllableResourceStates.includes(currentResource.status)) return <></>
    const translatedStatusName = currentResource.status && currentResource.status.length ? t(`controllable_resources_details.states.${currentResource.status}`) : "";
    let multiplier: number;
    switch (currentResource.status) {
        case ControllableResourceStatus.Confirmed:
            multiplier = 1;
            break;
        case ControllableResourceStatus.Sent:
            multiplier = 2;
            break;
        default:
            multiplier = 0;
    }
    return <Box width="100%" position="relative" marginTop={2}>
        <Typography
            sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                zIndex: 3,
                color: multiplier === 2 ? "white" : "gray",
                userSelect: "none",
                fontSize: 15,
                transform: "translate(-50%, -50%)"
            }}>
            {`Status: ${translatedStatusName}`}
        </Typography>
        <KendoProgressBar
            style={{ width: "100%", height: 40, background: "#f8ebbe", borderRadius: 30 }}
            progressClassName="progress_pulsing"
            value={33 * multiplier}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            label={_ => <Typography variant="body2" sx={{ userSelect: "none" }}>{t('sending_to_c+')}</Typography>}
            labelPlacement="center"
        />
    </Box>
}

export default ProgressBar;
