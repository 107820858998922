import React, { ReactElement } from "react";
import { Box, Divider, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "@mui/icons-material";
import { avatErrorsSelector } from "./store/slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setMessageErrors } from "../appStateSlice";

const OpenAvatErrors = (): ReactElement => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();

    const openAvatErrors = useAppSelector(avatErrorsSelector,
        (left, right) => left?.length === right?.length);

    return <Paper elevation={1} sx={{
        height: "100%",
        width: "100%", display: "flex", flexDirection: "column", alignItems: "center",
        justifyContent: openAvatErrors && openAvatErrors.length > 0 ? "space-between" : undefined,
    }}>
        <Typography gutterBottom variant="h6" component="div" sx={{ height: 30 }} padding={1}>
            {t('dashboard.open_avat_errors')}
        </Typography>
        <Divider flexItem/>
        {(!openAvatErrors || openAvatErrors.length === 0) &&
            <Box width="100%" display="flex" height="100%" justifyContent="center" flexDirection="column"
                 alignItems="center">
                <CheckCircle sx={{ fontSize: 100 }} color="primary"/>
                <Typography variant="body2">
                    {t('no_errors')}
                </Typography>
            </Box>
        }
        <Box overflow="auto" width="100%" height="100%">
            <Table>
                <TableBody>
                    {openAvatErrors?.map((e) => (
                        <TableRow
                            hover
                            onClick={() => dispatch(setMessageErrors([e.text, e.description]))}
                            key={e.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                        >
                            <TableCell
                                align="center">{`${e.itemExternalID} - ${e.text}`}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    </Paper>
}

export default OpenAvatErrors;
