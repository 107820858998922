import { createDateTime } from "ndr-designsystem";
import CodeNameProp from "../../fixed/CodeNameProp";
import CostInfo from "../../fixed/CostInfo/CostInfo";

export default interface CostInfoDto {
    id?: string
    businessType?: CodeNameProp
    direction?: CodeNameProp
    status?: CodeNameProp
    from?: string
    to?: string
    value?: number
}

export const fromDto = (dto: CostInfoDto): CostInfo => {
    const costInfo = new CostInfo();
    costInfo.value = dto.value
    costInfo.id = dto.id
    costInfo.to = dto.to ? createDateTime(dto.to) : undefined
    costInfo.from = dto.from ? createDateTime(dto.from) : undefined
    costInfo.status = dto.status
    costInfo.direction = dto.direction
    costInfo.businessType = dto.businessType

    return costInfo;
}

export const toDto = (costInfo: CostInfo): CostInfoDto => ({
    value: costInfo.value,
    id: costInfo.id,
    to: costInfo.to?.toISO(),
    from: costInfo.from?.toISO(),
    status: costInfo.status,
    direction: costInfo.direction,
    businessType: costInfo.businessType
})
