import React, { ChangeEvent, ReactElement, useState } from "react";
import SensitivitiesTable from "./SensitivitiesTable";
import GeneratedSensitivitiesTable from "./GeneratedSensitivitiesTable";

const Sensitivities = (): ReactElement => {
    const [currentlyViewing, setCurrentlyViewing] = useState<"configuration" | "generated">("configuration");
    const onViewChange = (e: ChangeEvent): void => {
        const newValue = (e.target as HTMLInputElement).value
        // @ts-ignore we know that possible values are configuration and generated
        setCurrentlyViewing(newValue)
    }
    if (currentlyViewing === "configuration") {
        return <SensitivitiesTable onViewChange={onViewChange} currentlyViewing={currentlyViewing}/>
    }

    return <GeneratedSensitivitiesTable onViewChange={onViewChange} currentlyViewing={currentlyViewing}/>
}

export default Sensitivities
