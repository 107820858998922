import { orderBy } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import Api from "../../../../api/Api";
import { removeNulls, TimeseriesPageSize } from "../../../../utils";
import { selectFirstTimeSeriesInfo, setAllGeneratedSensitivities, setCurrentGeneratedSensitivity,
    setIsNextFetchingPossible, setIsPreviousFetchingPossible } from "./store";
import { fetchAllControlGroupsAndResources } from "../../../appStateSlice";
import { ControllableResource } from "../../../../api/fixed/ControllableResource/ControllableResource";
import { ControlGroup } from "../../../../api/fixed/ControlGroup/ControlGroup";
import { fetchControllableResourceByIdAction } from "../../../controllableResources/store/thunks";
import { fetchControlGroupById } from "../../../ControlGroups/store/thunks";
import GeneratedSensitivityDto from "../../../../api/dtos/GeneratedSensitivity/GeneratedSensitivityDto";
import GeneratedSensitivityDetailsDto from "../../../../api/dtos/GeneratedSensitivity/GeneratedSensitivityDetailsDto";
import GeneratedSensitivityTimeSeriesDto
    from "../../../../api/dtos/GeneratedSensitivity/GeneratedSensitivityTimeSeriesDto";

export const fetchGeneratedSensitivities = createAsyncThunk(
    "generatedSensitivity/fetchAll",
    async ({ type, id, take }: { type: "resource" | "group", id: string, take: number }, { getState, dispatch }) => {
        let response: GeneratedSensitivityDto[] | undefined = [];
        let lastId: string | undefined;
        const state = getState() as RootState;
        const previousGeneratedSensitivities = state.generatedSensitivity.allGeneratedSensitivities ?? []

        if (state.generatedSensitivity && state.generatedSensitivity.allGeneratedSensitivities) {
            lastId = state.generatedSensitivity.allGeneratedSensitivities[state.generatedSensitivity.allGeneratedSensitivities.length - 1]?.dispatchId;
        }

        response = (await Api.fetchAllGeneratedSensitivities(id, type, lastId, take)).data ?? [];
        response = orderBy(response.filter(data => data.timeSeries.length > 0).map(data => removeNulls(data)), ['date'], ['desc']);

        if (response.length < take) dispatch(setIsNextFetchingPossible(false))
        if (lastId === undefined) dispatch(setIsPreviousFetchingPossible(false));

        dispatch(setAllGeneratedSensitivities([...previousGeneratedSensitivities, ...response]));
        return response;
    }
)

export const fetchGeneratedSensitivityById = createAsyncThunk(
    "generatedSensitivity/fetchById",
    async ({
        id, dispatchId, type, force
    }: {id: string, dispatchId: string, type: "resource" | "group", force: boolean}, { getState }) => {
        let response: GeneratedSensitivityDto | undefined;
        const state = getState() as RootState;

        if (!force && state.generatedSensitivity.allGeneratedSensitivities) {
            response = state.generatedSensitivity.allGeneratedSensitivities.find(s => s.dispatchId === dispatchId)
        }
        if (response === undefined) {
            response = removeNulls((await Api.fetchGeneratedSensitivityById(id, dispatchId, type)).data);
        }

        return response;
    }
)

export const fetchPreviousGeneratedSensitivity = createAsyncThunk(
    "generatedSensitivity/fetchPreviousGeneratedSensitivity",
    async ({ id, type, dispatchId, take }: {id: string, type: "resource" | "group", dispatchId: string, take: number}, { getState, dispatch }) => {
        const state = getState() as RootState;
        const { isPreviousFetchingPossible, allGeneratedSensitivities } = state.generatedSensitivity
        if (!isPreviousFetchingPossible) return [];

        let previousGeneratedSensitivities = (await Api.fetchPreviousGeneratedSensitivity(id, type, dispatchId, take)).data ?? [];
        previousGeneratedSensitivities = orderBy(previousGeneratedSensitivities.map(data => removeNulls(data)), ['date'], ['desc']);
        if (previousGeneratedSensitivities.length < take) dispatch(setIsPreviousFetchingPossible(false))
        dispatch(setAllGeneratedSensitivities([...previousGeneratedSensitivities, ...allGeneratedSensitivities ?? []]));
        return previousGeneratedSensitivities;
    }
)

export const fetchGeneratedSensitivityDetails = createAsyncThunk(
    "generatedSensitivity/fetchDetails",
    async ({
               allSeries,
               force,
               dispatchId
           }: { type: "resource" | "group", dispatchId: string, force: boolean, allSeries: string[] }, {
               getState,
               dispatch
           }) => {
        let response: GeneratedSensitivityDetailsDto | undefined;
        const state = getState() as RootState;

        if (!force && state.generatedSensitivity.currentGeneratedSensitivity) {
            response = state.generatedSensitivity.currentGeneratedSensitivity;
        } else {
            const timeSeries: GeneratedSensitivityTimeSeriesDto[] = [];
            await Promise.all(allSeries.map(async s => {
                timeSeries.push(removeNulls((await Api.fetchDispatchTimeSeries(dispatchId, s)).data))
            }))

            response = {
                ...state.generatedSensitivity.allGeneratedSensitivities!.find(d => d.dispatchId === dispatchId)!,
                timeSeries
            };
        }

        dispatch(setCurrentGeneratedSensitivity(response))
        return response;
    }
)

export const resetGeneratedSensitivityState = createAsyncThunk(
    "generatedSensitivity/reset",
    async (_, { dispatch }) => {
        await dispatch(setAllGeneratedSensitivities(undefined))
        await dispatch(setCurrentGeneratedSensitivity(undefined))
        await dispatch(selectFirstTimeSeriesInfo())
        await dispatch(setIsPreviousFetchingPossible(true));
        await dispatch(setIsNextFetchingPossible(true));
    }
)

export const buildGeneratedSensitivityState = createAsyncThunk(
    "generatedSensitivity/buildState",
    async ({
               id,
               type,
               force,
               dispatchId,
               take
           }: { id: string, type: "resource" | "group", force: boolean, dispatchId: string, take: number }, { dispatch, getState }) => {
        await dispatch(fetchAllControlGroupsAndResources({
            force,
            fetchResources: type === "resource",
            fetchGroups: type === "group"
        }))
        let item: ControllableResource | ControlGroup | undefined;

        if (type === "resource") {
            item = await dispatch(fetchControllableResourceByIdAction({
                id,
                force,
                setAsCurrent: true
            })).unwrap();
        } else {
            item = await dispatch(fetchControlGroupById({
                id,
                force,
                setAsCurrent: true
            })).unwrap();
        }

        if (item && item.inventoryItemId) {
            const generatedSensitivity = await dispatch(fetchGeneratedSensitivityById({
                id: item.inventoryItemId,
                type,
                force,
                dispatchId
            })).unwrap();
            const state = getState() as RootState;
            if (state.generatedSensitivity.allGeneratedSensitivities != null && state.generatedSensitivity.allGeneratedSensitivities.length > 0) {
                const currentItemIndex = state.generatedSensitivity.allGeneratedSensitivities?.findIndex(pd => pd.dispatchId === dispatchId);
                if (currentItemIndex === -1 || currentItemIndex == null) return;
                if (state.generatedSensitivity.allGeneratedSensitivities.length - currentItemIndex <= TimeseriesPageSize && state.generatedSensitivity.isNextFetchingPossible) {
                    await dispatch(fetchGeneratedSensitivities({ id: item.inventoryItemId, type, take: TimeseriesPageSize * 5 }))
                }
                if (currentItemIndex < TimeseriesPageSize && state.generatedSensitivity.isPreviousFetchingPossible) {
                    await dispatch(fetchPreviousGeneratedSensitivity({ id: item.inventoryItemId, type, dispatchId, take: TimeseriesPageSize * 5 }))
                }
            } else {
                await dispatch(fetchPreviousGeneratedSensitivity({ id: item.inventoryItemId, type, dispatchId, take }));
                await dispatch(fetchGeneratedSensitivities({ id: item.inventoryItemId, type, take }));
            }
            await dispatch(fetchGeneratedSensitivityDetails({
                type,
                dispatchId,
                allSeries: generatedSensitivity?.timeSeries ?? [],
                force
            }));
            await dispatch(selectFirstTimeSeriesInfo())
        }
    }
)
