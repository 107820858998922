import { Box, Switch, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { setShowProductionData } from "./store/store";
import { ToggleViewButton } from "../ToggleViewButton";
import useStyles from "../styles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

interface Props {
    onCurrentlyViewingToggle: (val: any) => void
    currentlyViewing: "grid" | "chart"
}

const Toolbar = ({ onCurrentlyViewingToggle, currentlyViewing }: Props): ReactElement => {
    const classes = useStyles();
    const { showProductionData, currentPlanningData, selectedTimeSeries } = useAppSelector(state => state.planningData);
    const dispatch = useAppDispatch()
    const { t } = useTranslation("tables");

    if (currentPlanningData == null || selectedTimeSeries === undefined || showProductionData === undefined) return <></>

    return <div className={classes.toolbar}>
        <ToggleViewButton view={currentlyViewing} onToggleView={onCurrentlyViewingToggle}/>
        {currentPlanningData.timeSeries[selectedTimeSeries].businessType.code === "A01" &&
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography>
                    {t("toolbar_buttons.production_data")}
                </Typography>
                <Switch checked={showProductionData}
                        onChange={(e) => dispatch(setShowProductionData(e.target.checked))}/>
            </Box>
        }
    </div>
}

export default Toolbar
