import { createDateTime } from "ndr-designsystem";
import DataPoint from "../../fixed/ProductionData/DataPoint";

export default interface DataPointDto {
    timestamp: string
    value: number
}

export const fromDto = (dto: DataPointDto): DataPoint => ({
    ...dto,
    timestamp: createDateTime(dto.timestamp)
})