import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

const ViewErrors = (props: GridCellProps & { onViewErrorsClick: (errors: string[]) => void }): ReactElement => {
    const { dataItem, onViewErrorsClick } = props;
    const { t } = useTranslation("tables")
    
    return <td>
        {dataItem.errors.length > 0 &&
            <Button color="secondary" onClick={() => onViewErrorsClick(props.dataItem.errors)}>
                {t('connect_plus_messages.view_errors')}
            </Button>
        }
    </td>
}

export default ViewErrors