import React, { ReactElement, useMemo } from "react";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridField } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { useAppSelector } from "../../../app/hooks";
import { TimeSeriesValue } from "../../../api/fixed/PlanningData/TimeSeriesValue";
import DateCell from "../../../Components/DateCell";
import { timeSeriesInfoForCurrentPlanningData } from "./store/store";

const TimeSeriesGrid = (): ReactElement => {
    const timeSeriesInfo = useAppSelector(timeSeriesInfoForCurrentPlanningData, (left, right) => isEqual(left, right));
    const { currentGeneratedCostInfo, selectedTimeSeries } = useAppSelector(state => state.generatedCostInfo)
    const internationalization = useInternationalization();
    const { t } = useTranslation("tables")

    const getGridFields = useMemo((): GridFieldElement[] => ([
        {
            name: "timestamp",
            title: t("timeSeries.timestamp"),
            minWidth: 200,
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="timestamp"
                displayFormat={DateTime.TIME_SIMPLE}
            />
        },
        {
            name: "value",
            title: t("timeSeries.value"),
            minWidth: 200,
            customCell: (props) => {
                const { dataItem }: { dataItem: TimeSeriesValue } = props;

                return <td>
                    {`${internationalization.formatNumber(dataItem.value, {
                        useGrouping: true,
                        maximumFractionDigits: 3
                    })} ${t(`api:cost_info_unit.${(currentGeneratedCostInfo!.timeSeries[selectedTimeSeries!])!.measurementUnit.code}`)}`}
                </td>
            }
        }
        // we want to re-render cells only on internationalization change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [internationalization, t]);

    return <GridField
        className="timeSeries_2"
        noRecordsText={t("no_records_text")}
        gridFields={getGridFields}
        defaultOpen
        take={10}
        dataItemKey="timestamp"
        values={timeSeriesInfo?.values}
    />
}

export default TimeSeriesGrid;
