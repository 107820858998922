import ActivationDto, { fromDto as fromActivationDto } from "./ActivationDto";
import TimeSeriesDto, { fromDto as fromTimeSeriesDto } from "./TimeSeriesDto";
import PhoneCallDto, { fromDto as fromPhoneCallDto } from "./PhoneCallDto";
import EmailDto, { fromDto as fromEmailDto } from "./EmailDto";
import ActivationDetails from "../../fixed/Activations/ActivationDetails";
import PeriodIntervalDto, { fromDto as fromPeriodIntervalDto } from "./PeriodIntervalDto";
import ConnectPlusDispatchDto, { fromDto as fromConnectPlusDispatchDto } from "./ConnectPlusDispatchDto";
import AvatCallDto, { fromDto as fromAvatCallDto } from "./AvatCallDto";

export default interface ActivationDetailsDto extends ActivationDto {
    absoluteTimeSeriesValue?: PeriodIntervalDto[]
    deltaTimeSeriesValue?: PeriodIntervalDto[]
    avatTimeSeriesValue?: PeriodIntervalDto[]
    upTimeSeries?: TimeSeriesDto
    downTimeSeries?: TimeSeriesDto
    phoneCalls: PhoneCallDto[]
    emails: EmailDto[]
    avatCalls: AvatCallDto[]
    connectPlusDispatches: ConnectPlusDispatchDto[]
}

export const fromDto = (dto: ActivationDetailsDto): ActivationDetails => ({
    ...fromActivationDto(dto),
    absoluteTimeSeriesValue: dto.absoluteTimeSeriesValue ? dto.absoluteTimeSeriesValue.map(v => fromPeriodIntervalDto(v)) : undefined,
    deltaTimeSeriesValue: dto.deltaTimeSeriesValue ? dto.deltaTimeSeriesValue.map(v => fromPeriodIntervalDto(v)) : undefined,
    avatTimeSeriesValue: dto.avatTimeSeriesValue ? dto.avatTimeSeriesValue.map(v => fromPeriodIntervalDto(v)) : undefined,
    upTimeSeries: dto.upTimeSeries ? fromTimeSeriesDto(dto.upTimeSeries) : undefined,
    downTimeSeries: dto.downTimeSeries ? fromTimeSeriesDto(dto.downTimeSeries) : undefined,
    phoneCalls: dto.phoneCalls ? dto.phoneCalls.map(c => fromPhoneCallDto(c)) : [],
    avatCalls: dto.avatCalls ? dto.avatCalls.map(c => fromAvatCallDto(c)) : [],
    emails: dto.emails ? dto.emails.map(c => fromEmailDto(c)) : [],
    connectPlusDispatches: dto.connectPlusDispatches ? dto.connectPlusDispatches.map(m => fromConnectPlusDispatchDto(m)) : []
})
