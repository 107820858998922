import React, { ReactElement, useCallback } from "react";
import { List } from "@mui/material";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import TimeSeriesListItem from "./ListItem";
import { currentGeneratedCostInfoSelector } from "../store/store";
import { useAppSelector } from "../../../../app/hooks";
import GeneratedCostInfoTimeSeries from "../../../../api/fixed/GeneratedCostInfo/GeneratedCostInfoTimeSeries";

interface TimeSeriesListViewProps {
    onItemClick: (item: GeneratedCostInfoTimeSeries) => void;
}

const ListView = ({ onItemClick }: TimeSeriesListViewProps): ReactElement => {
    const currentGeneratedCostInfo = useAppSelector(currentGeneratedCostInfoSelector, (left, right) => isEqual(left, right));
    const { selectedTimeSeries } = useAppSelector(state => state.generatedCostInfo)
    const { t } = useTranslation("api");

    const renderItems = useCallback(() => {
        if (currentGeneratedCostInfo) {
            return currentGeneratedCostInfo.timeSeries.map((pd, index) =>
                <TimeSeriesListItem
                    key={pd.id}
                    selected={selectedTimeSeries === index}
                    businessType={t(`cost_info_business_type.${pd.businessType?.code}`)}
                    direction={pd.direction && t(`direction.${pd.direction.code}`)}
                    status={pd.status && t(`cost_info_status.${pd.status?.code}`)}
                    // type={convertBusinessTypeAndDirection(pd.businessType, pd.direction) ?? "Unknown"}
                    onClick={() => onItemClick(pd)}
                />
            );
        }
        return [];
    }, [currentGeneratedCostInfo, onItemClick, t, selectedTimeSeries]);

    return (
        <List disablePadding>
            <div style={{ maxHeight: "100%" }}>
                {renderItems()}
            </div>
        </List>
    )
};

export default ListView;
