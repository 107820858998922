import { Grid } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { LoadingComponent } from "ndr-designsystem";
import AccountingGroupBalancingScheduleCard from "Pages/Cards/AccountingGroupBalancingScheduleCard";
import { fetchAccountingGroupBalancingSchedules } from "features/accountingGroupBalancingSchedules/store/thunks";
import { useAppDispatch } from "../../app/hooks";
import { fetchAllControlGroupsAndResources, setCurrentPage } from "../../features/appStateSlice";
import ControlGroupsCard from "../Cards/ControlGroupsCard";
import ControllableResourcesCard from "../Cards/ControllableResourcesCard";
import ConnectPlusMessagesCard from "../Cards/ConnectPlusMessagesCard";
import DashboardCard from "../Cards/DashboardCard";
import ConnectPlusDetailsDialog from "../../features/ConnectPlusMsgs/ConnectPlusDetailsDialog";
import MarketPartnersCard from "../Cards/MarketPartnersCard";
import TasksCard from "../Cards/TasksCard";
import AvatErrorsCard from "../Cards/AvatErrorsCard";

const HomeContent = (): ReactElement => {
    const dispatch = useAppDispatch();
    const [loaded, setLoaded] = useState(false);
    // const { t } = useTranslation();

    useEffect(() => {
        dispatch(fetchAllControlGroupsAndResources({
            force: false,
            fetchGroups: true,
            fetchResources: true
        })).then(() => setLoaded(true))
        dispatch(fetchAccountingGroupBalancingSchedules(false))
        dispatch(setCurrentPage("Home"))
    }, [dispatch])

    if (!loaded) return <LoadingComponent useLogo={false}/>

    return (
        <Grid container justifyContent="start" justifyItems="stretch" alignContent="center" alignItems="stretch"
              spacing={10}
              gridAutoRows="1fr"
              gridAutoColumns="max-content"
              sx={{ marginTop: 1 }}>
            <ConnectPlusDetailsDialog/>
            <Grid item md={3} sm={12} xs={12}>
                <ControlGroupsCard/>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <ControllableResourcesCard/>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <AccountingGroupBalancingScheduleCard/>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <MarketPartnersCard/>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <AvatErrorsCard/>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <ConnectPlusMessagesCard/>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <DashboardCard/>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <TasksCard/>
            </Grid>
        </Grid>
    )
}

export default HomeContent
