enum ReceivedMessageStatus {
    Initial = 0,
    Received = 1,
    Decrypted = 2,
    DecryptionFailed = 3,
    SchemaValidated = 4,
    SchemaValidationFailed = 5,
    DataExtracted = 6,
    DataExtractionFailed = 7,
    AcknowledgementXmlGenerated = 8,
    AcknowledgementXmlGenerationFailed = 9,
    AcknowledgementEncrypted = 10,
    AcknowledgementEncryptionFailed = 11,
    AcknowledgementSent = 12,
    AcknowledgementSendingFailed = 13,
    Success = 14
}


export default ReceivedMessageStatus;