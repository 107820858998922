import React, { ReactElement, useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridField } from "ndr-designsystem";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    allGeneratedCostInfoSelector,
    setSendingStatusGeneratedCostInfo
} from "../timeseries/generatedCostInfo/store/store";
import { fetchAllGeneratedCostInfo } from "../timeseries/generatedCostInfo/store/thunks";
import DateCell from "../../Components/DateCell";

const GeneratedCostInfoTable = (): ReactElement => {
    const allGeneratedCostInfo = useAppSelector(allGeneratedCostInfoSelector, (left, right) => left?.length === right?.length);
    const { currentGroup } = useAppSelector(state => state.controlGroups);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation('tables');

    useEffect(() => {
        if (allGeneratedCostInfo === undefined && currentGroup != null) {
            dispatch(fetchAllGeneratedCostInfo({ id: currentGroup.inventoryItemId!, type: "group", force: false, take: 100000 }));
        }
    }, [allGeneratedCostInfo, currentGroup, dispatch])


    const columns = useMemo(() => [{
        name: "date",
        title: t("timeSeries.date"),
        customCell: (props: GridCellProps) => <DateCell
            {...props}
            fieldPath="date"
            displayFormat={DateTime.DATE_SHORT}
        />
    }, {
        name: "timeSeries.length",
        title: t("timeSeries.no_contained_resources")
    },
        {
            title: t("controllable_resources_details.sensitivities.view_status"),
            customCell: (props: GridCellProps): ReactElement => <td><Button color="secondary" onClick={() => {
                dispatch(setSendingStatusGeneratedCostInfo(props.dataItem.dispatchId))
            }}>{t('controllable_resources_details.sensitivities.view_status_button')}</Button></td>
        }
    ], [t, dispatch])

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            defaultOpen
            dataItemKey="internalID"
            values={allGeneratedCostInfo}
            onRowClick={event => history.push(`/controlGroups/${currentGroup?.externalID}/generated-cost-info/${event.dataItem.dispatchId}`)}
            gridFields={columns}
        />
    )
}

export default GeneratedCostInfoTable
