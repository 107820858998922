import { createDateTime } from "ndr-designsystem";
import GeneratedSensitivity from "../../fixed/GeneratedSensitivity/GeneratedSensitivity";

export default interface GeneratedSensitivityDto {
    date: string,
    dispatchId: string,
    dispatchedAt: string,
    status: string,
    timeSeries: string[]
}

export const fromDto = (dto: GeneratedSensitivityDto): GeneratedSensitivity => ({
    ...dto,
    date: createDateTime(dto.date),
    dispatchedAt: createDateTime(dto.dispatchedAt)
})
