import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    dataItem: {
        hadData: boolean,
        downtimeWorkType: string
    }
}

const CalculateAndSendDowntimeWorkTaskView = ({ dataItem }: Props): ReactElement => {
    const { t } = useTranslation("tables");
    return <b>{t(dataItem.hadData ? 'tasks.calculate_downtime_work.with_data' : 'tasks.calculate_downtime_work.no_data', {
        downtimeWorkType: t(`tasks.calculate_downtime_work.downtimeWorkType.${dataItem.downtimeWorkType}`),
        timeframe: t(`tasks.calculate_downtime_work.timeframe.${dataItem.downtimeWorkType}`)
    })}</b>
}

export default CalculateAndSendDowntimeWorkTaskView;
