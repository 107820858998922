import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { RootState } from "../../../../app/store";
import Api from "../../../../api/Api";
import { removeNulls } from "../../../../utils";
import { fetchAllControlGroupsAndResources } from "../../../appStateSlice";
import { fetchControllableResourceByIdAction } from "../../../controllableResources/store/thunks";
import { setAllDowntimes, setCurrentDowntime } from "./slice";
import DowntimeDto from "../../../../api/dtos/Downtime/DowntimeDto";
import DowntimeDetailsDto from "../../../../api/dtos/Downtime/DowntimeDetailsDto";
import {
    fetchTechnicalResourceById,
    fetchTechnicalResourcesByControllableResource
} from "../../../technicalResources/store/thunks";

export const fetchDowntimesByTR = createAsyncThunk(
    "downtime/fetchByTRId",
    async ({ id, force }: { id: string, force: boolean }, { getState, dispatch }) => {
        let response: DowntimeDto[] | undefined;
        const state = getState() as RootState;

        response = state.downtime.allDowntimes;

        if (!response || response.length === 0 || force) {
            const allDowntimes = (await Api.fetchDowntime(id)).data;
            response = orderBy(allDowntimes.map(data => removeNulls(data)), ['start', 'periodEnd'], ['desc', 'desc']);
        }

        dispatch(setAllDowntimes(response));
    }
)

export const fetchDowntimeDetails = createAsyncThunk(
    "downtime/fetchDetails",
    async ({ id, force }: { id: string, force: boolean }, { getState, dispatch }) => {
        let response: DowntimeDetailsDto | undefined;
        const state = getState() as RootState;

        response = state.downtime.currentDowntime;

        if (!response || force || response.id !== id) {
            response = removeNulls((await Api.fetchDowntimeDetails(id)).data);
        }

        dispatch(setCurrentDowntime(response));
    }
)

export const resetDowntimesState = createAsyncThunk(
    "downtime/resetState",
    async (_: never, { dispatch }) => {
        await dispatch(setCurrentDowntime(undefined))
    })

export const buildDowntimesState = createAsyncThunk(
    "downtime/buildState",
    async ({
               controllableId,
               technicalId,
               seriesId,
               force
           }: { controllableId: string, technicalId: string, seriesId: string, force: boolean }, { dispatch }) => {
        await dispatch(fetchAllControlGroupsAndResources({ force, fetchResources: true, fetchGroups: false }))

        const controllableResource = await dispatch(fetchControllableResourceByIdAction({
            id: controllableId,
            force,
            setAsCurrent: true
        })).unwrap();

        if (controllableResource && controllableResource.inventoryItemId) {
            await dispatch(fetchTechnicalResourcesByControllableResource({
                CRID: controllableResource.inventoryItemId,
                force
            }));
            const response = await dispatch(fetchTechnicalResourceById({
                technicalId,
                setAsCurrent: true,
                force,
                controllableId: controllableResource.inventoryItemId
            })).unwrap()
            await dispatch(fetchDowntimesByTR({ id: response.resource?.inventoryItemId ?? "", force }));
            await dispatch(fetchDowntimeDetails({
                id: seriesId,
                force
            }));
        }
    }
)
