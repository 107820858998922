import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import Api from "../../../api/Api";
import { RootState } from "../../../app/store";
import { MarketPartner } from "../../../api/fixed/MarketPartner";
import { removeNulls } from "../../../utils";
import {
    setAdditionalAffectedGridOperatorAtIndex as controlSetAdditionalAffectedGridOperatorsAtIndex,
    setAffectedGridOperatorAtIndex as controlSetAffectedGridOperatorsAtIndex,
    setOperationsManager as controlSetOperationsManager
} from "../../controllableResources/store/controllableResourcesSlice";
import {
    setAdditionalAffectedGridOperatorAtIndex as groupSetAdditionalAffectedGridOperatorsAtIndex,
    setAffectedGridOperatorAtIndex as groupSetAffectedGridOperatorsAtIndex
} from "../../ControlGroups/store/controlGroupsSlice";
import {
    setConsumptionSupplier as trancheSetConsumptionSupplier,
    setConsumptionTrancheSupplierAtIndex as technicalTrancheSetConsumptionTrancheSupplierAtIndex,
    setOperator as technicalSetOperatorId,
    setProductionSupplier as trancheSetProductionSupplier,
    setProductionTrancheSupplierAtIndex as technicalTrancheSetProductionTrancheSupplierAtIndex
} from "../../technicalResources/store/technicalResourcesSlice";
import { setAddNewMarketPartnerData } from "../../appStateSlice";

export const addNewMarketPartner = createAsyncThunk(
    "marketPartners/add",
    async (data: { selectedCode: string, enteredCode: string, name: string }) => {
        const response = await Api.addMarketPartner({
            encoding: data.selectedCode,
            code: data.enteredCode.trim(),
            name: data.name.trim()
        });
        return response;
    }
)

export const fetchCurrentTenantMarketPartnerData = createAsyncThunk(
    "marketPartners/fetchCurrentTenantData",
    async (force: boolean, { getState }) => {
        const state = getState() as RootState;
        if (state.marketPartners.currentTenantMarketPartner === undefined || force) {
            const response = await Api.fetchCurrentTenantMarketPartnerData();
            return removeNulls(response.data)
        }

        return state.marketPartners.currentTenantMarketPartner
    }
)

export const deleteMarketPartner = createAsyncThunk<MarketPartner[], string, {
    rejectValue: any
}>(
    'marketPartners/delete',
    async (id: string, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState
            const newMps = (state.marketPartners.allPartners ?? []).filter(mp => mp.inventoryItemId !== id);

            const response = await Api.deleteMarketPartner(id);
            if (response.status === 200)
                return newMps
            return []
        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue(JSON.parse(error.response!.data))
        }
    }
)

export const editMarketPartner = createAsyncThunk(
    'marketPartners/edit',
    async (partner: { code: string, encoding: string, name: string, revision: number, inventoryItemId: string }): Promise<MarketPartner> => {

        await Api.editMarketPartner({
            encoding: partner.encoding,
            code: (partner.code ?? "").trim(),
            name: partner.name ?? "",
            revision: partner.revision ?? 0,
            inventoryItemId: partner.inventoryItemId ?? ""
        });

        return {
            encoding: {
                code: partner.encoding
            },
            code: partner.code.trim(),
            name: partner.name,
            revision: partner.revision !== undefined ? partner.revision + 1 : 0,
            inventoryItemId: partner.inventoryItemId
        }
    }
)

export const fetchMarketPartners = createAsyncThunk(
    'marketPartners/fetch',
    async (force: boolean, thunkAPI) => {
        const state: RootState = thunkAPI.getState() as RootState;
        let response: MarketPartner[] = [];

        if (state.marketPartners.allPartners && state.marketPartners.allPartners.length > 0 && !force)
            response = [...state.marketPartners.allPartners];
        else {
            response = await Api.fetchMarketPartners();
            response = response.map(mp => removeNulls(mp))
        }

        return response;
    }
)

export const selectMarketPartnerAfterAdd = createAsyncThunk(
    'marketPartners/selectMarketPartnerAfterAdd',
    async (marketPartnerId: string, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;
        const currentAddData = state.appState.addNewMarketPartner
        if (currentAddData.type === "control") {
            if (currentAddData.fieldPath === "affectedGridOperators")
                dispatch(controlSetAffectedGridOperatorsAtIndex({
                    index: currentAddData.elementIndex, value: marketPartnerId
                }))
            if (currentAddData.fieldPath === "additionalGridAffectedOperators")
                dispatch(controlSetAdditionalAffectedGridOperatorsAtIndex({
                    index: currentAddData.elementIndex, value: marketPartnerId
                }))
            if (currentAddData.fieldPath === "operationsManagerId") dispatch(controlSetOperationsManager(marketPartnerId))
        } else if (currentAddData.type === "group") {
            if (currentAddData.fieldPath === "affectedGridOperators")
                dispatch(groupSetAffectedGridOperatorsAtIndex({
                    index: currentAddData.elementIndex, value: marketPartnerId
                }))
            if (currentAddData.fieldPath === "additionalGridAffectedOperators")
                dispatch(groupSetAdditionalAffectedGridOperatorsAtIndex({
                    index: currentAddData.elementIndex, value: marketPartnerId
                }))
        } else if (currentAddData.type === "technical") {
            if (currentAddData.fieldPath === "operatorId") dispatch(technicalSetOperatorId(marketPartnerId))
            if (currentAddData.fieldPath === "consumption.supplierId") dispatch(trancheSetConsumptionSupplier(marketPartnerId))
            if (currentAddData.fieldPath === "production.supplierId") dispatch(trancheSetProductionSupplier(marketPartnerId))
        } else if (currentAddData.type === "technicalTranche") {
            if (currentAddData.fieldPath === "consumptionTranche")
                dispatch(technicalTrancheSetConsumptionTrancheSupplierAtIndex({
                    index: currentAddData.elementIndex, value: marketPartnerId
                }))
            if (currentAddData.fieldPath === "productionTranche")
                dispatch(technicalTrancheSetProductionTrancheSupplierAtIndex({
                    index: currentAddData.elementIndex, value: marketPartnerId
                }))
        }
        dispatch(setAddNewMarketPartnerData({
            show: false,
            type: "control",
            fieldPath: "affectedGridOperators",
            elementIndex: 0,
            data: undefined
        }))
    }
)
