import React, { ReactElement } from "react";
import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { Build } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MarketPartnersCard = (): ReactElement => {
    const history = useHistory();
    const { t } = useTranslation("common");

    return <Box display="flex" justifyContent="center" height="100%" width="100%">
        <Card sx={{ textAlign: "center", flexGrow: 1 }}>
            <CardActionArea sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                            onClick={() => history.push("/market-partners")}>
                <Build sx={{ fontSize: 50 }}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {t('home.market_partners_list')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('home.market_partners_list_details')}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Box>
}

export default MarketPartnersCard
