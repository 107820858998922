import { createDateTime } from "ndr-designsystem";
import FlexibilityConstraint from "../../fixed/FlexibilityConstraint/FlexibilityConstraint";
import Direction from "../../fixed/FlexibilityConstraint/Direction";
import GridElementCodingScheme from "../../fixed/FlexibilityConstraint/GridElementCodingScheme";

export interface FlexibilityConstraintDto {
    internalID: string
    date: string,
    direction: Direction
    gridElementCode: string
    gridElementCodingScheme: GridElementCodingScheme,
    code: string,
    name: string
}

export const fromDto = (dto: FlexibilityConstraintDto): FlexibilityConstraint => ({
    ...dto,
    date: createDateTime(dto.date)
})