import React, { ReactElement, useMemo } from "react";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridField } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { timeSeriesInfoForCurrentPlanningData } from "./store/store";
import EmptyWhileEditingCell from "../../../Components/EmptyWhileEditingGridCell";
import DateCell from "../../../Components/DateCell";
import { currentProductionDataSelector } from "../productionData/store/slice";

const TimeSeriesGrid = (): ReactElement => {
    const timeSeriesInfo = useAppSelector(timeSeriesInfoForCurrentPlanningData, (left, right) => left?.ExternalID === right?.ExternalID);
    const internationalization = useInternationalization();
    const currentProductionData = useAppSelector(currentProductionDataSelector, (left, right) => left?.id !== undefined && left?.id === right?.id);
    const { showProductionData } = useAppSelector(state => state.planningData)
    const { t } = useTranslation("tables")

    const PlanningValue = (props: GridCellProps): ReactElement => {
        const { dataItem } = props;

        return (
            <EmptyWhileEditingCell
                {...props}
                isEditing={false}
                defaultValue={dataItem && `${internationalization.formatNumber(dataItem.planningValue * 1000, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })} kW`}
            />
        );
    };

    const ProductionValue = (props: GridCellProps): ReactElement => {
        const { dataItem } = props;
        return (
            <EmptyWhileEditingCell
                {...props}
                isEditing={false}
                defaultValue={dataItem && `${internationalization.formatNumber(dataItem.productionValue, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })} kW`}
            />
        );
    };

    const getGridFields = useMemo(() => {
        const toReturn = [
            {
                name: "timeStamp",
                title: t("timeSeries.timestamp"),
                customCell: (props: GridCellProps) => <DateCell
                    {...props}
                    fieldPath="timeStamp"
                    displayFormat={DateTime.TIME_SIMPLE}
                />
            },
            {
                name: "value",
                title: showProductionData ? t("timeSeries.planned_value") : t("timeSeries.value"),
                customCell: PlanningValue
            }
        ]
        if (currentProductionData && showProductionData) {
            toReturn.push({
                name: "value",
                title: t("timeSeries.actual_value"),
                customCell: ProductionValue
            })
        }
        return toReturn;
        // we want to re-render cells only on internationalization change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internationalization, currentProductionData, showProductionData]);

    const gridValues = useMemo(() => {
        const toReturn = timeSeriesInfo?.values?.map(v => ({
            productionValue: currentProductionData?.dataPoints.find(cp => cp.timestamp.equals(v.timeStamp))?.value,
            timeStamp: v.timeStamp,
            planningValue: v.value
        }))

        return toReturn;
    }, [timeSeriesInfo, currentProductionData])

    return <GridField
        className={showProductionData ? "timeSeries_4" : "timeSeries_2"}
        noRecordsText={t("no_records_text")}
        gridFields={getGridFields}
        defaultOpen
        take={10}
        dataItemKey="timeStamp"
        values={gridValues}
    />
}

export default TimeSeriesGrid;
