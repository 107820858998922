import { useTranslation } from "react-i18next";
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import React, { ReactElement, useMemo, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { ClosableDialog } from "ndr-designsystem";
import { allowedCodes as MarketPartnerEncodingAllowedCodes } from "../../api/fixed/MarketPartnerEncoding";
import useStyles from "./styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    addNewMarketPartner as addNewMarketPartnerAction,
    editMarketPartner,
    selectMarketPartnerAfterAdd
} from "./store/thunks";
import { MPSchema } from "../../utils/inputChecking";
import { setAddNewMarketPartnerData } from "../appStateSlice";
import { transformToSelectEntry } from "../../utils";
import { addToMarketPartners } from "./store/marketPartnersSlice";


interface Props {
    open: boolean
    handleClose: () => void
}

interface IAddMarketPartnerForm {
    selectedCode: string;
    enteredCode: string;
    name: string;
}

const AddMarketPartnerDialog = ({ open, handleClose }: Props): ReactElement => {
    const { allPartners } = useAppSelector(state => state.marketPartners)
    const { addNewMarketPartner } = useAppSelector(state => state.appState)
    const [isSaving, setIsSaving] = useState(false);
    const { register, formState: { errors, isValid }, handleSubmit, reset, getValues } = useForm<IAddMarketPartnerForm>({
        mode: "onTouched",
        resolver: yupResolver(MPSchema(addNewMarketPartner.data ? [] : (allPartners ?? []))),
        defaultValues: {
            enteredCode: addNewMarketPartner?.data?.code ?? "",
            selectedCode: addNewMarketPartner?.data?.encoding?.code ?? "",
            name: addNewMarketPartner?.data?.name ?? ""
        }
    });
    const { t } = useTranslation();

    const classes = useStyles();
    const dispatch = useAppDispatch();

    const allowedMarketPartnerEncodingValues = useMemo(() => transformToSelectEntry(MarketPartnerEncodingAllowedCodes, t, "api:market_partner_encoding"), [t])

    const onSubmit: SubmitHandler<IAddMarketPartnerForm> = async (data) => {
        setIsSaving(true);
        let response;
        if (addNewMarketPartner.data !== undefined) {
            response = await dispatch(editMarketPartner({
                revision: addNewMarketPartner.data.revision ?? 0,
                inventoryItemId: addNewMarketPartner.data.inventoryItemId ?? "",
                encoding: data.selectedCode,
                code: data.enteredCode.trim(),
                name: data.name.trim()
            })).unwrap()
        } else {
            response = await dispatch(addNewMarketPartnerAction(data)).unwrap();
        }
        if (response.status === 200 && addNewMarketPartner.data === undefined) {
            dispatch(addToMarketPartners({
                code: data.enteredCode.trim(),
                name: data.name.trim(),
                revision: 0,
                inventoryItemId: `${response.data}`,
                encoding: allowedMarketPartnerEncodingValues.find(r => r.code === data.selectedCode)
            }))
            dispatch(selectMarketPartnerAfterAdd(response.data));
        }
        if (addNewMarketPartner.data !== undefined) {
            dispatch(setAddNewMarketPartnerData({
                show: false,
                type: undefined,
                fieldPath: undefined,
                elementIndex: 0,
                data: undefined
            }))
        }
        reset();
    }

    return (
        <ClosableDialog
            dialogTitle={
                <DialogTitle
                    style={{ paddingLeft: 0 }}>{t(`tables:market_partners_add_dialog.${addNewMarketPartner.data != null ? "edit_title" : "title"}`)}
                </DialogTitle>
            }
            contentPadding={2}
            open={open}
            onClose={handleClose}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <FormControl className={classes.formControl}
                             error={errors?.selectedCode !== undefined}
                >
                    <InputLabel
                        id="demo-simple-select-label">{t('tables:market_partners_add_dialog.encoding')}</InputLabel>
                    <Select
                        fullWidth
                        label={t('tables:market_partners_add_dialog.encoding')}
                        defaultValue={getValues().selectedCode}
                        disabled={addNewMarketPartner.data !== undefined || isSaving}
                        {...register('selectedCode')}
                    >
                        {allowedMarketPartnerEncodingValues.map(val =>
                            <MenuItem key={val.code} value={val.code}>{val.name}</MenuItem>
                        )}
                    </Select>
                    <FormHelperText>{t(`${errors?.selectedCode?.message ?? ""}`)}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}
                             error={errors?.enteredCode !== undefined}
                >
                    <TextField
                        margin="dense"
                        label={t('tables:market_partners_add_dialog.code')}
                        inputProps={{ maxLength: 13 }}
                        fullWidth
                        disabled={addNewMarketPartner.data !== undefined || isSaving}
                        {...register('enteredCode')}
                    />
                    <FormHelperText>{t(`${errors?.enteredCode?.message ?? ""}`)}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}
                             error={errors?.name !== undefined}
                >
                    <TextField
                        margin="dense"
                        label={t('tables:market_partners_add_dialog.name')}
                        fullWidth
                        disabled={isSaving}
                        {...register('name')}
                    />
                    <FormHelperText>{t(`${errors?.name?.message ?? ""}`)}</FormHelperText>
                </FormControl>
                <DialogActions>
                    {!isSaving &&
                        <Button onClick={handleClose} color="primary">
                            {t('tables:market_partners_add_dialog.buttons.cancel')}
                        </Button>
                    }
                    <Button type="submit" color="primary" disabled={isSaving || !isValid}>
                        {isSaving ?
                            <CircularProgress color="primary"
                                              variant="indeterminate"/> : t('tables:market_partners_add_dialog.buttons.create')}
                    </Button>
                </DialogActions>
            </form>
        </ClosableDialog>
    )
}

export default AddMarketPartnerDialog
