import React, { useEffect, useState } from "react";
import { GridDataState } from "ndr-designsystem";
import { isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { setMessageErrors } from "../../../appStateSlice";
import ConnectPlusMessagesGrid from "../../../../Components/connectPlusMessagesGrid/ConnectPlusMessagesGrid";
import {
    fetchSentMessagesByIds,
    resetSentMessages,
    setTotalItems
} from "../../../ConnectPlusMsgs/ConnectPlusMessagesSlice";

const ConnectPlusMessagesTable: React.FC = () => {
    const { totalItems } = useAppSelector((state) => state.connectPlusMessages);
    const { currentActivation } = useAppSelector((state: RootState) => state.activations);
    const sentMessages = useAppSelector((state) => state.connectPlusMessages.sentMessages, (left, right) => isEqual(left, right));
    const dispatch = useAppDispatch();
    const [dataState, setDataState] = useState<GridDataState | undefined>(undefined);
    const [data, setData] = useState<any[] | undefined>(undefined);

    useEffect(() => {
        setData(sentMessages);
    }, [sentMessages])

    useEffect(() => {
        const buildState = async (): Promise<void> => {
            if (currentActivation && dataState) {
                const ids = currentActivation.connectPlusDispatches.map(c => c.dispatchId);
                await dispatch(fetchSentMessagesByIds({ ids, dataState }));
            }
        }
        buildState()
    }, [currentActivation, dispatch, sentMessages, dataState])

    return (
        <ConnectPlusMessagesGrid
            filterable={false}
            data={data}
            onViewErrorsClick={(errors) => {
                dispatch(setMessageErrors(errors))
            }}
            direction="sent"
            processData={false}
            totalMessages={totalItems}
            onDataStateChange={async (newState) => {
                if (isEqual(dataState, newState)) return;

                dispatch(resetSentMessages());

                if (!isEqual(dataState?.filter, newState.filter)) {
                    dispatch(setTotalItems(undefined))
                }

                setDataState(newState);
            }}
        />
    )
}


export default ConnectPlusMessagesTable
