import React, { ReactElement, useMemo } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useAppSelector } from "app/hooks";
import CustomChart from "./Chart";

const TimeSeriesChart = (): ReactElement => {
    const { currentResource } = useAppSelector(state => state.technicalResources);
    const internationalization = useInternationalization();

    const chartData = useMemo(() => {
        if (currentResource) {
            const values = (currentResource.windPowerCurve?.elements ?? []).map(v => ({
                x: Math.round(v.speed * 10),
                y: v.value ?? 0
            }));
            return {
                labels: values.map(t => t.x),
                datasets: [{
                    label: "Value",
                    data: values.map(t => t.y),
                    backgroundColor: "#ef870f",
                    borderColor: "#ef870f",
                    pointRadius: 0,
                }]
            };
        }
        return { labels: [""], datasets: [] };
    }, [currentResource]);

    const getChartPlugins = {
        tooltip: {
            enabled: false,
        },
        legend: {
            display: false
        },
    }

    const getChartScales = useMemo(() => ({
        y: {
            min: 0,
            ticks: {
                callback: (value: number) => {
                    if (value <= (currentResource?.productionNetRatedCapacity ?? 0) * 1000) {
                        return `${internationalization.formatNumber(value, {
                            useGrouping: true,
                            maximumFractionDigits: 3
                        })} kW`
                    }
                    return ""
                },
            },
            max: (currentResource?.productionNetRatedCapacity ?? 0) * 1030,
            beginAtZero: true,
        },
        x: {
            ticks: {
                callback: (value: number) => `${internationalization.formatNumber(value / 10, {
                    useGrouping: true,
                    maximumFractionDigits: 1
                })} m/s`,
                stepSize: 10,
            },
            min: 0,
            max: (currentResource?.windPowerCurve?.netRatedCapacityWindSpeed ?? 0) * 10,
            beginAtZero: true,
        }
    }), [internationalization, currentResource])

    return <CustomChart
        maintainAspectRatio={false}
        locale={internationalization.locale}
        plugins={getChartPlugins}
        scales={getChartScales}
        data={chartData}
        zoom={false}
    />
}

export default TimeSeriesChart;

