import React, { ReactElement } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Slide, SlideProps } from "@mui/material";

/* eslint-disable react/require-default-props */
export const SlideUpTransition =  React.forwardRef((
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props}>{props.children}</Slide>);

export const SlideDownTransition =  React.forwardRef((
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) => <Slide direction="down" ref={ref} {...props}>{props.children}</Slide>);

export function SlideTransition(props: SlideProps): ReactElement {
    return <Slide {...props} direction="down" />;
}