import { createDateTime } from "ndr-designsystem";
import GeneratedCostInfoTimeSeriesValue from "../../fixed/GeneratedCostInfo/GeneratedCostInfoTimeSeriesValue";

export default interface GeneratedCostInfoTimeSeriesValueDto {
    timestamp: string
    value: number
}

export const fromDto = (dto: GeneratedCostInfoTimeSeriesValueDto): GeneratedCostInfoTimeSeriesValue => ({
    ...dto,
    timestamp: createDateTime(dto.timestamp)
})
