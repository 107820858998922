import React, { ReactElement, } from "react";
import { useTranslation } from "react-i18next";
import { toLocaleDate } from "ndr-designsystem";
import { DateTime } from "luxon";
import { useAppSelector } from "../../../app/hooks";
import TaskType from "../../../api/fixed/Task/TaskType";

interface Props {
    dataItem: { controllableResourceId: string | null, controlGroupId: string | null, date: string, [prop: string]: any }
    type: TaskType.DispatchPlanningDataTask | TaskType.DispatchCostInfoTask | TaskType.DispatchSensitivitiesTask
}

const DispatchTaskWithDateAndExternalID = ({ dataItem, type }: Props): ReactElement => {
    const isControlGroup = !!dataItem.controlGroupId;
    const { allResources } = useAppSelector(state => state.controllableResources)
    const { allGroups } = useAppSelector(state => state.controlGroups)
    const { t, i18n } = useTranslation("tables");
    const currentResource = isControlGroup ?
        (allGroups ?? []).find(r => r.inventoryItemId === dataItem.controlGroupId) :
        (allResources ?? []).find(r => r.inventoryItemId === dataItem.controllableResourceId);
    let translationKey = "";

    switch (type) {
        case TaskType.DispatchCostInfoTask:
            translationKey = dataItem.hadData ? "dispatch_cost_info" : "dispatch_cost_info_no_data";
            break;
        case TaskType.DispatchPlanningDataTask:
            translationKey = "dispatch_planning_data";
            break;
        case TaskType.DispatchSensitivitiesTask:
            translationKey = isControlGroup ? "dispatch_sensitivities_cg" : "dispatch_sensitivities_cr";
            break;
        default:
            break;
    }

    return <b>{t(`tasks.${translationKey}`, {
        externalID: currentResource?.externalID,
        date: toLocaleDate(dataItem.date, i18n.language, DateTime.DATE_SHORT)
    })}</b>
}

export default DispatchTaskWithDateAndExternalID
