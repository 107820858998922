import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { Box, Button } from "@mui/material";
import { GridField, LoadingComponent, TranslatedApiValueCell, useLocalStorage } from "ndr-designsystem";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { buildActivationsState, fetchExtraTimeseries, resetActivationsState } from "../store/thunks";
import DateCell from "../../../../Components/DateCell";
import AuditLogDialog from "../AuditLog/AuditLogDialog";
import Grid from "../Grid";
import Chart from "../Chart";
import { allActivationsSelector, currentActivationSelector } from "../store/store";
import Toolbar from "./Toolbar";
import { resetReceivedMessages, resetSentMessages } from "../../../ConnectPlusMsgs/ConnectPlusMessagesSlice";
import Navigation from "../../Navigation";

export type TimeSeriesViewType = "grid" | "chart"

interface RouteParams {
    inventoryId: string,
    seriesId: string
}

const DataView = (): ReactElement => {
    const { inventoryId, seriesId } = useParams<RouteParams>();
    const { headerHeight } = useAppSelector(state => state.appState)
    const [currentlyViewing, setCurrentlyViewing] = useLocalStorage<TimeSeriesViewType>("timeseries.view", {
        pollingInterval: -1,
        initialValue: "chart"
    })
    const location = useLocation();
    const [isInCR, setIsInCR] = useState(location.pathname.includes("controllable"))
    const [isAuditDialogOpen, setIsAuditDialogOpen] = useState(false);
    const { currentResource: controllableResource } = useAppSelector(state => state.controllableResources)
    const allActivations = useAppSelector(allActivationsSelector, (left, right) => isEqual(left, right));
    const { currentGroup: controlGroup } = useAppSelector(state => state.controlGroups)
    const currentActivation = useAppSelector(currentActivationSelector, (left, right) => isEqual(left, right));
    const history = useHistory()
    const dispatch = useAppDispatch();
    const { direction } = useAppSelector(state => state.activations)
    const { t } = useTranslation("tables");

    useEffect(() => {
        setIsInCR(location.pathname.includes("controllable"))
    }, [location])

    useEffect(() => {
        switch (direction) {
            case "avat":
                if (currentActivation?.avatTimeSeriesValue === undefined) dispatch(fetchExtraTimeseries({
                    seriesId: currentActivation?.avatTimeSeries!,
                    force: false,
                    type: direction
                }));
                break;
            case "delta":
                if (currentActivation?.deltaTimeSeriesValue === undefined) dispatch(fetchExtraTimeseries({
                    seriesId: currentActivation?.deltaTimeSeries!,
                    force: false,
                    type: direction
                }));
                break;
            case "absolute":
                if (currentActivation?.absoluteTimeSeriesValue === undefined) dispatch(fetchExtraTimeseries({
                    seriesId: currentActivation?.absoluteTimeSeries!,
                    force: false,
                    type: direction
                }));
                break;
            default:
                break;
        }
    }, [direction, currentActivation, dispatch])

    useEffect(() => {
        dispatch(buildActivationsState({
            inventoryId,
            seriesId,
            force: false,
            type: isInCR ? "resource" : "group"
        }));

        return () => dispatch(resetActivationsState())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryId, seriesId])

    const getGridFields = useMemo(() => [
        {
            title: t("controllable_resources_details.activations.date_received"),
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="receivedDate"
                    displayFormat={DateTime.DATETIME_SHORT}
                />
            )
        },
        {
            title: t("controllable_resources_details.activations.date_activation"),
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="activationDate"
                    displayFormat={DateTime.DATE_SHORT}
                />
            )
        },
        {
            title: t("controllable_resources_details.activations.processing_status"),
            customCell: () => <TranslatedApiValueCell
                namespace="api:processing_status"
                value={currentActivation?.processingStatus}
            />,
        },
        {
            title: t("controllable_resources_details.activations.audit_logs"),
            customCell: () => <td>
                <Button
                    color="secondary"
                    onClick={() => setIsAuditDialogOpen(true)}
                >
                    {t('controllable_resources_details.activations.view_logs')}
                </Button>
            </td>
        },
    ], [t, currentActivation])

    useEffect(() => () => {
        dispatch(resetReceivedMessages())
        dispatch(resetSentMessages())
    }, [dispatch])

    if (currentActivation === undefined) return <LoadingComponent/>

    return (
        <Box display="flex" height={`calc(100vh - ${headerHeight + 50}px)`} width="100%" flexDirection="column">
            <Navigation
                allElements={allActivations ?? []}
                currentElement={currentActivation}
                elementKey="id"
                onNextClick={e => {
                    history.push(`/${controlGroup ? "controlGroups" : "controllable"}/${controllableResource?.externalID}/activations/${e.id}`)
                }}
                onPreviousClick={e => {
                    history.push(`/${controlGroup ? "controlGroups" : "controllable"}/${controllableResource?.externalID}/activations/${e.id}`)
                }}
            />
            <GridField
                noRecordsText={t("no_records_text")}
                pageable={false}
                gridFields={getGridFields}
                defaultOpen
                take={10}
                values={[currentActivation]}
            />
            <Toolbar onToggleView={setCurrentlyViewing}/>
            <Box height="65%" width="100%">
                {/* eslint-disable-next-line no-nested-ternary */}
                {((direction === "up" && currentActivation.upTimeSeries === undefined) ||
                    (direction === "down" && currentActivation.upTimeSeries === undefined) ||
                    (direction === "avat" && currentActivation.avatTimeSeriesValue === undefined) ||
                    (direction === "delta" && currentActivation.deltaTimeSeriesValue === undefined) ||
                    (direction === "absolute" && currentActivation.absoluteTimeSeriesValue === undefined)) ?
                    <LoadingComponent/> : currentlyViewing === "grid" ? <Grid/> : <Chart/>
                }
            </Box>
            {isAuditDialogOpen &&
                <AuditLogDialog onClose={() => setIsAuditDialogOpen(false)}/>
            }
        </Box>
    )

}

export default DataView;
