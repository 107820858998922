import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IExcelImportResult, IValidationError } from "./store/excelImportSlice";

interface IExcelImportResultProps {
    result: IExcelImportResult
}

const ExcelImportResult: React.FC<IExcelImportResultProps> = ({ result }) => {
    const { t } = useTranslation("excelImport");

    const formatMessage = useCallback((error: IValidationError) => {
        const messageTemplate = t(`validationErrors.messageTemplates.${error.code}`);
        return messageTemplate.replace('{PropertyName}', error.excelValueName).replaceAll(/\{([^}]+)\}/g, x => error.values[x.substr(1, x.length - 2)]);
    }, [t]);

    return (
        <Box>
            {result.validationErrors && result.validationErrors.length > 0 && <>Validation errors:
                <table>
                    <thead>
                    <tr>
                        <td>{t("validationErrors.columns.propertyName")}</td>
                        <td>{t("validationErrors.columns.excelName")}</td>
                        <td>{t("validationErrors.columns.message")}</td>
                        <td>{t("validationErrors.columns.attemptedValue")}</td>
                    </tr>
                    </thead>
                    <tbody>

                    {result.validationErrors.map((x, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={idx}>
                            <td>{x.propertyName}</td>
                            <td>{x.excelValueName}</td>
                            <td>{formatMessage(x)}</td>
                            <td>{JSON.stringify(x.attemptedValue)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>}
        </Box>
    );
}

export default ExcelImportResult;
