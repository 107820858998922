/**
 *
 * @export
 * @interface MarketPartnerEncoding
 */
export interface MarketPartnerEncoding {
    /**
     *
     * @type {string}
     * @memberof MarketPartnerEncoding
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof MarketPartnerEncoding
     */
    name?: string;
}

export const allowedCodes = ["A10", "NDE"];
