import React, { ReactElement, useEffect } from "react";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { DateTime } from "luxon";
import { GridField, GridFieldActionsCell, GridFieldElement, TranslatedApiValueCell } from "ndr-designsystem";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { allCostInfoSelector, setAllCostInfo } from "../../../../costInfo/store/store";
import DateCell from "../../../../../Components/DateCell";
import { deleteCostInfo, fetchCostInfo } from "../../../../costInfo/store/thunks";
import { setAddNewCostInfo } from "../../../../appStateSlice";
import { ValidationResult } from "../../../../../utils/types";
import { toDto as toCostInfoDto } from "../../../../../api/dtos/CostInfo/CostInfo";
import ConfigurationToolbar, { ConfigurationProps } from "../../../../../Components/ConfigurationToolbar";

const CostInfoTable = ({ currentlyViewing, onViewChange }: ConfigurationProps): ReactElement => {
    const allCostInfo = useAppSelector(allCostInfoSelector, (left, right) => isEqual(left, right))
    const { currentResource } = useAppSelector(state => state.controllableResources)
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables")

    useEffect(() => {
        if (allCostInfo === undefined && currentResource != null) {
            dispatch(fetchCostInfo(currentResource.inventoryItemId!))
        }
    }, [allCostInfo, currentResource, dispatch])

    const remove = (index: number): void => {
        const dataItem = allCostInfo![index]
        const newElements = [...allCostInfo!]
        newElements.splice(index, 1);

        dispatch(deleteCostInfo({
            id: currentResource!.inventoryItemId ?? "",
            costInfoId: dataItem.id!,
        })).then(() => dispatch(setAllCostInfo(newElements.map(sens => toCostInfoDto(sens)))));
    }

    const edit = (index: number): void => {
        dispatch(setAddNewCostInfo({
            show: true,
            id: currentResource!.inventoryItemId!,
            data: toCostInfoDto(allCostInfo![index]),
        }))
    }

    const CostInfoActions = (props: GridCellProps): ReactElement => {
        const isElementValid: ValidationResult = {
            valid: true,
            error: null
        };

        return <GridFieldActionsCell {...props}
                                     inEdit={false}
                                     validationResult={isElementValid}
                                     edit={edit}
                                     remove={remove}
        />;
    }

    const gridFields = (): GridFieldElement[] => [
        {
            title: t("controllable_resources_details.cost_info.business_type"),
            name: "business_type",
            customCell: ({ dataItem }: GridCellProps) =>
                <TranslatedApiValueCell
                    namespace="api:cost_info_business_type"
                    value={dataItem.businessType?.code}
                />
        },
        {
            title: t("controllable_resources_details.cost_info.status"),
            name: "status",
            customCell: ({ dataItem }: GridCellProps) =>
                <TranslatedApiValueCell
                    namespace="api:cost_info_status"
                    value={dataItem.status?.code}
                />
        },
        {
            title: t("controllable_resources_details.cost_info.direction"),
            name: "direction",
            customCell: ({ dataItem }: GridCellProps) =>
                <TranslatedApiValueCell
                    namespace="api:cost_info.direction"
                    value={dataItem.direction?.code}
                />
        },
        {
            title: t("controllable_resources_details.cost_info.from"),
            name: "from",
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="from"
                    displayFormat={DateTime.DATE_SHORT}
                />
            )
        }, {
            title: t("controllable_resources_details.cost_info.to"),
            name: "to",
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="to"
                    displayFormat={DateTime.DATE_SHORT}
                />
            )
        },
        {
            title: t("controllable_resources_details.cost_info.value"),
            name: "unit",
            customCell: ({ dataItem }: GridCellProps) =>
                <TranslatedApiValueCell
                    value={dataItem.value}
                />
        },
    ]


    return <GridField
        defaultOpen
        gridFields={gridFields()}
        noRecordsText={t('no_records_text')}
        isEditing={allCostInfo !== undefined}
        customToolbar={<ConfigurationToolbar
            onAddClick={() => {
                dispatch(setAddNewCostInfo({
                    id: currentResource?.inventoryItemId ?? "",
                    data: undefined,
                    show: true
                }))
            }}
            currentlyViewing={currentlyViewing}
            onViewChange={onViewChange}/>}
        values={allCostInfo}
        commandsColumn={<GridColumn cell={CostInfoActions}/>}
    />
}

export default CostInfoTable
