/**
 *
 * @export
 * @interface VoltageTransformation
 */
export interface VoltageTransformation {
    /**
     *
     * @type {string}
     * @memberof VoltageTransformation
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof VoltageTransformation
     */
    name?: string;
}

export const allowedCodes = ["Z01", "Z02", "Z03"];
