import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        boxWithoutMarker: {
            listStyle: 'none',
        }
    }),
);

export default useStyles;
