import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { RootState } from "../../../../app/store";
import { removeNulls } from "../../../../utils";
import Api from "../../../../api/Api";
import { setActivationTimeSeries, setAllActivations, setCurrentActivation, setDirection } from "./store";
import { fetchAllControlGroupsAndResources } from "../../../appStateSlice";
import { fetchControllableResourceByIdAction } from "../../../controllableResources/store/thunks";
import { ControllableResource } from "../../../../api/fixed/ControllableResource/ControllableResource";
import { ControlGroup } from "../../../../api/fixed/ControlGroup/ControlGroup";
import { fetchControlGroupById } from "../../../ControlGroups/store/thunks";
import ActivationDto from "../../../../api/dtos/Activation/ActivationDto";
import ActivationDetailsDto from "../../../../api/dtos/Activation/ActivationDetailsDto";
import PeriodIntervalDto from "../../../../api/dtos/Activation/PeriodIntervalDto";

export const fetchActivations = createAsyncThunk(
    "activations/fetchByType",
    async ({ id, force, type }: { id: string, force: boolean, type: "resource" | "group" }, { getState, dispatch }) => {
        let response: ActivationDto[] | undefined;
        const state = getState() as RootState;

        response = state.activations.allActivations;

        if (response === undefined || response.length === 0 || force) {
            response = orderBy((await Api.fetchActivations(id, type)).data.map(un => removeNulls(un)), ['receivedAt', 'activationDate'], ['desc', 'desc']);
        }

        await dispatch(setAllActivations(response));
    }
)

export const fetchActivationById = createAsyncThunk(
    "activations/fetchById",
    async ({
               seriesId,
               force,
           }: { seriesId: string, force: boolean }, {
               getState,
               dispatch
           }) => {
        const state = getState() as RootState;
        let response: ActivationDetailsDto | undefined = state.activations.currentActivation;

        if (response === undefined || force) {
            response = removeNulls((await Api.fetchActivationById(seriesId)).data);
        }

        await dispatch(setCurrentActivation(response));
    }
)

export const fetchExtraTimeseries = createAsyncThunk(
    "activations/fetchExtraTimeseries",
    async ({
               type,
               seriesId,
               force,
           }: { seriesId: string, force: boolean, type: "avat" | "delta" | "absolute" }, {
               getState,
               dispatch,
               rejectWithValue
           }) => {
        const state = getState() as RootState;
        let response: PeriodIntervalDto[] | undefined;

        if (state.activations.currentActivation === undefined) {
            rejectWithValue("Called before having an activation")
            return;
        }

        switch (type) {
            case "avat":
                response = state.activations.currentActivation.avatTimeSeriesValue;
                break;
            case "delta":
                response = state.activations.currentActivation.deltaTimeSeriesValue;
                break;
            case "absolute":
                response = state.activations.currentActivation.absoluteTimeSeriesValue;
                break;
            default:
                response = undefined;
        }

        if (response === undefined || force) {
            response = removeNulls((await Api.fetchActivationTimeSeries(state.activations.currentActivation.id, seriesId)).data);
        }

        await dispatch(setActivationTimeSeries({ type, data: response }));
    }
)

export const resetActivationsState = createAsyncThunk(
    "activations/resetState",
    async (_: never, { dispatch }) => {
        await dispatch(setDirection(undefined))
        await dispatch(setCurrentActivation(undefined))
    })

export const buildActivationsState = createAsyncThunk(
    "activations/buildState",
    async ({
               inventoryId,
               seriesId,
               force,
               type
           }: { inventoryId: string, seriesId: string, force: boolean, type: "resource" | "group" }, {
               dispatch,
               getState
           }) => {
        await dispatch(fetchAllControlGroupsAndResources({
            force,
            fetchResources: type === "resource",
            fetchGroups: type === "group"
        }))
        let item: ControllableResource | ControlGroup | undefined;

        if (type === "resource") {
            item = await dispatch(fetchControllableResourceByIdAction({
                id: inventoryId,
                force,
                setAsCurrent: true
            })).unwrap();
        } else {
            item = await dispatch(fetchControlGroupById({
                id: inventoryId,
                force,
                setAsCurrent: true
            })).unwrap();
        }

        if (item && item.inventoryItemId) {
            await dispatch(fetchActivations({ id: item.inventoryItemId, force, type }));
            await dispatch(fetchActivationById({
                seriesId,
                force,
            }));
            const state = getState() as RootState
            if (state.activations.currentActivation) {
                if (state.activations.currentActivation.upTimeSeries) {
                    dispatch(setDirection("up"))
                } else if (state.activations.currentActivation.downTimeSeries) {
                    dispatch(setDirection("down"))
                } else if (state.activations.currentActivation.avatTimeSeries) {
                    dispatch(setDirection("avat"))
                } else if (state.activations.currentActivation.deltaTimeSeries) {
                    dispatch(setDirection("delta"))
                } else if (state.activations.currentActivation.absoluteTimeSeries) {
                    dispatch(setDirection("absolute"))
                }
            }
        }
    }
)
