import { createDateTime } from "ndr-designsystem";
import SentMessageStatus from "../../fixed/ConnectPlusMessage/SentMessageStatus";
import CodeNameProp from "../../fixed/CodeNameProp";
import ConnectPlusDispatch from "../../fixed/Activations/ConnectPlusDispatch";

export default interface ConnectPlusDispatchDto {
    dispatchId: string
    status: SentMessageStatus
    timeSeriesStatus: CodeNameProp
    createdAt: string
}

export const fromDto = (dto: ConnectPlusDispatchDto): ConnectPlusDispatch => ({ ...dto, createdAt: createDateTime(dto.createdAt) })