/**
 *
 * @export
 * @interface VoltageLevel
 */
export interface VoltageLevel {
    /**
     *
     * @type {string}
     * @memberof VoltageLevel
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof VoltageLevel
     */
    name?: string;
}

export const allowedCodes = ["Z01", "Z02", "Z03", "Z04"];
