import React, { ReactElement, useMemo } from "react";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridField } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import DateCell from "../../../Components/DateCell";
import { currentUnavailabilitySelector } from "./store/store";

const TimeSeriesGrid = (): ReactElement => {
    const timeSeriesInfo = useAppSelector(currentUnavailabilitySelector, (left, right) => left?.internalID === right?.internalID);
    const internationalization = useInternationalization();
    const { t } = useTranslation("tables")

    const getGridFields = useMemo(() => ([
        {
            name: "timestamp",
            title: t("timeSeries.timestamp"),
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="timestamp"
                displayFormat={DateTime.TIME_SIMPLE}
            />
        },
        {
            name: "value",
            title: t("timeSeries.value"),
            customCell: (props) => <td>{
                `${internationalization.formatNumber(props.dataItem.value, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })} MW`
            }</td>
        }
        // we want to re-render cells only on internationalization change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [internationalization]);

    return <GridField
        className="timeSeries_2"
        noRecordsText={t("no_records_text")}
        gridFields={getGridFields}
        defaultOpen
        take={10}
        dataItemKey="timestamp"
        values={timeSeriesInfo?.values}
    />
}

export default TimeSeriesGrid;
