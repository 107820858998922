import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import CostInfoDto, { fromDto as fromCostInfoDto } from "../../../api/dtos/CostInfo/CostInfo";
import CostInfo from "../../../api/fixed/CostInfo/CostInfo";
import { RootState } from "../../../app/store";

export interface ActivationsState {
    allCostInfo: CostInfoDto[] | undefined
}


const initialState: ActivationsState = {
    allCostInfo: undefined
};

export const costInfoSlice = createSlice({
    name: 'costInfo',
    initialState,
    reducers: {
        setAllCostInfo: (state, action: PayloadAction<CostInfoDto[] | undefined>) => {
            state.allCostInfo = action.payload;
        },
        addNewCostInfo: (state, action: PayloadAction<CostInfoDto>) => {
            if (action.payload !== null) {
                if (state.allCostInfo !== undefined) {
                    state.allCostInfo.push(action.payload);
                } else {
                    state.allCostInfo = [action.payload]
                }
            }
        }
    }
});

export const allCostInfoSelector = (state: RootState): CostInfo[] | undefined => {
    if (state.costInfo.allCostInfo) {
        return orderBy(state.costInfo.allCostInfo.map(a => fromCostInfoDto(a)), ['startDate', 'endDate'], ['desc', 'desc'])
    }
    return state.costInfo.allCostInfo;
};

export const {
    setAllCostInfo,
    addNewCostInfo
} = costInfoSlice.actions;

export default costInfoSlice.reducer;
