import React, { ReactElement, useMemo } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { DateTime } from "luxon";
import { createDateTime, toLocaleDate } from "ndr-designsystem";
import { useAppSelector } from "../../../app/hooks";
import CustomChart from "../../../Components/Chart";
import { currentUnavailabilitySelector } from "./store/store";

const TimeSeriesChart = (): ReactElement => {
    const timeSeriesInfo = useAppSelector(currentUnavailabilitySelector, (left, right) => left?.internalID === right?.internalID);
    const internationalization = useInternationalization();

    const chartData = useMemo(() => {
        if (timeSeriesInfo) {
            const values = timeSeriesInfo.values.map(v => {
                const newDate = createDateTime(v.timestamp);
                return { x: newDate, y: v.value };
            });

            return {
                labels: values.map(t => t.x),
                datasets: [{
                    label: "Value",
                    data: values.map(t => t.y),
                    backgroundColor: "#ef870f",
                    borderColor: "#e8cab9"
                }]
            };
        }
        return { labels: [""], datasets: [] };
    }, [timeSeriesInfo]);

    const getChartPlugins = useMemo(() => ({
        tooltip: {
            callbacks: {
                label: (item: any) => `${item.formattedValue} MW`,
                title: (items: any) => toLocaleDate(items[0].parsed.x, internationalization.locale, DateTime.DATETIME_SHORT)
            },
        },
        legend: {
            display: false
        },
    }), [internationalization])

    const getChartScales = useMemo(() => ({
        y: {
            ticks: {
                callback: (value: number) => `${internationalization.formatNumber(value, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })} MW`
            },
            offset: true
        },
        x: {
            adapters: {
                date: {
                    zone: "Europe/Berlin"
                }
            },
            type: "time",
            time: {
                unit: "minute",
                ticks: {
                    source: "data",
                },
                stepSize: 15,
                displayFormats: {
                    hour: internationalization.locale === "de" ? "HH" : "h a",
                    minute: internationalization.locale === "de" ? "HH:mm" : "h:mm a",
                    second: internationalization.locale === "de" ? "HH:mm:ss" : "h:mm:ss a"
                }
            },
            offset: true
        },
    }), [internationalization])

    return <CustomChart
        maintainAspectRatio={false}
        locale={internationalization.locale}
        plugins={getChartPlugins}
        scales={getChartScales}
        data={chartData}
        zoom={false}
    />
}

export default TimeSeriesChart;
