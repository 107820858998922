import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import ConnectPlusMessage from "../../../api/fixed/ConnectPlusMessage/ConnectPlusMessage";
import SentMessageStatus from "../../../api/fixed/ConnectPlusMessage/SentMessageStatus";
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";
import ControllableResourceStatus from "../../../api/fixed/ControllableResource/ControllableResourceStatus";
import { fetchAllControlGroupsAndResources, setCurrentPage } from "../../appStateSlice";
import Task from "../../../api/fixed/Task/Task";
import TaskStatus from "../../../api/fixed/Task/TaskStatus";
import { fromDto as fromTaskDto } from "../../../api/dtos/Task/TaskDto";
import AvatError from "../../../api/fixed/AvatError/AvatError";
import { fromDto as fromAvatErrorDto } from "../../../api/dtos/AvatError/AvatErrorDto";
import { fetchAllAvatErrors } from "../../avatErrors/store";

interface ResourcesComparisonResult {
    total: number
    accepted: ControllableResource[],
    rejected: ControllableResource[],
    unsent: ControllableResource[]
}

interface TasksOverviewResult {
    total: number
    success: Task[]
    failed: Task[]
}

export const rejectedMessagesSelector = (state: RootState): ConnectPlusMessage[] | undefined => {
    if (state.connectPlusMessages.sentMessages) {
        return state.connectPlusMessages.sentMessages.filter(message => message.state === SentMessageStatus.MessageRejected);
    }
    return state.connectPlusMessages.sentMessages;
};

export const resourcesAttentionSelector = (state: RootState): ControllableResource[] | undefined => {
    if (state.controllableResources.allResources) {
        return state.controllableResources.allResources.filter(res => !res.status || res.status === ControllableResourceStatus.Unknown ||
            res.status === ControllableResourceStatus.Created || res.status === ControllableResourceStatus.Received);
    }
    return state.controllableResources.allResources;
};

export const avatErrorsSelector = (state: RootState): AvatError[] | undefined => {
    if (state.avatErrors.allErrors) {
        return state.avatErrors.allErrors.filter(e => !e.resolved).map(e => fromAvatErrorDto(e));
    }
    return state.avatErrors.allErrors
}

export const resourcesComparisonSelector = (state: RootState): ResourcesComparisonResult => {
    const response: ResourcesComparisonResult = {
        accepted: [],
        rejected: [],
        unsent: [],
        total: 0
    }

    if (state.controllableResources.allResources) {
        response.accepted = state.controllableResources.allResources.filter(res => res.status === ControllableResourceStatus.Accepted);
        response.rejected = state.controllableResources.allResources.filter(res => res.status === ControllableResourceStatus.Rejected);
        response.unsent = state.controllableResources.allResources.filter(res => res.status === ControllableResourceStatus.Received || res.status === ControllableResourceStatus.Updated);
        response.total = response.accepted.length + response.rejected.length + response.unsent.length;
    }

    return response;
};

export const tasksOverviewSelector = (state: RootState): TasksOverviewResult | undefined => {
    const response: TasksOverviewResult = {
        total: 0,
        success: [],
        failed: []
    }
    if (state.tasks.allTasks === undefined) return undefined;

    if (state.tasks.allTasks) {
        response.success = state.tasks.allTasks.filter(t => t.status === TaskStatus.Succeeded).map(t => fromTaskDto(t));
        response.failed = state.tasks.allTasks.filter(t => t.status === TaskStatus.Failed).map(t => fromTaskDto(t));
    }

    response.total = response.success.length + response.failed.length
    return response;
}

export const buildDashboardState = createAsyncThunk("dashboard/buildState",
    async ({ force }: { force: boolean }, { dispatch }): Promise<void> => {
        // const state = getState() as RootState;

        await dispatch(fetchAllControlGroupsAndResources({ force, fetchResources: true, fetchGroups: false }));
        await dispatch(setCurrentPage("Dashboard"));
        await dispatch(fetchAllAvatErrors(force));
        // if (!state.connectPlusMessages.sentMessages) {
        //     await dispatch(fetchAllSentMessages());
        // }
        // if (!state.connectPlusMessages.receivedMessages) {
        //     await dispatch(fetchAllReceivedMessages());
        // }
    })
