import React, { ReactElement, ReactNode } from "react";
import { GridCellProps, GridDetailRowProps } from "@progress/kendo-react-grid";
import { createDateTime, GridField, toLocaleDate, TranslatedApiValueCell } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import { keys } from "lodash";
import { Switch } from "@mui/material";
import { DateTime } from "luxon";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { keys as ignoredKeys } from "../../../api/fixed/Task/Task";
import { useAppSelector } from "../../../app/hooks";

const GeneralDetailsView = ({ dataItem }: GridDetailRowProps): ReactElement => {
    const { t, i18n } = useTranslation("tables");
    const { allResources } = useAppSelector(state => state.controllableResources)
    const { allGroups } = useAppSelector(state => state.controlGroups)

    const renderValue = (value: any, title: string): ReactNode => {
        if (value === "true" || value === "false" || value === true || value === false) {
            return <Switch disabled checked={value === "true" || value === true}/>
        }
        const date = createDateTime(value)
        if (date.isValid) {
            return toLocaleDate(date, i18n.language, DateTime.DATETIME_MED_WITH_SECONDS)
        }

        if (title === "controllableResourceId") {
            const resource = allResources?.find(r => r.inventoryItemId === value);
            if (resource)
                return resource.externalID
        }

        if (title === "controlGroupId") {
            const group = allGroups?.find(r => r.inventoryItemId === value);
            if (group)
                return group.externalID
        }

        return value;
    }

    const gridFields = [{
        title: "Title",
        name: "title",
        customCell: ({ dataItem: item }) => <TranslatedApiValueCell
            value={item.title}
            namespace="api:tasks"
        />,
    }, {
        title: "Value",
        name: "value",
        customCell: (props: GridCellProps) => <td>
            {renderValue(props.dataItem.value, props.dataItem.title)}
        </td>
    }] as GridFieldElement[]

    const extendedIgnoredKeys = [...ignoredKeys, "showDetails"]

    const otherKeys = keys(dataItem).filter(k => !extendedIgnoredKeys.includes(k))

    return <GridField
        defaultOpen
        gridFields={gridFields}
        noRecordsText={t('no_records_text')}
        values={otherKeys.filter(v => dataItem[v] !== undefined).map(v => ({ title: v, value: dataItem[v] }))}
    />
}

export default GeneralDetailsView
