import { GridCellProps } from "@progress/kendo-react-grid";
import { useInternationalization } from "@progress/kendo-react-intl";
import { DateTimeFormatOptions } from "luxon";
import React, { ReactElement } from "react";
import { toLocaleDate } from "ndr-designsystem";
import EmptyWhileEditingCell from "./EmptyWhileEditingGridCell";

export interface DateCellProps extends GridCellProps {
    fieldPath: string
    displayFormat: DateTimeFormatOptions
}

const DateCell = (props: DateCellProps): ReactElement => {
    const { dataItem, displayFormat, fieldPath } = props;
    const internationalization = useInternationalization();
    return (
        <EmptyWhileEditingCell
            {...props}
            isEditing={false}
            defaultValue={dataItem && toLocaleDate(dataItem[fieldPath], internationalization.locale, displayFormat)}
        />
    );
};

export default DateCell;