import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom"
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from "react-redux";
import { loadMessages } from "@progress/kendo-react-intl";
import App from './App';
import '@progress/kendo-theme-material/dist/all.css';
import './i18n';
import Theme from "./app/theme";
import { store } from "./app/store";
import Config from "./app-config";
import messagesDE from "./locales/messagesDE.json";

loadMessages(messagesDE, "de")

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={Theme}>
            <Router basename={Config.basename}>
                <Provider store={store}>
                    <App/>
                </Provider>
            </Router>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

