import { Autocomplete, Box, Card, CardActionArea, CardContent, Divider, TextField, Typography } from "@mui/material";
import { GroupWork } from "@mui/icons-material";
import React, { ReactElement, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

const ControlGroupsCard = (): ReactElement => {
    const { allGroups } = useAppSelector((state: RootState) => state.controlGroups)
    const history = useHistory();
    const { t } = useTranslation("common");

    const preparedGroups = useMemo(() => allGroups?.map(gr => ({
        label: gr.name && gr.name.length > 0 ? gr.name : gr.externalID,
        id: gr.externalID
    })) ?? [], [allGroups])

    return <Box display="flex" justifyContent="center" height="100%" width="100%">
        <Card sx={{ textAlign: "center", display: "flex", flexDirection: "column", flexGrow: 1, pb: 1 }}>
            <CardActionArea sx={{ width: "100%" }}
                            onClick={() => history.push("/controlGroups")}>
                <GroupWork sx={{ fontSize: 50, pt: 2 }}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {t('home.control_groups_list')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('home.control_groups_list_details')}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <Box paddingX={2} paddingBottom={2}>
                <Divider flexItem sx={{ marginBottom: 1 }}/>
                <Autocomplete
                    openOnFocus
                    onChange={(event, newValue) => {
                        if (newValue) {
                            history.push(`/controlGroups/${newValue.id}`)
                        }
                    }}
                    options={preparedGroups}
                    renderInput={(params) => <TextField {...params} label="ID"/>}
                />
            </Box>
        </Card>
    </Box>
}

export default ControlGroupsCard
