import React, { ReactElement } from "react";
import { ListItem, ListItemText } from "@mui/material";

interface TimeSeriesListItemProps {
    businessType: string;
    direction: string | undefined;
    gridElementCode: string | undefined;
    onClick: () => void;
    selected: boolean
}

const TimeSeriesListItem = ({
                                onClick,
                                businessType,
                                direction,
                                gridElementCode,
                                selected
                            }: TimeSeriesListItemProps): ReactElement => (
    <ListItem button selected={selected}>
        <ListItemText primary={`${gridElementCode}`}
                      secondary={businessType ? `${businessType} - ${direction ? ` ${direction}` : ""}` : undefined}
                      onClick={onClick}
                      tabIndex={-1}/>

    </ListItem>
);

export default TimeSeriesListItem;
