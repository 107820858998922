import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        formControl: {
            width: "100%",
            marginTop: 5
        },
        addNewRightButton: {
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
    }),
);

export default useStyles;