import React, { ReactElement, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { CustomEndAdornment, Field, GeneralSimpleElementTable, isFieldValid, isSchemaValid } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import {
    setControllabilityType,
    setFixation,
    setLevels,
    setMaxSteps,
    setMinimumMinutesRequiredBeforeActivation,
    setMinSteps,
    setStepLength,
    setUnit,
    swapLevels,
    updateNewLevel
} from "./store/controlGroupsSlice";
import { allowedCodes as FixationAllowedCodes, } from "../../api/fixed/Fixation";
import {
    controlGroupsAllowedCodes as ControllabilityTypeAllowedCodes,
    ControllabilityType
} from "../../api/fixed/ControllableResource/ControllabilityType";
import { allowedControlGroupCodes as UnitAllowedCodes } from "../../api/fixed/Unit";
import { CGControllabilityTabSchema, ParameterWithUnitSchema } from "../../utils/inputChecking";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { FieldWithGridProps, PageStatus } from "./types";
import SchemaConstants from "../../utils/schemaConsts";
import { setNotificationAlertMessage } from "../appStateSlice";
import { transformToSelectEntry } from "../../utils";

const ControllabilitySubGrid = ({ pageStatus }: FieldWithGridProps): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { t } = useTranslation("tables")

    const { currentGroup } = useSelector((state: RootState) => state.controlGroups);
    const dispatch = useAppDispatch();

    const getLevelsTableActions = useCallback(() => ({
        setElements: (e: number[] | undefined) => dispatch(setLevels(e)),
        updateNewElement: (e: number) => dispatch(updateNewLevel(e)),
        swapElements: (startIndex: number, endIndex: number) => dispatch(swapLevels({ startIndex, endIndex }))
    }), [dispatch]);

    const unitAllowedCodes = useMemo(() => transformToSelectEntry(UnitAllowedCodes, t, "api:unit"), [t])
    const fixationAllowedCodes = useMemo(() => transformToSelectEntry(FixationAllowedCodes, t, "api:fixation"), [t])
    const controllabilityTypeAllowedValues = useMemo(() => transformToSelectEntry(ControllabilityTypeAllowedCodes, t, "api:controllability_type"), [t])

    if (currentGroup === undefined || currentGroup === null) {
        return <></>;
    }

    const ControllabilitySchema = CGControllabilityTabSchema();
    const LevelSchema = ParameterWithUnitSchema(currentGroup.unit);

    return (
        <>
            <Field
                onValueChange={val => dispatch(setFixation(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={fixation => isFieldValid(ControllabilitySchema, [SchemaConstants.fixation], { fixation })}
                defaultValue={fixationAllowedCodes.find(f => f.code === currentGroup.fixation?.code)}
                title={t("controllable_resources_details.fixation")}
                values={fixationAllowedCodes}
                keyField="code"
                textField="name"
                inputType='select'
            />
            <Field
                onValueChange={val => dispatch(setControllabilityType(Number.parseInt(val?.code ?? "", 10)))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={controllabilityType => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType], { controllabilityType: controllabilityType?.code })}
                defaultValue={{
                    code: `${currentGroup.controllabilityType}`,
                    name: controllabilityTypeAllowedValues.find(r => r.code === `${currentGroup.controllabilityType}`)?.name
                }}
                title={t("controllable_resources_details.controllabilityType")}
                values={controllabilityTypeAllowedValues}
                keyField="code"
                textField="name"
                inputType='select'
            />
            <Field
                onValueChange={val => dispatch(setUnit(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={unit => isFieldValid(ControllabilitySchema, [SchemaConstants.unit], { unit })}
                title={t("controllable_resources_details.unit")}
                values={unitAllowedCodes}
                defaultValue={unitAllowedCodes.find(f => f.code === currentGroup.unit?.code)}
                keyField="code"
                textField="name"
                inputType='select'
            />
            {ControllabilitySchema.pick([SchemaConstants.unit]).isValidSync({ unit: currentGroup.unit }) &&
                <>
                    {currentGroup.controllabilityType === ControllabilityType.LEVELS &&
                        <GeneralSimpleElementTable
                            canReorder
                            noRecordsText={t("no_records_text")}
                            onMoreThanOneError={() => {
                                dispatch(setNotificationAlertMessage("alert_messages.one_by_one"))
                            }}
                            addButtonText={t('toolbar_buttons.add')}
                            maxFractionDigits={3}
                            type="number"
                            title={t("controllable_resources_details.levels")}
                            description={t("controllable_resources_details.levelsDescription")}
                            pageStatus={pageStatus}
                            defaultOpen
                            actions={getLevelsTableActions()}
                            defaultElement={undefined}
                            elements={currentGroup.levels}
                            valueCheckFunction={val => isSchemaValid(LevelSchema, val)}
                            gridCheckFunction={levels => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType, SchemaConstants.unit, SchemaConstants.levels],
                                {
                                    controllabilityType: currentGroup.controllabilityType,
                                    unit: currentGroup.unit,
                                    levels
                                })}
                        />
                    }
                    {currentGroup.controllabilityType === ControllabilityType.STEPS &&
                        <>
                            <Field
                                maxFractionDigits={3}
                                isEditing={isEditing || isAdding}
                                defaultValue={currentGroup.stepLength}
                                onValueChange={val => dispatch(setStepLength(val))}
                                valueCheckFunction={stepLength => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType, SchemaConstants.unit, SchemaConstants.stepLength], {
                                    controllabilityType: currentGroup.controllabilityType,
                                    unit: currentGroup.unit,
                                    stepLength
                                })}
                                title={t("controllable_resources_details.stepLength")}
                                description={t("controllable_resources_details.stepLengthDescription")}
                                inputType="number"
                            />
                            <Field
                                maxFractionDigits={3}
                                isEditing={isEditing || isAdding}
                                defaultValue={currentGroup.minSteps}
                                onValueChange={val => dispatch(setMinSteps(val))}
                                valueCheckFunction={minSteps => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType, SchemaConstants.unit, SchemaConstants.minSteps], {
                                    controllabilityType: currentGroup.controllabilityType,
                                    unit: currentGroup.unit,
                                    minSteps
                                })}
                                inputType="number"
                                title={t("controllable_resources_details.minSteps")}
                                description={t("controllable_resources_details.minStepsDescription")}
                            />
                            <Field
                                maxFractionDigits={3}
                                isEditing={isEditing || isAdding}
                                defaultValue={currentGroup.maxSteps}
                                onValueChange={val => dispatch(setMaxSteps(val))}
                                valueCheckFunction={maxSteps => isFieldValid(ControllabilitySchema, [SchemaConstants.controllabilityType, SchemaConstants.unit, SchemaConstants.maxSteps], {
                                    controllabilityType: currentGroup.controllabilityType,
                                    unit: currentGroup.unit,
                                    maxSteps
                                })}
                                inputType="number"
                                title={t("controllable_resources_details.maxSteps")}
                                description={t("controllable_resources_details.maxStepsDescription")}
                            />
                        </>
                    }
                </>
            }
            <Field
                maxFractionDigits={0}
                inputType="number"
                onValueChange={val => dispatch(setMinimumMinutesRequiredBeforeActivation(val))}
                isEditing={isEditing || isAdding}
                endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")} />}
                valueCheckFunction={minimumMinutesRequiredBeforeActivation =>
                    isFieldValid(ControllabilitySchema, [SchemaConstants.minimumMinutesRequiredBeforeActivation], { minimumMinutesRequiredBeforeActivation })
                }
                defaultValue={currentGroup.minimumMinutesRequiredBeforeActivation}
                title={t("control_groups_details.minimumMinutesRequiredBeforeActivation")}
                description={t("control_groups_details.minimumMinutesRequiredBeforeActivationDescription")}
            />
        </>
    )
}

export default ControllabilitySubGrid
