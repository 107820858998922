import React, { ReactElement, useState } from "react";
import { Box, DialogContent, DialogTitle } from "@mui/material";
import { ClosableDialog, CustomButton, FormattedNumberOrTextInput, isFieldValid } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import SchemaConstants from "../../utils/schemaConsts";
import { useAppSelector } from "../../app/hooks";
import { MarketLocationSchema } from "../../utils/inputChecking";

interface Props {
    type: "production" | "consumption"
    onClose: () => void
    onSuccess: (val: string) => void
}

const MarketLocationIDDialog = ({ type, onClose, onSuccess }: Props): ReactElement => {
    const { currentResource } = useAppSelector(state => state.technicalResources)
    const [value, setValue] = useState("");
    const { t } = useTranslation("tables")

    if (!currentResource) return <></>
    const MarketLocationTabSchema = MarketLocationSchema([]);
    return <ClosableDialog
        open
        contentPadding={2}
        onClose={onClose}
        dialogTitle={
            <DialogTitle
                style={{ paddingLeft: 2 }}>{t('tables:market_location_external_id.title')}
            </DialogTitle>
        }>

        <DialogContent>
            <Box display="flex" flexDirection="column" width={500}>
                <Box marginBottom={3} display="flex">
                    <FormattedNumberOrTextInput
                        maxFractionDigits={0}
                        type="controlledText"
                        fullWidth
                        InputProps={{ autoFocus: true }}
                        defaultValue={type === "production" ? currentResource.production?.externalID : currentResource.consumption?.externalID}
                        isEditing
                        valueCheckFunction={externalID => isFieldValid(MarketLocationTabSchema, [SchemaConstants.externalID], { externalID })}
                        alwaysNotify
                        onValueChange={val => {
                            setValue(val)
                        }}
                    />
                </Box>
                <CustomButton
                    disabled={!isFieldValid(MarketLocationTabSchema, [SchemaConstants.externalID], { externalID: value }).valid}
                    title={t('tables:market_location_external_id.create')}
                    onClick={() => onSuccess(value)}/>
            </Box>
        </DialogContent>
    </ClosableDialog>
}

export default MarketLocationIDDialog