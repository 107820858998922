import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import i18n from "i18next";
import { RootState } from "../../../../app/store";
import Activation from "../../../../api/fixed/Activations/Activation";
import ActivationDto, { fromDto as fromActivationDto } from "../../../../api/dtos/Activation/ActivationDto";
import ActivationDetailsDto, {
    fromDto as fromActivationDetailsDto
} from "../../../../api/dtos/Activation/ActivationDetailsDto";
import ActivationDetails from "../../../../api/fixed/Activations/ActivationDetails";
import PeriodIntervalDto from "../../../../api/dtos/Activation/PeriodIntervalDto";

export interface ActivationsState {
    allActivations: ActivationDto[] | undefined,
    currentActivation: ActivationDetailsDto | undefined,
    direction: "up" | "down" | "avat" | "delta" | "absolute" | undefined
}

export interface AuditStep {
    id?: string
    title: string,
    date: DateTime,
    success?: boolean
    attachment?: string
}

export interface AuditStepsWithDetails extends AuditStep {
    details?: AuditStepsWithDetails[]
}


const initialState: ActivationsState = {
    allActivations: undefined,
    currentActivation: undefined,
    direction: undefined
};

export const activationsSlice = createSlice({
    name: 'activationsTimeSeries',
    initialState,
    reducers: {
        setAllActivations: (state, action: PayloadAction<ActivationDto[] | undefined>) => {
            state.allActivations = action.payload;
        },
        setCurrentActivation: (state, action: PayloadAction<ActivationDetailsDto | undefined>) => {
            state.currentActivation = action.payload;
        },
        setDirection: (state, action: PayloadAction<"up" | "down" | "avat" | "absolute" | "delta" | undefined>) => {
            state.direction = action.payload;
        },
        setActivationTimeSeries: (state, action: PayloadAction<{ type: "avat" | "delta" | "absolute", data: PeriodIntervalDto[] | undefined }>) => {
            if (state.currentActivation) {
                const { type, data } = action.payload;
                switch (type) {
                    case "avat":
                        state.currentActivation.avatTimeSeriesValue = data ?? [];
                        break;
                    case "delta":
                        state.currentActivation.deltaTimeSeriesValue = data ?? [];
                        break;
                    case "absolute":
                        state.currentActivation.absoluteTimeSeriesValue = data ?? [];
                        break;
                    default:
                        break;
                }
            }

        }
    }
});

export const allActivationsSelector = (state: RootState): Activation[] | undefined => {
    if (state.activations.allActivations) {
        return state.activations.allActivations.map(a => fromActivationDto(a))
    }
    return state.activations.allActivations;
};

export const currentActivationSelector = (state: RootState): ActivationDetails | undefined => {
    if (state.activations.currentActivation) {
        return fromActivationDetailsDto(state.activations.currentActivation);
    }
    return state.activations.currentActivation;
};

export const auditStepsSelector = (state: RootState): AuditStepsWithDetails[] | undefined => {
    if (state.activations.currentActivation) {
        const activation = fromActivationDetailsDto(state.activations.currentActivation);
        const toReturn: AuditStepsWithDetails[] = [{
            title: "audit.activation_received",
            date: activation.receivedDate,
            success: true,
        }]
        activation.phoneCalls.forEach(call => {
            const wasCallSuccess = (call.events.find(ev => ev.status?.code === "confirmed") !== undefined);
            const toPush: AuditStepsWithDetails = {
                title: `audit.phone.title:${call.toNumber}`,
                date: call.requestedAt,
                success: wasCallSuccess,
                details: call.events.map(event => ({
                    title: `audit.phone.status.${event.status?.code}`,
                    date: event.timestamp,
                }))
            }
            toReturn.push(toPush)
        })

        activation.emails.forEach(mail => {
            const toPush: AuditStepsWithDetails = {
                id: mail.id,
                title: `audit.email.title:${mail.toAddress}`,
                date: mail.requestedAt,
                success: mail.status === "Sent",
                attachment: mail.attachmentName
            }
            toReturn.push(toPush)
        })

        activation.avatCalls.forEach(call => {
            toReturn.push({
                id: call.id,
                title: `${i18n.t(`tables:audit.avat_call.title`)} - ${i18n.t(`tables:audit.avat_call.status.${call.status}`)}`,
                date: call.requestedAt,
                success: call.status === 1,
            })
        })

        return toReturn;
    }

    return undefined;
}

export const {
    setAllActivations,
    setCurrentActivation,
    setDirection,
    setActivationTimeSeries
} = activationsSlice.actions;

export default activationsSlice.reducer;
