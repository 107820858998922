import Unavailability from "api/fixed/Unavailability/Unavailability";
import { createDateTime } from "ndr-designsystem";
import { UnavailabilityType } from "../../fixed/Unavailability/UnavailabilityType";
import { ProcessType } from "../../fixed/Unavailability/ProcessType";

export default interface UnavailabilityDto {
    /**
     *
     * @type {string}
     * @memberof Unavailability
     */
    internalID: string;
    /**
     *
     * @type {DateTime}
     * @memberof Unavailability
     */
    start?: string;
    /**
     *
     * @type {DateTime}
     * @memberof Unavailability
     */
    end?: string;
    /**
     *
     * @type {UnavailabilityType}
     * @memberof Unavailability
     */
    type?: UnavailabilityType;
    /**
     *
     * @type {ProcessType}
     * @memberof Unavailability
     */
    processType?: ProcessType;
}

export const fromDto = (dto: UnavailabilityDto): Unavailability => ({
    ...dto,
    start: dto.start ? createDateTime(dto.start) : undefined,
    end: dto.end ? createDateTime(dto.end) : undefined,
});
