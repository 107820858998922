import React, { ReactElement, useMemo } from "react";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridField } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { useAppSelector } from "../../../app/hooks";
import { TimeSeriesValue } from "../../../api/fixed/PlanningData/TimeSeriesValue";
import EmptyWhileEditingCell from "../../../Components/EmptyWhileEditingGridCell";
import DateCell from "../../../Components/DateCell";
import { timeSeriesInfoForCurrentGeneratedSensitivity } from "./store/store";

const TimeSeriesGrid = (): ReactElement => {
    const timeSeriesInfo = useAppSelector(timeSeriesInfoForCurrentGeneratedSensitivity, (left, right) => isEqual(left, right));
    // const { currentGeneratedSensitivity, selectedTimeSeries } = useAppSelector(state => state.generatedSensitivity)
    const internationalization = useInternationalization();
    const { t } = useTranslation("tables")

    const UnitCell = (props: GridCellProps): ReactElement => {
        const { dataItem }: { dataItem: TimeSeriesValue } = props;

        return (
            <EmptyWhileEditingCell
                {...props}
                isEditing={false}
                defaultValue={dataItem && `${internationalization.formatNumber(dataItem.value * 100, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })}%`}
            />
        );
    };

    const getGridFields = useMemo(() => ([
        {
            name: "timestamp",
            title: t("timeSeries.timestamp"),
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="timestamp"
                displayFormat={DateTime.TIME_SIMPLE}
            />
        },
        {
            name: "value",
            title: t("timeSeries.value"),
            customCell: UnitCell
        }
        // we want to re-render cells only on internationalization change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [internationalization, t]);

    return <GridField
        className="timeSeries_2"
        noRecordsText={t("no_records_text")}
        gridFields={getGridFields}
        defaultOpen
        take={10}
        dataItemKey="timestamp"
        values={timeSeriesInfo?.values}
    />
}

export default TimeSeriesGrid;
