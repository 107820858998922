import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { createSensitivity, fetchSensitivities, updateSensitivity } from "./thunks";
import SensitivityDto, { fromDto as fromSensitivityDto } from "../../../../api/dtos/Sensitivity/SensitivityDto";
import { RootState } from "../../../../app/store";
import { SensitivityData } from "../../../../api/fixed/Sensitivity/SensitivityData";

export interface SensitivitiesState {
    allSensitivities: SensitivityDto[] | undefined,
}

const initialState: SensitivitiesState = {
    allSensitivities: undefined,
};

export const sensitivitiesSlice = createSlice({
    name: "sensitivities",
    initialState,
    reducers: {
        setAllSensitivities: (state, action: PayloadAction<SensitivityDto[] | undefined>) => {
            state.allSensitivities = action.payload;
        },
    },
    extraReducers: (builder) => builder
        .addCase(fetchSensitivities.fulfilled, (state, action) => {
            state.allSensitivities = action.payload;
        })
        .addCase(createSensitivity.fulfilled, (state, action) => {
            if (action.payload !== null) {
                if (state.allSensitivities !== undefined) {
                    state.allSensitivities.push(action.payload);
                } else {
                    state.allSensitivities = [action.payload]
                }
            }
        })
        .addCase(updateSensitivity.fulfilled, (state, action) => {
            if (action.payload != null) {
                if (state.allSensitivities) {
                    const foundIndex = state.allSensitivities.findIndex(s => s.id === action.payload?.id);
                    if (foundIndex === -1) return;
                    state.allSensitivities[foundIndex] = action.payload;
                }
            }
        })
})

export const allSensitivitiesSelector = (state: RootState): SensitivityData[] | undefined => {
    if (state.sensitivities.allSensitivities) {
        return orderBy(state.sensitivities.allSensitivities.map(pd => fromSensitivityDto(pd)), ['intervalStart', 'intervalEnd'], ['desc', 'desc']);
    }
    return state.sensitivities.allSensitivities
};

export const {
    setAllSensitivities,
} = sensitivitiesSlice.actions;
export default sensitivitiesSlice.reducer
