import React, { ReactElement, useEffect, useState } from "react";
import { DialogTitle } from "@mui/material";
import { ClosableDialog, GridDataState, toLocaleDate } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    fetchSentMessagesByIds,
    resetSentMessages,
    setTotalItems,
} from "../../ConnectPlusMsgs/ConnectPlusMessagesSlice";
import ConnectPlusMessagesGrid from "../../../Components/connectPlusMessagesGrid/ConnectPlusMessagesGrid";
import { setMessageErrors } from "../../appStateSlice";
import { setSendingStatusPlanningData } from "./store/store";

const SendingStatusDialog = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { sendingStatusPlanningData } = useAppSelector((state) => state.planningData);
    const { totalItems } = useAppSelector((state) => state.connectPlusMessages);
    const sentMessages = useAppSelector(
        (state) => state.connectPlusMessages.sentMessages,
        (left, right) => isEqual(left, right),
    );
    const { t } = useTranslation("tables");
    const [dataState, setDataState] = useState<GridDataState | undefined>(undefined);
    const [data, setData] = useState<any[] | undefined>(undefined);
    const handleClose = (): void => {
        dispatch(setSendingStatusPlanningData(undefined));
        dispatch(resetSentMessages());
    };
    const internationalization = useInternationalization();

    useEffect(() => {
        const dispatchExist = sendingStatusPlanningData?.dispatches.length! > 0;

        const filteredMessages = sentMessages?.filter(
            (messages) =>
                messages.messageType === "PlannedResourceScheduleDocument" &&
                dispatchExist &&
                toLocaleDate(
                    sendingStatusPlanningData?.dispatches[0].dispatchedAt,
                    internationalization.locale,
                    DateTime.DATE_SHORT,
                ).toString() === toLocaleDate(messages.createdAt, internationalization.locale, DateTime.DATE_SHORT),
        );

        setData(filteredMessages);
    }, [sentMessages]);

    useEffect(() => {
        dispatch(resetSentMessages());
    }, [dispatch]);

    useEffect(() => {
        if (sentMessages === undefined && sendingStatusPlanningData !== undefined && dataState) {
            // Return only first page of results. Its workaround for the filtering
            dataState.skip = 0;
            dispatch(
                fetchSentMessagesByIds({
                    ids: sendingStatusPlanningData.dispatches.map((d) => d.dispatchId),
                    dataState,
                }),
            );
        }
    }, [dispatch, sendingStatusPlanningData, sentMessages, dataState]);

    return (
        <ClosableDialog
            contentPadding={2}
            dialogTitle={
                <DialogTitle style={{ textAlign: sentMessages === undefined ? "center" : "left", paddingLeft: 0 }}>
                    {t("planning_data_dialog.title")}
                </DialogTitle>
            }
            open
            onClose={handleClose}
            maxWidth="xl"
        >
            <ConnectPlusMessagesGrid
                filterable={false}
                direction="sent"
                onViewErrorsClick={(errors) => {
                    dispatch(setMessageErrors(errors));
                }}
                data={data}
                processData={false}
                totalMessages={totalItems}
                onDataStateChange={async (newState) => {
                    if (isEqual(dataState, newState)) return;

                    dispatch(resetSentMessages());

                    if (!isEqual(dataState?.filter, newState.filter)) {
                        dispatch(setTotalItems(undefined));
                    }

                    setDataState(newState);
                }}
            />
        </ClosableDialog>
    );
};

export default SendingStatusDialog;
