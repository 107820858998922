import React, { ReactElement } from "react";
import { Box, Tooltip } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import SentToConnectPlusStatus from "api/fixed/PlanningData/SentToConnectPlusStatus";

const PlanningDataSentIndicator = ({ sentToConnectPlusStatus }: { sentToConnectPlusStatus: SentToConnectPlusStatus}): ReactElement => {
    const { t } = useTranslation("tables");
    const green =  "rgba(55, 180, 0,0.32)" 
    const red = "rgba(243, 23, 0, 0.32)" ;
    const orange = "rgba(243,146,0,0.68)" ;

    let icon;
    let tooltip;

    switch (sentToConnectPlusStatus) {
        case SentToConnectPlusStatus.Pending:
            icon = <Circle style={{ color: orange }} />;
            tooltip = t("controllable_resources_details.SentToConnectPlusStatus.Pending");
            break;
        case SentToConnectPlusStatus.Successful:
            icon = <Circle style={{ color: green }} />;
            tooltip = t("controllable_resources_details.SentToConnectPlusStatus.Successful");
            break;
        case SentToConnectPlusStatus.Failed:
            icon = <Circle style={{ color: red }} />;
            tooltip = t("controllable_resources_details.SentToConnectPlusStatus.Failed");
            break;
        case SentToConnectPlusStatus.NotSent:
        default:
            icon = <Circle style={{ color: red }} />;
            tooltip = t("controllable_resources_details.SentToConnectPlusStatus.NotSent");
            break;
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="end" width="100%">
            <Tooltip title={tooltip}>{icon}</Tooltip>
        </Box>
    );
};

export default PlanningDataSentIndicator;
