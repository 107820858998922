/**
 *
 * @export
 * @enum {string}
 */


enum ControllableResourceStatus {
    Unknown = "Unknown",
    Received = "Received",
    Created = "Created",
    Confirmed = "Confirmed",
    Sent = "Sent",
    Updated = "Updated",
    SendingFailed = "SendingFailed",
    Accepted = "Accepted",
    Rejected = "Rejected",
    Enhanced = "Enhanced"
}

export const stableControllableResourceStates = [
    ControllableResourceStatus.Received,
    ControllableResourceStatus.Accepted,
    ControllableResourceStatus.Rejected,
    ControllableResourceStatus.Enhanced,
    ControllableResourceStatus.Updated,
    ControllableResourceStatus.SendingFailed,
    ControllableResourceStatus.Created,
]

export default ControllableResourceStatus;