import CodeNameProp from "../../fixed/CodeNameProp";
import PeriodIntervalDto, { fromDto as fromPeriodIntervalDto } from "./PeriodIntervalDto";
import TimeSeries from "../../fixed/Activations/TimeSeries";

export default interface TimeSeriesDto {
    businessType: CodeNameProp
    connectingArea: CodeNameProp
    measureUnit: CodeNameProp
    periodIntervals: PeriodIntervalDto[]
    reasons: CodeNameProp[]
}

export const fromDto = (dto: TimeSeriesDto): TimeSeries => ({
    ...dto,
    periodIntervals: dto.periodIntervals.map(p => fromPeriodIntervalDto(p))
})