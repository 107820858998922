import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import React, { ChangeEvent, ReactElement } from "react";
import { useLocalStorage } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { ToggleViewButton, View } from "../../ToggleViewButton";
import useStyles from "../../styles";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { TimeSeriesViewType } from "./DataView";
import { currentActivationSelector, setDirection } from "../store/store";

const Toolbar = ({ onToggleView }: { onToggleView: (view: View) => void }): ReactElement => {
    const classes = useStyles();
    const { direction } = useAppSelector(state => state.activations)
    const [currentlyViewing, setCurrentlyViewing] = useLocalStorage<TimeSeriesViewType>("timeseries.view", {
        pollingInterval: -1,
        initialValue: "chart"
    })
    const { t } = useTranslation("tables");
    const currentActivation = useAppSelector(currentActivationSelector, (left, right) => left?.id === right?.id);
    const dispatch = useAppDispatch();

    const onDirectionChange = (e: ChangeEvent): void => {
        const newValue = (e.target as HTMLInputElement).value
        // @ts-ignore we know that possible values are up and down
        dispatch(setDirection(newValue))
    }

    return <Box className={classes.toolbar}>
        <ToggleViewButton view={currentlyViewing} onToggleView={(v) => {
            setCurrentlyViewing(v);
            onToggleView(v);
        }}/>
        <FormControl component="fieldset">
            <RadioGroup value={direction ?? "down"}
                        onChange={onDirectionChange}
                        row aria-label="gender" name="row-radio-buttons-group">
                <Tooltip
                    title={currentActivation?.upTimeSeries === undefined ? `${t('timeSeries.unavailable_direction')}` : ""}>
                    <FormControlLabel disabled={currentActivation?.upTimeSeries === undefined} value="up"
                                      control={<Radio color="primary"/>}
                                      label={`${t("timeSeries.up")}`}/>
                </Tooltip>
                <Tooltip
                    title={currentActivation?.downTimeSeries === undefined ? `${t('timeSeries.unavailable_direction')}` : ""}>
                    <FormControlLabel disabled={currentActivation?.downTimeSeries === undefined} value="down"
                                      control={<Radio color="primary"/>}
                                      label={`${t("timeSeries.down")}`}/>
                </Tooltip>
                <Tooltip
                    title={currentActivation?.avatTimeSeries === undefined ? `${t('timeSeries.unavailable_direction')}` : ""}>
                    <FormControlLabel disabled={currentActivation?.avatTimeSeries === undefined} value="avat"
                                      control={<Radio color="primary"/>}
                                      label={`${t("timeSeries.avat")}`}/>
                </Tooltip>
                <Tooltip
                    title={currentActivation?.absoluteTimeSeries === undefined ? `${t('timeSeries.unavailable_direction')}` : ""}>
                    <FormControlLabel disabled={currentActivation?.absoluteTimeSeries === undefined} value="absolute"
                                      control={<Radio color="primary"/>}
                                      label={`${t("timeSeries.absolute")}`}/>
                </Tooltip>
                <Tooltip
                    title={currentActivation?.deltaTimeSeries === undefined ? `${t('timeSeries.unavailable_direction')}` : ""}>
                    <FormControlLabel disabled={currentActivation?.deltaTimeSeries === undefined} value="delta"
                                      control={<Radio color="primary"/>}
                                      label={`${t("timeSeries.delta")}`}/>
                </Tooltip>
            </RadioGroup>
        </FormControl>
    </Box>
}

export default Toolbar