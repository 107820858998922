import { useHistory } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridRowClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GridField, TranslatedApiValueCell } from "ndr-designsystem";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { setCurrentPage } from "../../appStateSlice";
import { fetchControllableResources } from "../store/thunks";
import Toolbar from "./Toolbar";
import SentIndicator from "./columns/SentIndicator";
import ValidationIndicator from "./columns/ValidationIndicator";
import EivIndicator from "./columns/EivIndicator";
import RowRender from "./RowRender";
import { setAllResources } from "../../technicalResources/store/technicalResourcesSlice";
import BooleanColumn from "../../../Components/BooleanColumn";

const ControllableResourcesList: React.FC<{}> = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation(["tables", "common"]);
    const { allResources } = useSelector((state: RootState) => state.controllableResources);

    useEffect(() => {
        dispatch(setAllResources(undefined))
    }, [dispatch])

    const onRowClick = async (event: GridRowClickEvent): Promise<void> => {
        history.push(`/controllable/${event.dataItem.externalID}`);
    }

    useEffect(() => {
        dispatch(fetchControllableResources(false));
        dispatch(setCurrentPage("ControllableResourcesList"));
    }, [dispatch]);

    const gridFields = useMemo((): GridFieldElement[] => [
        {
            customCell: (props: GridCellProps) => <SentIndicator alwaysShowText={false}
                                                                 controllableResource={props.dataItem}/>,
            // width: 50
        }, {
            customCell: ValidationIndicator
        }, {
            customCell: (props: GridCellProps) => <EivIndicator alwaysShowText={false}
                                                                controllableResource={props.dataItem}/>
        },
        {
            title: t('controllable_resources_list.status'),
            customCell: ({ dataItem }) => <TranslatedApiValueCell
                value={dataItem.status}
                namespace="tables:controllable_resources_details.states"
            />
        }, {
            title: t('controllable_resources_list.externalID'),
            name: "externalID"
        },
        {
            title: t('controllable_resources_list.name'),
            name: "name"
        }, {
            title: t('controllable_resources_list.energySource'),
            name: "energySource.name",
            customCell: ({ dataItem }: GridCellProps) =>
                <TranslatedApiValueCell
                    namespace="api:energy_source"
                    value={dataItem.energySource?.code}
                />

        }, {
            title: t('controllable_resources_list.compensationType'),
            name: "compensationType.name",
            customCell: ({ dataItem }: GridCellProps) =>
                <TranslatedApiValueCell
                    namespace="api:compensation_type"
                    value={dataItem.compensationType?.code}
                />

        }, {
            title: t('controllable_resources_list.isTolerationCase'),
            customCell: (props: GridCellProps) => <BooleanColumn {...props} path="isTolerationCase"/>
        }, {
            title: t('controllable_resources_list.accountingModel'),
            name: "accountingModel.name",
            customCell: ({ dataItem }: GridCellProps) =>
                <TranslatedApiValueCell
                    namespace="api:accounting_model"
                    value={dataItem.accountingModel?.code}
                />

        }, {
            title: t('controllable_resources_list.controlArea'),
            name: "controlArea.name",
            customCell: ({ dataItem }: GridCellProps) =>
                <TranslatedApiValueCell
                    namespace="api:control_area"
                    value={dataItem.controlArea?.code}
                />

        }, {
            title: t('controllable_resources_list.no_contained_resources'),
            name: "technicalResourceIds.length",
        }], [t])

    return (
        <GridField
            values={allResources}
            defaultOpen
            rowRender={RowRender}
            gridFields={gridFields}
            onRowClick={onRowClick}
            dataItemKey="inventoryItemId"
            customToolbar={<Toolbar/>}
            noRecordsText={t("no_records_text")}
        />
    )
}

export default ControllableResourcesList
