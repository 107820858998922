import { createDateTime } from "ndr-designsystem";
import GeneratedSensitivityDetails from "../../fixed/GeneratedSensitivity/GeneratedSensitivityDetails";
import GeneratedSensitivityTimeSeriesDto, {
    fromDto as fromGeneratedSensitivityTimeSeriesDto
} from "./GeneratedSensitivityTimeSeriesDto";

export default interface GeneratedSensitivityDetailsDto {
    date: string,
    dispatchId: string,
    dispatchedAt: string,
    status: string,
    timeSeries: GeneratedSensitivityTimeSeriesDto[]
}

export const fromDto = (dto: GeneratedSensitivityDetailsDto): GeneratedSensitivityDetails => ({
    ...dto,
    timeSeries: dto.timeSeries.map(fromGeneratedSensitivityTimeSeriesDto),
    date: createDateTime(dto.date),
    dispatchedAt: createDateTime(dto.dispatchedAt)
})
