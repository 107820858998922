import { createDateTime } from "ndr-designsystem";
import CodeNameProp from "../../fixed/CodeNameProp";
import PhoneCallEvent from "../../fixed/Activations/PhoneCallEvent";

export default interface PhoneCallEventDto {
    id: string
    detail?: string
    dtmfDigits?: string
    dtmfTimedOut?: boolean
    fromNumber: string
    status: CodeNameProp
    timestamp: string
    toNumber: string
}

export const fromDto = (dto: PhoneCallEventDto): PhoneCallEvent => ({
    ...dto,
    timestamp: createDateTime(dto.timestamp)
})