import { GridCellProps } from "@progress/kendo-react-grid";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { allowedCodes as MarketPartnerEncodingAllowedCodes } from "../../../../api/fixed/MarketPartnerEncoding";
import { transformToSelectEntry } from "../../../../utils";

const Encoding = (props: GridCellProps): ReactElement => {
    const { t } = useTranslation("tables")
    const allowedMarketPartnerEncodingValues = useMemo(() => transformToSelectEntry(MarketPartnerEncodingAllowedCodes, t, "api:market_partner_encoding"), [t])
    return <td>{allowedMarketPartnerEncodingValues.find(p => p.code === props.dataItem?.encoding?.code)?.name}</td>
}

export default Encoding