import { createDateTime } from "ndr-designsystem";
import { BusinessType } from "../../fixed/PlanningData/BusinessType";
import { Direction } from "../../fixed/PlanningData/Direction";
import { ControlArea } from "../../fixed/ControllableResource/ControlArea";
import { PlannedResourceTimeSeries } from "../../fixed/PlanningData/PlannedResourceTimeSeries";

interface TimeSeriesValueDto {
    timestamp: string;
    value: number;
}

export interface PlannedResourceTimeSeriesDto {
    // TODO: change to externalID in backend
    ExternalID: string;
    businessType: BusinessType;
    direction: Direction;
    connectingArea: ControlArea;
    startingTime?: string;
    values: Array<TimeSeriesValueDto>;
}

export const fromDto = (dto: PlannedResourceTimeSeriesDto): PlannedResourceTimeSeries => ({
    ...dto,
    startingTime: dto.startingTime ? createDateTime(dto.startingTime) : undefined,
    values: dto.values ? dto.values.map(val => ({
        value: val.value,
        timeStamp: createDateTime(val.timestamp)
    })) : undefined
});