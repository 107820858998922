import React, { ReactElement, useEffect, useMemo } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { DateTime } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import { GridField } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import DateCell from "../../../../Components/DateCell";
import { allFlexibilityConstraintsSelector } from "../../../timeseries/flexibilityConstraints/store/slice";
import { fetchFlexibilityConstraintsByResource } from "../../../timeseries/flexibilityConstraints/store/thunks";

const FlexibilityConstraintsTable = (): ReactElement => {
    const allFlexibilityConstraints = useAppSelector(allFlexibilityConstraintsSelector, (left, right) => left?.length === right?.length);
    const { currentResource } = useAppSelector((state) => state.controllableResources)
    const internationalization = useInternationalization();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables")

    useEffect(() => {
        if (allFlexibilityConstraints === undefined && currentResource != null) {
            dispatch(fetchFlexibilityConstraintsByResource({ id: currentResource.inventoryItemId!, force: false }));
        }
    }, [allFlexibilityConstraints, currentResource, dispatch])

    const gridFields = useMemo(() => [
        {
            name: "gridElementCode",
            title: t("controllable_resources_details.flexibility_constraints.grid_element_code")
        },
        {
            title: t("controllable_resources_details.flexibility_constraints.start_time"),
            customCell: (props: GridCellProps) => (
                <DateCell
                    {...props}
                    fieldPath="date"
                    displayFormat={DateTime.DATE_SHORT}
                />
            )
        },
        // we want to re-render the DateCells when locale changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [internationalization])

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            defaultOpen
            dataItemKey="internalID"
            onRowClick={event => history.push(`/controllable/${currentResource?.externalID}/network-constraints/${event.dataItem.internalID}`)}
            values={allFlexibilityConstraints}
            gridFields={gridFields}
        />
    )
}

export default FlexibilityConstraintsTable;