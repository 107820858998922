import {
    Button,
    CircularProgress,
    DialogActions,
    DialogTitle,
    FormControl,
    FormHelperText,
    TextField
} from "@mui/material";
import { ClosableDialog } from "ndr-designsystem";
import React, { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import useStyles from "./HeaderStyle";
import Api from "../../api/Api";

interface IChangePasswordForm {
    currentPassword: string,
    newPassword: string,
    newPasswordVerify: string
}

const schema = yup.object().shape({
    currentPassword: yup.string().required('validation:required'),
    newPassword: yup.string().required('validation:required'),
    newPasswordVerify: yup.string().oneOf([yup.ref('newPassword'), null], "validation:passwords_match").required('validation:required'),
})

interface Props {
    isVisible: boolean,
    onClose: () => void
}

const ChangePasswordModal: FC<Props> = ({ isVisible, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation("common");
    const [isSaving, setIsSaving] = useState(false);
    const [invalidPassword, setInvalidPassword]= useState(false);

    const { register, formState: { errors, isValid,  }, handleSubmit, reset, clearErrors } = useForm<IChangePasswordForm>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            currentPassword: "",
            newPassword: ""
        }
    });

    const onSubmit: SubmitHandler<IChangePasswordForm> = async (data) => {
        setInvalidPassword(false);
        setIsSaving(true);
        try {
            reset();
            clearErrors();
            await Api.changePassword(data);
            onClose();
        } catch (e) {
            setInvalidPassword(true);
        }
        setIsSaving(false);
    }

    const onCancel: () => void = () => {
        setInvalidPassword(false);
        reset();
        clearErrors();
        onClose();
    }

    return <ClosableDialog
        dialogTitle={
            <DialogTitle
                style={{ paddingLeft: 0 }}>{t("change_password_title")}
            </DialogTitle>
        }
        contentPadding={2}
        open={isVisible}
        onClose={onCancel}>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
            <FormControl className={classes.formControl}
                         error={errors?.currentPassword !== undefined}
            >
                <TextField
                    type="password"
                    margin="dense"
                    label={t('current_password')}
                    fullWidth
                    disabled={isSaving}
                    {...register('currentPassword')}
                />
                <FormHelperText error>{t(`${errors?.currentPassword?.message ?? ""}`) || (invalidPassword ? t('invalid_password'): '')}</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}
                         error={errors?.newPassword !== undefined}
            >
                <TextField
                    type="password"
                    margin="dense"
                    label={t('new_password')}
                    fullWidth
                    disabled={isSaving}
                    {...register('newPassword')}
                />
                <FormHelperText>{t(`${errors?.newPassword?.message ?? ""}`)}</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}
                         error={errors?.newPasswordVerify !== undefined}
            >
                <TextField
                    type="password"
                    margin="dense"
                    label={t('new_password')}
                    fullWidth
                    disabled={isSaving}
                    {...register('newPasswordVerify')}
                />
                <FormHelperText>{t(`${errors?.newPasswordVerify?.message ?? ""}`)}</FormHelperText>
            </FormControl>
            <DialogActions>
                <Button type="submit" color="primary" disabled={isSaving || !isValid}>
                    {isSaving ?
                        <CircularProgress color="primary"
                                          variant="indeterminate"/> : t('change_password')}
                </Button>
            </DialogActions>
        </form>
    </ClosableDialog>
}

export default ChangePasswordModal
