import { Box, Typography } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProgressBar as KendoProgressBar } from "@progress/kendo-react-progressbars";
import { CustomButton, LoadingComponent, toLocaleDate } from "ndr-designsystem";
import { setLastActionResult } from "features/appStateSlice";
import { DateTime, Duration } from "luxon";
import { useInternationalization } from "@progress/kendo-react-intl";
import Api from "../../../api/Api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import TransitionToPlannedValueModelStatus from "../../../api/fixed/ControllableResource/TransitionToPlannedValueModelStatus";

const downloadExport = async (data: ArrayBuffer, fileName: string): Promise<void> => {
    const blob = new Blob([data], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

const PlanningDataStatus = ({ isButtonDisabled }: { isButtonDisabled: boolean }): ReactElement => {
    const { currentResource: resource } = useAppSelector((state) => state.controllableResources);
    const { t } = useTranslation("tables");
    const [exporting, setExporting] = useState(false);
    const acceptedColor = "rgba(55, 180, 0,0.32)";
    const rejectedColor = "rgba(243, 23, 0, 0.32)";
    const startedColor = "#f8ebbe";
    const minPlanningDataDays = 28;
    const dispatch = useAppDispatch();
    const internationalization = useInternationalization();

    if (!resource) return <></>;

    const transitionToPlannedValueModelStatus =
        resource.transitionToPlannedValueModelStatus || TransitionToPlannedValueModelStatus.NotStarted;

    const translatedStatusName = t(
        `controllable_resources_details.transitionToPlannedValueModelStatus.${transitionToPlannedValueModelStatus}`,
    );
    const quality = resource.prognosisQuality || 0;
    const sufficientPrognosisDataExpectedOn =
        (resource.sufficientPrognosisDataExpectedOn && DateTime.fromISO(resource.sufficientPrognosisDataExpectedOn)) ||
        DateTime.now().plus({ days: minPlanningDataDays });

    // calculate the progress by the current dateTime, the sufficientPrognosisDataExpectedOn dateTime and the minPlanningDataDays
    const completeDuration = Duration.fromObject({ days: minPlanningDataDays });
    const remainingDuration = sufficientPrognosisDataExpectedOn.diff(DateTime.now());
    let progress = (1 - remainingDuration.as("days") / completeDuration.as("days")) * 100;
    const hasSufficientPrognosisData = resource.sufficientPrognosisDataExpectedOn !== undefined && resource.prognosisQuality !== undefined;
    let progressLabel;
    let color;

    const generateStartedProgressLabel = (): string => {
        if (!hasSufficientPrognosisData) {
            return t("controllable_resources_details.transitionToPlannedValueModel.no_data");
        }
        return `${t("controllable_resources_details.transitionToPlannedValueModel.no_data")} ${t(
            "controllable_resources_details.transitionToPlannedValueModel.estimation",
            {
                estimation: toLocaleDate(
                    sufficientPrognosisDataExpectedOn,
                    internationalization.locale,
                    DateTime.DATE_SHORT,
                ),
            },
        )}`;
    };

    switch (transitionToPlannedValueModelStatus) {
        case TransitionToPlannedValueModelStatus.Accepted:
            color = acceptedColor;
            progressLabel = `${t("controllable_resources_details.transitionToPlannedValueModel.quality", {
                quality,
            })}`;
            break;
        case TransitionToPlannedValueModelStatus.Rejected:
            color = rejectedColor;
            progressLabel = `${t("controllable_resources_details.transitionToPlannedValueModel.quality", {
                quality,
            })}`;
            break;
        case TransitionToPlannedValueModelStatus.Started:
        default:
            color = startedColor;
            progressLabel = generateStartedProgressLabel();
            // make sure the progress isn't 100% if the state is still on started
            progress = Math.min(99, progress);
            break;
    }

    return (
        <>
            {transitionToPlannedValueModelStatus !== TransitionToPlannedValueModelStatus.NotStarted && (
                <Box width="100%" position="relative" marginTop={3}>
                    {exporting && <LoadingComponent />}
                    <Box
                        sx={{
                            position: "absolute",
                            left: "50%",
                            top: hasSufficientPrognosisData ? "25%" : "50%",
                            zIndex: 3,
                            color: "gray",
                            userSelect: "none",
                            fontSize: 15,
                            transform: "translate(-50%, -50%)",
                            alignItems: "center",
                            padding: "0 16px",
                            width: "100%",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "gray",
                                userSelect: "none",
                                fontSize: 15,
                                textAlign: "center",
                            }}
                        >
                            {progressLabel}
                        </Typography>
                    </Box>
                    <KendoProgressBar
                        style={{ width: "100%", height: 40, background: color, borderRadius: 30 }}
                        progressClassName={progress === 100 ? "" : "progress_pulsing"}
                        value={progress}
                        label={(_) => (
                            <Typography variant="body2" sx={{ userSelect: "none" }}>
                                {t("controllable_resources_details.transitionToPlannedValueModel.title")} :{" "}
                                <Box fontWeight="bold" display="inline">
                                    {translatedStatusName}
                                </Box>
                            </Typography>
                        )}
                        labelPlacement="center"
                    />
                    {hasSufficientPrognosisData && (
                        <Box width="100" marginTop={1} sx={{ float: "right" }}>
                            <CustomButton
                                title={t(`controllable_resources_details.transitionToPlannedValueModel.export_button`)}
                                disabled={isButtonDisabled}
                                onClick={async () => {
                                    if (resource?.inventoryItemId) {
                                        setExporting(true);
                                        const response = await Api.getPrognosisQualityExport(resource?.inventoryItemId);
                                        if (response.data === undefined || response.status !== 200) {
                                            dispatch(
                                                setLastActionResult({
                                                    message: t(
                                                        `controllable_resources_details.transitionToPlannedValueModel.export_error`,
                                                    ),
                                                    status: false,
                                                }),
                                            );
                                            setExporting(false);
                                        } else {
                                            downloadExport(response.data, response.fileName ?? "export.txt");
                                            setExporting(false);
                                        }
                                    }
                                }}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

export default PlanningDataStatus;
