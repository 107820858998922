import React, { ChangeEvent, ReactElement, useState } from "react";
import GeneratedCostInfoTable from "./GeneratedCostInfoTable";
import CostInfoTable from "./CostInfoTable";

const CostInfo = (): ReactElement => {
    const [currentlyViewing, setCurrentlyViewing] = useState<"configuration" | "generated">("configuration");
    const onViewChange = (e: ChangeEvent): void => {
        const newValue = (e.target as HTMLInputElement).value
        // @ts-ignore we know that possible values are configuration and generated
        setCurrentlyViewing(newValue)
    }
    if (currentlyViewing === "configuration") {
        return <CostInfoTable onViewChange={onViewChange} currentlyViewing={currentlyViewing}/>
    }

    return <GeneratedCostInfoTable onViewChange={onViewChange} currentlyViewing={currentlyViewing}/>
}

export default CostInfo
