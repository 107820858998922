import React, { ReactElement } from "react";
import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { toLocaleDate } from "ndr-designsystem";
import { CheckCircle } from "@mui/icons-material";
import { rejectedMessagesSelector } from "./store/slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setMessageErrors } from "../appStateSlice";

const RejectedMessages = (): ReactElement => {
    const { t, i18n } = useTranslation("common");
    const dispatch = useAppDispatch();

    const rejectedMessages = useAppSelector(rejectedMessagesSelector,
        (left, right) => left?.length === right?.length);

    return <Paper elevation={2} sx={{
        height: "100%",
        width: "100%", display: "flex", flexDirection: "column", alignItems: "center",
    }}>
        <Typography gutterBottom variant="h6" component="div" sx={{ height: 30 }} padding={1}>
            {t('dashboard.rejected_messages')}
        </Typography>
        <Divider flexItem/>
        {(!rejectedMessages || rejectedMessages.length === 0) &&
            <Box width="100%" display="flex" height="100%" justifyContent="center" flexDirection="column"
                 alignItems="center">
                <CheckCircle sx={{ fontSize: 100 }} color="primary"/>
                <Typography variant="body2">
                    {t('no_rejected_message')}
                </Typography>
            </Box>
        }
        <Box overflow="auto" width="100%">
            <TableContainer>
                <Table style={{ width: "100%" }} aria-label="simple table">
                    <TableBody>
                        {rejectedMessages?.slice(0, 4).map((msg) => (
                            <TableRow
                                hover
                                onClick={() => dispatch(setMessageErrors(msg.errors))}
                                key={msg.internalId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                            >
                                <TableCell
                                    align="center">{toLocaleDate(msg.createdAt, i18n.language, DateTime.DATETIME_MED_WITH_WEEKDAY)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </Paper>
}

export default RejectedMessages;
