import { Box, Paper, Typography } from "@mui/material";
import { LoadingComponent } from "ndr-designsystem";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const VerifyAuth = (): ReactElement => {
    const { t } = useTranslation("common")

    return <Box height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    userSelect: "none",
                    backgroundImage: `url(${`${process.env.PUBLIC_URL}/bgimg.png`}),url(${`${process.env.PUBLIC_URL}/bgimg.jpg`})`,
                    backgroundSize: "cover,cover",
                }}

    >
        <Paper elevation={10}
               sx={{
                   padding: 4,
                   bgcolor: '#F6F8F7',
                   display: "flex",
                   flexDirection: "column",
                   gap: 6,
                   width: 300,
                   height: 200,
                   alignItems: "center"
               }}>
            <Typography fontWeight={500} variant="h5">
                {t("verify_login")}
            </Typography>
            <LoadingComponent/>
        </Paper>
    </Box>
}

export default VerifyAuth