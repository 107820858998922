import DataPointDto, { fromDto as fromDataPointDto } from "./DataPointDto";
import ProductionData from "../../fixed/ProductionData/ProductionData";

export default interface ProductionDataDto {
    unitName: string
    resolution: {
        factor: number
        timeUnit: string
    }
    dataPoints: DataPointDto[],
    // DATE WHEN FETCHED
    id: number
}

export const fromDto = (dto: ProductionDataDto): ProductionData => ({
    ...dto,
    dataPoints: dto.dataPoints.map(p => fromDataPointDto(p))
})