/**
 *
 * @export
 * @interface Fixation
 */
export interface Fixation {
    /**
     *
     * @type {string}
     * @memberof Fixation
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof Fixation
     */
    name?: string;
}

export const allowedCodes = ["Z01", "Z02", "Z03"];
