import { Interval } from "luxon";
import { createDateTime } from "ndr-designsystem";
import { SensitivityData } from "../../fixed/Sensitivity/SensitivityData";
import { GridElementCodingScheme } from "../../fixed/Sensitivity/GridElementCodingScheme";
import Direction from "../../fixed/Sensitivity/Direction";

export default interface SensitivityDto {
    id?: string,
    intervalStart: string | null,
    intervalEnd: string | null,
    revision: number,
    gridElementSensitivities: {
        gridElementCode: string,
        gridElementCodingScheme: GridElementCodingScheme,
        id?: string
        quantity: number,
    }[]
    direction: Direction
}

export interface SensitivityCreateDto {
    intervalStart: string | null,
    intervalEnd: string | null,
    gridElementSensitivities: {
        gridElementCode: string,
        gridElementCodingScheme: string,
        id?: string
        quantity: number,
    }[]
    direction: string
}

export const toCreateDto = (dto: SensitivityDto): SensitivityCreateDto => ({
    ...dto,
    gridElementSensitivities: dto.gridElementSensitivities.map(s => ({
        ...s,
        gridElementCodingScheme: s.gridElementCodingScheme.code
    })),
    direction: dto.direction.code
})

export const fromDto = (dto: SensitivityDto): SensitivityData => ({
    ...dto,
    period: Interval.fromDateTimes(createDateTime(dto.intervalStart ?? ""), createDateTime(dto.intervalEnd ?? "")),

})

export const toDto = (original: SensitivityData): SensitivityDto => {
    const backup = { ...original };
    delete backup.period

    return {
        ...backup,
        intervalEnd: original.period!.end.toISO(),
        intervalStart: original.period!.start.toISO(),
    }
}
