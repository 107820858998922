import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridDataState, useQuery } from "ndr-designsystem";
import { isEqual } from "lodash";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setMessageErrors } from "../appStateSlice";
import {
    fetchAllReceivedMessages,
    fetchAllSentMessages,
    resetReceivedMessages,
    resetSentMessages,
    setTotalItems
} from "../ConnectPlusMsgs/ConnectPlusMessagesSlice";
import { RootState } from "../../app/store";
import ConnectPlusMessagesGrid from "../../Components/connectPlusMessagesGrid/ConnectPlusMessagesGrid";
import { ConnectPlusMessageDirection, ConnectPlusMessagesViewType } from "../../utils";

const ConnectPlusMsgsTable: React.FC<{}> = () => {
    const { totalItems } = useAppSelector((state) => state.connectPlusMessages);
    const sentMessages = useAppSelector((state) => state.connectPlusMessages.sentMessages, (left, right) => isEqual(left, right));
    const receivedMessages = useAppSelector((state) => state.connectPlusMessages.receivedMessages, (left, right) => isEqual(left, right));
    const { currentGroup } = useAppSelector((state: RootState) => state.controlGroups);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { t } = useTranslation("tables")
    const { direction } = useQuery<ConnectPlusMessageDirection>();
    const [currentlyViewing, setCurrentlyViewing] = useState<ConnectPlusMessagesViewType>(direction != null ? direction : "sent")
    const [dataState, setDataState] = useState<GridDataState | undefined>(undefined);
    const [data, setData] = useState<any[] | undefined>(undefined);

    useEffect(() => {
        const buildState = async (): Promise<void> => {
            if (currentGroup && dataState) {
                let action: any = null;
                if (currentlyViewing === "sent" && sentMessages === undefined) {
                    action = fetchAllSentMessages({ id: currentGroup.inventoryItemId, type: "group", dataState })
                } else if (currentlyViewing === "received" && receivedMessages === undefined)
                    action = fetchAllReceivedMessages({ id: currentGroup.inventoryItemId!, type: "group", dataState })
                if (action)
                    dispatch(action);
            }
        }
        buildState();
    }, [currentGroup, dispatch, currentlyViewing, sentMessages, receivedMessages, dataState])

    useEffect(() => {
        if (currentlyViewing === "sent") setData(sentMessages);
        else setData(receivedMessages)
    }, [currentlyViewing, sentMessages, receivedMessages])

    useEffect(() => () => {
        dispatch(resetReceivedMessages())
        dispatch(resetSentMessages())
    }, [dispatch])

    const onViewChange = (e: ChangeEvent): void => {
        const newValue = (e.target as HTMLInputElement).value
        // @ts-ignore we know that possible values are sent and received
        setCurrentlyViewing(newValue)
        history.replace({ search: qs.stringify({ direction: newValue }, { encode: false }) })
    }

    const Toolbar = useMemo(() =>
        <FormControl component="fieldset">
            <RadioGroup value={currentlyViewing}
                        onChange={onViewChange}
                        row aria-label="gender" name="row-radio-buttons-group">
                <FormControlLabel value="sent" control={<Radio color="primary"/>}
                                  label={`${t('connect_plus_messages.sent')}`}/>
                <FormControlLabel value="received" control={<Radio color="primary"/>}
                                  label={`${t('connect_plus_messages.received')}`}/>
            </RadioGroup>
        </FormControl>, /* eslint-disable react-hooks/exhaustive-deps */ [currentlyViewing, t])

    return (
        <ConnectPlusMessagesGrid
            filterable
            data={data}
            onViewErrorsClick={(errors) => {
                dispatch(setMessageErrors(errors))
            }}
            toolbar={Toolbar}
            direction={currentlyViewing}
            processData={false}
            totalMessages={totalItems}
            onDataStateChange={async (newState) => {
                if (isEqual(dataState, newState)) return;

                if (currentlyViewing === "sent") {
                    dispatch(resetSentMessages());
                } else dispatch(resetReceivedMessages())

                if (!isEqual(dataState?.filter, newState.filter)) {
                    dispatch(setTotalItems(undefined))
                }

                setDataState(newState);
            }}
        />
    )
}


export default ConnectPlusMsgsTable
