import { DateTime } from "luxon";
import CodeNameProp from "../CodeNameProp";

export default class CostInfo {
    public id?: string

    public businessType?: CodeNameProp

    public direction?: CodeNameProp

    public status?: CodeNameProp

    public from?: DateTime

    public to?: DateTime

    public value?: number

    get unit(): CodeNameProp | undefined {
        if (this.businessType?.code === "Z01") return {
            code: "Z01",
            name: "EUR/piece"
        }
        if (this.businessType?.code === "Z02") return {
            code: "Z02",
            name: "EUR/MWh"
        }
        if (["A01", "A04", "Z03", "Z06"].includes(this.businessType?.code ?? "")) return {
            code: "Z03",
            name: "EUR/h"
        }
        return undefined;
    }

    get allowedDirectionCodes(): string[] | undefined {
        switch (this.businessType?.code) {
            case "A01":
            case "A04":
            case "Z01":
                return ["A01", "A02"]
            default:
                return undefined
        }
    }

    get allowedStatusCodes(): string[] | undefined {
        switch (this.businessType?.code) {
            case "A01":
                return ["Z01", "Z02"]
            case "Z01":
                return ["Z03", "Z04", "Z05"]
            default :
                return undefined
        }
    }
}

export const allowedBusinessTypeCodes: string[] = ["A01", "A04", "Z01", "Z02", "Z03", "Z06"]
