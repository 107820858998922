import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fromDto as fromPlanningDataDto, PlanningDataDto } from "../../../../api/dtos/PlanningData/PlanningDataDto";
import {
    fromDto as fromPlanningDataDetailsDto,
    PlanningDataDetailsDto
} from "../../../../api/dtos/PlanningData/PlanningDataDetailsDto";
import { RootState } from "../../../../app/store";
import { PlanningDataDetails } from "../../../../api/fixed/PlanningData/PlanningDataDetails";
import { PlanningData } from "../../../../api/fixed/PlanningData/PlanningData";
import { PlannedResourceTimeSeries } from "../../../../api/fixed/PlanningData/PlannedResourceTimeSeries";
import {
    fromDto as fromPlannedResourceTimeSeriesDto
} from "../../../../api/dtos/PlanningData/PlannedResourceTimeSeriesDto";

export interface planningDataState {
    allPlanningData: PlanningDataDto[] | undefined,
    currentPlanningData: PlanningDataDetailsDto | undefined | null
    selectedTimeSeries: number | undefined,
    sendingStatusPlanningData: PlanningDataDto | undefined,
    showProductionData: boolean,
    isPreviousFetchingPossible: boolean,
    isNextFetchingPossible: boolean
}

const initialState: planningDataState = {
    currentPlanningData: undefined,
    allPlanningData: undefined,
    selectedTimeSeries: undefined,
    sendingStatusPlanningData: undefined,
    showProductionData: true,
    isPreviousFetchingPossible: true,
    isNextFetchingPossible: true
};

export const store = createSlice({
    name: 'planningDataSlice',
    initialState,
    reducers: {
        setAllPlanningData: (state, action: PayloadAction<PlanningDataDto[] | undefined>) => {
            state.allPlanningData = action.payload;
        },
        setCurrentPlanningData: (state, action: PayloadAction<PlanningDataDetailsDto | undefined>) => {
            state.currentPlanningData = action.payload;
        },
        setIsPreviousFetchingPossible: (state, action: PayloadAction<boolean>) => {
            state.isPreviousFetchingPossible = action.payload
        },
        setIsNextFetchingPossible: (state, action: PayloadAction<boolean>) => {
            state.isNextFetchingPossible = action.payload
        },
        setSendingStatusPlanningData: (state, action: PayloadAction<string | undefined>) => {
            state.sendingStatusPlanningData = state.allPlanningData?.find(p => p.internalID === action.payload);
        },
        selectCurrentTimeSeriesInfo: (state, action: PayloadAction<string>) => {
            if (state.currentPlanningData) {
                const idx = state.currentPlanningData.timeSeries.findIndex(pd => pd.ExternalID === action.payload);
                state.selectedTimeSeries = idx !== -1 ? idx : undefined;
                if (idx !== -1 && state.currentPlanningData.timeSeries[idx].businessType.code !== "A01" && state.showProductionData) {
                    state.showProductionData = false;
                } else if (idx !== -1 && state.currentPlanningData.timeSeries[idx].businessType.code === "A01" && !state.showProductionData) {
                    state.showProductionData = true;
                }
            }
        },
        selectFirstTimeSeriesInfo: (state) => {
            if (state.currentPlanningData && state.currentPlanningData.timeSeries.length > 0) {
                state.selectedTimeSeries = 0;
                if (state.currentPlanningData.timeSeries[0].businessType?.code === "A01") {
                    state.showProductionData = true;
                }
            } else {
                state.selectedTimeSeries = undefined;
            }
        },
        setShowProductionData: (state, action: PayloadAction<boolean>) => {
            state.showProductionData = action.payload
        }
    }
});

export const currentPlanningDataSelector = (state: RootState): PlanningDataDetails | null | undefined => {
    if (state.planningData.currentPlanningData) {
        return fromPlanningDataDetailsDto(state.planningData.currentPlanningData);
    }
    return state.planningData.currentPlanningData;
};

export const allPlanningDataSelector = (state: RootState): PlanningData[] | undefined => {
    if (state.planningData.allPlanningData) {
        return state.planningData.allPlanningData.map(pd => fromPlanningDataDto(pd));
    }
    return state.planningData.allPlanningData;
};

export const timeSeriesInfoForCurrentPlanningData = (state: RootState): PlannedResourceTimeSeries | undefined => {
    if (state.planningData && state.planningData.currentPlanningData && state.planningData.selectedTimeSeries !== undefined) {
        return fromPlannedResourceTimeSeriesDto(state.planningData.currentPlanningData.timeSeries[state.planningData.selectedTimeSeries]);
    }
    return undefined;
};

export const {
    setAllPlanningData,
    selectCurrentTimeSeriesInfo,
    selectFirstTimeSeriesInfo,
    setSendingStatusPlanningData,
    setCurrentPlanningData,
    setShowProductionData,
    setIsPreviousFetchingPossible,
    setIsNextFetchingPossible
} = store.actions;

export default store.reducer;
