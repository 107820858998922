import React, { ReactElement } from "react";
import { ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TimeSeriesListItemProps {
    startDate: string;
    type: string;
    itemsCount?: number;
    onClick: () => void;
    selected: boolean
}

const TimeSeriesListItem = ({
                                onClick,
                                startDate,
                                type,
                                itemsCount,
                                selected
                            }: TimeSeriesListItemProps): ReactElement => {
    const { t } = useTranslation("timeSeries");

    return (
        <ListItem button selected={selected}>
            <ListItemText primary={`${type}`} secondary={t("starting_time", { startDate })}
                          onClick={onClick}
                          tabIndex={-1}/>
            {itemsCount &&
                <ListItemSecondaryAction>
                    {t("total_items", { totalItems: itemsCount })}
                </ListItemSecondaryAction>
            }
        </ListItem>
    );
};

TimeSeriesListItem.defaultProps = {
    itemsCount: undefined
};

export default TimeSeriesListItem;
