import { createDateTime } from "ndr-designsystem";
import CodeNameProp from "../../fixed/CodeNameProp";
import PeriodInterval from "../../fixed/Activations/PeriodInterval";

export default interface PeriodIntervalDto {
    timestamp: string
    quantity: number
    reasons: CodeNameProp[],
    isActive: boolean
}

export const fromDto = (dto: PeriodIntervalDto): PeriodInterval => ({
    ...dto,
    timestamp: createDateTime(dto.timestamp)
})
