export enum PageStatus {
    VIEW,
    EDIT,
    ADD
}

export interface FieldWithGridProps {
    pageStatus: PageStatus;
}

export interface ValidationResult {
    valid: boolean,
    error: string | null
}