import { GridCellProps } from "@progress/kendo-react-grid";
import React, { ReactElement } from "react";
import { Box, Tooltip } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ControlGroupStatus from "../../../../api/fixed/ControlGroup/ControlGroupStatus";

const ValidationIndicator = (props: GridCellProps): ReactElement => {
    const { t } = useTranslation();

    const { dataItem } = props;
    let icon: ReactElement | undefined
    if ([ControlGroupStatus.Unknown, ControlGroupStatus.Updated, ControlGroupStatus.Created].includes(dataItem.status))
        icon = <Box display="flex" alignItems="center">
            <Tooltip title={t("validation:invalid_fields") ?? ""}>
                <ErrorOutline color="error"/>
            </Tooltip>
        </Box>
    return <td>{icon}</td>
}

export default ValidationIndicator;
