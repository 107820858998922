import * as React from "react";
import { ReactElement, useRef } from "react";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Box, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import { DateTime } from "luxon";
import { createDateTime } from "ndr-designsystem";
import { useTranslation } from "react-i18next";

interface DropdownFilterCellProps extends GridFilterCellProps {
    namespace: string;
    defaultOperator: string
}

const DateTimeFilterCell = ({
                                onChange: propsOnChange,
                                value, namespace,
                                defaultOperator
                            }: DropdownFilterCellProps): ReactElement => {
    let hasValue: any = (v: any) => Boolean(v && (v.isValid || createDateTime(v).isValid));
    const filterItem = useRef<string>(defaultOperator);
    const { t } = useTranslation();

    const onChange = (v: DateTime | null): void => {
        hasValue = hasValue(v?.toJSDate());
        const operator = filterItem.current ? filterItem.current : "gte"
        propsOnChange({
            value: hasValue ? v?.toISO() : "",
            operator: hasValue ? operator : "",
            // @ts-ignore
            syntheticEvent: undefined
        });
    };

    const onDirectionChange = (): void => {
        const dateValue = createDateTime(value);
        hasValue = hasValue(dateValue);
        const operator = filterItem.current ? filterItem.current : "gte"
        propsOnChange({
            value: hasValue ? dateValue?.toISO() : "",
            operator: hasValue ? operator : "",
            // @ts-ignore
            syntheticEvent: undefined
        });
    }

    const onClearButtonClick = (event: any): void => {
        event.preventDefault();
        propsOnChange({
            value: "",
            operator: "",
            syntheticEvent: event,
        });
    };

    const itemRender = (props: { item: any; itemIndex: number }): ReactElement => (
        <span style={{ color: props.item === filterItem.current ? "red" : "black" }}>
            {t(`${namespace}.${props.item}`)}
        </span>
    );

    return (
        <div className="k-filtercell">
            <Box flexDirection="row" display="flex" width="100%" alignItems="center">
                <DateTimePicker
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} size="small"/>}
                />
                <Box flexDirection="row" display="flex" alignItems="center">
                    <DropDownButton
                        itemRender={itemRender}
                        icon="filter" items={["gte", "lte"]}
                        onItemClick={e => {
                            filterItem.current = e.item
                            onDirectionChange()
                        }}/>
                    <Button
                        title="Clear"
                        disabled={!hasValue(value)}
                        onClick={onClearButtonClick}
                        icon="filter-clear"
                    />
                </Box>
            </Box>
        </div>
    );
};

export default DateTimeFilterCell