import React, { useMemo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { useTranslation } from 'react-i18next';

export type View = 'grid' | 'chart'

interface ToggleViewProps {
    view: View,
    onToggleView: (view: View) => void

}

const icons = {
    'grid': <TableChartIcon/>,
    'chart': <TimelineOutlinedIcon/>,
}

export const ToggleViewButton: React.FC<ToggleViewProps> = ({ view, onToggleView }) => {
    const { t } = useTranslation('timeSeries')

    const onClick = (): void => {
        const nextView = view === 'grid' ? 'chart' : 'grid'
        onToggleView(nextView)
    }

    const tooltip = useMemo(() => t(`toggle_view_from_${view}`), [view, t])

    return (
        <Tooltip title={tooltip}>
            <IconButton
                onClick={onClick}
            >
                {icons[view]}
            </IconButton>
        </Tooltip>
    );
}