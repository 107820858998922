import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { GridRowClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { GridField } from "ndr-designsystem";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchControlGroups } from "../store/thunks";
import { setCurrentPage } from "../../appStateSlice";
import Toolbar from "./Toolbar";
import RowRender from "./RowRender";
import SentIndicator from "./columns/SentIndicator";
import ValidationIndicator from "./columns/ValidationIndicator";

const ListControlGroups: React.FC<{}> = () => {
    const { t } = useTranslation("tables");
    const { allGroups } = useAppSelector(state => state.controlGroups);
    const history = useHistory();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchControlGroups(false));
        dispatch(setCurrentPage("ControlGroupList"))
    }, [dispatch]);

    const onRowClick = (e: GridRowClickEvent): void => {
        history.push(`/controlGroups/${e.dataItem.externalID}`);
    };

    const gridFields = useMemo((): GridFieldElement[] => [
        {
            customCell: (props) => <SentIndicator controlGroup={props.dataItem} alwaysShowText={false}/>
        },
        {
            customCell: ValidationIndicator
        },
        {
            title: t("control_groups_list.externalID"),
            name: "externalID"
        },
        {
            title: t("control_groups_list.name"),
            name: "name"
        },
        {
            title: t("control_groups_list.controllableResourcesCount"),
            name: "controllableResources.length"
        }

    ], [t])

    return <GridField
        defaultOpen
        customToolbar={<Toolbar/>}
        values={allGroups}
        onRowClick={onRowClick}
        dataItemKey="inventoryItemId"
        noRecordsText={t("no_records_text")}
        gridFields={gridFields}
        rowRender={RowRender}
    />
}

export default ListControlGroups
