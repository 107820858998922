import React, { ReactElement, useMemo } from "react";
import { Autocomplete, Box, Card, CardActionArea, CardContent, Divider, TextField, Typography } from "@mui/material";
import { Build } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

const AccountingGroupBalancingScheduleCard = (): ReactElement => {
    const { allAccountingGroups: allAccountingGroupBalancingSchedules } = useAppSelector((state: RootState) => state.accountingGroupBalancingSchedules)
    const history = useHistory();
    const { t } = useTranslation("common");

    const preparedResources = useMemo(
        () =>
            allAccountingGroupBalancingSchedules?.map((res) => ({
                label:
                    res &&
                    res.controllableResource &&
                    res?.controllableResource?.name &&
                    res?.controllableResource?.name?.length > 0
                        ? res.controllableResource?.name
                        : res.controllableResource?.externalId,
                id: res.inventoryItemId,
            })) ?? [],
        [allAccountingGroupBalancingSchedules],
    );

    return (
        <Box display="flex" justifyContent="center" height="100%" width="100%">
            <Card sx={{ textAlign: "center", display: "flex", flexDirection: "column", flexGrow: 1, pb: 1 }}>
                <CardActionArea onClick={() => history.push("/accountingGroups")}>
                    <Build sx={{ fontSize: 50, pt: 2 }} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {t("home.accounting_group_balancing_schedule")}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {t("home.accounting_group_balancing_schedule_details")}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <Box paddingX={2} paddingBottom={2}>
                    <Divider flexItem sx={{ marginBottom: 1 }} />
                    <Autocomplete
                        openOnFocus
                        onChange={(event, newValue) => {
                            if (newValue) {
                                history.push(`/accountingGroups/${newValue.id}`);
                            }
                        }}
                        options={preparedResources}
                        renderInput={(params) => <TextField {...params} label="ID" />}
                    />
                </Box>
            </Card>
        </Box>
    );
}

export default AccountingGroupBalancingScheduleCard
