import { GridCellProps } from "@progress/kendo-react-grid";
import { Switch } from "@mui/material";
import React, { ReactElement } from "react";

const BooleanColumn = (props: GridCellProps & { path: string }): ReactElement => {
    const { dataItem, path } = props;

    return <td><Switch disabled checked={dataItem[path] === true}/></td>
}

export default BooleanColumn
