import React, { ReactElement, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { createDateTime, LoadingComponent, useLocalStorage } from "ndr-designsystem";
import { isEqual } from "lodash";
import TimeSeriesGrid from "./Grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ListView from "./listView/ListView";
import { PlannedResourceTimeSeries } from "../../../api/fixed/PlanningData/PlannedResourceTimeSeries";
import {
    buildPlanningDataState,
    resetPlanningDataState
} from "./store/thunks";
import {
    allPlanningDataSelector,
    selectCurrentTimeSeriesInfo,
    selectFirstTimeSeriesInfo, setIsNextFetchingPossible,
} from "./store/store";
import TimeSeriesChart from "./Chart";
import { fetchProductionDataByCR } from "../productionData/store/thunks";
import Toolbar from "./Toolbar";
import { currentProductionDataSelector } from "../productionData/store/slice";
import Navigation from "../Navigation";
import { TimeseriesPageSize } from "../../../utils";

export type TimeSeriesViewType = "grid" | "chart"

interface RouteParams {
    controllableId: string,
    seriesId: string
}

const DataView = (): ReactElement => {
    const { controllableId, seriesId } = useParams<RouteParams>();
    const history = useHistory();
    const { headerHeight } = useAppSelector(state => state.appState)
    const [currentlyViewing, setCurrentlyViewing] = useLocalStorage<TimeSeriesViewType>("timeseries.view", {
        pollingInterval: -1,
        initialValue: "chart"
    })
    const { currentResource: controllableResource } = useAppSelector(state => state.controllableResources)
    const currentProductionData = useAppSelector(currentProductionDataSelector, (left, right) => isEqual(left, right))
    const allPlanningData = useAppSelector(allPlanningDataSelector, (left, right) => isEqual(left, right))
    const dispatch = useAppDispatch();
    const {
        selectedTimeSeries,
        currentPlanningData,
        showProductionData
    } = useAppSelector(state => state.planningData);

    useEffect(() => {
        dispatch(setIsNextFetchingPossible(true))
        dispatch(buildPlanningDataState({
            controllableId,
            seriesId,
            force: false,
            take: TimeseriesPageSize * 5
        })).then(() => dispatch(selectFirstTimeSeriesInfo()));
        return () => {
            dispatch(resetPlanningDataState())
        }
        // we need to trigger this only when technicalId gets available (at the same time with controllableId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seriesId])

    useEffect(() => {
        if (showProductionData && currentPlanningData && currentPlanningData.date && controllableResource && currentProductionData === undefined) {
            const date = createDateTime(currentPlanningData.date)
            dispatch(fetchProductionDataByCR({
                id: controllableResource.inventoryItemId!,
                from: date.startOf("day"),
                to: date.endOf("day"),
            }));
        }
    }, [showProductionData, currentPlanningData, controllableResource, dispatch, currentProductionData])

    const handleListViewClick = (item: PlannedResourceTimeSeries): void => {
        dispatch(selectCurrentTimeSeriesInfo(item.ExternalID));
    };

    if (currentPlanningData === undefined || selectedTimeSeries === undefined || showProductionData === undefined) return <LoadingComponent/>

    return (
        <Box display="flex" height={`calc(100vh - ${headerHeight + 60}px)`} flexDirection="column">
            <Navigation
                allElements={allPlanningData ?? []}
                currentElement={currentPlanningData}
                elementKey="internalID"
                onNextClick={e => {
                    history.push(`/controllable/${controllableResource?.externalID}/planning-data/${e.internalID}`)
                }}
                onPreviousClick={e => {
                    history.push(`/controllable/${controllableResource?.externalID}/planning-data/${e.internalID}`)
                }}
            />
            <Box display="flex" height="100%" width="100%">
                <Box width="15%" height="100%"
                     style={{ overflowY: "auto" }}>
                    <ListView onItemClick={handleListViewClick}/>
                </Box>
                <Box height="100%" width="100%" display="flex" flexDirection="column">
                    <Toolbar onCurrentlyViewingToggle={setCurrentlyViewing} currentlyViewing={currentlyViewing}/>
                    <Box display="flex" flexDirection={currentlyViewing === "grid" ? "row" : "column"} height="100%">
                        <Box flex={1} display="flex" flexDirection="column" sx={{ userSelect: "none" }}>
                            <Box height="100%">
                                {currentlyViewing === "grid" ?
                                    <TimeSeriesGrid/> :
                                    <TimeSeriesChart/>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )

}

export default DataView;
