import React, { ReactElement, useEffect, useMemo } from "react";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GridField, GridFieldActionsCell, TranslatedApiValueCell } from "ndr-designsystem";
import { SensitivityData } from "../../../../../api/fixed/Sensitivity/SensitivityData";
import DateCell from "../../../../../Components/DateCell";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { allSensitivitiesSelector, setAllSensitivities, } from "../../../../simplePlanningData/sensitivities/store/slice";
import { ValidationResult } from "../../../../../utils/types";
import { deleteSensitivity, fetchSensitivities } from "../../../../simplePlanningData/sensitivities/store/thunks";
import { toDto as toSensitivityDto } from "../../../../../api/dtos/Sensitivity/SensitivityDto";
import { setAddNewSimplePlanningData } from "../../../../appStateSlice";
import ConfigurationToolbar, { ConfigurationProps } from "../../../../../Components/ConfigurationToolbar";

const SensitivitiesTable = ({ currentlyViewing, onViewChange }: ConfigurationProps): ReactElement => {
    const dispatch = useAppDispatch();
    const allSensitivities = useSelector(allSensitivitiesSelector);
    const { currentResource } = useAppSelector((state) => state.controllableResources)
    const { t } = useTranslation("tables")

    useEffect(() => {
        if (allSensitivities === undefined && currentResource != null && currentResource.inventoryItemId) {
            dispatch(fetchSensitivities({ id: currentResource.inventoryItemId, force: false }))
        }
    }, [allSensitivities, currentResource, dispatch])

    const remove = (index: number): void => {
        const dataItem = allSensitivities![index]
        const newElements = [...allSensitivities!]
        newElements.splice(index, 1);

        dispatch(deleteSensitivity({
            inventoryId: currentResource!.inventoryItemId ?? "",
            id: dataItem.id!,
        })).then(() => dispatch(setAllSensitivities(newElements.map(sens => toSensitivityDto(sens)))));
    }

    const edit = (index: number): void => {
        dispatch(setAddNewSimplePlanningData({
            show: true,
            id: currentResource!.inventoryItemId!,
            data: toSensitivityDto(allSensitivities![index]),
            addType: "sensitivity"
        }))
    }

    const SensitivityCommands = (props: GridCellProps): ReactElement => {
        const isElementValid: ValidationResult = {
            valid: true,
            error: null
        };

        return <GridFieldActionsCell {...props}
                                     inEdit={false}
                                     validationResult={isElementValid}
                                     edit={edit}
                                     remove={remove}
        />;
    }

    const gridFields = useMemo(() => [
        {
            title: t("controllable_resources_details.sensitivities.start_date"),
            customCell: (props: GridCellProps) => {
                const { dataItem }: { dataItem: SensitivityData } = props;
                return <DateCell
                    {...props}
                    dataItem={dataItem.period}
                    fieldPath="start"
                    displayFormat={DateTime.DATE_SHORT}
                />
            }
        },
        {
            title: t("controllable_resources_details.sensitivities.end_date"),
            customCell: (props: GridCellProps) => {
                const { dataItem }: { dataItem: SensitivityData } = props;
                return <DateCell
                    {...props}
                    dataItem={dataItem.period}
                    fieldPath="end"
                    displayFormat={DateTime.DATE_SHORT}
                />
            }
        },
        {
            name: "direction.name",
            title: t("controllable_resources_details.sensitivities.direction"),
            customCell: ({ dataItem }: { dataItem: any }) => <TranslatedApiValueCell
                value={dataItem.direction?.code}
                namespace="api:direction"
            />
        }
    ], [t])

    return <GridField
        isEditing={allSensitivities !== undefined}
        noRecordsText={t("no_records_text")}
        customToolbar={<ConfigurationToolbar
            onAddClick={() => dispatch(setAddNewSimplePlanningData({
                show: true,
                id: currentResource?.inventoryItemId!,
                data: undefined,
                addType: "sensitivity"
            }))}
            currentlyViewing={currentlyViewing}
            onViewChange={onViewChange}/>}
        defaultOpen
        take={10}
        gridFields={gridFields}
        values={allSensitivities}
        dataItemKey="id"
        commandsColumn={<GridColumn cell={SensitivityCommands}/>}
    />
}

export default SensitivitiesTable;
