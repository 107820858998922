import React, { ReactElement, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { LoadingComponent } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { buildDashboardState } from "../../features/dashboard/store/slice";
import ResourcesAttention from "../../features/dashboard/ResourcesAttention";
import ResourcesComparison from "../../features/dashboard/ResourcesComparison";
import RejectedMessages from "../../features/dashboard/RejectedMessages";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import TasksOverview from "../../features/dashboard/TasksOverview";
import { fetchTasks } from "../../features/tasks/store/thunks";
import { setAllTasks } from "../../features/tasks/store/store";
import OpenAvatErrors from "../../features/dashboard/OpenAvatErrors";

const Dashboard = (): ReactElement => {
    const dispatch = useAppDispatch();
    const [loaded, setLoaded] = useState(false);
    const { headerHeight } = useAppSelector(state => state.appState)
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(buildDashboardState({ force: false })).then(() => setLoaded(true));
    }, [dispatch, t])

    if (!loaded) return <LoadingComponent/>

    return <Box display="flex" height={`calc(100vh - ${headerHeight + 50}px)`} width="100%">
        <Grid container spacing={1} justifyContent="space-between" id="mainContainer">
            <Grid item sm={12} md={6}>
                <ResourcesComparison/>
            </Grid>
            <Grid item sm={12} md={6}>
                <TasksOverview onPeriodChange={async (days) => {
                    dispatch(setAllTasks(undefined));
                    await dispatch(fetchTasks({
                        skip: 0,
                        take: 10000,
                        filter: {
                            logic: "and",
                            filters: [
                                {
                                    field: "startedAt",
                                    operator: "gte",
                                    value: DateTime.now().minus({ days }).startOf('day').toISO()
                                },
                                { field: "completedAt", operator: "lte", value: DateTime.now().endOf('day').toISO() },
                            ]
                        }
                    }))
                }}/>
            </Grid>
            <Grid item sm={12} md={4} style={{ height: "60%" }}>
                <ResourcesAttention/>
            </Grid>
            <Grid item sm={12} md={4} style={{ height: "60%" }}>
                <OpenAvatErrors/>
            </Grid>
            <Grid item sm={12} md={4} style={{ height: "60%" }}>
                <RejectedMessages/>
            </Grid>
        </Grid>
    </Box>

}

export default Dashboard;
