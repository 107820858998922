import React, { ReactElement, useMemo } from "react";
import { useSelector } from "react-redux";
import { CustomEndAdornment, Field, isFieldValid } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../app/store";
import {
    setMinCapacityGradient,
    setMinCapacityGradientBaseSize,
    setMinCapacityGradientUnit,
    setMinDownTime,
    setMinOperatingTime,
    setMinPower,
    setRampUpTimeCold,
    setRampUpTimeHot,
    setRatedCapacityGradient,
    setRatedCapacityGradientBaseSize,
    setRatedCapacityGradientUnit,
    setShutdownTime,
    setStartUpTimeCold,
    setStartUpTimeHot
} from "../../store/controllableResourcesSlice";
import { CRTechnicalParametersSchema } from "../../../../utils/inputChecking";
import { useAppDispatch } from "../../../../app/hooks";
import { allowedCodes as GradientUnitAllowedCodes } from "../../../../api/fixed/ControllableResource/GrdientUnit";
import { FieldWithGridProps, PageStatus } from "../../../../utils/types";
import SchemaConstants from "../../../../utils/schemaConsts";
import { technicalParametersAllowedValues } from "../../../../api/fixed/ControllableResource/EnergySource";
import { transformToSelectEntry } from "../../../../utils";

const TechnicalParametersSubGrid = ({ pageStatus }: FieldWithGridProps): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;

    const { t } = useTranslation("tables")
    const { currentResource: resource } = useSelector((state: RootState) => state.controllableResources);
    const dispatch = useAppDispatch();

    const allowingGradientUnitValues = useMemo(() => transformToSelectEntry(GradientUnitAllowedCodes, t, "api:gradient_unit"), [t])

    if (!resource) {
        return <></>;
    }

    const TechnicalParametersSchema = CRTechnicalParametersSchema();

    return (
        <>
            <Field
                isFilledByEiv
                filledByEivText={t("filled_by_eiv")}
                maxFractionDigits={3}
                isEditing={isEditing || isAdding}
                onValueChange={val => dispatch(setMinPower(val))}
                valueCheckFunction={minPower => isFieldValid(TechnicalParametersSchema, [SchemaConstants.minPower], { minPower })}
                defaultValue={resource.minPower}
                endAdornment={<CustomEndAdornment content="MW"/>}
                inputType="number"
                title={t("controllable_resources_details.minPower")}
                description={t('controllable_resources_details.minPowerDescription')}
            />
            {technicalParametersAllowedValues.includes(resource.energySource?.code ?? "") &&
                <>
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={0}
                        isEditing={isEditing || isAdding}
                        onValueChange={val => dispatch(setMinOperatingTime(val))}
                        inputType="number"
                        valueCheckFunction={minOperatingTime => isFieldValid(TechnicalParametersSchema, [SchemaConstants.minOperatingTime], { minOperatingTime })}
                        defaultValue={resource.minOperatingTime}
                        endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")}/>}
                        title={t('controllable_resources_details.minOperatingTime')}
                        description={t('controllable_resources_details.minOperatingTimeDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={0}
                        isEditing={isEditing || isAdding}
                        onValueChange={val => dispatch(setMinDownTime(val))}
                        inputType="number"
                        valueCheckFunction={minDownTime => isFieldValid(TechnicalParametersSchema, [SchemaConstants.minDownTime], { minDownTime })}
                        defaultValue={resource.minDownTime}
                        endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")}/>}
                        title={t('controllable_resources_details.minDownTime')}
                        description={t('controllable_resources_details.minDownTimeDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={0}
                        isEditing={isEditing || isAdding}
                        onValueChange={val => dispatch(setRampUpTimeCold(val))}
                        inputType="number"
                        valueCheckFunction={rampupTimeCold => isFieldValid(TechnicalParametersSchema, [SchemaConstants.rampUpTimeCold], { rampupTimeCold })}
                        defaultValue={resource.rampupTimeCold}
                        endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")}/>}
                        title={t('controllable_resources_details.rampUpTimeCold')}
                        description={t('controllable_resources_details.rampUpTimeColdDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={0}
                        isEditing={isEditing || isAdding}
                        onValueChange={val => dispatch(setRampUpTimeHot(val))}
                        inputType="number"
                        valueCheckFunction={rampupTimeHot => isFieldValid(TechnicalParametersSchema, [SchemaConstants.rampUpTimeHot], { rampupTimeHot })}
                        defaultValue={resource.rampupTimeHot}
                        endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")}/>}
                        title={t('controllable_resources_details.rampUpTimeHot')}
                        description={t('controllable_resources_details.rampUpTimeHotDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={0}
                        isEditing={isEditing || isAdding}
                        endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")}/>}
                        onValueChange={val => dispatch(setStartUpTimeCold(val))}
                        inputType="number"
                        valueCheckFunction={startupTimeCold => isFieldValid(TechnicalParametersSchema, [SchemaConstants.startupTimeCold], { startupTimeCold })}
                        defaultValue={resource.startupTimeCold}
                        title={t('controllable_resources_details.startUpTimeCold')}
                        description={t('controllable_resources_details.startUpTimeColdDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={0}
                        endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")}/>}
                        isEditing={isEditing || isAdding}
                        onValueChange={val => dispatch(setStartUpTimeHot(val))}
                        inputType="number"
                        valueCheckFunction={startupTimeHot => isFieldValid(TechnicalParametersSchema, [SchemaConstants.startupTimeHot], { startupTimeHot })}
                        defaultValue={resource.startupTimeHot}
                        title={t('controllable_resources_details.startUpTimeHot')}
                        description={t('controllable_resources_details.startUpTimeHotDescription')}
                    />
                    <Field
                        isFilledByEiv
                        filledByEivText={t("filled_by_eiv")}
                        maxFractionDigits={0}
                        endAdornment={<CustomEndAdornment content={t("controllable_resources_details.minutes")}/>}
                        isEditing={isEditing || isAdding}
                        onValueChange={val => dispatch(setShutdownTime(val))}
                        inputType="number"
                        valueCheckFunction={shutdownTime => isFieldValid(TechnicalParametersSchema, [SchemaConstants.shutdownTime], { shutdownTime })}
                        defaultValue={resource.shutdownTime}
                        title={t('controllable_resources_details.shutdownTime')}
                        description={t('controllable_resources_details.shutdownTimeDescription')}
                    />
                </>
            }
            <Field
                isFilledByEiv
                filledByEivText={t("filled_by_eiv")}
                onValueChange={val => dispatch(setRatedCapacityGradientUnit(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={ratedCapacityGradientUnit => isFieldValid(TechnicalParametersSchema, [SchemaConstants.ratedCapacityGradientUnit], { ratedCapacityGradientUnit })}
                title={t('controllable_resources_details.ratedCapacityGradientUnit')}
                values={allowingGradientUnitValues}
                defaultValue={allowingGradientUnitValues.find(f => f.code === resource.ratedCapacityGradientUnit?.code)}
                keyField="code"
                textField="name"
                inputType='select'
            />
            {resource.ratedCapacityGradientUnit &&
                <Field
                    isFilledByEiv
                    filledByEivText={t("filled_by_eiv")}
                    maxFractionDigits={3}
                    onValueChange={val => dispatch(setRatedCapacityGradient(val))}
                    isEditing={isEditing || isAdding}
                    inputType="number"
                    valueCheckFunction={ratedCapacityGradient => isFieldValid(TechnicalParametersSchema, [SchemaConstants.ratedCapacityGradient, SchemaConstants.ratedCapacityGradientUnit], {
                        ratedCapacityGradient,
                        ratedCapacityGradientUnit: resource.ratedCapacityGradientUnit
                    })}
                    defaultValue={resource.ratedCapacityGradient}
                    title={t('controllable_resources_details.ratedCapacityGradient')}
                    description={t('controllable_resources_details.ratedCapacityGradientDescription')}
                />
            }
            {resource.ratedCapacityGradientUnit?.code === "Z01" &&
                <Field
                    isFilledByEiv
                    filledByEivText={t("filled_by_eiv")}
                    maxFractionDigits={3}
                    onValueChange={val => dispatch(setRatedCapacityGradientBaseSize(val))}
                    isEditing={isEditing || isAdding}
                    inputType="number"
                    valueCheckFunction={ratedCapacityGradientBaseSize =>
                        isFieldValid(TechnicalParametersSchema, [SchemaConstants.ratedCapacityGradientBaseSize, SchemaConstants.ratedCapacityGradientUnit], {
                            ratedCapacityGradientBaseSize,
                            ratedCapacityGradientUnit: resource.ratedCapacityGradientUnit
                        })
                    }
                    defaultValue={resource.ratedCapacityGradientBaseSize}
                    title={t("controllable_resources_details.ratedCapacityGradientBaseSize")}
                />
            }
            <Field
                isFilledByEiv
                filledByEivText={t("filled_by_eiv")}
                onValueChange={val => dispatch(setMinCapacityGradientUnit(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={minCapacityGradientUnit => isFieldValid(TechnicalParametersSchema, [SchemaConstants.minCapacityGradientUnit], { minCapacityGradientUnit })}
                title={t('controllable_resources_details.minCapacityGradientUnit')}
                values={allowingGradientUnitValues}
                defaultValue={allowingGradientUnitValues.find(f => f.code === resource.minCapacityGradientUnit?.code)}
                keyField="code"
                textField="name"
                inputType='select'
            />
            {resource.minCapacityGradientUnit &&
                <Field
                    isFilledByEiv
                    filledByEivText={t("filled_by_eiv")}
                    maxFractionDigits={3}
                    onValueChange={val => dispatch(setMinCapacityGradient(val))}
                    isEditing={isEditing || isAdding}
                    inputType="number"
                    valueCheckFunction={minCapacityGradient => isFieldValid(TechnicalParametersSchema, [SchemaConstants.minCapacityGradient, SchemaConstants.minCapacityGradientUnit], {
                        minCapacityGradient,
                        minCapacityGradientUnit: resource.minCapacityGradientUnit
                    })}
                    defaultValue={resource.minCapacityGradient}
                    title={t('controllable_resources_details.minCapacityGradient')}
                    description={t('controllable_resources_details.minCapacityGradientDescription')}
                />
            }
            {resource.minCapacityGradientUnit?.code === "Z01" &&
                <Field
                    isFilledByEiv
                    filledByEivText={t("filled_by_eiv")}
                    maxFractionDigits={3}
                    onValueChange={val => dispatch(setMinCapacityGradientBaseSize(val))}
                    isEditing={isEditing || isAdding}
                    inputType="number"
                    valueCheckFunction={minCapacityGradientBaseSize =>
                        isFieldValid(TechnicalParametersSchema, [SchemaConstants.minCapacityGradientBaseSize, SchemaConstants.minCapacityGradientUnit], {
                            minCapacityGradientBaseSize,
                            minCapacityGradientUnit: resource.minCapacityGradientUnit
                        })
                    }
                    defaultValue={resource.minCapacityGradientBaseSize}
                    title={t("controllable_resources_details.minCapacityGradientBaseSize")}
                />
            }
        </>
    )
}

export default TechnicalParametersSubGrid
