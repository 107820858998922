import { GridCellProps } from "@progress/kendo-react-grid";
import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
// import ReceivedMessage from "../../../api/fixed/ConnectPlusMessage/ReceivedMessage";
import SentMessage from "../../../api/fixed/ConnectPlusMessage/SentMessage";
import { setCurrentXMLFile } from "../../ConnectPlusMsgs/ConnectPlusMessagesSlice";
import { useAppDispatch } from "../../../app/hooks";

const FilesField = (props: GridCellProps & { files: SentMessage, direction: "sent" | "received" }): ReactElement => {
    const { dataItem, direction, files }: { dataItem: GridCellProps; direction: "sent" | "received"; files: SentMessage } = props;
    const links: ReactElement[] = [];
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");

    if ((dataItem !== undefined) && (files !== undefined)) {
        if (files.hasXml) {
            links.push(
                <Button
                    key="xml"
                    onClick={() => {
                        dispatch(
                            setCurrentXMLFile({ messageType: direction, id: files.internalId, fileType: "xml" }),
                        );
                    }}
                    style={{ color: "blue" }}
                >
                    {t("connect_plus_messages.get_xml")}
                </Button>,
            );
        }

        if (files.hasAckXml) {
            links.push(<br key="br" />);
            links.push(
                <Button
                    key="ackxml"
                    onClick={() =>
                        dispatch(
                            setCurrentXMLFile({
                                messageType: direction,
                                id: files.internalId,
                                fileType: "ackxml",
                            }),
                        )
                    }
                    style={{ color: "blue" }}
                >
                    {t("connect_plus_messages.get_ackxml")}
                </Button>,
            );
        }

        return <td>{links.map((btn) => btn)}</td>;
    }

    return <td />;
};

export default FilesField;
