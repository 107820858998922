/**
 *
 * @export
 * @interface ActivationType
 */
export interface ActivationType {
    /**
     *
     * @type {string}
     * @memberof ActivationType
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof ActivationType
     */
    name?: string | null;
}

export const allowedCodes = ["Z01", "Z02"];
