import React, { ReactElement, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { LoadingComponent, useLocalStorage } from "ndr-designsystem";
import { isEqual } from "lodash";
import TimeSeriesGrid from "./Grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ToggleViewButton } from "../ToggleViewButton";
import useStyles from "../styles";
import TimeSeriesChart from "./Chart";
import { allDowntimesSelector, currentDowntimeSelector } from "./store/slice";
import { buildDowntimesState, resetDowntimesState } from "./store/thunks";
import Navigation from "../Navigation";

export type TimeSeriesViewType = "grid" | "chart"

interface RouteParams {
    controllableId: string,
    technicalId: string,
    seriesId: string
}

const DataView = (): ReactElement => {
    const { technicalId, seriesId, controllableId } = useParams<RouteParams>();
    const { headerHeight } = useAppSelector(state => state.appState)
    const [currentlyViewing, setCurrentlyViewing] = useLocalStorage<TimeSeriesViewType>("network_constraints.view", {
        pollingInterval: -1,
        initialValue: "chart"
    })
    const history = useHistory()
    const { currentResource: controllableResource } = useAppSelector(state => state.controllableResources)
    const { currentResource: technicalResource } = useAppSelector(state => state.technicalResources)
    const allDowntimes = useAppSelector(allDowntimesSelector, (left, right) => isEqual(left, right))
    const currentDowntime = useAppSelector(currentDowntimeSelector, (left, right) => left?.id === right?.id);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch(buildDowntimesState({ controllableId, technicalId, seriesId, force: false }))
        return () => dispatch(resetDowntimesState())
        // we need to trigger this only when technicalId gets available (at the same time with controllableId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seriesId, controllableId])

    const Toolbar = useCallback((): ReactElement => <div className={classes.toolbar}>
        <ToggleViewButton view={currentlyViewing} onToggleView={setCurrentlyViewing}/>
    </div>, [currentlyViewing, classes, setCurrentlyViewing]);

    if (currentDowntime === undefined) return <LoadingComponent/>
    return (
        <Box display="flex" flexDirection="column" height={`calc(100vh - ${headerHeight + 160}px)`} width="100%">
            <Navigation
                allElements={allDowntimes ?? []}
                currentElement={currentDowntime}
                elementKey="id"
                onNextClick={e => {
                    history.push(`/controllable/${controllableResource?.externalID}/resources/${technicalResource?.externalID}/downtime/${e.id}`)
                }}
                onPreviousClick={e => {
                    history.push(`/controllable/${controllableResource?.externalID}/resources/${technicalResource?.externalID}/downtime/${e.id}`)
                }}
            />
            <Toolbar/>
            {currentlyViewing === "grid" ?
                <TimeSeriesGrid/> :
                <TimeSeriesChart/>
            }
        </Box>
    )

}

export default DataView;
