import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import GeneratedCostInfoDto, {
    fromDto as fromGeneratedCostInfoDto
} from "../../../../api/dtos/GeneratedCostInfo/GeneratedCostInfoDto";
import GeneratedCostInfo from "../../../../api/fixed/GeneratedCostInfo/GeneratedCostInfo";
import GeneratedCostInfoTimeSeries from "../../../../api/fixed/GeneratedCostInfo/GeneratedCostInfoTimeSeries";
import {
    fromDto as fromGeneratedCostInfoTimeSeriesDto
} from "../../../../api/dtos/GeneratedCostInfo/GeneratedCostInfoTimeSeriesDto";
import GeneratedCostInfoDetailsDto, {
    fromDto as fromGeneratedCostInfoDetailsDto
} from "../../../../api/dtos/GeneratedCostInfo/GeneratedCostInfoDetailsDto";
import GeneratedCostInfoDetails from "../../../../api/fixed/GeneratedCostInfo/GeneratedCostInfoDetails";


export interface GeneratedCostInfoState {
    allGeneratedCostInfo: GeneratedCostInfoDto[] | undefined,
    currentGeneratedCostInfo: GeneratedCostInfoDetailsDto | undefined,
    selectedTimeSeries: number | undefined,
    sendingStatusGeneratedCostInfo: GeneratedCostInfoDto | undefined
}

const initialState: GeneratedCostInfoState = {
    allGeneratedCostInfo: undefined,
    currentGeneratedCostInfo: undefined,
    selectedTimeSeries: undefined,
    sendingStatusGeneratedCostInfo: undefined
};

export const store = createSlice({
    name: 'generatedCostSlice',
    initialState,
    reducers: {
        setAllGeneratedCostInfo: (state, action: PayloadAction<GeneratedCostInfoDto[] | undefined>) => {
            state.allGeneratedCostInfo = action.payload
        },
        setCurrentGeneratedCostInfo: (state, action: PayloadAction<GeneratedCostInfoDetailsDto | undefined>) => {
            state.currentGeneratedCostInfo = action.payload
        },
        selectFirstTimeSeriesInfo: (state) => {
            if (state.currentGeneratedCostInfo && state.currentGeneratedCostInfo.timeSeries.length > 0) {
                state.selectedTimeSeries = 0;
            } else {
                state.selectedTimeSeries = undefined;
            }
        },
        selectCurrentTimeSeriesInfo: (state, action: PayloadAction<string>) => {
            const idx = state.currentGeneratedCostInfo?.timeSeries.findIndex(pd => pd.id === action.payload);
            state.selectedTimeSeries = idx !== -1 ? idx : undefined;
        },
        setSendingStatusGeneratedCostInfo: (state, action: PayloadAction<string | undefined>) => {
            state.sendingStatusGeneratedCostInfo = state.allGeneratedCostInfo?.find(p => p.dispatchId === action.payload);
        },
    }
});

export const allGeneratedCostInfoSelector = (state: RootState): GeneratedCostInfo[] | undefined => {
    if (state.generatedCostInfo.allGeneratedCostInfo) {
        return state.generatedCostInfo.allGeneratedCostInfo.map(t => fromGeneratedCostInfoDto(t))
    }
    return state.generatedCostInfo.allGeneratedCostInfo
}

export const currentGeneratedCostInfoSelector = (state: RootState): GeneratedCostInfoDetails | undefined => {
    if (state.generatedCostInfo.currentGeneratedCostInfo) {
        return fromGeneratedCostInfoDetailsDto(state.generatedCostInfo.currentGeneratedCostInfo)
    }
    return state.generatedCostInfo.currentGeneratedCostInfo
}

export const timeSeriesInfoForCurrentPlanningData = (state: RootState): GeneratedCostInfoTimeSeries | undefined => {
    if (state.generatedCostInfo && state.generatedCostInfo.currentGeneratedCostInfo && state.generatedCostInfo.selectedTimeSeries !== undefined) {
        return fromGeneratedCostInfoTimeSeriesDto(state.generatedCostInfo.currentGeneratedCostInfo.timeSeries[state.generatedCostInfo.selectedTimeSeries]);
    }
    return undefined;
};

export const {
    setAllGeneratedCostInfo,
    setCurrentGeneratedCostInfo,
    selectFirstTimeSeriesInfo,
    selectCurrentTimeSeriesInfo,
    setSendingStatusGeneratedCostInfo
} = store.actions;

export default store.reducer;
