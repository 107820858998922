import { createDateTime } from "ndr-designsystem";
import { TimeSeriesValue } from "../../fixed/FlexibilityConstraint/TimeSeriesValue";

export interface TimeSeriesValueDto {
    value: number,
    timestamp: string
}

export const fromDto = (dto: TimeSeriesValueDto): TimeSeriesValue => ({
    ...dto,
    timestamp: createDateTime(dto.timestamp)
})