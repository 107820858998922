import { FlexibilityConstraintDto,fromDto as fromFlexibilityConstraintDto } from "./FlexibilityConstraintDto";
import { TimeSeriesValueDto, fromDto as fromTimeSeriesValueDto } from "./TimeSeriesValueDto";
import { FlexibilityConstraintDetails } from "../../fixed/FlexibilityConstraint/FlexibilityConstraintDetails";


export interface FlexibilityConstraintDetailsDto extends FlexibilityConstraintDto {
    values: TimeSeriesValueDto[]
}

export const fromDto = (dto: FlexibilityConstraintDetailsDto): FlexibilityConstraintDetails => ({
    ...fromFlexibilityConstraintDto(dto),
    values: dto.values.map(val => fromTimeSeriesValueDto(val))
})
