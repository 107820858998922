import React, { ReactElement } from "react";
import { ToggleViewButton } from "../ToggleViewButton";
import useStyles from "../styles";
import { useAppSelector } from "../../../app/hooks";

interface Props {
    onCurrentlyViewingToggle: (val: any) => void
    currentlyViewing: "grid" | "chart"
}

const Toolbar = ({ onCurrentlyViewingToggle, currentlyViewing }: Props): ReactElement => {
    const classes = useStyles();
    const { currentGeneratedSensitivity, selectedTimeSeries } = useAppSelector(state => state.generatedSensitivity);

    if (currentGeneratedSensitivity == null || selectedTimeSeries === undefined) return <></>

    return <div className={classes.toolbar}>
        <ToggleViewButton view={currentlyViewing} onToggleView={onCurrentlyViewingToggle}/>
    </div>
}

export default Toolbar
