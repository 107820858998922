/**
 *
 * @export
 * @interface TechnicalResourceType
 */
export interface TechnicalResourceType {
    /**
     *
     * @type {string}
     * @memberof TechnicalResourceType
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof TechnicalResourceType
     */
    name?: string;
}


export const allowedCodes = ["SEE", "SSE"];

