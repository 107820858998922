import React, { ReactElement } from "react";
import { GridRowProps } from "@progress/kendo-react-grid";
import ReceivedMessage from "../../api/fixed/ConnectPlusMessage/ReceivedMessage";
import SentMessage from "../../api/fixed/ConnectPlusMessage/SentMessage";
import ReceivedMessageStatus from "../../api/fixed/ConnectPlusMessage/ReceivedMessageStatus";
import SentMessageStatus from "../../api/fixed/ConnectPlusMessage/SentMessageStatus";

const RowRender = (
    trElement: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps & { direction: "sent" | "received" },
): ReactElement => {
    const { dataItem, direction }: { dataItem: ReceivedMessage | SentMessage; direction: "sent" | "received" } = props;
    const green = { backgroundColor: "rgb(55, 180, 0,0.32)" };
    const red = { backgroundColor: "rgb(243, 23, 0, 0.32)" };
    const orange = { backgroundColor: "rgba(243,146,0,0.68)" };

    let trProps;
    if (direction === "received") {
        switch (dataItem.state as ReceivedMessageStatus) {
            case ReceivedMessageStatus.DecryptionFailed:
            case ReceivedMessageStatus.SchemaValidationFailed:
            case ReceivedMessageStatus.DataExtractionFailed:
            case ReceivedMessageStatus.AcknowledgementXmlGenerationFailed:
            case ReceivedMessageStatus.AcknowledgementEncryptionFailed:
            case ReceivedMessageStatus.AcknowledgementSendingFailed:
                trProps = { style: red };
                break;
            case ReceivedMessageStatus.Success:
            case ReceivedMessageStatus.AcknowledgementSent:
                trProps = { style: green };
                break;
            default:
                trProps = { style: orange };
                break;
        }
    } else {
        switch (dataItem.state as SentMessageStatus) {
            case SentMessageStatus.XmlGenerationFailed:
            case SentMessageStatus.EncryptionFailed:
            case SentMessageStatus.SendingFailed:
            case SentMessageStatus.MessageRejected:
                trProps = { style: red };
                break;
            case SentMessageStatus.MessageAccepted:
                trProps = { style: green };
                break;
            default:
                trProps = { style: orange };
                break;
        }
    }

    // Mark row as error (red) if there is errors to be displayed
    if (dataItem.errors && dataItem.errors.length > 0) trProps = { style: red };

    return React.cloneElement(
        trElement,
        // @ts-ignore
        { ...trProps },
        trElement.props.children,
    );
};

export default RowRender;
