import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    FlexibilityConstraintDto,
    fromDto as fromFlexibilityConstraintDto
} from "../../../../api/dtos/FlexibilityConstraint/FlexibilityConstraintDto";
import {
    FlexibilityConstraintDetailsDto,
    fromDto as fromFlexibilityConstraintDetailsDto
} from "../../../../api/dtos/FlexibilityConstraint/FlexibilityConstraintDetailsDto";
import { RootState } from "../../../../app/store";
import FlexibilityConstraint from "../../../../api/fixed/FlexibilityConstraint/FlexibilityConstraint";
import { FlexibilityConstraintDetails } from "../../../../api/fixed/FlexibilityConstraint/FlexibilityConstraintDetails";

export interface FlexibilityConstraintsState {
    allFlexibilityConstraints: FlexibilityConstraintDto[] | undefined,
    currentFlexibility: FlexibilityConstraintDetailsDto | undefined
}

const initialState: FlexibilityConstraintsState = {
    allFlexibilityConstraints: undefined,
    currentFlexibility: undefined
};

export const unavailabilitySlice = createSlice({
    name: 'flexibilityTimeSeries',
    initialState,
    reducers: {
        setAllFlexibilityConstraints: (state, action: PayloadAction<FlexibilityConstraintDto[] | undefined>) => {
            state.allFlexibilityConstraints = action.payload;
        },
        setCurrentFlexibilityConstraint: (state, action: PayloadAction<FlexibilityConstraintDetailsDto | undefined>) => {
            state.currentFlexibility = action.payload;
        }
    },
});

export const allFlexibilityConstraintsSelector = (state: RootState): FlexibilityConstraint[] | undefined => {
    if (state.flexibilityConstraints.allFlexibilityConstraints) {
        return state.flexibilityConstraints.allFlexibilityConstraints.map(pd => fromFlexibilityConstraintDto(pd));
    }
    return state.flexibilityConstraints.allFlexibilityConstraints;
};

export const currentFlexibilityConstraintSelector = (state: RootState): FlexibilityConstraintDetails | undefined => {
    if (state.flexibilityConstraints.currentFlexibility) {
        return fromFlexibilityConstraintDetailsDto(state.flexibilityConstraints.currentFlexibility)
    }

    return state.flexibilityConstraints.currentFlexibility;
}


export const { setAllFlexibilityConstraints, setCurrentFlexibilityConstraint } = unavailabilitySlice.actions;

export default unavailabilitySlice.reducer;