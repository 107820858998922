import React, { ReactElement, useMemo } from "react";
import { Autocomplete, Box, Card, CardActionArea, CardContent, Divider, TextField, Typography } from "@mui/material";
import { Build } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

const ControllableResourcesCard = (): ReactElement => {
    const { allResources } = useAppSelector((state: RootState) => state.controllableResources)
    const history = useHistory();
    const { t } = useTranslation("common");

    const preparedResources = useMemo(() => allResources?.map(res => ({
        label: res.name && res.name.length > 0 ? res.name : res.externalID,
        id: res.externalID
    })) ?? [], [allResources])

    return <Box display="flex" justifyContent="center" height="100%" width="100%">
        <Card sx={{ textAlign: "center", display: "flex", flexDirection: "column", flexGrow: 1, pb: 1 }}>
            <CardActionArea onClick={() => history.push("/controllable")}>
                <Build sx={{ fontSize: 50, pt: 2 }}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {t('home.controllable_resources_list')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('home.controllable_resources_list_details')}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <Box paddingX={2} paddingBottom={2}>
                <Divider flexItem sx={{ marginBottom: 1 }}/>
                <Autocomplete
                    openOnFocus
                    onChange={(event, newValue) => {
                        if (newValue) {
                            history.push(`/controllable/${newValue.id}`)
                        }
                    }}
                    options={preparedResources}
                    renderInput={(params) => <TextField {...params} label="ID"/>}
                />
            </Box>
        </Card>
    </Box>
}

export default ControllableResourcesCard
