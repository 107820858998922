import React, { ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { GridRowClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { GridField, GridFieldActionsCell } from "ndr-designsystem";
import { GridFieldElement, ValidationResult } from "ndr-designsystem/dist/utils";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { deleteAccountingGroupBalancingSchedule, fetchAccountingGroupBalancingSchedules } from "../store/thunks";
import { setCurrentPage } from "../../appStateSlice";

const ListAccountingGroupBalancingSchedules: React.FC<{}> = () => {
    const { t } = useTranslation("tables");
    const { allAccountingGroups } = useAppSelector((state) => state.accountingGroupBalancingSchedules);
    const history = useHistory();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAccountingGroupBalancingSchedules(true));
        dispatch(setCurrentPage("AccountingGroupBalancingScheduleList"));
    }, [dispatch]);

    const edit = (inventoryItemId: any): void => {
        history.push(`/accountingGroups/${inventoryItemId}`);
    };

    const onRowClick = (e: GridRowClickEvent): void => {
        edit(e.dataItem.inventoryItemId);
    };

    const forceRefresh = async (): Promise<void> => {
        dispatch(fetchAccountingGroupBalancingSchedules(true));
    };

    const Commands = (props: GridCellProps): ReactElement => {
        const { dataItem } = props;
        const isElementValid: ValidationResult = {
            valid: true,
            error: null,
        };
        return (
            <GridFieldActionsCell
                {...props}
                inEdit={false}
                validationResult={isElementValid}
                edit={(): void => {
                    if (dataItem) edit(dataItem.inventoryItemId!);
                }}
                remove={(): void => {
                    if (dataItem) dispatch(deleteAccountingGroupBalancingSchedule(dataItem.inventoryItemId ?? ""));
                }}
            />
        );
    };

    const gridFields = useMemo(
        (): GridFieldElement[] => [
            {
                title: t("accounting_group_balancing_schedules_list.externalID"),
                name: "controllableResource.externalId",
            },
            {
                title: t("accounting_group_balancing_schedules_list.name"),
                name: "controllableResource.name",
            },
            {
                title: t("accounting_group_balancing_schedules_list.requestingGridOperatorsCount"),
                name: "requestingGridOperators.length",
            },
            {
                customCell: Commands,
                exactWidth: 150,
            },
        ],
        [t],
    );

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            addButtonText={t("toolbar_buttons.add")}
            defaultOpen
            isEditing
            values={allAccountingGroups}
            onAddNew={() => {
                history.push("/accountingGroups/add");
            }}
            onRefresh={() => forceRefresh()}
            onRowClick={onRowClick}
            dataItemKey="inventoryItemId"
            gridFields={gridFields}
        />
    );
};

export default ListAccountingGroupBalancingSchedules;
