import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";

interface Props {
    dataItem: {
        createdControllableResources: string[], deletedControllableResources: string[], updatedControllableResources: string[],
        recreatedControllableResources: string[], [prop: string]: any
    }
}

const UpdatePrognosisModelsTaskView = ({ dataItem }: Props): ReactElement => {
    const allResources = useAppSelector(state => state.controllableResources.allResources) || [];
    const { t } = useTranslation("tables");
    const resourcesMap: { [id: string]: ControllableResource } = {};
    allResources.filter(x => !!x.inventoryItemId).forEach(x => { resourcesMap[x.inventoryItemId!] = x });

    const result: ReactElement[] = [];

    function getExternalIds(internalIds: string[]): string {
        return internalIds.map(x => resourcesMap[x]?.externalID).join(', ');
    }

    function format(internalIds: string[], translationKey: string): void {
        if (internalIds?.length) {
            result.push(<p>{t(`tasks.update_prognosis_models.${translationKey}`)}{getExternalIds(internalIds)}</p>);
        }
    }

    format(dataItem.createdControllableResources, 'created');
    format(dataItem.deletedControllableResources, 'deleted');
    format(dataItem.recreatedControllableResources, 'recreated');
    format(dataItem.updatedControllableResources, 'updated');

    return <b>{result}</b>;
}

export default UpdatePrognosisModelsTaskView
