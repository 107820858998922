import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDateTime } from "ndr-designsystem";
import { cloneDeep, differenceWith, isEqual } from "lodash";
import { RootState } from "../../../../app/store";
import Api from "../../../../api/Api";
import { removeNulls } from "../../../../utils";
import SensitivityDto, { toCreateDto as toSensitivityCreateDto } from "../../../../api/dtos/Sensitivity/SensitivityDto";

export const fetchSensitivities = createAsyncThunk(
    "sensitivity/fetchByControllableResourceId",
    async ({ id, force }: { id: string, force: boolean }, { getState }) => {
        const state = getState() as RootState;
        let sensitivites: SensitivityDto[] = [];

        if (state.sensitivities.allSensitivities !== undefined && state.sensitivities.allSensitivities.length > 0 && !force) {
            sensitivites = [...state.sensitivities.allSensitivities];
        } else {
            const response = await Api.fetchSensitivities(id);
            if (response.status === 200) {
                response.data.up = response.data.up.map(sens => ({
                    ...sens,
                    direction: {
                        code: "A01",
                    }
                }))
                response.data.down = response.data.down.map(sens => ({
                    ...sens,
                    direction: {
                        code: "A02",
                    }
                }))
                const allSensitivities = [...response.data.up, ...response.data.down];

                sensitivites = allSensitivities.map(sens => removeNulls(sens));
            }
        }
        return sensitivites;
    }
)

export const createSensitivity = createAsyncThunk(
    "sensitivity/create",
    async ({ id, data }: { id: string, data: SensitivityDto }) => {
        const response = await Api.createSensitivity(id, toSensitivityCreateDto(data));
        if (response.status === 200) {
            return cloneDeep({
                id: response.data.sensitivityId,
                ...data,
                gridElementSensitivities: data.gridElementSensitivities.map((g, index) => ({
                    ...g,
                    id: response.data.gridElementSensitivitiesIds[index]
                })),
                dispatches: []
            });
        }
        return null;
    }
)

export const updateSensitivity = createAsyncThunk(
    "sensitivity/update",
    async ({
               id,
               data,
           }: { id: string, data: SensitivityDto }, { getState }): Promise<SensitivityDto | null> => {
        const state = getState() as RootState;

        let originalSensitivity = state.sensitivities.allSensitivities?.find(s => s.id === data.id);
        if (!originalSensitivity) return null;
        originalSensitivity = { ...originalSensitivity }

        const hasDifferentInterval = createDateTime(originalSensitivity.intervalStart!).toMillis() !== createDateTime(data.intervalStart!).toMillis() ||
            createDateTime(originalSensitivity.intervalEnd!).toMillis() !== createDateTime(data.intervalEnd!).toMillis();
        const allPromises: Promise<any>[] = [];

        if (hasDifferentInterval) {
            allPromises.push(Api.updateSensitivityInterval(id, data.id!, {
                intervalStart: data.intervalStart,
                intervalEnd: data.intervalEnd
            }))
            originalSensitivity.intervalEnd = data.intervalEnd
            originalSensitivity.intervalStart = data.intervalStart
        }

        const notActiveGridConnectionPoints = differenceWith(originalSensitivity.gridElementSensitivities, data.gridElementSensitivities, isEqual);
        const newGridConnectionPoints = differenceWith(data.gridElementSensitivities, originalSensitivity.gridElementSensitivities, isEqual)

        notActiveGridConnectionPoints.forEach(g => {
            allPromises.push(Api.deleteSensitivityGridConnectionPoint(id, data.id!, g.id!));
        })

        // eslint-disable-next-line no-restricted-syntax
        for (const g of newGridConnectionPoints) {
            // eslint-disable-next-line no-await-in-loop
            const newSensId = await Api.createSensitivityGridConnectionPoint(id, data.id!, ({
                ...g,
                gridElementCodingScheme: g.gridElementCodingScheme.code
            }));
            g.id = newSensId.data;
        }

        await Promise.all(allPromises);

        return cloneDeep({
            ...originalSensitivity,
            gridElementSensitivities: originalSensitivity.gridElementSensitivities.filter(s => notActiveGridConnectionPoints.find(s2 => s2.id === s.id) === undefined).concat(newGridConnectionPoints)
        });
    }
)

export const deleteSensitivity = createAsyncThunk(
    "sensitivity/delete",
    async ({ inventoryId, id }: { inventoryId: string, id: string }) => {
        const response = await Api.deleteSensitivity(inventoryId, id);
        return response;
    }
)

