import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { toLocaleDate } from "ndr-designsystem";
import { DateTime } from "luxon";
import { useAppSelector } from "../../../app/hooks";
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";

interface Props {
    dataItem: {
        controllableResources: string[], date: string, [prop: string]: any
    }
}

const ImportPrognosisTaskView = ({ dataItem }: Props): ReactElement => {
    const allResources = useAppSelector(state => state.controllableResources.allResources) || [];
    const { t, i18n } = useTranslation("tables");
    const resourcesMap: { [id: string]: ControllableResource } = {};
    allResources.filter(x => !!x.inventoryItemId).forEach(x => { resourcesMap[x.inventoryItemId!] = x });

    function getExternalIds(internalIds: string[]): string {
        return internalIds.map(x => resourcesMap[x]?.externalID).join(', ');
    }

    return <b>{t(`tasks.import_prognosis`, {
        externalIDs: getExternalIds(dataItem.controllableResources),
        date: toLocaleDate(dataItem.date, i18n.language, DateTime.DATE_SHORT)
    })}</b>
}

export default ImportPrognosisTaskView;
