import React, { ReactElement, useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridField } from "ndr-designsystem";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import DateCell from "../../../../../Components/DateCell";
import {
    allGeneratedCostInfoSelector,
    setSendingStatusGeneratedCostInfo
} from "../../../../timeseries/generatedCostInfo/store/store";
import { fetchAllGeneratedCostInfo } from "../../../../timeseries/generatedCostInfo/store/thunks";
import ConfigurationToolbar, { ConfigurationProps } from "../../../../../Components/ConfigurationToolbar";


const GeneratedCostInfoTable = ({ currentlyViewing, onViewChange }: ConfigurationProps): ReactElement => {
    const allGeneratedCostInfo = useAppSelector(allGeneratedCostInfoSelector, (left, right) => left?.length === right?.length);
    const { currentResource } = useAppSelector(state => state.controllableResources);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation('tables');

    useEffect(() => {
        if (allGeneratedCostInfo === undefined && currentResource != null) {
            dispatch(fetchAllGeneratedCostInfo({ id: currentResource.inventoryItemId!, type: "resource", force: false, take: 100000 }));
        }
    }, [allGeneratedCostInfo, currentResource, dispatch])


    const columns = useMemo(() => [
        {
            name: "date",
            title: t("timeSeries.date"),
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="date"
                displayFormat={DateTime.DATE_SHORT}
            />
        }, {
            name: "timeSeries.length",
            title: t("timeSeries.no_contained_resources")
        },
        {
            title: t("controllable_resources_details.sensitivities.view_status"),
            customCell: (props: GridCellProps): ReactElement => <td><Button color="secondary" onClick={() => {
                dispatch(setSendingStatusGeneratedCostInfo(props.dataItem.dispatchId))
            }}>{t('controllable_resources_details.sensitivities.view_status_button')}</Button></td>
        }
    ], [t, dispatch])

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            defaultOpen
            dataItemKey="internalID"
            values={allGeneratedCostInfo}
            customToolbar={<ConfigurationToolbar
                currentlyViewing={currentlyViewing}
                onViewChange={onViewChange}/>}
            onRowClick={event => history.push(`/controllable/${currentResource?.externalID}/cost-info/${event.dataItem.dispatchId}`)}
            gridFields={columns}
        />
    )
}

export default GeneratedCostInfoTable
