import { GridCellProps } from "@progress/kendo-react-grid";
import React, { ReactElement } from "react";
import { GridFieldActionsCell } from "ndr-designsystem";
import { deleteMarketPartner } from "../../store/thunks";
import { setAddNewMarketPartnerData } from "../../../appStateSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

const ActionsCell = (props: GridCellProps): ReactElement => {
    const { allPartners } = useAppSelector(state => state.marketPartners)
    const dispatch = useAppDispatch();

    const remove = (index: number): void => {
        if (allPartners)
            dispatch(deleteMarketPartner(allPartners[index].inventoryItemId ?? ""))
    }

    const edit = (index: number): void => {
        if (!allPartners) return;
        const mp = allPartners[index]
        dispatch(setAddNewMarketPartnerData({
            show: true,
            type: undefined,
            fieldPath: undefined,
            elementIndex: 0,
            data: mp
        }))
    }
    return <GridFieldActionsCell {...props}
                                 remove={remove}
                                 edit={edit}/>
}

export default ActionsCell

