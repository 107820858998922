enum SentMessageStatus {
    Initial = 0,
    Created,
    XmlGenerated,
    XmlGenerationFailed,
    Encrypted,
    EncryptionFailed,
    PendingAck,
    SendingFailed,
    MessageRejected,
    MessageAccepted
}


export default SentMessageStatus;