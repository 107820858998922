import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { RootState } from "../../../../app/store";
import Api from "../../../../api/Api";
import { removeNulls } from "../../../../utils";
import { FlexibilityConstraintDto, } from "../../../../api/dtos/FlexibilityConstraint/FlexibilityConstraintDto";
import {
    FlexibilityConstraintDetailsDto
} from "../../../../api/dtos/FlexibilityConstraint/FlexibilityConstraintDetailsDto";
import { setAllFlexibilityConstraints, setCurrentFlexibilityConstraint } from "./slice";
import { fetchAllControlGroupsAndResources } from "../../../appStateSlice";
import { fetchControllableResourceByIdAction } from "../../../controllableResources/store/thunks";

export const fetchFlexibilityConstraintsByResource = createAsyncThunk(
    "networkConstraints/fetchByControllableResourceId",
    async ({ id, force }: { id: string, force: boolean }, { getState, dispatch }) => {
        let response: FlexibilityConstraintDto[] | undefined;
        const state = getState() as RootState;

        response = state.flexibilityConstraints.allFlexibilityConstraints;

        if (!response || response.length === 0 || force) {
            const allNetworkConstraints = (await Api.fetchFlexibilityConstraints(id)).data;
            response = orderBy(allNetworkConstraints.map(data => removeNulls(data)), ['date'], ['desc']);
        }

        dispatch(setAllFlexibilityConstraints(response));
    }
)

export const fetchFlexibilityConstraintsDetails = createAsyncThunk(
    "networkConstraints/fetchDetails",
    async ({ id, force }: { CRID: string, id: string, force: boolean }, { getState, dispatch }) => {
        let response: FlexibilityConstraintDetailsDto | undefined;
        const state = getState() as RootState;

        response = state.flexibilityConstraints.currentFlexibility;

        if (!response || force) {
            response = removeNulls((await Api.fetchFlexibilityConstraintDetails(id)).data);
        }

        dispatch(setCurrentFlexibilityConstraint(response));
    }
)

export const resetFlexibilityConstraintsState = createAsyncThunk(
    "networkConstraints/resetState",
    async (_: never, { dispatch }) => {
        await dispatch(setCurrentFlexibilityConstraint(undefined))
    })

export const buildFlexibilityConstraintsState = createAsyncThunk(
    "networkConstraints/buildState",
    async ({
               controllableId,
               seriesId,
               force
           }: { controllableId: string, seriesId: string, force: boolean }, { dispatch }) => {
        await dispatch(fetchAllControlGroupsAndResources({ force, fetchResources: true, fetchGroups: false }))

        const controllableResource = await dispatch(fetchControllableResourceByIdAction({
            id: controllableId,
            force,
            setAsCurrent: true
        })).unwrap();

        if (controllableResource && controllableResource.inventoryItemId) {
            await dispatch(fetchFlexibilityConstraintsByResource({ id: controllableResource.inventoryItemId, force }));
            await dispatch(fetchFlexibilityConstraintsDetails({
                CRID: controllableResource.inventoryItemId,
                id: seriesId,
                force
            }));
        }
    }
)
