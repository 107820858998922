import { createDateTime } from "ndr-designsystem";
import PhoneCallEventDto, { fromDto as fromPhoneCallEventDto } from "./PhoneCallEventDto";
import PhoneCall from "../../fixed/Activations/PhoneCall";

export default interface PhoneCallDto {
    id: string
    requestedAt: string
    toNumber: string
    events: PhoneCallEventDto[]
}

export const fromDto = (dto: PhoneCallDto): PhoneCall => ({
    ...dto,
    requestedAt: createDateTime(dto.requestedAt),
    events: dto.events.map(e => fromPhoneCallEventDto(e))
})