import React, { ReactElement } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useAppDispatch } from "app/hooks";
import { RootState } from "app/store";
import { setAddNewIndividualQuota } from "features/appStateSlice";
import { deleteIndividualQuota } from "features/individualQuotes/store/thunks";
import { GridFieldActionsCell } from "ndr-designsystem";

import { useSelector } from "react-redux";
import { setAllIndividualQuotas } from "features/individualQuotes/store/individualQuotesSlice";

const ActionsCell = (props: GridCellProps): ReactElement => {
    const { currentResource: resource } = useSelector((state: RootState) => state.controllableResources);
    const individualQuotas = useSelector((state: RootState) => state.individualQuotas.allIndividualQuotas);
    const dispatch = useAppDispatch();

    const remove = (index: number): void => {
        if (individualQuotas === undefined) return;
        const data = [...individualQuotas];
        const element = data[index];
        data.splice(index, 1);
        dispatch(setAllIndividualQuotas(undefined));
        dispatch(
            deleteIndividualQuota({
                id: resource?.inventoryItemId!,
                individualQuotaId: element.inventoryItemId!,
            }),
        ).then(() => dispatch(setAllIndividualQuotas(data)));
    };

    const edit = (index: number): void => {
        if (!individualQuotas) return;
        const individualQuota = individualQuotas[index];
        dispatch(
            setAddNewIndividualQuota({
                show: true,
                data: individualQuota,
                id: individualQuota.inventoryItemId!,
            }),
        );
    };
    return <GridFieldActionsCell {...props} remove={remove} edit={edit} />;
};

export default ActionsCell;
