import { orderBy } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import Api from "../../../../api/Api";
import { removeNulls } from "../../../../utils";
import { selectFirstTimeSeriesInfo, setAllGeneratedCostInfo, setCurrentGeneratedCostInfo } from "./store";
import { fetchAllControlGroupsAndResources } from "../../../appStateSlice";
import { ControllableResource } from "../../../../api/fixed/ControllableResource/ControllableResource";
import { ControlGroup } from "../../../../api/fixed/ControlGroup/ControlGroup";
import { fetchControllableResourceByIdAction } from "../../../controllableResources/store/thunks";
import { fetchControlGroupById } from "../../../ControlGroups/store/thunks";
import GeneratedCostInfoDto from "../../../../api/dtos/GeneratedCostInfo/GeneratedCostInfoDto";
import GeneratedCostInfoTimeSeriesDto from "../../../../api/dtos/GeneratedCostInfo/GeneratedCostInfoTimeSeriesDto";
import GeneratedCostInfoDetailsDto from "../../../../api/dtos/GeneratedCostInfo/GeneratedCostInfoDetailsDto";

export const fetchAllGeneratedCostInfo = createAsyncThunk(
    "generatedCostInfo/fetchAll",
    async ({ type, id, force, take }: { type: "resource" | "group", id: string, force: boolean, take: number }, { getState, dispatch }) => {
        let response: GeneratedCostInfoDto[] | undefined = [];
        const state = getState() as RootState;

        response = state.generatedCostInfo.allGeneratedCostInfo;

        if (response === undefined || force) {
            response = (await Api.fetchAllGeneratedCostInfo(id, type, undefined, take)).data;
            response = orderBy(response.filter(data => data.timeSeries.length > 0).map(data => removeNulls(data)), ['date'], ['desc']);
        }

        dispatch(setAllGeneratedCostInfo(response));
        return response;
    })

export const fetchGeneratedCostInfo = createAsyncThunk(
    "generatedCostInfo/fetch",
    async ({
               allSeries,
               force,
               dispatchId
           }: { type: "resource" | "group", dispatchId: string, force: boolean, allSeries: string[] }, {
               getState,
               dispatch
           }) => {
        let response: GeneratedCostInfoDetailsDto | undefined;
        const state = getState() as RootState;

        if (!force && state.generatedCostInfo.currentGeneratedCostInfo) {
            response = state.generatedCostInfo.currentGeneratedCostInfo;
        } else {
            const timeSeries: GeneratedCostInfoTimeSeriesDto[] = [];
            await Promise.all(allSeries.map(async s => {
                timeSeries.push(removeNulls((await Api.fetchDispatchTimeSeries(dispatchId, s)).data))
            }))

            response = {
                ...state.generatedCostInfo.allGeneratedCostInfo!.find(d => d.dispatchId === dispatchId)!,
                timeSeries
            };
        }

        dispatch(setCurrentGeneratedCostInfo(response))
        return response;
    }
)

export const resetGeneratedCostInfoState = createAsyncThunk(
    "generatedCostInfo/reset",
    async (_, { dispatch }) => {
        await dispatch(setAllGeneratedCostInfo(undefined))
        await dispatch(setCurrentGeneratedCostInfo(undefined))
        await dispatch(selectFirstTimeSeriesInfo())
    }
)

export const buildGeneratedCostInfoState = createAsyncThunk(
    "generatedCostInfo/buildState",
    async ({
               id,
               type,
               force,
               dispatchId
           }: { id: string, type: "resource" | "group", force: boolean, dispatchId: string }, { dispatch, getState }) => {
        await dispatch(fetchAllControlGroupsAndResources({
            force,
            fetchResources: type === "resource",
            fetchGroups: type === "group"
        }))
        let item: ControllableResource | ControlGroup | undefined;

        if (type === "resource") {
            item = await dispatch(fetchControllableResourceByIdAction({
                id,
                force,
                setAsCurrent: true
            })).unwrap();
        } else {
            item = await dispatch(fetchControlGroupById({
                id,
                force,
                setAsCurrent: true
            })).unwrap();
        }

        if (item && item.inventoryItemId) {
            await dispatch(fetchAllGeneratedCostInfo({ id: item.inventoryItemId, type, force, take:100000 }));
            const currentGeneratedCostInfo = (getState() as RootState).generatedCostInfo.allGeneratedCostInfo?.find(c => c.dispatchId === dispatchId);
            await dispatch(fetchGeneratedCostInfo({
                type,
                dispatchId,
                allSeries: currentGeneratedCostInfo?.timeSeries ?? [],
                force
            }));
            await dispatch(selectFirstTimeSeriesInfo())
        }
    }
)
