import CodeNameProp from "../../fixed/CodeNameProp";

import GeneratedSensitivityTimeSeries from "../../fixed/GeneratedSensitivity/GeneratedSensitivityTimeSeries";
import GeneratedSensitivityTimeSeriesValueDto, {
    fromDto as fromGeneratedSensitivityTimeSeriesValueDto
} from "./GeneratedSensitivityTimeSeriesValueDto";

export default interface GeneratedSensitivityTimeSeriesDto {
    businessType: CodeNameProp
    direction: CodeNameProp
    id: string
    measurementUnit: CodeNameProp
    status: CodeNameProp
    gridElementCode: string,
    gridElementCodingScheme: CodeNameProp
    values: GeneratedSensitivityTimeSeriesValueDto[]
}

export const fromDto = (dto: GeneratedSensitivityTimeSeriesDto): GeneratedSensitivityTimeSeries => ({
    ...dto,
    values: dto.values.map(v => fromGeneratedSensitivityTimeSeriesValueDto(v)),
})
