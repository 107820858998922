import { TechnicalResource } from "../../fixed/TechnicalResource/TechnicalResource";

export interface TechnicalResourceDto {
    externalID?: string
    name?: string
    marketMasterDataRegistryId?: string
    technicalResourceType?: string
    powerPlantCode?: string
    assignedStorages?: string[]
    productionId?: string
    consumptionId?: string
    eegUnitCodes?: string[]
    billingModel?: string
    operatorId?: string
    preliminaryDecommissioningDateReached?: boolean
    finalDecommissioningDateReached?: boolean
    productionNetRatedCapacity?: number
    consumptionNetRatedCapacity?: number
    productionNetBottleneckCapacity?: number
    consumptionNetBottleneckCapacity?: number
    grossRatedCapacity?: number
    accumulatedInverterPower?: number
    reduction70Percent?: boolean
    plantType?: string
    hubHeight?: number
    longitude?: number
    latitude?: number
    efficiencyOfEnergyStorage?: number
    usableEnergyContentOfEnergyStorage?: number
    maxEffectiveStoringCapacity?: number
    maxEffectiveRetrievingCapacity?: number
    revision?: number
    incline?: number
    direction?: number
    shutdownWindSpeed?: number
    netRatedCapacityWindSpeed?: number
    windPowerPoints?: {
        speed: number,
        power: number
    }[]
}

export const toDto = (original: TechnicalResource): TechnicalResourceDto => {
    const backup = { ...original }

    delete backup.billingModel
    delete backup.technicalResourceType

    delete backup.production;
    delete backup.consumption;
    delete backup.producesEnergy;
    delete backup.consumesEnergy;

    delete backup.inventoryItemId
    delete backup.windPowerCurve

    return {
        ...backup,
        productionId: original.production?.inventoryItemId,
        consumptionId: original.consumption?.inventoryItemId,
        technicalResourceType: original.technicalResourceType?.code,
        billingModel: original.billingModel?.code,
        shutdownWindSpeed: original.windPowerCurve?.shutdownWindSpeed,
        netRatedCapacityWindSpeed: original.windPowerCurve?.netRatedCapacityWindSpeed,
        windPowerPoints: original.windPowerCurve?.elements?.map(v => ({ speed: v.speed, power: v.value ?? 0 }))
    };
}
