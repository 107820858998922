import { Box } from "@mui/material";
import { SentimentVeryDissatisfied } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setCurrentPage } from "../../features/appStateSlice";

const PageNotFound = (): ReactElement => {
    const { t } = useTranslation("common")
    const { pageNotFound } = useAppSelector(state => state.appState)
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("Home"))
    }, [dispatch])

    return <Box
        style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            userSelect: "none"
        }}>
        <Box style={{ textAlign: "center" }}>
            <SentimentVeryDissatisfied color="primary" style={{ fontSize: 200 }}/>
            <Typography fontWeight={500} fontSize={20}>
                {t(`page_not_found.${pageNotFound}`).toUpperCase()}
            </Typography>
        </Box>
    </Box>
}

export default PageNotFound