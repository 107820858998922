import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    match: { path: string }
}

const ListBreadcrumb = ({ match }: Props): ReactElement => {
    const { t } = useTranslation("common")
    let content: string = "";
    if (match.path === "/controllable") {
        content = "controllable_resources_list"
    } else if (match.path === "/controlGroups") {
        content = "control_groups_list"
    } else if (match.path === "/market-partners") {
        content = "market_partners_list"
    } else if (match.path === "/dashboard") {
        content = "dashboard"
    } else if (match.path === "/") {
        content = "home"
    } else if (match.path === "/tasks") {
        content = "tasks"
    } else if (match.path === "/avat-errors") {
        content = "avat_errors"
    } else if (match.path === "/messages") {
        content = "all_messages";
    } else if (match.path === "/accountingGroups") {
        content = "accounting_group_balancing_schedule_list"
    }
    return <>{t(`breadcrumbs.${content}`)}</>
}

export default ListBreadcrumb
