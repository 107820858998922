import CodeNameProp from "../../fixed/CodeNameProp";
import GeneratedCostInfoTimeSeriesValueDto, {
    fromDto as fromGeneratedCostInfoTimeSeriesValueDto
} from "./GeneratedCostInfoTimeSeriesValueDto";
import GeneratedCostInfoTimeSeries from "../../fixed/GeneratedCostInfo/GeneratedCostInfoTimeSeries";

export default interface GeneratedCostInfoTimeSeriesDto {
    businessType: CodeNameProp
    direction: CodeNameProp
    id: string
    measurementUnit: CodeNameProp
    status: CodeNameProp
    values: GeneratedCostInfoTimeSeriesValueDto[]
}

export const fromDto = (dto: GeneratedCostInfoTimeSeriesDto): GeneratedCostInfoTimeSeries => ({
    ...dto,
    values: dto.values.map(v => fromGeneratedCostInfoTimeSeriesValueDto(v))
})
