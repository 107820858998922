import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Field, GeneralSimpleElementTable, isFieldValid, isSchemaValid } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import { PageStatus } from "../../utils/types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { MarketLocationSchema, MeasurementLocationSchema } from "../../utils/inputChecking";
import { allowedCodes as allowedVoltageLevelCodes } from "../../api/fixed/MarketLocation/VoltageLevel";
import { allowedCodes as allowedVoltageTransformationCodes } from "../../api/fixed/MarketLocation/VoltageTransformation";
import { MarketLocation } from "../../api/fixed/MarketLocation/MarketLocation";
import {
    buildMarketLocationGridActionsByField,
    FieldForActions,
    FieldWithBuiltActions,
    MarketLocationSubGridActions
} from "./utils";
import { buildMarketPartnerText, getMarketPartnersId, transformToSelectEntry } from "../../utils";
import SchemaConstants from "../../utils/schemaConsts";
import { setAddNewMarketPartnerData, setNotificationAlertMessage } from "../appStateSlice";
import {
    setConsumptionMeasurementLocations,
    setProductionMeasurementLocations,
    updateNewConsumptionMeasurementLocation,
    updateNewProductionMeasurementLocation
} from "./store/technicalResourcesSlice";

const MarketLocationSubGrid = ({ pageStatus, field }: FieldWithBuiltActions): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT
    const isAdding = pageStatus === PageStatus.ADD

    const { currentResource: resource } = useAppSelector(state => state.technicalResources)
    const { allPartners: marketPartners } = useAppSelector(state => state.marketPartners)
    const [actions, setActions] = useState<MarketLocationSubGridActions>(buildMarketLocationGridActionsByField(field));
    // @ts-ignore pick our currentField
    const fieldValues: MarketLocation = resource[field];
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables")

    const allowedVoltageTransformationValues = useMemo(() => transformToSelectEntry(allowedVoltageTransformationCodes, t, "api:voltage_transformation"), [t])
    const allowedVoltageLevelValues = useMemo(() => transformToSelectEntry(allowedVoltageLevelCodes, t, "api:voltage_level"), [t])

    const prepareMarketPartnersList = useCallback(() => marketPartners?.map(value => ({
        id: `${value.inventoryItemId}`,
        text: buildMarketPartnerText(value)
    })) ?? [], [marketPartners])

    const supplierDefaultValue = useCallback(() => {
        if (fieldValues.supplierId) {
            const marketPartner = marketPartners?.find(p => p.inventoryItemId === fieldValues.supplierId)

            if (marketPartner) {
                return {
                    id: marketPartner.inventoryItemId,
                    text: buildMarketPartnerText(marketPartner)
                }
            }
            return undefined;
        }
        return undefined;
    }, [marketPartners, fieldValues.supplierId])

    const getMeasurementLocationsActions = useCallback(() => {
        if (field === FieldForActions.PRODUCTION)
            return {
                setElements: (e: string[] | undefined) => dispatch(setProductionMeasurementLocations(e)),
                updateNewElement: (e: string) => dispatch(updateNewProductionMeasurementLocation(e))
            }

        return {
            setElements: (e: string[] | undefined) => dispatch(setConsumptionMeasurementLocations(e)),
            updateNewElement: (e: string) => dispatch(updateNewConsumptionMeasurementLocation(e))
        }
    }, [field, dispatch]);

    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);

    const MarketLocationTabSchema = MarketLocationSchema(prepareMP());

    useEffect(() => {
        setActions(buildMarketLocationGridActionsByField(field));
    }, [field]);

    if (resource === undefined || resource === null) return <></>;

    return (
        <>
            {(!fieldValues.tranches || fieldValues.tranches.length === 0) &&
                <>
                    <Field
                        inputType="controlledText"
                        onValueChange={val => dispatch(actions.setAccountingGroup(val))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={accountingGroup => isFieldValid(MarketLocationTabSchema, [SchemaConstants.accountingGroup, SchemaConstants.tranches], {
                            accountingGroup,
                            tranches: fieldValues.tranches
                        })}
                        defaultValue={fieldValues.accountingGroup}
                        title={t("marketLocation.accountingGroup")}
                        description={t("marketLocation.accountingGroupDescription")}
                    />
                    <Field
                        allowAdd
                        onAddNewClick={() => {
                            dispatch(setAddNewMarketPartnerData({
                                show: true,
                                type: "technical",
                                fieldPath: `${field}.supplierId`,
                                data: undefined
                            }))
                        }}
                        onValueChange={val => dispatch(actions.setSupplier(val?.id))}
                        isEditing={isEditing || isAdding}
                        valueCheckFunction={supplier => isFieldValid(MarketLocationTabSchema, [SchemaConstants.supplierId, SchemaConstants.tranches], {
                            supplierId: supplier?.id,
                            tranches: fieldValues.tranches
                        })}
                        defaultValue={fieldValues.supplierId && supplierDefaultValue()}
                        title={t("marketLocation.supplier")}
                        description={t("marketLocation.supplierDescription")}
                        values={prepareMarketPartnersList()}
                        keyField="id"
                        textField="text"
                        inputType='select'
                    />
                </>
            }
            {/* <TranchesTable */}
            {/*     validationResult={isFieldValid(MarketLocationTabSchema, [SchemaConstants.tranches], { tranches: fieldValues.tranches })} */}
            {/*     pageStatus={pageStatus} field={field}/> */}
            <Field
                onValueChange={val => dispatch(actions.setVoltageLevel(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={voltageLevel => isFieldValid(MarketLocationTabSchema, [SchemaConstants.voltageLevel], { voltageLevel })}
                title={t("marketLocation.voltageLevel")}
                description={t("marketLocation.voltageLevelDescription")}
                values={allowedVoltageLevelValues}
                defaultValue={allowedVoltageLevelValues.find(f => f.code === fieldValues.voltageLevel?.code)}
                keyField="code"
                textField="name"
                inputType='select'
            />
            <Field
                onValueChange={val => dispatch(actions.setVoltageTransformation(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={voltageTransformation => isFieldValid(MarketLocationTabSchema, [SchemaConstants.voltageTransformation], { voltageTransformation })}
                title={t('marketLocation.voltageTransformation')}
                description={t('marketLocation.voltageTransformationDescription')}
                values={allowedVoltageTransformationValues}
                defaultValue={allowedVoltageTransformationValues.find(f => f.code === fieldValues.voltageTransformation?.code)}
                keyField="code"
                textField="name"
                inputType='select'
            />
            <GeneralSimpleElementTable
                canReorder={false}
                noRecordsText={t("no_records_text")}
                addButtonText={t('toolbar_buttons.add')}
                onMoreThanOneError={() => {
                    dispatch(setNotificationAlertMessage("alert_messages.one_by_one"))
                }}
                type="controlledText"
                title={t("marketLocation.measurementLocations")}
                elements={fieldValues.measurementLocations}
                actions={getMeasurementLocationsActions()}
                defaultElement=""
                defaultOpen
                valueCheckFunction={val => isSchemaValid(MeasurementLocationSchema(), val)}
                gridCheckFunction={measurementLocations => isFieldValid(MarketLocationTabSchema, [SchemaConstants.measurementLocations], { measurementLocations })}
                pageStatus={pageStatus}
            />
        </>
    )
}

export default MarketLocationSubGrid