import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Route, Switch, useLocation, withRouter } from "react-router-dom";
import ControllableResourcesList from "features/controllableResources/list/ControllableResourcesList";
import { Box } from "@mui/material";
import { useWindowDimensions } from "ndr-designsystem";
import AccountingGroupBalancingScheduleDetailsView from "features/accountingGroupBalancingSchedules/details/AccountingGroupBalancingScheduleDetailsView";
import ListAccountingGroupBalancingSchedules from "features/accountingGroupBalancingSchedules/list/ListAccountingGroupBalancingSchedules";
import Header from "../../Components/Header/Header";
import ControllableResourceDetailsView
    from "../../features/controllableResources/details/ControllableResourceDetailsView";
import TechnicalResourceDetailsView from "../../features/technicalResources/TechnicalResourceDetailsView";
import ListControlGroups from "../../features/ControlGroups/list/ListControlGroups";
import ControlGroupsDetails from "../../features/ControlGroups/ControlGroupsDetails";
import ImportExcelFiles from "../../features/ImportExcel/ImportExcelFiles";
import SimpleBreadcrumbs from "../../Components/Breadcrumbs/SimpleBreadcrumbs";
import AppAlerts from "./AppAlerts";
import PlanningDataView from "../../features/timeseries/planningData/DataView";
import UnavailabilitiesDataView from "../../features/timeseries/unavailability/DataView";
import HomeContent from "./HomeContent";
import useStyles from "./styles";
import NetworkConstraintsDataView from "../../features/timeseries/flexibilityConstraints/DataView";
import AllMessagesView from "../../features/ConnectPlusMsgs/AllMessagesView";
import { fetchCurrentTenantMarketPartnerData, fetchMarketPartners } from "../../features/marketpartner/store/thunks";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Dashboard from "../Dashboard";
import { setHeaderHeight } from "../../features/appStateSlice";
import ActivationsDataView from "../../features/timeseries/activations/DataView/DataView";
import DowntimeDataView from "../../features/timeseries/downtime/DataView";
import PageNotFound from "./PageNotFound";
import MarketPartnersList from "../../features/marketpartner/list/MarketPartnersList";
import TasksView from "../../features/tasks/TasksView";
import AvatErrorsList from "../../features/avatErrors/list/AvatErrorsList";
import GeneratedCostInfoDataView from "../../features/timeseries/generatedCostInfo/DataView";
import GeneratedSensitivitiesDataView from "../../features/timeseries/generatedSensitivities/DataView";

const Home = (): ReactElement => {
    const classes = useStyles();
    const location = useLocation();
    const { height, width } = useWindowDimensions();
    const { headerHeight, pageNotFound } = useAppSelector(state => state.appState)
    const dispatch = useAppDispatch();
    const [topOffset, setTopOffset] = useState(0);

    useEffect(() => {
        setTopOffset(((width ?? 0) / 1920) * 53)
    }, [headerHeight, width])

    const headerRef = useCallback(node => {
        if (node !== null) {
            dispatch(setHeaderHeight(node.clientHeight));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchMarketPartners(false));
        dispatch(fetchCurrentTenantMarketPartnerData(false));
    }, [dispatch])

    const isHome = useMemo(() => ["", "/"].includes(location.pathname) || location.pathname.includes("/get"), [location])

    const Background = useCallback(() => <Box sx={{
        position: "absolute",
        top: headerHeight ?? 0,
        left: 0,
        width: "100%",
        // eslint-disable-next-line
        backgroundImage: `url(${process.env.PUBLIC_URL + '/bgimg.png'}),url(${process.env.PUBLIC_URL + '/bgimg.jpg'})`,
        backgroundSize: "cover,cover",
        height: (height ?? 0) - (headerHeight ?? 0),
        zIndex: -2,
    }}/>, [headerHeight, height])

    const Logo = useCallback(() => <Box sx={{
        position: "absolute",
        top: (headerHeight ?? 0) + 20,
        right: 20,
        width: ((width ?? 0) / 1920) * 300,
        height: ((width ?? 0) / 1920) * 105,
        // eslint-disable-next-line
        backgroundImage: `url(${process.env.PUBLIC_URL + '/logo.png'})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        zIndex: -1,
    }}/>, [headerHeight, width])

    return (
        <Box className={classes.root}>
            <Header ref={headerRef} isHome={isHome}/>
            <Box marginX={isHome ? 0 : 2} display="flex" flex={1}>
                <Box display="flex" flexDirection="column" width="100%" height="100%">
                    {!isHome &&
                        <SimpleBreadcrumbs/>
                    }
                    {pageNotFound !== undefined && <PageNotFound/>}
                    {pageNotFound === undefined &&
                        <Switch>
                            <Route exact path="/importExcel">
                                <ImportExcelFiles/>
                            </Route>
                            <Route exact path="/market-partners">
                                <MarketPartnersList/>
                            </Route>
                            <Route exact path="/controlGroups">
                                <ListControlGroups/>
                            </Route>
                            <Route exact path="/controlGroups/:id">
                                <ControlGroupsDetails/>
                            </Route>
                            <Route exact path="/controlGroups/:id/generated-cost-info/:dispatchId">
                                <GeneratedCostInfoDataView/>
                            </Route>
                            <Route exact path="/controlGroups/:id/generated-sensitivities/:dispatchId">
                                <GeneratedSensitivitiesDataView/>
                            </Route>
                            <Route exact path="/controlGroups/:inventoryId/activations/:seriesId">
                                <ActivationsDataView/>
                            </Route>
                            <Route exact path="/controlGroups/:id/:page">
                                <ControlGroupsDetails/>
                            </Route>
                            <Route exact path="/controllable">
                                <ControllableResourcesList/>
                            </Route>
                            <Route exact path="/messages">
                                <AllMessagesView/>
                            </Route>
                            <Route exact path="/controllable/:controllableId/planning-data/:seriesId">
                                <PlanningDataView/>
                            </Route>
                            <Route exact path="/controllable/:controllableId/unavailability/:seriesId">
                                <UnavailabilitiesDataView/>
                            </Route>
                            <Route exact path="/controllable/:controllableId/network-constraints/:seriesId">
                                <NetworkConstraintsDataView/>
                            </Route>
                            <Route exact path="/controllable/:inventoryId/activations/:seriesId">
                                <ActivationsDataView/>
                            </Route>
                            <Route exact path="/controllable/:controllableId/resources/:technicalId">
                                <TechnicalResourceDetailsView/>
                            </Route>
                            <Route exact path="/controllable/:controllableId/resources/:technicalId/unavailability/:seriesId">
                                <UnavailabilitiesDataView/>
                            </Route>
                            <Route exact path="/controllable/:controllableId/resources/:technicalId/downtime/:seriesId">
                                <DowntimeDataView/>
                            </Route>
                            <Route exact path="/controllable/:id/cost-info/:dispatchId">
                                <GeneratedCostInfoDataView/>
                            </Route>
                            <Route exact path="/controllable/:id/sensitivities/:dispatchId">
                                <GeneratedSensitivitiesDataView/>
                            </Route>
                            <Route exact path="/controllable/:controllableId/resources/:technicalId/:page">
                                <TechnicalResourceDetailsView/>
                            </Route>
                            <Route exact path="/controllable/:id">
                                <ControllableResourceDetailsView/>
                            </Route>
                            <Route exact path="/controllable/:id/:page">
                                <ControllableResourceDetailsView/>
                            </Route>
                            <Route exact path="/accountingGroups">
                                <ListAccountingGroupBalancingSchedules/>
                            </Route>                           
                            <Route exact path="/accountingGroups/:id">
                                <AccountingGroupBalancingScheduleDetailsView/>
                            </Route>
                            <Route exact path="/accountingGroups/add">
                                <AccountingGroupBalancingScheduleDetailsView/>
                            </Route>
                            <Route exact path="/dashboard">
                                <Dashboard/>
                            </Route>
                            <Route exact path="/tasks">
                                <TasksView/>
                            </Route>
                            <Route exact path="/avat-errors">
                                <AvatErrorsList/>
                            </Route>
                            <Route exact path={["/", "/messages/:direction/:messageId/:type/get"]}>
                                <Logo/>
                                <Background/>
                                <Box sx={{ marginTop: `${topOffset}px`, marginX: 10 }}>
                                    <HomeContent/>
                                </Box>
                            </Route>
                            <Route path="">
                                <PageNotFound/>
                            </Route>
                        </Switch>
                    }
                </Box>
            </Box>
            <AppAlerts/>
        </Box>
    )
}

export default withRouter(Home);
