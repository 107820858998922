import { Direction } from "api/fixed/PlanningData/Direction";
import { MarketPartner } from "../api/fixed/MarketPartner";
import { BusinessType } from "../api/fixed/PlanningData/BusinessType";

export * from "./transitions";
export * from "./comparers";

export const TimeseriesPageSize = 10;
export const TimeSeriesButtonsCount = 4;

export const buildMarketPartnerText = (value: MarketPartner, withName = true): string => {
    let text = "";
    if (value.encoding && (value.encoding.name || value.encoding.code)) {
        if (value.encoding.code === "NDE")
            text += value.encoding.code;
        else
            text += value.encoding.name;
    }
    if (value.name && withName)
        text += `: ${value.code} (${value.name})`;
    else
        text += `: ${value.code}`;
    return text;
};

export const convertBusinessTypeAndDirection = (businessType: BusinessType | undefined, direction: Direction | undefined): string | undefined => {
    if (businessType === undefined) return undefined

    if (businessType.code === "A01") return "PROD";
    if (businessType.code === "A04") return "VERB";
    if (businessType.code === "A93") return "Pdar (Wind)"
    if (businessType.code === "A94") return "Pdar (Solar)"

    if (direction === undefined) return undefined;

    if (businessType.code === "A61" && direction.code === "A01") return "Pmax"
    if (businessType.code === "A60" && direction.code === "A01") return "Pmin"
    if (businessType.code === "A61" && direction.code === "A02") return "Vmax"
    if (businessType.code === "A60" && direction.code === "A02") return "Vmin"
    if (businessType.code === "A11" && direction.code === "A01") return "+PRL"
    if (businessType.code === "A11" && direction.code === "A02") return "-PRL"
    if (businessType.code === "A12" && direction.code === "A01") return "+SRL"
    if (businessType.code === "A12" && direction.code === "A02") return "-SRL"
    if (businessType.code === "A10" && direction.code === "A01") return "+MRL"
    if (businessType.code === "A10" && direction.code === "A02") return "-MRL"
    if (businessType.code === "A77" && direction.code === "A01") return "+RDV"
    if (businessType.code === "A77" && direction.code === "A02") return "-RDV"
    if (businessType.code === "A46" && direction.code === "A01") return "+RDA"
    if (businessType.code === "A46" && direction.code === "A02") return "-RDA"
    if (businessType.code === "Z05" && direction.code === "A02") return "-wRDV"
    if (businessType.code === "A79" && direction.code === "A01") return "+BES"
    if (businessType.code === "A79" && direction.code === "A02") return "-BES"

    return undefined
}

export const getMarketPartnersId = (marketPartners: MarketPartner[] | undefined): string[] => {
    if (marketPartners) {
        return marketPartners.map(mp => mp.inventoryItemId!);
    }
    return [];
}

export const transformToSelectEntry = (codes: string[] | number[], t: any, translationPrefix: string): { code: string, name: string }[] => codes.map(code => ({
    code: `${code}`,
    name: t(`${translationPrefix}.${(typeof code !== "number" ? code.toUpperCase() : code)}`)
}))

export const TITLE_BASE = "Redispatch 2.0"
export const areMarketPartnersSame = (mp1: MarketPartner | undefined, mp2: MarketPartner | undefined): boolean => mp1 != null && mp2 != null && mp1.code === mp2.code && mp1.encoding?.code === mp2.encoding?.code
export type ConnectPlusMessagesViewType = "sent" | "received"

export interface ConnectPlusMessageDirection {
    direction: ConnectPlusMessagesViewType
}
